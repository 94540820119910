import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, MissionDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export interface MissionGanttListXsStoreActions extends PagedEntitiesXsStoreActions<MissionDto> {
}

export class MissionGanttListXsStore extends PagedEntitiesXsStore<MissionDto> {
  public declare actions: MissionGanttListXsStoreActions;

  protected createActions(label: string): MissionGanttListXsStoreActions {
    return {
      ...super.createActions(label)
    }
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<MissionDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<MissionDto>, MissionGanttListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MissionDto) => MissionDto,
    prepareModel: (entity: MissionDto, model: MissionDto) => MissionDto,
    sanitizeModel: (model: MissionDto, entity: MissionDto) => MissionDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<MissionDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<MissionDto> {

      constructor(
        protected injector: Injector,
        private translate: TranslateService
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
