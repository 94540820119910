import {ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CombineOperator, FilterOperators, FilterTypes, TemplateContextType} from '@nexnox-web/core-shared';
import {CorePortalEntityCreatePresetService} from "@nexnox-web/core-portal";
import {TextTemplateListComponent} from "../../containers";
import {TemplateUsageTypes} from "../../models";

@Component({
  selector: 'nexnox-web-settings-templates-solution-text-list-wrapper',
  templateUrl: '../../containers/text-templates/text-template-list/text-template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionTextTemplateListWrapperComponent extends TextTemplateListComponent implements OnInit, OnDestroy {

  public templateUsageType = TemplateUsageTypes.Solution;

  constructor(
    protected injector: Injector,
    protected entityCreatePresetService: CorePortalEntityCreatePresetService
  ) {
    super(injector);

    this.defaultFilter = [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        property: 'context',
        children: [
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.NoteBase.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.NoteForTicket.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.NoteForMission.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.NoteForMissionByTicket.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.NoteForMissionByTask.toString()
          }
        ]
      }
    ];

    this.componentId = 'SolutionTextTemplateListWrapper';
  }

  public ngOnInit(): void {
    this.setWrapperCreatePreset();
    super.ngOnInit();
    this.detailLink = 'templates/solution-text-templates';
  }

  public ngOnDestroy(): void {
    this.isEmbedded = true;
    super.ngOnDestroy();
  }

  public setWrapperCreatePreset(): void {
    this.entityCreatePresetService.setPreset('TechPortalFeatureTextTemplateEditComponent', {
      context: TemplateContextType.NoteBase
    });
  }
}
