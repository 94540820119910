<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="title"
                        [stereotyped]="true">
</nexnox-web-entity-edit>

<ng-container *ngIf="(modelSubject | async) as model">
  <nexnox-web-cardbox paddingClasses="px-2 pb-3"
                      ghostPaddingClasses="p-3"
                      [title]="'core-shared.shared.fields.reason' | translate">
    <form [formGroup]="form">
      <formly-form class="form row"
                   [form]="form"
                   [model]="model"
                   [fields]="reasonFields"
                   (modelChange)="onModelChange($event)">
      </formly-form>
    </form>
  </nexnox-web-cardbox>
</ng-container>
