export * from './lib/resources.module';
export * from './lib/resources-declarations.module';
export {
  CorePortalFeatureResourcePathComponent,
  CorePortalFeatureResourceInventoryNumberComponent
} from './lib/components';
export {
  CorePortalFeatureResourceService,
  CorePortalFeatureResourceTaskResourceSelectionService,
  CorePortalFeatureResourceInheritableService
} from './lib/services';
export {
  TechPortalFeatureResourceDatatableSearchModalComponent
} from './lib/modals';

export * from './lib/sidebars';
