import {
  contractSettingsStore,
  ContractSettingsStoreState,
  resourcesByLocationStereotypeDetailStore,
  ResourcesByLocationStereotypeDetailStoreState,
  resourcesByLocationStereotypeListStore,
  ResourcesByLocationStereotypeListStoreState,
  stereotypeDetailStore,
  StereotypeDetailStoreState,
  stereotypeListStore,
  StereotypeListStoreState,
  ticketsByLocationStereotypeDetailStore,
  TicketsByLocationStereotypeDetailStoreState,
  ticketsByLocationStereotypeListStore,
  TicketsByLocationStereotypeListStoreState
} from './stores';

export interface StereotypesState {
  stereotypeList: StereotypeListStoreState;
  stereotypeDetail: StereotypeDetailStoreState;
  contractSettings: ContractSettingsStoreState;
  resourcesByLocationStereotypeList: ResourcesByLocationStereotypeListStoreState;
  resourcesByLocationStereotypeDetail: ResourcesByLocationStereotypeDetailStoreState;
  ticketsByLocationStereotypeList: TicketsByLocationStereotypeListStoreState;
  ticketsByLocationStereotypeDetail: TicketsByLocationStereotypeDetailStoreState;
}

export const getInitialStereotypesState = (): StereotypesState => ({
  stereotypeList: stereotypeListStore.getInitialState(),
  stereotypeDetail: stereotypeDetailStore.getInitialState(),
  contractSettings: contractSettingsStore.getInitialState(),
  resourcesByLocationStereotypeList: resourcesByLocationStereotypeListStore.getInitialState(),
  resourcesByLocationStereotypeDetail: resourcesByLocationStereotypeDetailStore.getInitialState(),
  ticketsByLocationStereotypeList: ticketsByLocationStereotypeListStore.getInitialState(),
  ticketsByLocationStereotypeDetail: ticketsByLocationStereotypeDetailStore.getInitialState()
});
