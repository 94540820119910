import {ChangeDetectionStrategy, Component, Injector, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalStereotypeService
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ConsumptionCodeDto,
  CrossCreationTypes,
  ErrorCodeDto,
  Filter,
  FilterDto,
  FilterOperators,
  FilterTypes,
  ResourceTaskConsumptionScheduleDto,
  ResourceTaskConsumptionTriggerType,
  ResourceTaskDto,
  ResourceTaskErrorScheduleDto,
  ResourceTaskFixedScheduleDto,
  ResourceTaskOffsetPatternDto,
  ResourceTaskPatternType,
  ResourceTaskScheduleType,
  ResourceTaskSelectorDto,
  SteppedResourceTaskConsumptionTriggerDto,
  StereotypeDto,
  TemplateContextType,
  TemplateDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {of} from 'rxjs';
import {TechPortalFeatureTextTemplateService} from '@nexnox-web/tech-portal/features/templates';
import {TranslateService} from '@ngx-translate/core';
import {
  resourceTaskConsumptionScheduleLookBackIntervalEnumOptions,
  resourceTaskConsumptionScheduleTypeEnumOptions,
  resourceTaskPatternTypeEnumOptions,
  resourceTaskScheduleTypeEnumOptions
} from '../../models';
import {map, mergeMap} from 'rxjs/operators';
import {
  TechPortalFeatureTicketSettingsPriorityService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {timespanTo} from '@nexnox-web/lodash';
import dayjs from 'dayjs';
import {formatDate} from 'ngx-bootstrap/chronos';
import {TechPortalFeatureConnectedConsumptionCodeService} from '../../../../../connected/consumption-codes';
import {TechPortalFeatureConnectedErrorCodeService} from '../../../../../connected/error-codes';
import {isFinite} from 'lodash';

@Component({
  selector: 'nexnox-web-resource-tasks-resource-task-edit',
  templateUrl: './resource-task-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureResourceTaskEditComponent extends CorePortalEntityEditBaseComponent<ResourceTaskDto> {
  @ViewChild('consumptionCodeSelectLabelTitleTemplate', {static: true})
  public consumptionCodeSelectLabelTitleTemplate: TemplateRef<any>;

  @ViewChild('consumptionCodeSelectOptionTitleTemplate', {static: true})
  public consumptionCodeSelectOptionTitleTemplate: TemplateRef<any>;

  public isResourceFilter: boolean;

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private stereotypeService: CorePortalStereotypeService,
    private templateService: TechPortalFeatureTextTemplateService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private bsLocaleService: BsLocaleService,
    private consumptionCodeService: TechPortalFeatureConnectedConsumptionCodeService,
    private errorCodeService: TechPortalFeatureConnectedErrorCodeService
  ) {
    super(injector);
  }

  public onSelectorChange(selector: ResourceTaskSelectorDto): void {
    this.isResourceFilterApplied(selector);
    setTimeout(() => this.onModelChange({...this.model, selector}));
  }

  public isResourceFilterApplied(selector?: ResourceTaskSelectorDto): boolean {
    // Logic
    selector = selector ?? this.model?.selector;
    this.isResourceFilter = Boolean(selector?.view?.filters && selector.view.filters.length > 0);
    // Model valid subject disables / enables primary button
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      resourceFilter: this.isResourceFilter
    });
    // Return
    return this.isResourceFilter;
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'isActivated',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 field-group-mb-0',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-activated' // is-deactivated
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'isCancelingObsoleteMissions',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: false,
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.fields.cancel-obsolete-missions'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'isAutoInstructing',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-auto-activation'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },

      /* Timing */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.timing',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'startDate',
            type: 'core-portal-datepicker',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.actual-start',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.DATE,
                format: 'LL'
              } as CorePortalFormlyReadonlyTyping
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.required': () => !this.readonly
            }
          },
          {
            key: 'endDate',
            type: 'core-portal-datepicker',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.actual-end',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.DATE,
                format: 'LL'
              } as CorePortalFormlyReadonlyTyping
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.required': () => !this.readonly
            }
          },
          {
            key: 'startOffSet',
            type: 'core-portal-timepicker',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.start-offset'
              },
              corePortalTimepicker: {
                mode: 'timespan',
                showYears: true,
                showWeeks: true,
                showDays: true,
                showHours: false,
                showMinutes: false
              }
            },
            expressionProperties: {
              'templateOptions.required': () => !this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'endOffset',
            type: 'core-portal-timepicker',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.end-offset'
              },
              corePortalTimepicker: {
                mode: 'timespan',
                showYears: false,
                showWeeks: false,
                showDays: true,
                showHours: true,
                showMinutes: true
              }
            },
            expressionProperties: {
              'templateOptions.required': () => !this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          }
        ]
      },

      /* Description */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.description',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'missionStereotype',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6 field-group-md-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'resource-tasks.fields.mission-type',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'name',
                link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.stereotypeService,
              idKey: 'stereotypeId',
              displayKey: 'name',
              wholeObject: true,
              skipGetOne: true,
              defaultFilters$: of([
                {
                  property: 'isArchived',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.Equal,
                  value: 'false'
                },
                {
                  property: 'entityType',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.Equal,
                  value: AppEntityType.Mission.toString()
                }
              ] as Filter[]),
              link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
              module: 'settings'
            },
            expressionProperties: {
              'templateOptions.required': () => !this.readonly,
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'missionDescription',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-6 field-group-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'resource-tasks.fields.mission-template',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'title',
                link: (template: TemplateDto) => template?.templateId ? ['/templates', 'text', template.templateId] : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.templateService,
              idKey: 'templateId',
              displayKey: 'title',
              wholeObject: true,
              skipGetOne: true,
              defaultFilters$: of([{
                property: 'context',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: TemplateContextType.MissionByTaskDescription.toString()
              }] as FilterDto[]),
              link: (template: TemplateDto) => template?.templateId ? ['/templates', 'text', template.templateId] : null,
              module: 'settings'
            },
            expressionProperties: {
              'templateOptions.required': () => !this.readonly,
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          }
        ]
      },

      /* Schedule */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.schedule',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'schedule.type',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12 field-group-mb-0',
            defaultValue: resourceTaskScheduleTypeEnumOptions[0].value,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: resourceTaskScheduleTypeEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: resourceTaskScheduleTypeEnumOptions,
                translate: true
              } as CorePortalFormlyNgSelectTyping,
              required: true,
              description$: this.modelSubject.asObservable().pipe(
                mergeMap(model => {
                  let key: string;

                  switch (model?.schedule?.type) {
                    case ResourceTaskScheduleType.Relative:
                      key = 'resource-tasks.descriptions.relative-schedule';
                      break;
                    case ResourceTaskScheduleType.Fixed:
                      key = 'resource-tasks.descriptions.fixed-schedule';
                      break;
                    case ResourceTaskScheduleType.Consumption:
                      switch ((model?.schedule as ResourceTaskConsumptionScheduleDto)?.trigger.type) {
                        case ResourceTaskConsumptionTriggerType.Stepped:
                          key = 'resource-tasks.descriptions.consumption-stepped-schedule';
                          break;
                      }
                      break;
                    case ResourceTaskScheduleType.Error:
                      key = 'resource-tasks.descriptions.error-schedule';
                      break;
                  }

                  if (!key) return of(null);

                  switch (model?.schedule?.type) {
                    case ResourceTaskScheduleType.Relative:
                    case ResourceTaskScheduleType.Fixed: {
                      const offset = (model.pattern as ResourceTaskOffsetPatternDto).offset;
                      const time: { years: number, weeks: number, days: number } = timespanTo(offset);

                      if (model?.schedule?.type === ResourceTaskScheduleType.Fixed) {
                        const date: dayjs.Dayjs = dayjs((model.schedule as ResourceTaskFixedScheduleDto).nextOccurrence).local();

                        return this.bsLocaleService.locale.asObservable().pipe(
                          map(locale => formatDate(date.toDate(), 'LL', locale)),
                          map(next => this.translate.instant(key, {...time, next}))
                        );
                      }

                      return this.translate.stream(key, time);
                    }
                    case ResourceTaskScheduleType.Consumption: {
                      const {trigger, consumption} = (model.schedule as ResourceTaskConsumptionScheduleDto);
                      const unit = (consumption as ConsumptionCodeDto)?.unit;// ToDo: Add to SimpleDto
                      if (!isFinite(parseInt((trigger as SteppedResourceTaskConsumptionTriggerDto).limit?.toString(), 10)) || !consumption) return of(null);

                      return this.translate.stream(key, {
                        parameter: consumption?.displayName,
                        value: (trigger as SteppedResourceTaskConsumptionTriggerDto).limit,
                        unit: unit ? ` ${unit}` : ''
                      });
                    }
                    case ResourceTaskScheduleType.Error: {
                      const {error} = (model.schedule as ResourceTaskErrorScheduleDto);
                      if (!error) return of(null);

                      return this.translate.stream(key, {
                        error: error?.displayName
                      });
                    }
                  }

                  return of(null);
                })
              )
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },
          {
            key: 'schedule.timeOfOccurrence',
            type: 'core-portal-timepicker',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6 mt-3',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.execution-time',
                validationMessages: {
                  required: 'core-portal.core.validation.required'
                }
              },
              corePortalTimepicker: {
                mode: 'timespan'
              }
            },
            hideExpression: () => {
              const type = this.form.get('schedule.type').value;
              return !(type === ResourceTaskScheduleType.Relative || type === ResourceTaskScheduleType.Fixed)
            },
            expressionProperties: {
              'templateOptions.required': () => !this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          }
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      },

      /* Rotation */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.rotation',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'pattern.type',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            defaultValue: ResourceTaskPatternType.Offset,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: resourceTaskPatternTypeEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: resourceTaskPatternTypeEnumOptions,
                translate: true
              } as CorePortalFormlyNgSelectTyping,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },

          {
            key: 'pattern.offset',
            type: 'core-portal-timepicker',
            className: 'col-md-12 field-group-mb-0',
            templateOptions: {
              corePortalTimepicker: {
                mode: 'timespan',
                showYears: true,
                showWeeks: true,
                showDays: true,
                showHours: false,
                showMinutes: false
              },
              required: true
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => this.model?.pattern?.type !== ResourceTaskPatternType.Offset
          }
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly &&
            this.model?.pattern?.type === ResourceTaskPatternType.Offset &&
            (
              this.model?.schedule?.type === ResourceTaskScheduleType.Relative ||
              this.model?.schedule?.type === ResourceTaskScheduleType.Fixed
            ),
        },
        hideExpression: () => this.model?.schedule?.type !== ResourceTaskScheduleType.Relative &&
          this.model?.schedule?.type !== ResourceTaskScheduleType.Fixed
      },

      /* Consumption */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.consumption',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'schedule.consumption',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3 field-group-md-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.consumption-code',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'displayName',
                link: (row: ConsumptionCodeDto) => row?.consumptionCodeId ? ['/connected/consumption-codes', row.consumptionCodeId] : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.consumptionCodeService,
              idKey: 'consumptionCodeId',
              displayKey: 'displayName',
              wholeObject: true,
              skipGetOne: true,
              enableCrossCreation: CrossCreationTypes.CONSUMPTION_CODE,
              link: (row: ConsumptionCodeDto) => row?.consumptionCodeId ? ['/connected/consumption-codes', row.consumptionCodeId] : null,
              module: 'settings',
              selectLabelTitleTemplate: this.consumptionCodeSelectLabelTitleTemplate,
              selectOptionTitleTemplate: this.consumptionCodeSelectOptionTitleTemplate,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },

          {
            key: 'schedule.trigger.type',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-3 field-group-md-mb-0',
            defaultValue: ResourceTaskConsumptionTriggerType.Stepped,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.trigger',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: resourceTaskConsumptionScheduleTypeEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: resourceTaskConsumptionScheduleTypeEnumOptions,
                translate: true
              } as CorePortalFormlyNgSelectTyping,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          },

          {
            key: 'schedule.trigger.limit',
            type: 'core-portal-input-group-input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-2 field-group-mb-0',
            defaultValue: 0,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.limit',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalInputGroupInput: {},
              type: 'number',
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.schedule as ResourceTaskConsumptionScheduleDto)?.trigger?.type !== ResourceTaskConsumptionTriggerType.Stepped
          },
          {
            key: 'schedule.trigger.lowerLimit',
            type: 'core-portal-input-group-input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-2 field-group-mb-0',
            defaultValue: 0,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.lower-limit',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalInputGroupInput: {},
              type: 'number',
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.schedule as ResourceTaskConsumptionScheduleDto)?.trigger?.type === ResourceTaskConsumptionTriggerType.Stepped
          },
          {
            key: 'schedule.trigger.upperLimit',
            type: 'core-portal-input-group-input',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-2 field-group-mb-0',
            defaultValue: 0,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.upper-limit',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.BASIC
              } as CorePortalFormlyReadonlyTyping,
              corePortalInputGroupInput: {},
              type: 'number',
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.schedule as ResourceTaskConsumptionScheduleDto)?.trigger?.type === ResourceTaskConsumptionTriggerType.Stepped
          },
          {
            key: 'schedule.trigger.interval',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-2 field-group-mb-0',
            defaultValue: resourceTaskConsumptionScheduleLookBackIntervalEnumOptions[0].value,
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.interval',
                hideRequiredMarker: true
              } as CorePortalFormlyTranslatedTyping,
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: resourceTaskConsumptionScheduleLookBackIntervalEnumOptions,
                translate: true
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items: resourceTaskConsumptionScheduleLookBackIntervalEnumOptions,
                translate: true
              } as CorePortalFormlyNgSelectTyping,
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            },
            hideExpression: () => (this.model?.schedule as ResourceTaskConsumptionScheduleDto)?.trigger?.type !== ResourceTaskConsumptionTriggerType.LookBack
          }
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && this.model?.schedule?.type === ResourceTaskScheduleType.Consumption
        },
        hideExpression: () => this.model?.schedule?.type !== ResourceTaskScheduleType.Consumption
      },

      /* Error */
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 mt-3 px-0',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'resource-tasks.subtitles.error',
            title: true,
            labelClass: 'pl-2'
          }
        },
        fieldGroup: [
          {
            key: 'schedule.error',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12 field-group-mb-0',
            defaultValue: null,
            templateOptions: {
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENTITY,
                displayKey: 'displayName',
                link: (row: ErrorCodeDto) => row?.errorCodeId ? ['/connected/error-codes', row.errorCodeId] : null,
                module: 'settings'
              } as CorePortalFormlyReadonlyTyping,
              entityService: this.errorCodeService,
              idKey: 'errorCodeId',
              displayKey: 'displayName',
              wholeObject: true,
              skipGetOne: true,
              enableCrossCreation: CrossCreationTypes.ERROR_CODE,
              link: (row: ErrorCodeDto) => row?.errorCodeId ? ['/connected/error-codes', row.errorCodeId] : null,
              module: 'settings',
              selectLabelTitleTemplate: this.consumptionCodeSelectLabelTitleTemplate, // ToDo: Error codes
              selectOptionTitleTemplate: this.consumptionCodeSelectOptionTitleTemplate, // ToDo: Error codes
              required: true
            },
            expressionProperties: {
              'templateOptions.readonly': () => this.readonly,
              'templateOptions.disabled': () => this.readonly
            }
          }
        ],
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && this.model?.schedule?.type === ResourceTaskScheduleType.Error
        },
        hideExpression: () => this.model?.schedule?.type !== ResourceTaskScheduleType.Error
      }
    ];
  }
}
