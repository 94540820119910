import {
  CorePortalFeatureStereotypeAdditionalFieldsToken
} from '@nexnox-web/core-portal/features/settings/features/stereotypes';
import {Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalStereotypeService
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  CombineOperator,
  DocumentTemplateContextType,
  DocumentTemplateSimpleDto,
  Filter,
  FilterOperators,
  FilterTypes,
  InventoryNumberPatternDto,
  PrioritySetDto,
  StateMachineDto,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {
  TechPortalFeatureTicketSettingsPrioritySetService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {
  TechPortalFeatureTicketSettingsStateMachineService
} from '@nexnox-web/tech-portal/features/ticket-settings/features/state-machines';
import {
  CorePortalFeatureInventoryNumberPatternService
} from '@nexnox-web/core-portal/features/settings/features/inventory-numbers';
import {of} from 'rxjs';
import {TechPortalFeatureDocumentTemplateService} from '@nexnox-web/tech-portal/features/templates';

export function provideStereotypeAdditionalFields(): CorePortalFeatureStereotypeAdditionalFieldsToken {
  return (injector: Injector, component: CorePortalEntityEditBaseComponent<any>) => ({
    [AppEntityType.Ticket]: [
      {key: 'prioritySetId'},
      {
        key: 'prioritySet',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.priority-set',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (prioritySet: PrioritySetDto) => prioritySet?.prioritySetId ?
              ['priority-sets', prioritySet.prioritySetId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: injector.get(TechPortalFeatureTicketSettingsPrioritySetService),
          idKey: 'prioritySetId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (prioritySet: PrioritySetDto) => prioritySet?.prioritySetId ?
            ['priority-sets', prioritySet.prioritySetId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.required': () => !component.readonly,
          'templateOptions.disabled': () => component.readonly,
          'templateOptions.readonly': () => component.readonly
        },
        hooks: {
          onInit: field => component.changeFieldHook(field, value => {
            component.form.controls.prioritySetId.setValue(value?.prioritySetId ?? null);
          })
        }
      },
      {key: 'stateMachineId'},
      {
        key: 'stateMachine',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.state-machine',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stateMachine: StateMachineDto) => stateMachine?.stateMachineId ?
              ['state-machines', stateMachine.stateMachineId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: injector.get(TechPortalFeatureTicketSettingsStateMachineService),
          idKey: 'stateMachineId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (stateMachine: StateMachineDto) => stateMachine?.stateMachineId ?
            ['state-machines', stateMachine.stateMachineId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.required': () => !component.readonly,
          'templateOptions.disabled': () => component.readonly,
          'templateOptions.readonly': () => component.readonly
        },
        hooks: {
          onInit: field => component.changeFieldHook(field, value => {
            component.form.controls.stateMachineId.setValue(value?.stateMachineId ?? null);
          })
        }
      }
    ],

    [AppEntityType.Resource]: [
      {
        key: 'inventoryNumberPattern',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.inventory-number-pattern'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (pattern: InventoryNumberPatternDto) => pattern?.inventoryNumberPatternId ?
              ['inventory-number-patterns', pattern.inventoryNumberPatternId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: injector.get(CorePortalFeatureInventoryNumberPatternService),
          idKey: 'inventoryNumberPatternId',
          displayKey: 'title',
          wholeObject: true,
          skipGetOne: true,
          link: (pattern: InventoryNumberPatternDto) => pattern?.inventoryNumberPatternId ?
            ['inventory-number-patterns', pattern.inventoryNumberPatternId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => component.readonly,
          'templateOptions.readonly': () => component.readonly
        }
      }
    ],

    [AppEntityType.Mission]: [
      {
        key: 'missionByTaskActivationTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'missions.fields.mission-by-task-activation-template'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: injector.get(TechPortalFeatureDocumentTemplateService),
          idKey: 'documentTemplateId',
          displayKey: 'title',
          clearable: true,
          firstToDefault: false,
          wholeObject: true,
          skipGetOne: true,
          defaultFilters$: of([{
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.MissionBase.toString()
              },
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.ActivationMission.toString()
              },
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.ActivationMissionByTask.toString()
              }
            ]
          }] as Filter[]),
          link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => component.readonly,
          'templateOptions.readonly': () => component.readonly
        }
      },
      {
        key: 'missionByTicketActivationTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'missions.fields.mission-by-ticket-activation-template'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: injector.get(TechPortalFeatureDocumentTemplateService),
          idKey: 'documentTemplateId',
          displayKey: 'title',
          clearable: true,
          firstToDefault: false,
          wholeObject: true,
          skipGetOne: true,
          defaultFilters$: of([{
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.MissionBase.toString()
              },
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.ActivationMission.toString()
              },
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.ActivationMissionByTicket.toString()
              }
            ]
          }] as Filter[]),
          link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => component.readonly,
          'templateOptions.readonly': () => component.readonly
        }
      },
      {
        key: 'missionByManualActivationTemplate',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'missions.fields.mission-by-manual-activation-template'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: injector.get(TechPortalFeatureDocumentTemplateService),
          idKey: 'documentTemplateId',
          displayKey: 'title',
          clearable: true,
          firstToDefault: false,
          wholeObject: true,
          skipGetOne: true,
          defaultFilters$: of([{
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.Or,
            children: [
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.MissionBase.toString()
              },
              {
                property: 'contextType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: DocumentTemplateContextType.ActivationMission.toString()
              }
            ]
          }] as Filter[]),
          link: (row: DocumentTemplateSimpleDto) => row?.documentTemplateId ? ['templates/document', row.documentTemplateId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.disabled': () => component.readonly,
          'templateOptions.readonly': () => component.readonly
        }
      },
      {
        key: 'ticketRestrictions',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.subtitles.stereotype-ticket-restrictions',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: injector.get(CorePortalStereotypeService),
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: false,
          clearable: true,
          showAll: true,
          multiple: true,
          link: (row: StereotypeDto) => row?.stereotypeId ? `/stereotypes/${row.stereotypeId}` : null,
          module: 'settings',
          defaultFilters$: of([
            {
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              property: 'entityType',
              value: AppEntityType.Ticket.toString()
            },
            {
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              property: 'isArchived',
              value: false
            }
          ] as Filter[])
        },
        expressionProperties: {
          'templateOptions.disabled': () => component.readonly
        }
      }
    ]
  });
}
