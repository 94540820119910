<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-settings-stereotypes-stereotype-edit #modelComponent
                                                   [title]="title"
                                                   [id]="id"
                                                   [model]="model$ | async"
                                                   [readonly]="readonly$ | async"
                                                   [creating]="false"
                                                   [loading]="loading$ | async"
                                                   [stereotyped]="false"
                                                   (modelChange)="onModelChange($event)">
  </nexnox-web-settings-stereotypes-stereotype-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="stereotype"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']"
                          [ready$]="loaded$">

  <!-- Resources by location definition Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isLocation$ | async"
               tabId="resources-by-location"
               [heading]="'core-shared.shared.skeletons.resource-skeletons'"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['resources-by-location'])">

    <nexnox-web-settings-stereotypes-resources-by-location-definition-list *ngIf="passedIds && +id > 0"
                                                                           [stereotypeId]="id"
                                                                           [parentIds]="passedIds">
    </nexnox-web-settings-stereotypes-resources-by-location-definition-list>

  </ng-template>

  <!-- Resources by location definition Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isLocation$ | async"
               tabId="tickets-by-location"
               [heading]="'core-shared.shared.skeletons.ticket-skeletons'"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['tickets-by-location'])">

    <nexnox-web-settings-stereotypes-tickets-by-location-definition-list *ngIf="passedIds && +id > 0"
                                                                         [stereotypeId]="id"
                                                                         [parentIds]="passedIds">
    </nexnox-web-settings-stereotypes-tickets-by-location-definition-list>

  </ng-template>

  <!-- Contract Settings Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isContract$ | async"
               tabId="contract-settings"
               [heading]="'core-portal.settings.subtitles.contract-settings'"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['contract-settings'])">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-stereotypes-contract-settings-detail [contractStereotypeId]="model?.stereotypeId"
                                                       [ticketByContractSimpleDefinition]="model?.ticketByContractDefinition"
                                                       [readonly$]="readonly$">
      </nexnox-web-stereotypes-contract-settings-detail>
    </ng-container>
  </ng-template>
</nexnox-web-entity-detail>
