<div class="row no-gutters w-100 align-items-center" title="">
  <div class="col-1 pl-0">
    <p-toggleButton #toggleNoteButton
                    styleClass="p-button-sm p-button-text p-button-icon-only p-togglebutton-plain"
                    [ngModel]="item.model?.note"
                    [onIcon]="faCaretUp | nexnoxWebFaIconStringPipe"
                    [offIcon]="faCaretDown | nexnoxWebFaIconStringPipe">
    </p-toggleButton>
  </div>
  <ng-container *ngIf="item.fields && item.model">
    <form [formGroup]="form" class="col-9">
      <formly-form class="row w-100 align-items-center"
                   [form]="form"
                   [model]="item.model"
                   [fields]="item.fields"
                   (modelChange)="onArticleChange($event)">
      </formly-form>
    </form>
  </ng-container>

  <div class="d-flex justify-content-end align-items-center flex-grow-1">
    {{ total | nexnoxWebLocaleStringPipe:2 }} €
  </div>
</div>

<div *ngIf="toggleNoteButton.checked"
     class="row w-100 align-items-center">
  <div class="col-12 pt-2 px-0">
    <textarea pInputTextarea
              class="p-input text-sm w-100"
              [placeholder]="'core-shared.shared.fields.note' | translate"
              [rows]="2"
              [readOnly]="readonly"
              [ngModel]="item.model?.note"
              (ngModelChange)="onChangeArticleNote($event)">
    </textarea>
  </div>
</div>
