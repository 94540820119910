import {CorePortalRolesConfig} from '@nexnox-web/core-portal';
import {AppEntityType, AppFunctions} from '@nexnox-web/core-shared';

export function provideRolesConfig(): CorePortalRolesConfig[] {
  return [
    {
      type: AppEntityType.Contact,
      label: 'core-portal.master-data.subtitles.contacts',
      permissions: [
        { value: AppFunctions.DisplayContact, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadContact, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditContact, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteContact, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: AppEntityType.Location,
      label: 'core-portal.master-data.subtitles.locations',
      permissions: [
        { value: AppFunctions.DisplayLocation, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadLocation, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditLocation, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteLocation, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: AppEntityType.Address,
      label: 'core-portal.master-data.subtitles.addresses',
      permissions: [
        { value: AppFunctions.DisplayAddress, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadAddress, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditAddress, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteAddress, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: AppEntityType.Company,
      label: 'core-portal.master-data.subtitles.companies',
      permissions: [
        { value: AppFunctions.DisplayCompany, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadCompany, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditCompany, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteCompany, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: AppEntityType.Ticket,
      label: 'tickets.subtitles.ticket-list',
      permissions: [
        { value: AppFunctions.DisplayTicket, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadTicket, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditTicket, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteTicket, label: 'core-shared.shared.role-functions.delete' },
        { value: AppFunctions.ForceTicketState, label: 'core-shared.shared.role-functions.force-ticket-state' },
        { value: AppFunctions.AssignTicketToMe, label: 'core-shared.shared.role-functions.assign-ticket-to-me' },
        { value: AppFunctions.AssignTicketToEditor, label: 'core-shared.shared.role-functions.assign-to-editor' },
        { value: AppFunctions.AssignTicketResource, label: 'core-shared.shared.role-functions.assign-resource-to-ticket' },
        { value: AppFunctions.UseEscalation, label: 'core-shared.shared.role-functions.escalate' },
      ]
    },
    {
      type: AppEntityType.Setting,
      label: 'core-portal.settings.title',
      permissions: [
        { value: AppFunctions.EditSettings, label: 'core-portal.settings.actions.edit-settings' },

        { value: AppFunctions.ManipulateStereotype, label: 'core-portal.settings.actions.edit-stereotype' },

        { value: AppFunctions.EditRole, label: 'core-portal.settings.actions.edit-role' },
        { value: AppFunctions.EditAreaAssignment, label: 'core-portal.master-data.actions.maintain-contact-areas' },

        { value: AppFunctions.EditDashboard, label: 'core-portal.settings.actions.edit-dashboard' },

        { value: AppFunctions.EditStateMachine, label: 'core-portal.settings.actions.ticket-settings.edit-state-machine' },

        { value: AppFunctions.EditPriority, label: 'core-portal.settings.actions.ticket-settings.edit-priority-set' },

        { value: AppFunctions.EditEscalation, label: 'core-portal.settings.actions.ticket-settings.edit-escalation-level' },

        { value: AppFunctions.EditTemplate, label: 'core-portal.settings.actions.templates.edit-text-template' },
        { value: AppFunctions.EditDocumentTemplate, label: 'core-portal.settings.actions.templates.edit-document-template' },

        { value: AppFunctions.EditInventoryNumberPattern, label: 'core-portal.settings.actions.edit-inventory-number-pattern' }
      ]
    },
    {
      type: AppEntityType.IssueTemplate,
      label: 'issue-templates.subtitles.issue-template-list',
      permissions: [
        {value: AppFunctions.DisplayIssueTemplate, label: 'core-shared.shared.role-functions.show'},
        {value: AppFunctions.ReadIssueTemplate, label: 'core-shared.shared.role-functions.read'},
        {value: AppFunctions.EditIssueTemplate, label: 'core-shared.shared.role-functions.edit'},
        {value: AppFunctions.DeleteIssueTemplate, label: 'core-shared.shared.role-functions.delete'}
      ]
    },
    {
      type: 'dashboard',
      label: 'core-portal.settings.subtitles.dashboard-list',
      permissions: [
        { value: AppFunctions.ReadGraph, label: 'core-shared.shared.role-functions.show-location-overview' },
        { value: AppFunctions.ShowDashboard, label: 'core-shared.shared.role-functions.show-cockpits' },
        { value: AppFunctions.ReadDashboard, label: 'core-shared.shared.role-functions.read-dashboards', force: true },
        { value: AppFunctions.EditAllDashboard, label: 'core-shared.shared.role-functions.edit-all-dashboards' }
      ]
    },
    {
      type: AppEntityType.Resource,
      label: 'resources.subtitles.resource-list',
      permissions: [
        { value: AppFunctions.DisplayResource, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadResource, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditResource, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteResource, label: 'core-shared.shared.role-functions.delete' },
        { value: AppFunctions.DisplayQuickAccess, label: 'resources.subtitles.quick-access-options' }
      ]
    },
    {
      type: 'resource-tasks',
      label: 'resource-tasks.subtitles.resource-task-list',
      permissions: [
        { value: AppFunctions.DisplayResourceTask, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadResourceTask, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditResourceTask, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteResourceTask, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: AppEntityType.Contract,
      label: 'contracts.subtitles.contract-list',
      permissions: [
        { value: AppFunctions.DisplayContract, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadContract, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditContract, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteContract, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: AppEntityType.Article,
      label: 'articles.subtitles.article-list',
      permissions: [
        { value: AppFunctions.DisplayArticle, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadArticle, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditArticle, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteArticle, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: 'datatable-view',
      label: 'core-shared.shared.table.subtitles.datatable-views.title',
      permissions: [
        { value: AppFunctions.DisplayDataTable, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadDataTable, label: 'core-shared.shared.role-functions.read', force: true },
        { value: AppFunctions.EditDataTable, label: 'core-shared.shared.role-functions.edit' }
      ]
    },
    {
      type: 'solution',
      label: 'solutions.title',
      permissions: [
        { value: AppFunctions.ReadSolution, label: 'core-shared.shared.role-functions.read' }
      ]
    },
    {
      type: 'audit',
      label: 'core-portal.audit.subtitles.audit-list',
      permissions: [
        { value: AppFunctions.ReadAudit, label: 'core-shared.shared.role-functions.read' }
      ]
    },
    {
      type: AppEntityType.Mission,
      label: 'missions.subtitles.missions',
      permissions: [
        { value: AppFunctions.DisplayMission, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadMission, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditMission, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteMission, label: 'core-shared.shared.role-functions.delete' },
        { value: AppFunctions.EditActualMissionDate, label: 'core-shared.shared.role-functions.edit-actual-mission-date' },
        { value: AppFunctions.EditPlannedMissionDate, label: 'core-shared.shared.role-functions.edit-planned-mission-date' },
        { value: AppFunctions.EditClosedMission, label: 'core-shared.shared.role-functions.edit-closed-mission' },

        { value: AppFunctions.AssignMissionToMe, label: 'core-shared.shared.role-functions.assign-mission-to-me' },
        { value: AppFunctions.AssignMissionToEditor, label: 'core-shared.shared.role-functions.assign-to-editor' },
        { value: AppFunctions.AssignMissionResource, label: 'core-shared.shared.role-functions.assign-resource-to-mission' },

        { value: AppFunctions.DisplayMissionMap, label: 'core-shared.shared.role-functions.display-mission-map' },
        { value: AppFunctions.DisplayMissionPlan, label: 'core-shared.shared.role-functions.display-mission-plan' },
        { value: AppFunctions.ReadCalendar, label: 'core-shared.shared.role-functions.read-calendar' }
      ]
    },
    {
      type: 'text-templates',
      label: 'core-portal.settings.subtitles.templates.text-template-list',
      permissions: [
        { value: AppFunctions.ReadTemplate, label: 'core-shared.shared.role-functions.apply' }
      ]
    },
    {
      type: 'export',
      label: 'core-shared.shared.role-function-groups.export',
      permissions: [
        { value: AppFunctions.ReadDocumentTemplate, label: 'core-shared.shared.role-functions.export-pdf' },
        { value: AppFunctions.ExportDataTable, label: 'core-shared.shared.role-functions.export-datatable' }
      ]
    },
    {
      type: 'holidays',
      label: 'core-portal.master-data.subtitles.holidays-list',
      permissions: [
        { value: AppFunctions.EditHoliday, label: 'core-shared.shared.role-functions.edit' }
      ]
    },
    {
      type: 'categories',
      label: 'orga-portal.categories.subtitles.category-list',
      permissions: [
        { value: AppFunctions.DisplayCategory, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadCategory, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditCategory, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteCategory, label: 'core-shared.shared.role-functions.delete' }
      ]
    },
    {
      type: 'chat',
      label: 'orga-portal.chat.subtitles.chat-list',
      permissions: [
        { value: AppFunctions.EditChatByFormDefinition, label: 'core-shared.shared.role-functions.edit-chat-config' },
        { value: AppFunctions.DeleteChatByFormDefinition, label: 'core-shared.shared.role-functions.delete-chat-config' },
        { value: AppFunctions.ShowAllChatsPackage, label: 'core-shared.shared.role-functions.show-all-chats' },
        { value: AppFunctions.ShowBackendChat, label: 'core-shared.shared.role-functions.show' }
      ]
    },
    {
      type: 'documents',
      label: 'orga-portal.documents.subtitles.document-list',
      permissions: [
        { value: AppFunctions.ReadExternalAllDocument, label: 'core-shared.shared.role-functions.show-all-documents' },
        { value: AppFunctions.EditExternalDocument, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.ShowBackendDocuments, label: 'core-shared.shared.role-functions.show' }
      ]
    },
    {
      type: 'app',
      label: 'core-shared.shared.role-function-groups.app',
      permissions: [
        { value: AppFunctions.IsOrgaUser, label: 'core-shared.shared.role-functions.use' },
        { value: AppFunctions.EditPing, label: 'core-shared.shared.role-functions.perform-ping' },
        { value: AppFunctions.EditDevice, label: 'core-shared.shared.role-functions.edit-device' }
      ]
    },
    {
      type: 'infos',
      label: 'orga-portal.infos.subtitles.info-list',
      permissions: [
        { value: AppFunctions.DisplayInfo, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadInfo, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditInfo, label: 'core-shared.shared.role-functions.edit' }
      ]
    },
    {
      type: 'knowledge',
      label: 'orga-portal.knowledge.subtitles.knowledge',
      permissions: [
        { value: AppFunctions.DisplayKnowledge, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadKnowledge, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditKnowledge, label: 'core-shared.shared.role-functions.edit' }
      ]
    },
    {
      type: 'forms',
      label: 'orga-portal.forms.subtitles.form-list',
      permissions: [
        { value: AppFunctions.EditForm, label: 'core-shared.shared.role-functions.edit' }
      ]
    },
    {
      type: 'tasks',
      label: 'orga-portal.tasks.subtitles.task-list',
      permissions: [
        { value: AppFunctions.EditTask, label: 'core-shared.shared.role-functions.edit' }
      ]
    },
    {
      type: 'labels',
      label: 'core-portal.settings.subtitles.label-list',
      permissions: [
        { value: AppFunctions.DisplayLabel, label: 'core-shared.shared.role-functions.show' },
        { value: AppFunctions.ReadLabel, label: 'core-shared.shared.role-functions.read' },
        { value: AppFunctions.EditLabel, label: 'core-shared.shared.role-functions.edit' },
        { value: AppFunctions.DeleteLabel, label: 'core-shared.shared.role-functions.delete' },
        { value: AppFunctions.ModifyLabelManually, label: 'core-shared.shared.role-functions.modify-label-manually' }
      ]
    }
  ];
}
