import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ExportType, StereotypeDto} from '@nexnox-web/core-shared';
import {stereotypeDetailStore} from '../../store';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-settings-stereotypes-stereotype-detail',
  templateUrl: './stereotype-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StereotypeDetailComponent extends CorePortalEntityDetailBaseComponent<StereotypeDto> implements OnInit {
  public title = 'core-portal.settings.subtitles.stereotype';

  public faTimes = faTimes;
  public isContract$: Observable<boolean>;
  public isMission$: Observable<boolean>;
  public isMissionReport$: Observable<boolean>
  public isLocation$: Observable<boolean>;
  public isTicket$: Observable<boolean>;

  protected idParam = 'stereotypeId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, stereotypeDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isContract$ = this.model$.pipe(map((model => model.entityType === AppEntityType.Contract)));
    this.isMission$ = this.model$.pipe(map((model => model.entityType === AppEntityType.Mission)));
    this.isMissionReport$ = this.model$.pipe(map(model => model.entityType === AppEntityType.MissionReport));
    this.isLocation$ = this.model$.pipe(map(model => model.entityType === AppEntityType.Location));
    this.isTicket$ = this.model$.pipe(map(model => model.entityType === AppEntityType.Ticket));
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.edit-stereotype',
      'core-portal.settings.actions.save-stereotype',
      'core-portal.settings.actions.delete-stereotype',
      'core-portal.settings.descriptions.delete-stereotype',
      AppPermissions.UpdateStereotype,
      AppPermissions.DeleteStereotype,
      undefined,
      undefined,
      undefined,
      ExportType.STEREOTYPE
    );
  }
}
