import {InjectionToken} from '@angular/core';
import {CoreSharedModule, Environment} from '@nexnox-web/core-shared';
import {
  CorePortalBreadcrumbsModule,
  CorePortalCardboxModule,
  CorePortalEntityModule,
  CorePortalFormlyModule,
  CorePortalHierarchicalModule,
  CorePortalLayoutModule,
  CorePortalRuleEditorListModule,
  CorePortalSidebarModule,
  CorePortalTabsModule
} from './modules';
import {
  CorePortalContactService,
  CorePortalDocumentTemplateService,
  CorePortalFunctionService,
  CorePortalLocationGroupService,
  CorePortalLocationService,
  CorePortalReceiversService,
  CorePortalResourceInheritableService,
  CorePortalResourceService,
  CorePortalStereotypeService,
  CorePortalTenantSettingsService,
  CorePortalTextTemplateService,
  CorePortalVirtualResourceService
} from './services';
import {
  CorePortalComponentConfigGuard,
  CorePortalDynamicDefaultRouteGuard,
  CorePortalPermissionGuard,
  CorePortalSyncCanActivateGuard,
  CorePortalTenantGuard
} from './guards';
import {LanguageInterceptor} from './interceptors';
import {CorePortalRouterOverridesModule} from './router-overrides';

export const _CORE_SHARED_ENVIRONMENT = new InjectionToken<Environment>('@nexnox-web/core-shared Internal environment');
export const _CORE_SHARED_PRODUCT = new InjectionToken('@nexnox-web/core-shared Internal product');

export const rootModules = [
  CorePortalTabsModule.forRoot(),
  CorePortalFormlyModule.forRoot()
];

export const sharedModules = [
  CoreSharedModule,
  CorePortalRouterOverridesModule,

  CorePortalBreadcrumbsModule,
  CorePortalSidebarModule,
  CorePortalHierarchicalModule,
  CorePortalCardboxModule,
  CorePortalTabsModule,
  CorePortalFormlyModule,
  CorePortalEntityModule,
  CorePortalRuleEditorListModule,
  CorePortalLayoutModule
];

export const sharedServices = [
  CorePortalStereotypeService,
  CorePortalContactService,
  CorePortalResourceService,
  CorePortalResourceInheritableService,
  CorePortalFunctionService,
  CorePortalLocationService,
  CorePortalLocationGroupService,
  CorePortalTenantSettingsService,
  CorePortalReceiversService,
  CorePortalTextTemplateService,
  CorePortalDocumentTemplateService,
  CorePortalVirtualResourceService
];

export const sharedGuards = [
  CorePortalPermissionGuard,
  CorePortalTenantGuard,
  CorePortalSyncCanActivateGuard,
  CorePortalDynamicDefaultRouteGuard,
  CorePortalComponentConfigGuard
];

export const sharedInterceptors = [
  LanguageInterceptor
];
