/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";

/**
 * An interface representing FilterDto.
 */
export interface FilterDto {
  property?: string;
  value?: string;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'DataTransferObject', 'Custom', 'Grouped'
   */
  type?: FilterTypes;
}

/**
 * An interface representing AcceptEditorRequestDto.
 */
export interface AcceptEditorRequestDto {
  contactId?: number;
  actualStart?: string;
  actualEnd?: string;
}

/**
 * An interface representing AcceptInvitationDto.
 */
export interface AcceptInvitationDto {
  /**
   * Possible values include: 'Identify'
   */
  type?: InvitationType;
}

/**
 * An interface representing AcceptInvitationViaIdentityDto.
 */
export interface AcceptInvitationViaIdentityDto {
  /**
   * Possible values include: 'Identify'
   */
  type?: InvitationType;
  pin?: string;
  lastName?: string;
}

/**
 * An interface representing AcceptedInvitationDto.
 */
export interface AcceptedInvitationDto {
  tenantId?: number;
}

/**
 * An interface representing AccessOfContactDto.
 */
export interface AccessOfContactDto {
  since?: string;
  isLockedOut?: boolean;
}

/**
 * An interface representing AcknowledgeIssueAutomaticQueueDto.
 */
export interface AcknowledgeIssueAutomaticQueueDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Mission'
   */
  controlContentType?: ControlContentType;
}

/**
 * An interface representing AddLabelDto.
 */
export interface AddLabelDto {
  entityId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Mission'
   */
  type?: LabelDescriptorType;
}

/**
 * An interface representing PointDto.
 */
export interface PointDto {
  lat?: number;
  lng?: number;
}

/**
 * An interface representing CustomValueDto.
 */
export interface CustomValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
}

/**
 * An interface representing FilledCustomValueRelationDto.
 */
export interface FilledCustomValueRelationDto {
  customValueId?: number;
  customValue?: CustomValueDto;
  filledCustomValueId?: number;
}

/**
 * An interface representing FilledCustomValueDto.
 */
export interface FilledCustomValueDto {
  filledCustomValueId?: number;
  propertyId?: number;
  tenantId?: number;
  customValues?: FilledCustomValueRelationDto[];
}

/**
 * An interface representing AddressDto.
 */
export interface AddressDto {
  addressId?: number;
  point?: PointDto;
  tenantId?: number;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  isPrimaryInvoice?: boolean;
  isPrimaryShipping?: boolean;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing StereotypeSimpleDto.
 */
export interface StereotypeSimpleDto {
  stereotypeId?: number;
  name?: string;
  tenantId?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  externalId?: string;
}

/**
 * An interface representing AddressInMissionDto.
 */
export interface AddressInMissionDto {
  addressId?: number;
  point?: PointDto;
  street?: string;
  houseNumber?: string;
  zipcode?: string;
  city?: string;
  state?: string;
  stereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing PointListDto.
 */
export interface PointListDto {
  lat?: number;
  lng?: number;
}

/**
 * An interface representing StereotypeSimpleListDto.
 */
export interface StereotypeSimpleListDto {
  stereotypeId?: number;
  name?: string;
  position?: number;
  tenantId?: number;
  rowVersion?: number[];
  isArchived?: boolean;
  externalId?: string;
}

/**
 * An interface representing AddressInMissionListDto.
 */
export interface AddressInMissionListDto {
  addressId?: number;
  point?: PointListDto;
  tenantId?: number;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing FileDto.
 */
export interface FileDto {
  fileId?: number;
  tenantId?: number;
  path?: string;
  hash?: string;
  creationDateTime?: string;
  name?: string;
  mimeType?: string;
  size?: number;
  uri?: string;
}

/**
 * An interface representing CustomValueCompleteDto.
 */
export interface CustomValueCompleteDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: any;
  ownFileValue?: FileDto;
  inheritedValue?: any;
  inheritedFileValue?: FileDto;
}

/**
 * An interface representing FilledCustomValueRelationListDto.
 */
export interface FilledCustomValueRelationListDto {
  customValue?: CustomValueCompleteDto;
}

/**
 * An interface representing FilledCustomValueListDto.
 */
export interface FilledCustomValueListDto {
  propertyId?: number;
  customValues?: FilledCustomValueRelationListDto[];
}

/**
 * An interface representing AddressListDto.
 */
export interface AddressListDto {
  addressId?: number;
  tenantId?: number;
  point?: PointListDto;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
}

/**
 * An interface representing AddressListForCompanyDto.
 */
export interface AddressListForCompanyDto {
  addressId?: number;
  tenantId?: number;
  point?: PointListDto;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing AddressListForLocationDto.
 */
export interface AddressListForLocationDto {
  addressId?: number;
  tenantId?: number;
  point?: PointListDto;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing HolidaySetSimpleDto.
 */
export interface HolidaySetSimpleDto {
  holidaySetId?: number;
  name?: string;
}

/**
 * An interface representing AddressSimpleDto.
 */
export interface AddressSimpleDto {
  addressId?: number;
  point?: PointDto;
  tenantId?: number;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  rowVersion?: number[];
}

/**
 * An interface representing AttachmentForTechDto.
 */
export interface AttachmentForTechDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File'
   */
  type?: AttachmentTypeForTech;
}

/**
 * An interface representing LocationDto.
 */
export interface LocationDto {
  locationId?: number;
  tenantId?: number;
  name?: string;
  /**
   * Possible values include: 'Open', 'Closed'
   */
  state?: LocationState;
  closedSince?: string;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  isIncomingEmailEnabled?: boolean;
  emailAddress?: string;
  info?: string;
  locationNumber?: string;
  phoneNumber?: string;
  rowVersion?: number[];
  holidaySetId?: number;
  holidaySet?: HolidaySetSimpleDto;
  stereotypeId?: number;
  externalId?: string;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  shippingAddress?: AddressSimpleDto;
  invoiceAddress?: AddressSimpleDto;
  attachments?: AttachmentForTechDto[];
  lastModified?: string;
  createdAt?: string;
}

/**
 * An interface representing AddressLocationDto.
 */
export interface AddressLocationDto {
  addressId?: number;
  address?: AddressDto;
  locationId?: number;
  location?: LocationDto;
}

/**
 * An interface representing AddressSimpleListDto.
 */
export interface AddressSimpleListDto {
  addressId?: number;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
}

/**
 * An interface representing AddressTtm.
 */
export interface AddressTtm {
  addressId?: number;
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  zipcode?: string;
}

/**
 * An interface representing AlertImpactConsequenceDto.
 */
export interface AlertImpactConsequenceDto {
  impactId?: number;
  jobId?: number;
}

/**
 * An interface representing ConditionV1Dto.
 */
export interface ConditionV1Dto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
}

/**
 * An interface representing AlertImpactCreateV1Dto.
 */
export interface AlertImpactCreateV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  taskId?: number;
  functionId?: number;
}

/**
 * An interface representing ConditionDto.
 */
export interface ConditionDto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
}

/**
 * An interface representing FunctionSimpleDto.
 */
export interface FunctionSimpleDto {
  functionId?: number;
  name?: string;
  externalId?: string;
}

/**
 * An interface representing AlertImpactDto.
 */
export interface AlertImpactDto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionDto;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing AlertImpactV1Dto.
 */
export interface AlertImpactV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing AllResourceTicketSkeletonSelectorDto.
 */
export interface AllResourceTicketSkeletonSelectorDto {
  id?: number;
  /**
   * Possible values include: 'Base', 'All', 'RestrictedByType', 'Whitelisted', 'Fixed'
   */
  type?: ResourceTicketSkeletonSelectorType;
}

/**
 * An interface representing AllowedContextDto.
 */
export interface AllowedContextDto {
  tnt?: number;
  src?: number;
  /**
   * Possible values include: 'Global', 'Location', 'Tenant', 'LocationGroup', 'User'
   */
  type?: ContextKind;
}

/**
 * An interface representing EditorSuggestionTargetDto.
 */
export interface EditorSuggestionTargetDto {
  /**
   * Possible values include: 'Base', 'ByContact', 'ByFunction'
   */
  type?: EditorSuggestionTargetType;
  processingTime?: string;
}

/**
 * An interface representing AlternativeEditorSuggestionDto.
 */
export interface AlternativeEditorSuggestionDto {
  id?: number;
  target?: EditorSuggestionTargetDto;
}

/**
 * An interface representing AnalyticByTimePortalRequestDto.
 */
export interface AnalyticByTimePortalRequestDto {
  start?: string;
  end?: string;
  /**
   * Possible values include: 'Year', 'Month', 'Week', 'Day'
   */
  graphKind?: TimeGraphKind;
  locationGroupExternalId?: string;
}

/**
 * An interface representing AnalyticParams.
 */
export interface AnalyticParams {
  year?: number;
  locationGroupExternalId?: string;
}

/**
 * An interface representing AnalyticPortalRequestDto.
 */
export interface AnalyticPortalRequestDto {
  start?: string;
  end?: string;
  locationGroupExternalId?: string;
}

/**
 * An interface representing LocationSimpleDto.
 */
export interface LocationSimpleDto {
  locationId?: number;
  name?: string;
  externalId?: string;
  locationNumber?: string;
}

/**
 * An interface representing LocationGroupSimpleDto.
 */
export interface LocationGroupSimpleDto {
  locationGroupId?: number;
  name?: string;
  externalId?: string;
}

/**
 * An interface representing AppShortcutValueDto.
 */
export interface AppShortcutValueDto {
  id?: number;
  appShortcutValueId?: number;
  value?: string;
  /**
   * Possible values include: 'Text', 'RelativeDate', 'Entity'
   */
  type?: AppShortcutValueType;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  tenantId?: number;
}

/**
 * An interface representing AppShortcutParameterDto.
 */
export interface AppShortcutParameterDto {
  id?: number;
  appShortcutParameterId?: number;
  property?: string;
  tenantId?: number;
  values?: AppShortcutValueDto[];
}

/**
 * An interface representing AppShortcutDto.
 */
export interface AppShortcutDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
}

/**
 * An interface representing AppOverviewDto.
 */
export interface AppOverviewDto {
  appOverviewId?: number;
  title?: string;
  tenantId?: number;
  externalId?: string;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  shortcuts?: AppShortcutDto[];
}

/**
 * An interface representing AppShortcutExportDto.
 */
export interface AppShortcutExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  parameters?: AppShortcutParameterDto[];
}

/**
 * An interface representing AppOverviewExportDto.
 */
export interface AppOverviewExportDto {
  externalId?: string;
  title?: string;
  shortcuts?: AppShortcutExportDto[];
}

/**
 * An interface representing AppOverviewListDto.
 */
export interface AppOverviewListDto {
  appOverviewId?: number;
  title?: string;
  externalId?: string;
  tenantId?: number;
}

/**
 * An interface representing AppPermissionDto.
 */
export interface AppPermissionDto {
  /**
   * Possible values include: 'ReadTicket', 'CreateTicket', 'UpdateTicket', 'DeleteTicket',
   * 'ForceTicketState', 'AssignTicketEditor', 'AssignTicketToMe', 'AssignTicketResource',
   * 'ReadResource', 'CreateResource', 'UpdateResource', 'DeleteResource', 'ReadCustomProperty',
   * 'CreateCustomProperty', 'UpdateCustomProperty', 'DeleteCustomProperty',
   * 'ReadCustomPropertySet', 'CreateCustomPropertySet', 'UpdateCustomPropertySet',
   * 'DeleteCustomPropertySet', 'ReadStereotype', 'CreateStereotype', 'UpdateStereotype',
   * 'DeleteStereotype', 'ReadSetting', 'UpdateSetting', 'ReadCompany', 'CreateCompany',
   * 'UpdateCompany', 'DeleteCompany', 'ReadLocation', 'CreateLocation', 'UpdateLocation',
   * 'DeleteLocation', 'ReadContact', 'CreateContact', 'UpdateContact', 'DeleteContact',
   * 'CreateSystemContact', 'ReadSystemContact', 'UpdateSystemContact', 'DeleteSystemContact',
   * 'ReadFunction', 'CreateFunction', 'UpdateFunction', 'DeleteFunction', 'ReadAddress',
   * 'CreateAddress', 'UpdateAddress', 'DeleteAddress', 'ReadNote', 'CreateNote', 'UpdateNote',
   * 'DeleteNote', 'ReadNoteMailDroppedAttachment', 'ReadRole', 'CreateRole', 'UpdateRole',
   * 'DeleteRole', 'ReadAppPermissions', 'ReadTenant', 'CreateTenant', 'UpdateTenant',
   * 'DeleteTenant', 'ReadTenantUsage', 'ReadLoginProvider', 'CreateLoginProvider',
   * 'UpdateLoginProvider', 'DeleteLoginProvider', 'ReadDevice', 'CreateDevice', 'UpdateDevice',
   * 'DeleteDevice', 'UsePinLogin', 'UsePushRegister', 'UseDeviceRegister', 'UseExternalAppLogin',
   * 'UseBadgeLogin', 'ReadLocationGroup', 'CreateLocationGroup', 'UpdateLocationGroup',
   * 'DeleteLocationGroup', 'ReadInfo', 'CreateInfo', 'UpdateInfo', 'DeleteInfo', 'ReadTask',
   * 'CreateTask', 'UpdateTask', 'DeleteTask', 'ReadForm', 'CreateForm', 'UpdateForm',
   * 'DeleteForm', 'ReadSensitiveForm', 'ReadFilledForm', 'CreateFilledForm', 'UpdateFilledForm',
   * 'DeleteFilledForm', 'ReadChat', 'CreateChat', 'UpdateChat', 'DeleteChat', 'UseEmailPerChat',
   * 'UseChatWithoutCloak', 'ReadOverview', 'ReadKnowledgeArticle', 'CreateKnowledgeArticle',
   * 'UpdateKnowledgeArticle', 'DeleteKnowledgeArticle', 'ReadHolidaySet', 'CreateHolidaySet',
   * 'UpdateHolidaySet', 'DeleteHolidaySet', 'ReadFile', 'CreateFile', 'UpdateFile', 'DeleteFile',
   * 'ReadLoginChallenge', 'CreateLoginChallenge', 'UpdateLoginChallenge', 'DeleteLoginChallenge',
   * 'ReadFilledFormField', 'CreateFilledFormField', 'UpdateFilledFormField',
   * 'DeleteFilledFormField', 'ReadFormRow', 'CreateFormRow', 'UpdateFormRow', 'DeleteFormRow',
   * 'ReadFormField', 'CreateFormField', 'UpdateFormField', 'DeleteFormField', 'ReadAttachment',
   * 'CreateAttachment', 'UpdateAttachment', 'DeleteAttachment', 'ReadExecution',
   * 'CreateExecution', 'UpdateExecution', 'DeleteExecution', 'ReadImpact', 'CreateImpact',
   * 'UpdateImpact', 'DeleteImpact', 'ReadCondition', 'CreateCondition', 'UpdateCondition',
   * 'DeleteCondition', 'ReadChatMessage', 'CreateChatMessage', 'UpdateChatMessage',
   * 'DeleteChatMessage', 'ReadChatMember', 'CreateChatMember', 'UpdateChatMember',
   * 'DeleteChatMember', 'ReadChatMemberMessage', 'CreateChatMemberMessage',
   * 'UpdateChatMemberMessage', 'DeleteChatMemberMessage', 'ReadHoliday', 'CreateHoliday',
   * 'UpdateHoliday', 'DeleteHoliday', 'ReadHistory', 'CreateHistory', 'UpdateHistory',
   * 'DeleteHistory', 'ReadTaskJob', 'CreateTaskJob', 'UpdateTaskJob', 'DeleteTaskJob',
   * 'ReadTaskJobStatus', 'CreateTaskJobStatus', 'UpdateTaskJobStatus', 'DeleteTaskJobStatus',
   * 'ReadFolderAccess', 'CreateFolderAccess', 'UpdateFolderAccess', 'DeleteFolderAccess',
   * 'UseAllFolderAccess', 'ReadTaskJobStateReasonSet', 'CreateTaskJobStateReasonSet',
   * 'UpdateTaskJobStateReasonSet', 'DeleteTaskJobStateReasonSet', 'ReadTaskJobStateReason',
   * 'CreateTaskJobStateReason', 'UpdateTaskJobStateReason', 'DeleteTaskJobStateReason',
   * 'ReadPing', 'CreatePing', 'UpdatePing', 'DeletePing', 'ReadMe', 'ReadProductSubscription',
   * 'CreateProductSubscription', 'UpdateProductSubscription', 'DeleteProductSubscription',
   * 'UseExpiredSubscription', 'ShowExpiredSubscription', 'UseAdmin', 'ReadGraph',
   * 'ReadDateTimePattern', 'CreateDateTimePattern', 'UpdateDateTimePattern',
   * 'DeleteDateTimePattern', 'ReadFormValue', 'CreateFormValue', 'UpdateFormValue',
   * 'DeleteFormValue', 'ReadPlannedTime', 'CreatePlannedTime', 'UpdatePlannedTime',
   * 'DeletePlannedTime', 'ReadTaskJobCondition', 'CreateTaskJobCondition',
   * 'UpdateTaskJobCondition', 'DeleteTaskJobCondition', 'ReadCustomValue', 'CreateCustomValue',
   * 'UpdateCustomValue', 'DeleteCustomValue', 'ReadCustomPropertyValue',
   * 'CreateCustomPropertyValue', 'UpdateCustomPropertyValue', 'DeleteCustomPropertyValue',
   * 'ReadSettingValue', 'CreateSettingValue', 'UpdateSettingValue', 'DeleteSettingValue',
   * 'ReadState', 'CreateState', 'UpdateState', 'DeleteState', 'ReadStateMachine',
   * 'CreateStateMachine', 'UpdateStateMachine', 'DeleteStateMachine', 'ReadStateTransition',
   * 'CreateStateTransition', 'UpdateStateTransition', 'DeleteStateTransition', 'ReadDataTable',
   * 'CreateDataTable', 'UpdateDataTable', 'DeleteDataTable', 'ExportDataTable',
   * 'ReadDataTableColumn', 'CreateDataTableColumn', 'UpdateDataTableColumn',
   * 'DeleteDataTableColumn', 'ReadDashboard', 'CreateDashboard', 'UpdateDashboard',
   * 'DeleteDashboard', 'UseAllDashboard', 'ReadSolution', 'CreateSolution', 'UpdateSolution',
   * 'DeleteSolution', 'ReadSolutionItem', 'CreateSolutionItem', 'UpdateSolutionItem',
   * 'DeleteSolutionItem', 'ReadDataTableFilter', 'CreateDataTableFilter', 'UpdateDataTableFilter',
   * 'DeleteDataTableFilter', 'ReadPrioritySet', 'CreatePrioritySet', 'UpdatePrioritySet',
   * 'DeletePrioritySet', 'ReadPriority', 'CreatePriority', 'UpdatePriority', 'DeletePriority',
   * 'ReadEscalationLevel', 'CreateEscalationLevel', 'UpdateEscalationLevel',
   * 'DeleteEscalationLevel', 'ReadEscalationRule', 'CreateEscalationRule', 'UpdateEscalationRule',
   * 'DeleteEscalationRule', 'ReadTicketEvent', 'CreateTicketEvent', 'UpdateTicketEvent',
   * 'DeleteTicketEvent', 'ReadAudit', 'ReadMission', 'CreateMission', 'UpdateMission',
   * 'DeleteMission', 'UpdateActualMissionDates', 'UpdatePlannedMissionDates',
   * 'UpdateClosedMission', 'DisplayMissionMap', 'DisplayMissionPlan', 'AssignMissionResource',
   * 'AssignMissionEditor', 'AssignMissionToMe', 'ReadLink', 'CreateLink', 'UpdateLink',
   * 'DeleteLink', 'ReadLinkedElement', 'CreateLinkedElement', 'UpdateLinkedElement',
   * 'DeleteLinkedElement', 'ReadAttachmentForTech', 'CreateAttachmentForTech',
   * 'UpdateAttachmentForTech', 'DeleteAttachmentForTech', 'ReadMailBox', 'CreateMailBox',
   * 'UpdateMailBox', 'DeleteMailBox', 'ReadTemplate', 'CreateTemplate', 'UpdateTemplate',
   * 'DeleteTemplate', 'ReadDocumentTemplate', 'CreateDocumentTemplate', 'UpdateDocumentTemplate',
   * 'DeleteDocumentTemplate', 'EditBetaDocumentTemplates', 'ReadInventoryNumberPattern',
   * 'CreateInventoryNumberPattern', 'UpdateInventoryNumberPattern',
   * 'DeleteInventoryNumberPattern', 'ReadInventoryNumber', 'CreateInventoryNumber',
   * 'UpdateInventoryNumber', 'DeleteInventoryNumber', 'ReadContract', 'CreateContract',
   * 'UpdateContract', 'DeleteContract', 'ReadResourceTaskSelector', 'CreateResourceTaskSelector',
   * 'UpdateResourceTaskSelector', 'DeleteResourceTaskSelector', 'ReadResourceTaskPattern',
   * 'CreateResourceTaskPattern', 'UpdateResourceTaskPattern', 'DeleteResourceTaskPattern',
   * 'ReadResourceTaskCondition', 'CreateResourceTaskCondition', 'UpdateResourceTaskCondition',
   * 'DeleteResourceTaskCondition', 'ReadResourceTask', 'CreateResourceTask', 'UpdateResourceTask',
   * 'DeleteResourceTask', 'ReadArticleKind', 'CreateArticleKind', 'UpdateArticleKind',
   * 'DeleteArticleKind', 'ReadMissionReport', 'CreateMissionReport', 'UpdateMissionReport',
   * 'DeleteMissionReport', 'ReadMissionReportGroup', 'CreateMissionReportGroup',
   * 'UpdateMissionReportGroup', 'DeleteMissionReportGroup', 'ReadArticleUsage',
   * 'CreateArticleUsage', 'UpdateArticleUsage', 'DeleteArticleUsage', 'ReadArticle',
   * 'CreateArticle', 'UpdateArticle', 'DeleteArticle', 'ReadAppOverview', 'CreateAppOverview',
   * 'UpdateAppOverview', 'DeleteAppOverview', 'ReadAppShortcut', 'CreateAppShortcut',
   * 'UpdateAppShortcut', 'DeleteAppShortcut', 'ReadAppShortcutParameter',
   * 'CreateAppShortcutParameter', 'UpdateAppShortcutParameter', 'DeleteAppShortcutParameter',
   * 'ReadAppShortcutValue', 'CreateAppShortcutValue', 'UpdateAppShortcutValue',
   * 'DeleteAppShortcutValue', 'ReadTicketByProcessDefinition', 'CreateTicketByProcessDefinition',
   * 'UpdateTicketByProcessDefinition', 'DeleteTicketByProcessDefinition', 'CreateTicketByProcess',
   * 'ReadChatSkeleton', 'CreateChatSkeleton', 'UpdateChatSkeleton', 'DeleteChatSkeleton',
   * 'ReadTicketSkeleton', 'CreateTicketSkeleton', 'UpdateTicketSkeleton', 'DeleteTicketSkeleton',
   * 'ReadResourceTicketSkeletonSelector', 'CreateResourceTicketSkeletonSelector',
   * 'UpdateResourceTicketSkeletonSelector', 'DeleteResourceTicketSkeletonSelector',
   * 'ReadMissionSkeleton', 'CreateMissionSkeleton', 'UpdateMissionSkeleton',
   * 'DeleteMissionSkeleton', 'ReadAuditOfCollection', 'ReadAuditOfProperty', 'ReadCategory',
   * 'CreateCategory', 'UpdateCategory', 'DeleteCategory', 'ReadChatByFormDefinition',
   * 'CreateChatByFormDefinition', 'UpdateChatByFormDefinition', 'DeleteChatByFormDefinition',
   * 'ReadChatByFormMessagePattern', 'CreateChatByFormMessagePattern',
   * 'UpdateChatByFormMessagePattern', 'DeleteChatByFormMessagePattern', 'ReadAreaAssignment',
   * 'CreateAreaAssignment', 'UpdateAreaAssignment', 'DeleteAreaAssignment', 'ReadDeviceMetaEntry',
   * 'CreateDeviceMetaEntry', 'UpdateDeviceMetaEntry', 'DeleteDeviceMetaEntry',
   * 'ReadMissionsPerLocationByMonth', 'ReadMissionsPerSolutionContactByMonth',
   * 'ReadMissionsPerLocationPerTypeByMonth', 'ReadTicketsPerLocationByMonth',
   * 'ReadTicketsPerLocationPerTypeByMonth', 'ReadMissionsPerType', 'ReadMissionsPerResource',
   * 'ReadMissionsPerSolutionContact', 'ReadMissionsPerResourceManufacturer',
   * 'ReadTicketsPerResourceManufacturer', 'ReadTicketsPerType', 'ReadMissionsPerLocationByTime',
   * 'ReadMissionsPerSolutionContactByTime', 'ReadMissionsPerLocationPerTypeByTime',
   * 'ReadTicketsPerLocationByTime', 'ReadTicketsPerLocationPerTypeByTime', 'ReadMissionReceipt',
   * 'CreateMissionReceipt', 'UpdateMissionReceipt', 'DeleteMissionReceipt',
   * 'ReadMissionReceiptReport', 'CreateMissionReceiptReport', 'UpdateMissionReceiptReport',
   * 'DeleteMissionReceiptReport', 'ReadApiKey', 'CreateApiKey', 'UpdateApiKey', 'DeleteApiKey',
   * 'ReadErrorCode', 'CreateErrorCode', 'UpdateErrorCode', 'DeleteErrorCode',
   * 'ReadConsumptionCode', 'CreateConsumptionCode', 'UpdateConsumptionCode',
   * 'DeleteConsumptionCode', 'ReadConnectedDefinition', 'CreateConnectedDefinition',
   * 'UpdateConnectedDefinition', 'DeleteConnectedDefinition', 'ReadOccurredConnectedError',
   * 'CreateOccurredConnectedError', 'UpdateOccurredConnectedError',
   * 'DeleteOccurredConnectedError', 'ReadOccurredConnectedConsumption',
   * 'CreateOccurredConnectedConsumption', 'UpdateOccurredConnectedConsumption',
   * 'DeleteOccurredConnectedConsumption', 'ReadTimelineSetting', 'CreateTimelineSetting',
   * 'UpdateTimelineSetting', 'DeleteTimelineSetting', 'UseGetMeTimeline', 'ReadMailNode',
   * 'CreateMailNode', 'UpdateMailNode', 'DeleteMailNode', 'ReadTicketMailNodeContent',
   * 'CreateTicketMailNodeContent', 'UpdateTicketMailNodeContent', 'DeleteTicketMailNodeContent',
   * 'CreateTicketByContractDefinition', 'ReadTicketByContractDefinition',
   * 'UpdateTicketByContractDefinition', 'DeleteTicketByContractDefinition',
   * 'ReadEditorSuggestion', 'CreateEditorSuggestion', 'UpdateEditorSuggestion',
   * 'DeleteEditorSuggestion', 'ReadEditorSuggestionTarget', 'CreateEditorSuggestionTarget',
   * 'UpdateEditorSuggestionTarget', 'DeleteEditorSuggestionTarget', 'ReadEditorQuest',
   * 'CreateEditorQuest', 'UpdateEditorQuest', 'DeleteEditorQuest', 'ReadEditorRequest',
   * 'CreateEditorRequest', 'UpdateEditorRequest', 'DeleteEditorRequest', 'DisplayEditorRequest',
   * 'ReadResourceTaskConsumptionTrigger', 'CreateResourceTaskConsumptionTrigger',
   * 'UpdateResourceTaskConsumptionTrigger', 'DeleteResourceTaskConsumptionTrigger',
   * 'ReadOccurredConnectedConsumptionEntry', 'CreateOccurredConnectedConsumptionEntry',
   * 'UpdateOccurredConnectedConsumptionEntry', 'DeleteOccurredConnectedConsumptionEntry',
   * 'CreateNotificationRule', 'ReadNotificationRule', 'UpdateNotificationRule',
   * 'DeleteNotificationRule', 'CreateNotificationTarget', 'ReadNotificationTarget',
   * 'UpdateNotificationTarget', 'DeleteNotificationTarget', 'ImportEntity', 'ExportEntity',
   * 'ReadSearchList', 'CreateSearchList', 'UpdateSearchList', 'DeleteSearchList',
   * 'DisplaySearchLists', 'UseSetPassword', 'UseClassification', 'ReadLabel', 'CreateLabel',
   * 'UpdateLabel', 'DeleteLabel', 'DisplayLabels', 'ModifyLabelManually', 'DisplayBackend',
   * 'DisplayMasterData', 'DisplayContacts', 'DisplayFunctions', 'DisplayLocations',
   * 'DisplayLocationGroups', 'DisplayForms', 'DisplayTasks', 'DisplayInfos', 'DisplayKnowledge',
   * 'DisplayDevices', 'DisplayChats', 'DisplayDocuments', 'DisplayHolidays', 'DisplayDashboard',
   * 'DisplayManual', 'DisplayAddresses', 'DisplayCompanies', 'DisplayRole',
   * 'DisplayProductSubscription', 'DisplayStereoTypes', 'DisplaySettings', 'DisplayResources',
   * 'DisplayTickets', 'DisplayStateMachines', 'DisplayDataTables', 'DisplayDataDashboard',
   * 'DisplayPriorities', 'DisplayEscalations', 'DisplayMissions',
   * 'DisplayInventoryNumberPatterns', 'DisplayContracts', 'DisplayResourceTasks',
   * 'DisplayArticles', 'DisplayAppOverviews', 'DisplayProcesses', 'DisplayCategories',
   * 'DisplayServices', 'DisplayConnectedDefinition', 'DisplayMissionReceipts', 'DisplayMailNodes',
   * 'DisplayPortalAnalytics', 'DisplayNotificationRules', 'DisplayAppDashboard',
   * 'DisplayAppInfos', 'DisplayAppTasks', 'DisplayAppTaskArchive', 'DisplayAppKnowledge',
   * 'DisplayAppDocuments', 'DisplayAppChats', 'DisplayKnowledgeExam', 'ReadCalendarMission',
   * 'CreateCalendarMission', 'UpdateCalendarMission', 'DeleteCalendarMission', 'ReadMailLog',
   * 'CreateMailLog', 'UpdateMailLog', 'DeleteMailLog', 'ReadResourcesByLocationDefinition',
   * 'CreateResourcesByLocationDefinition', 'UpdateResourcesByLocationDefinition',
   * 'DeleteResourcesByLocationDefinition', 'ReadTicketsByLocationDefinition',
   * 'CreateTicketsByLocationDefinition', 'UpdateTicketsByLocationDefinition',
   * 'DeleteTicketsByLocationDefinition', 'ReadResourceSkeleton', 'CreateResourceSkeleton',
   * 'UpdateResourceSkeleton', 'DeleteResourceSkeleton', 'ReadTicketByLocationSkeleton',
   * 'CreateTicketByLocationSkeleton', 'UpdateTicketByLocationSkeleton',
   * 'DeleteTicketByLocationSkeleton', 'ReadSafe', 'CreateSafe', 'UpdateSafe', 'DeleteSafe',
   * 'DisplaySafe', 'BypassSafe', 'ReadKnowledgePath', 'CreateKnowledgePath',
   * 'UpdateKnowledgePath', 'DeleteKnowledgePath', 'ReadQuickAccessConfiguration',
   * 'CreateQuickAccessConfiguration', 'UpdateQuickAccessConfiguration',
   * 'DeleteQuickAccessConfiguration', 'DisplayQuickAccessConfiguration',
   * 'ReadQuickAccessKnowledgeConfiguration', 'ReadQuickAccessIssueTemplateConfiguration',
   * 'ReadQuickAccessDashboardConfiguration', 'ReadHintCode', 'CreateHintCode', 'UpdateHintCode',
   * 'DeleteHintCode', 'ReadOccurredConnectedHint', 'CreateOccurredConnectedHint',
   * 'UpdateOccurredConnectedHint', 'DeleteOccurredConnectedHint', 'ReadResourceEvent',
   * 'CreateResourceEvent', 'UpdateResourceEvent', 'DeleteResourceEvent',
   * 'ReadResourceManufacturer', 'CreateResourceManufacturer', 'UpdateResourceManufacturer',
   * 'DeleteResourceManufacturer', 'DisplayResourceManufacturers', 'ReadResourceModel',
   * 'CreateResourceModel', 'UpdateResourceModel', 'DeleteResourceModel', 'MergeResourceModel',
   * 'ReadIssueTemplate', 'CreateIssueTemplate', 'UpdateIssueTemplate', 'DeleteIssueTemplate',
   * 'DisplayIssueTemplates', 'ReadIssueNotification', 'CreateIssueNotification',
   * 'UpdateIssueNotification', 'DeleteIssueNotification', 'ReadMessageIssueContent',
   * 'CreateMessageIssueContent', 'UpdateMessageIssueContent', 'DeleteMessageIssueContent',
   * 'ReadWinterhalterOrder', 'ReadWinterhalterOrderOperation', 'ReadIssue', 'CreateIssue',
   * 'UpdateIssue', 'DeleteIssue', 'ReadAllIssue', 'ReadRecentIssue', 'ReadUserIssue',
   * 'ReadWinterhalterServiceCallIssue', 'ReadWinterhalterProductOrderIssue',
   * 'ReadWinterhalterProductOrder', 'ReadOccurredConnectedHintHistory',
   * 'ReadOccurredConnectedErrorHistory', 'ReadOccurredConnectedConsumptionHistory',
   * 'UseMassImport', 'ReadInvitation', 'CreateInvitation', 'UpdateInvitation', 'DeleteInvitation',
   * 'ShowReportForMaintenance', 'ShowReportForPrime', 'ReadKnowledgeExam', 'CreateKnowledgeExam',
   * 'UpdateKnowledgeExam', 'DeleteKnowledgeExam', 'ReadKnowledgeExamAnswer',
   * 'CreateKnowledgeExamAnswer', 'UpdateKnowledgeExamAnswer', 'DeleteKnowledgeExamAnswer',
   * 'ReadTicketIssueContent', 'CreateTicketIssueContent', 'UpdateTicketIssueContent',
   * 'DeleteTicketIssueContent', 'ReadMissionIssueContent', 'CreateMissionIssueContent',
   * 'UpdateMissionIssueContent', 'DeleteMissionIssueContent',
   * 'ReadWinterhalterServiceIssueContent', 'CreateWinterhalterServiceIssueContent',
   * 'UpdateWinterhalterServiceIssueContent', 'DeleteWinterhalterServiceIssueContent',
   * 'ReadWinterhalterOrderIssueContent', 'CreateWinterhalterOrderIssueContent',
   * 'UpdateWinterhalterOrderIssueContent', 'DeleteWinterhalterOrderIssueContent',
   * 'ReadMessageControlContent', 'CreateMessageControlContent', 'UpdateMessageControlContent',
   * 'DeleteMessageControlContent', 'ReadMissionControlContent', 'CreateMissionControlContent',
   * 'UpdateMissionControlContent', 'DeleteMissionControlContent', 'ReadControl', 'CreateControl',
   * 'UpdateControl', 'DeleteControl', 'DisplayControls', 'ReadControlCondition',
   * 'CreateControlCondition', 'UpdateControlCondition', 'DeleteControlCondition',
   * 'ReadControlTrigger', 'CreateControlTrigger', 'UpdateControlTrigger', 'DeleteControlTrigger',
   * 'ReadControlNotification', 'CreateControlNotification', 'UpdateControlNotification',
   * 'DeleteControlNotification', 'ReadControlIssue', 'ReadIssueReason', 'ReadImportConfiguration',
   * 'CreateImportConfiguration', 'UpdateImportConfiguration', 'DeleteImportConfiguration',
   * 'DisplayImportConfigurations', 'ReadImportProtocol', 'CreateImportProtocol',
   * 'UpdateImportProtocol', 'DeleteImportProtocol', 'DisplayImportProtocol',
   * 'ReadWinterhalterServiceCallMailNodeContent', 'CreateWinterhalterServiceCallMailNodeContent',
   * 'UpdateWinterhalterServiceCallMailNodeContent',
   * 'DeleteWinterhalterServiceCallMailNodeContent', 'ReadMailNodeNotification',
   * 'CreateMailNodeNotification', 'UpdateMailNodeNotification', 'DeleteMailNodeNotification',
   * 'ReadKnowledgePathProgress', 'ReadMailReceiver', 'CreateMailReceiver', 'UpdateMailReceiver',
   * 'DeleteMailReceiver', 'MergeResourceManufacturer'
   */
  permission?: AppPermissions;
  group?: string;
  availableModes?: string[];
}

/**
 * An interface representing PermissionFunctionDto.
 */
export interface PermissionFunctionDto {
  /**
   * Possible values include: 'EditTenant', 'ReadTenantUsage', 'ReadFile', 'EditFile',
   * 'ManipulateFile', 'ShowAppDocuments', 'ShowBackendDocuments', 'SynchronizeMasterData',
   * 'ManipulateMasterData', 'ReadContact', 'EditContact', 'DisplayContact', 'DeleteContact',
   * 'EditSystemContact', 'ReadLocation', 'EditLocation', 'DisplayLocation', 'DeleteLocation',
   * 'ReadAddress', 'EditAddress', 'DisplayAddress', 'DeleteAddress', 'ReadCompany', 'EditCompany',
   * 'DisplayCompany', 'DeleteCompany', 'DisplayInfo', 'ReadInfo', 'EditInfo', 'ReadDevice',
   * 'EditDevice', 'RegisterDevice', 'EditPing', 'EditTask', 'EditTaskJob', 'EditForm',
   * 'EditFilledForm', 'ManipulateFilledForm', 'DisplayKnowledge', 'ReadKnowledge',
   * 'EditKnowledge', 'SubmitAnswer', 'DisplayKnowledgeExam', 'ReadHoliday', 'EditHoliday',
   * 'EditChat', 'ShowAppChat', 'ShowBackendChat', 'AllowChatPerEmail', 'ShowAllChatsPackage',
   * 'ReadFolderAccess', 'EditFolderAccess', 'EditRole', 'EditHistory', 'ReadLoginChallenge',
   * 'EditLoginChallenge', 'ReadLoginProvider', 'EditLoginProvider', 'EditProductSubscription',
   * 'ReadStereotype', 'EditStereotype', 'ManipulateStereotype', 'DisplayStereotype', 'ReadTicket',
   * 'EditTicket', 'ForceTicketState', 'AssignTicketToMe', 'AssignTicketToEditor', 'DisplayTicket',
   * 'DeleteTicket', 'AssignTicketResource', 'EditSettings', 'ReadSettings', 'ReadGraph',
   * 'ShowDashboard', 'ShowAppOverview', 'ReadExternalDocument', 'EditExternalDocument',
   * 'ReadExternalAllDocument', 'ReadResource', 'EditResource', 'DisplayResource',
   * 'DeleteResource', 'ReadQuickAccess', 'EditQuickAccess', 'DisplayQuickAccess',
   * 'ReadStateMachine', 'EditStateMachine', 'DisplayStateMachine', 'ReadDataTable',
   * 'EditDataTable', 'DisplayDataTable', 'ExportDataTable', 'ReadDashboard', 'EditDashboard',
   * 'DisplayDashboard', 'EditAllDashboard', 'ReadPriority', 'EditPriority', 'DisplayPriority',
   * 'ReadEscalation', 'UseEscalation', 'EditEscalation', 'DisplayEscalation', 'ReadAudit',
   * 'AssignMissionToMe', 'AssignMissionToEditor', 'AssignMissionResource', 'ReadMission',
   * 'EditMission', 'EditActualMissionDate', 'EditPlannedMissionDate', 'EditClosedMission',
   * 'DisplayMission', 'DeleteMission', 'DisplayMissionMap', 'DisplayMissionPlan', 'ReadLink',
   * 'EditLink', 'ReadAttachmentForTech', 'EditAttachmentForTech', 'ReadTemplate', 'EditTemplate',
   * 'ReadDocumentTemplate', 'EditDocumentTemplate', 'EditBetaDocumentTemplates',
   * 'ReadInventoryNumberPattern', 'EditInventoryNumberPattern', 'DisplayInventoryNumberPattern',
   * 'ReadContract', 'EditContract', 'DisplayContract', 'DeleteContract', 'ReadResourceTask',
   * 'EditResourceTask', 'DisplayResourceTask', 'DeleteResourceTask', 'ReadArticle', 'EditArticle',
   * 'DisplayArticle', 'DeleteArticle', 'ReadMissionReport', 'EditMissionReport', 'ReadSolution',
   * 'EditSolution', 'ReadAppOverview', 'EditAppOverview', 'DisplayAppOverview',
   * 'DeleteAppOverview', 'ReadTicketByProcessDefinition', 'EditTicketByProcessDefinition',
   * 'DisplayTicketByProcessDefinition', 'DeleteTicketByProcessDefinition',
   * 'CreateTicketByProcess', 'ReadCategory', 'EditCategory', 'DisplayCategory', 'DeleteCategory',
   * 'ReadChatByFormDefinition', 'EditChatByFormDefinition', 'DisplayChatByFormDefinition',
   * 'DeleteChatByFormDefinition', 'StartChatByForm', 'ReadAreaAssignment', 'EditAreaAssignment',
   * 'DisplayServicePackage', 'ReadSubscriptionPackage', 'ReadAnalytic', 'ReadPortalAnalytic',
   * 'ReadApiKey', 'EditApiKey', 'ReadMissionReceipt', 'EditMissionReceipt',
   * 'DisplayMissionReceipt', 'ReadConnectedDefinition', 'EditConnectedDefinition',
   * 'DisplayConnectedDefinition', 'DeleteConnectedDefinition', 'ReadTimelineSetting',
   * 'EditTimelineSetting', 'DeleteTimelineSetting', 'ReadMeTimelineSetting', 'ReadMailNode',
   * 'EditMailNode', 'DeleteMailNode', 'DisplayMailNode', 'ReadNotificationRule',
   * 'EditNotificationRule', 'ReadEditorQuest', 'EditEditorQuest', 'ImportExport',
   * 'ReadSearchList', 'EditSearchList', 'DisplaySearchList', 'DeleteSearchList',
   * 'ReadResourcesByLocationDefinition', 'EditResourcesByLocationDefinition',
   * 'ReadTicketsByLocationDefinition', 'EditTicketsByLocationDefinition', 'UseSetPassword',
   * 'ReadMailLog', 'UseClassification', 'ReadLabel', 'EditLabel', 'DisplayLabel', 'DeleteLabel',
   * 'ModifyLabelManually', 'IsOrgaUser', 'Manager', 'ShowsExpiredSubscription', 'IsAdmin',
   * 'IsSystemAdmin', 'CanUseExpiredSubscription', 'Admin', 'IsOrgaUserInTech',
   * 'IsQuickAccessIssueHistory', 'IsServiceEmployee', 'IsQuickAccessUser',
   * 'IsQuickAccessKnowledge', 'IsQuickAccessIssueTemplate', 'IsQuickAccessDashboard', 'TeamUser',
   * 'AnalystUser', 'TechUser', 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
   * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
   * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
   * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon',
   * 'ClassificationAddon', 'CalendarMission', 'SyncLocationAddon', 'ReadCalendar', 'ReadSafe',
   * 'MaintainSafe', 'AdministrateSafe', 'ReadResourceManufacturer', 'EditResourceManufacturer',
   * 'DisplayResourceManufacturer', 'DeleteResourceManufacturer', 'OrgaTechClassicModule',
   * 'SyncReceiptsClassicAddon', 'SyncContactsClassicAddon', 'SyncDriveClassicAddon',
   * 'NotificationClassicAddon', 'OrgaTechModule', 'SyncReceiptsAddon', 'SyncContactsAddon',
   * 'SyncDriveAddon', 'NotificationAddon', 'TeamModule', 'MdmClassicModule',
   * 'MaintenanceReportAddon', 'PrimeReportAddon', 'ReadIssueTemplate', 'EditIssueTemplate',
   * 'DisplayIssueTemplate', 'DeleteIssueTemplate', 'DeleteWinterhalterIssueTemplate',
   * 'ReadMessageIssueContent', 'EditMessageIssueContent', 'ReadTicketIssueContent',
   * 'EditTicketIssueContent', 'ReadMissionIssueContent', 'EditMissionIssueContent',
   * 'ReadWinterhalterServiceCallIssueContent', 'EditWinterhalterServiceCallIssueContent',
   * 'ReadWinterhalterProductOrderIssueContent', 'EditWinterhalterProductOrderIssueContent',
   * 'EditIssue', 'DeleteIssue', 'SendIssue', 'ReadRecentIssue', 'ReadAllIssue', 'ReadIssue',
   * 'UseMassImport', 'EditInvitation', 'ShowReportForMaintenance', 'ShowReportForPrime',
   * 'ReadControl', 'EditControl', 'DisplayControl', 'DeleteControl', 'ReadImportConfiguration',
   * 'EditImportConfiguration', 'DeleteImportConfiguration', 'DisplayImportConfiguration',
   * 'ReadImportProtocol', 'EditImportProtocol', 'DeleteImportProtocol', 'DisplayImportProtocol'
   */
  package?: AppFunctions;
}

/**
 * An interface representing AppRoleDto.
 */
export interface AppRoleDto {
  appRoleId?: number;
  externalId?: string;
  title?: string;
  isDefault?: boolean;
  /**
   * Possible values include: 'OrgaUser', 'Admin', 'Manager', 'DeviceUser', 'SystemAdmin',
   * 'Custom', 'ServiceEmployee', 'QuickAccessUser', 'QuickAccessKnowledge',
   * 'QuickAccessIssueTemplate', 'QuickAccessDashboard', 'TeamUser', 'Analyst',
   * 'QuickAccessIssueHistory', 'TechUser'
   */
  term?: RoleGeneralTerms;
  tenantId?: number;
  concurrencyStamp?: string;
  isSystemRole?: boolean;
  packages?: PermissionFunctionDto[];
}

/**
 * An interface representing AppRoleExportDto.
 */
export interface AppRoleExportDto {
  externalId?: string;
  title?: string;
  isDefault?: boolean;
  packages?: PermissionFunctionDto[];
}

/**
 * An interface representing LocationRelationDto.
 */
export interface LocationRelationDto {
  locationId?: number;
  location?: LocationSimpleDto;
}

/**
 * An interface representing LocationGroupRelationDto.
 */
export interface LocationGroupRelationDto {
  locationGroupId?: number;
  locationGroup?: LocationGroupSimpleDto;
}

/**
 * An interface representing ContactSimpleDto.
 */
export interface ContactSimpleDto {
  contactId?: number;
  displayName?: string;
  userId?: number;
}

/**
 * An interface representing ContactRelationDto.
 */
export interface ContactRelationDto {
  contactId?: number;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing FunctionRelationDto.
 */
export interface FunctionRelationDto {
  functionId?: number;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing AppRoleInfoDto.
 */
export interface AppRoleInfoDto {
  roleId?: number;
  name?: string;
  /**
   * Possible values include: 'OrgaUser', 'Admin', 'Manager', 'DeviceUser', 'SystemAdmin',
   * 'Custom', 'ServiceEmployee', 'QuickAccessUser', 'QuickAccessKnowledge',
   * 'QuickAccessIssueTemplate', 'QuickAccessDashboard', 'TeamUser', 'Analyst',
   * 'QuickAccessIssueHistory', 'TechUser'
   */
  term?: RoleGeneralTerms;
  locations?: LocationRelationDto[];
  locationGroup?: LocationGroupRelationDto[];
  contacts?: ContactRelationDto[];
  functions?: FunctionRelationDto[];
}

/**
 * An interface representing AppRoleListDto.
 */
export interface AppRoleListDto {
  appRoleId?: number;
  title?: string;
  externalId?: string;
  tenantId?: number;
  concurrencyStamp?: string;
  isDefault?: boolean;
  isSystemRole?: boolean;
  /**
   * Possible values include: 'OrgaUser', 'Admin', 'Manager', 'DeviceUser', 'SystemAdmin',
   * 'Custom', 'ServiceEmployee', 'QuickAccessUser', 'QuickAccessKnowledge',
   * 'QuickAccessIssueTemplate', 'QuickAccessDashboard', 'TeamUser', 'Analyst',
   * 'QuickAccessIssueHistory', 'TechUser'
   */
  term?: RoleGeneralTerms;
}

/**
 * An interface representing AppRoleListForContactDto.
 */
export interface AppRoleListForContactDto {
  appRoleId?: number;
  title?: string;
  externalId?: string;
  tenantId?: number;
  concurrencyStamp?: string;
  isDefault?: boolean;
  isSystemRole?: boolean;
  /**
   * Possible values include: 'OrgaUser', 'Admin', 'Manager', 'DeviceUser', 'SystemAdmin',
   * 'Custom', 'ServiceEmployee', 'QuickAccessUser', 'QuickAccessKnowledge',
   * 'QuickAccessIssueTemplate', 'QuickAccessDashboard', 'TeamUser', 'Analyst',
   * 'QuickAccessIssueHistory', 'TechUser'
   */
  term?: RoleGeneralTerms;
  isInRelation?: boolean;
}

/**
 * An interface representing AppRoleListForContactDtoPageOutputDto.
 */
export interface AppRoleListForContactDtoPageOutputDto {
  paging?: any;
  items?: AppRoleListForContactDto[];
}

/**
 * An interface representing AppRolePermissionDto.
 */
export interface AppRolePermissionDto {
  /**
   * Possible values include: 'ReadTicket', 'CreateTicket', 'UpdateTicket', 'DeleteTicket',
   * 'ForceTicketState', 'AssignTicketEditor', 'AssignTicketToMe', 'AssignTicketResource',
   * 'ReadResource', 'CreateResource', 'UpdateResource', 'DeleteResource', 'ReadCustomProperty',
   * 'CreateCustomProperty', 'UpdateCustomProperty', 'DeleteCustomProperty',
   * 'ReadCustomPropertySet', 'CreateCustomPropertySet', 'UpdateCustomPropertySet',
   * 'DeleteCustomPropertySet', 'ReadStereotype', 'CreateStereotype', 'UpdateStereotype',
   * 'DeleteStereotype', 'ReadSetting', 'UpdateSetting', 'ReadCompany', 'CreateCompany',
   * 'UpdateCompany', 'DeleteCompany', 'ReadLocation', 'CreateLocation', 'UpdateLocation',
   * 'DeleteLocation', 'ReadContact', 'CreateContact', 'UpdateContact', 'DeleteContact',
   * 'CreateSystemContact', 'ReadSystemContact', 'UpdateSystemContact', 'DeleteSystemContact',
   * 'ReadFunction', 'CreateFunction', 'UpdateFunction', 'DeleteFunction', 'ReadAddress',
   * 'CreateAddress', 'UpdateAddress', 'DeleteAddress', 'ReadNote', 'CreateNote', 'UpdateNote',
   * 'DeleteNote', 'ReadNoteMailDroppedAttachment', 'ReadRole', 'CreateRole', 'UpdateRole',
   * 'DeleteRole', 'ReadAppPermissions', 'ReadTenant', 'CreateTenant', 'UpdateTenant',
   * 'DeleteTenant', 'ReadTenantUsage', 'ReadLoginProvider', 'CreateLoginProvider',
   * 'UpdateLoginProvider', 'DeleteLoginProvider', 'ReadDevice', 'CreateDevice', 'UpdateDevice',
   * 'DeleteDevice', 'UsePinLogin', 'UsePushRegister', 'UseDeviceRegister', 'UseExternalAppLogin',
   * 'UseBadgeLogin', 'ReadLocationGroup', 'CreateLocationGroup', 'UpdateLocationGroup',
   * 'DeleteLocationGroup', 'ReadInfo', 'CreateInfo', 'UpdateInfo', 'DeleteInfo', 'ReadTask',
   * 'CreateTask', 'UpdateTask', 'DeleteTask', 'ReadForm', 'CreateForm', 'UpdateForm',
   * 'DeleteForm', 'ReadSensitiveForm', 'ReadFilledForm', 'CreateFilledForm', 'UpdateFilledForm',
   * 'DeleteFilledForm', 'ReadChat', 'CreateChat', 'UpdateChat', 'DeleteChat', 'UseEmailPerChat',
   * 'UseChatWithoutCloak', 'ReadOverview', 'ReadKnowledgeArticle', 'CreateKnowledgeArticle',
   * 'UpdateKnowledgeArticle', 'DeleteKnowledgeArticle', 'ReadHolidaySet', 'CreateHolidaySet',
   * 'UpdateHolidaySet', 'DeleteHolidaySet', 'ReadFile', 'CreateFile', 'UpdateFile', 'DeleteFile',
   * 'ReadLoginChallenge', 'CreateLoginChallenge', 'UpdateLoginChallenge', 'DeleteLoginChallenge',
   * 'ReadFilledFormField', 'CreateFilledFormField', 'UpdateFilledFormField',
   * 'DeleteFilledFormField', 'ReadFormRow', 'CreateFormRow', 'UpdateFormRow', 'DeleteFormRow',
   * 'ReadFormField', 'CreateFormField', 'UpdateFormField', 'DeleteFormField', 'ReadAttachment',
   * 'CreateAttachment', 'UpdateAttachment', 'DeleteAttachment', 'ReadExecution',
   * 'CreateExecution', 'UpdateExecution', 'DeleteExecution', 'ReadImpact', 'CreateImpact',
   * 'UpdateImpact', 'DeleteImpact', 'ReadCondition', 'CreateCondition', 'UpdateCondition',
   * 'DeleteCondition', 'ReadChatMessage', 'CreateChatMessage', 'UpdateChatMessage',
   * 'DeleteChatMessage', 'ReadChatMember', 'CreateChatMember', 'UpdateChatMember',
   * 'DeleteChatMember', 'ReadChatMemberMessage', 'CreateChatMemberMessage',
   * 'UpdateChatMemberMessage', 'DeleteChatMemberMessage', 'ReadHoliday', 'CreateHoliday',
   * 'UpdateHoliday', 'DeleteHoliday', 'ReadHistory', 'CreateHistory', 'UpdateHistory',
   * 'DeleteHistory', 'ReadTaskJob', 'CreateTaskJob', 'UpdateTaskJob', 'DeleteTaskJob',
   * 'ReadTaskJobStatus', 'CreateTaskJobStatus', 'UpdateTaskJobStatus', 'DeleteTaskJobStatus',
   * 'ReadFolderAccess', 'CreateFolderAccess', 'UpdateFolderAccess', 'DeleteFolderAccess',
   * 'UseAllFolderAccess', 'ReadTaskJobStateReasonSet', 'CreateTaskJobStateReasonSet',
   * 'UpdateTaskJobStateReasonSet', 'DeleteTaskJobStateReasonSet', 'ReadTaskJobStateReason',
   * 'CreateTaskJobStateReason', 'UpdateTaskJobStateReason', 'DeleteTaskJobStateReason',
   * 'ReadPing', 'CreatePing', 'UpdatePing', 'DeletePing', 'ReadMe', 'ReadProductSubscription',
   * 'CreateProductSubscription', 'UpdateProductSubscription', 'DeleteProductSubscription',
   * 'UseExpiredSubscription', 'ShowExpiredSubscription', 'UseAdmin', 'ReadGraph',
   * 'ReadDateTimePattern', 'CreateDateTimePattern', 'UpdateDateTimePattern',
   * 'DeleteDateTimePattern', 'ReadFormValue', 'CreateFormValue', 'UpdateFormValue',
   * 'DeleteFormValue', 'ReadPlannedTime', 'CreatePlannedTime', 'UpdatePlannedTime',
   * 'DeletePlannedTime', 'ReadTaskJobCondition', 'CreateTaskJobCondition',
   * 'UpdateTaskJobCondition', 'DeleteTaskJobCondition', 'ReadCustomValue', 'CreateCustomValue',
   * 'UpdateCustomValue', 'DeleteCustomValue', 'ReadCustomPropertyValue',
   * 'CreateCustomPropertyValue', 'UpdateCustomPropertyValue', 'DeleteCustomPropertyValue',
   * 'ReadSettingValue', 'CreateSettingValue', 'UpdateSettingValue', 'DeleteSettingValue',
   * 'ReadState', 'CreateState', 'UpdateState', 'DeleteState', 'ReadStateMachine',
   * 'CreateStateMachine', 'UpdateStateMachine', 'DeleteStateMachine', 'ReadStateTransition',
   * 'CreateStateTransition', 'UpdateStateTransition', 'DeleteStateTransition', 'ReadDataTable',
   * 'CreateDataTable', 'UpdateDataTable', 'DeleteDataTable', 'ExportDataTable',
   * 'ReadDataTableColumn', 'CreateDataTableColumn', 'UpdateDataTableColumn',
   * 'DeleteDataTableColumn', 'ReadDashboard', 'CreateDashboard', 'UpdateDashboard',
   * 'DeleteDashboard', 'UseAllDashboard', 'ReadSolution', 'CreateSolution', 'UpdateSolution',
   * 'DeleteSolution', 'ReadSolutionItem', 'CreateSolutionItem', 'UpdateSolutionItem',
   * 'DeleteSolutionItem', 'ReadDataTableFilter', 'CreateDataTableFilter', 'UpdateDataTableFilter',
   * 'DeleteDataTableFilter', 'ReadPrioritySet', 'CreatePrioritySet', 'UpdatePrioritySet',
   * 'DeletePrioritySet', 'ReadPriority', 'CreatePriority', 'UpdatePriority', 'DeletePriority',
   * 'ReadEscalationLevel', 'CreateEscalationLevel', 'UpdateEscalationLevel',
   * 'DeleteEscalationLevel', 'ReadEscalationRule', 'CreateEscalationRule', 'UpdateEscalationRule',
   * 'DeleteEscalationRule', 'ReadTicketEvent', 'CreateTicketEvent', 'UpdateTicketEvent',
   * 'DeleteTicketEvent', 'ReadAudit', 'ReadMission', 'CreateMission', 'UpdateMission',
   * 'DeleteMission', 'UpdateActualMissionDates', 'UpdatePlannedMissionDates',
   * 'UpdateClosedMission', 'DisplayMissionMap', 'DisplayMissionPlan', 'AssignMissionResource',
   * 'AssignMissionEditor', 'AssignMissionToMe', 'ReadLink', 'CreateLink', 'UpdateLink',
   * 'DeleteLink', 'ReadLinkedElement', 'CreateLinkedElement', 'UpdateLinkedElement',
   * 'DeleteLinkedElement', 'ReadAttachmentForTech', 'CreateAttachmentForTech',
   * 'UpdateAttachmentForTech', 'DeleteAttachmentForTech', 'ReadMailBox', 'CreateMailBox',
   * 'UpdateMailBox', 'DeleteMailBox', 'ReadTemplate', 'CreateTemplate', 'UpdateTemplate',
   * 'DeleteTemplate', 'ReadDocumentTemplate', 'CreateDocumentTemplate', 'UpdateDocumentTemplate',
   * 'DeleteDocumentTemplate', 'EditBetaDocumentTemplates', 'ReadInventoryNumberPattern',
   * 'CreateInventoryNumberPattern', 'UpdateInventoryNumberPattern',
   * 'DeleteInventoryNumberPattern', 'ReadInventoryNumber', 'CreateInventoryNumber',
   * 'UpdateInventoryNumber', 'DeleteInventoryNumber', 'ReadContract', 'CreateContract',
   * 'UpdateContract', 'DeleteContract', 'ReadResourceTaskSelector', 'CreateResourceTaskSelector',
   * 'UpdateResourceTaskSelector', 'DeleteResourceTaskSelector', 'ReadResourceTaskPattern',
   * 'CreateResourceTaskPattern', 'UpdateResourceTaskPattern', 'DeleteResourceTaskPattern',
   * 'ReadResourceTaskCondition', 'CreateResourceTaskCondition', 'UpdateResourceTaskCondition',
   * 'DeleteResourceTaskCondition', 'ReadResourceTask', 'CreateResourceTask', 'UpdateResourceTask',
   * 'DeleteResourceTask', 'ReadArticleKind', 'CreateArticleKind', 'UpdateArticleKind',
   * 'DeleteArticleKind', 'ReadMissionReport', 'CreateMissionReport', 'UpdateMissionReport',
   * 'DeleteMissionReport', 'ReadMissionReportGroup', 'CreateMissionReportGroup',
   * 'UpdateMissionReportGroup', 'DeleteMissionReportGroup', 'ReadArticleUsage',
   * 'CreateArticleUsage', 'UpdateArticleUsage', 'DeleteArticleUsage', 'ReadArticle',
   * 'CreateArticle', 'UpdateArticle', 'DeleteArticle', 'ReadAppOverview', 'CreateAppOverview',
   * 'UpdateAppOverview', 'DeleteAppOverview', 'ReadAppShortcut', 'CreateAppShortcut',
   * 'UpdateAppShortcut', 'DeleteAppShortcut', 'ReadAppShortcutParameter',
   * 'CreateAppShortcutParameter', 'UpdateAppShortcutParameter', 'DeleteAppShortcutParameter',
   * 'ReadAppShortcutValue', 'CreateAppShortcutValue', 'UpdateAppShortcutValue',
   * 'DeleteAppShortcutValue', 'ReadTicketByProcessDefinition', 'CreateTicketByProcessDefinition',
   * 'UpdateTicketByProcessDefinition', 'DeleteTicketByProcessDefinition', 'CreateTicketByProcess',
   * 'ReadChatSkeleton', 'CreateChatSkeleton', 'UpdateChatSkeleton', 'DeleteChatSkeleton',
   * 'ReadTicketSkeleton', 'CreateTicketSkeleton', 'UpdateTicketSkeleton', 'DeleteTicketSkeleton',
   * 'ReadResourceTicketSkeletonSelector', 'CreateResourceTicketSkeletonSelector',
   * 'UpdateResourceTicketSkeletonSelector', 'DeleteResourceTicketSkeletonSelector',
   * 'ReadMissionSkeleton', 'CreateMissionSkeleton', 'UpdateMissionSkeleton',
   * 'DeleteMissionSkeleton', 'ReadAuditOfCollection', 'ReadAuditOfProperty', 'ReadCategory',
   * 'CreateCategory', 'UpdateCategory', 'DeleteCategory', 'ReadChatByFormDefinition',
   * 'CreateChatByFormDefinition', 'UpdateChatByFormDefinition', 'DeleteChatByFormDefinition',
   * 'ReadChatByFormMessagePattern', 'CreateChatByFormMessagePattern',
   * 'UpdateChatByFormMessagePattern', 'DeleteChatByFormMessagePattern', 'ReadAreaAssignment',
   * 'CreateAreaAssignment', 'UpdateAreaAssignment', 'DeleteAreaAssignment', 'ReadDeviceMetaEntry',
   * 'CreateDeviceMetaEntry', 'UpdateDeviceMetaEntry', 'DeleteDeviceMetaEntry',
   * 'ReadMissionsPerLocationByMonth', 'ReadMissionsPerSolutionContactByMonth',
   * 'ReadMissionsPerLocationPerTypeByMonth', 'ReadTicketsPerLocationByMonth',
   * 'ReadTicketsPerLocationPerTypeByMonth', 'ReadMissionsPerType', 'ReadMissionsPerResource',
   * 'ReadMissionsPerSolutionContact', 'ReadMissionsPerResourceManufacturer',
   * 'ReadTicketsPerResourceManufacturer', 'ReadTicketsPerType', 'ReadMissionsPerLocationByTime',
   * 'ReadMissionsPerSolutionContactByTime', 'ReadMissionsPerLocationPerTypeByTime',
   * 'ReadTicketsPerLocationByTime', 'ReadTicketsPerLocationPerTypeByTime', 'ReadMissionReceipt',
   * 'CreateMissionReceipt', 'UpdateMissionReceipt', 'DeleteMissionReceipt',
   * 'ReadMissionReceiptReport', 'CreateMissionReceiptReport', 'UpdateMissionReceiptReport',
   * 'DeleteMissionReceiptReport', 'ReadApiKey', 'CreateApiKey', 'UpdateApiKey', 'DeleteApiKey',
   * 'ReadErrorCode', 'CreateErrorCode', 'UpdateErrorCode', 'DeleteErrorCode',
   * 'ReadConsumptionCode', 'CreateConsumptionCode', 'UpdateConsumptionCode',
   * 'DeleteConsumptionCode', 'ReadConnectedDefinition', 'CreateConnectedDefinition',
   * 'UpdateConnectedDefinition', 'DeleteConnectedDefinition', 'ReadOccurredConnectedError',
   * 'CreateOccurredConnectedError', 'UpdateOccurredConnectedError',
   * 'DeleteOccurredConnectedError', 'ReadOccurredConnectedConsumption',
   * 'CreateOccurredConnectedConsumption', 'UpdateOccurredConnectedConsumption',
   * 'DeleteOccurredConnectedConsumption', 'ReadTimelineSetting', 'CreateTimelineSetting',
   * 'UpdateTimelineSetting', 'DeleteTimelineSetting', 'UseGetMeTimeline', 'ReadMailNode',
   * 'CreateMailNode', 'UpdateMailNode', 'DeleteMailNode', 'ReadTicketMailNodeContent',
   * 'CreateTicketMailNodeContent', 'UpdateTicketMailNodeContent', 'DeleteTicketMailNodeContent',
   * 'CreateTicketByContractDefinition', 'ReadTicketByContractDefinition',
   * 'UpdateTicketByContractDefinition', 'DeleteTicketByContractDefinition',
   * 'ReadEditorSuggestion', 'CreateEditorSuggestion', 'UpdateEditorSuggestion',
   * 'DeleteEditorSuggestion', 'ReadEditorSuggestionTarget', 'CreateEditorSuggestionTarget',
   * 'UpdateEditorSuggestionTarget', 'DeleteEditorSuggestionTarget', 'ReadEditorQuest',
   * 'CreateEditorQuest', 'UpdateEditorQuest', 'DeleteEditorQuest', 'ReadEditorRequest',
   * 'CreateEditorRequest', 'UpdateEditorRequest', 'DeleteEditorRequest', 'DisplayEditorRequest',
   * 'ReadResourceTaskConsumptionTrigger', 'CreateResourceTaskConsumptionTrigger',
   * 'UpdateResourceTaskConsumptionTrigger', 'DeleteResourceTaskConsumptionTrigger',
   * 'ReadOccurredConnectedConsumptionEntry', 'CreateOccurredConnectedConsumptionEntry',
   * 'UpdateOccurredConnectedConsumptionEntry', 'DeleteOccurredConnectedConsumptionEntry',
   * 'CreateNotificationRule', 'ReadNotificationRule', 'UpdateNotificationRule',
   * 'DeleteNotificationRule', 'CreateNotificationTarget', 'ReadNotificationTarget',
   * 'UpdateNotificationTarget', 'DeleteNotificationTarget', 'ImportEntity', 'ExportEntity',
   * 'ReadSearchList', 'CreateSearchList', 'UpdateSearchList', 'DeleteSearchList',
   * 'DisplaySearchLists', 'UseSetPassword', 'UseClassification', 'ReadLabel', 'CreateLabel',
   * 'UpdateLabel', 'DeleteLabel', 'DisplayLabels', 'ModifyLabelManually', 'DisplayBackend',
   * 'DisplayMasterData', 'DisplayContacts', 'DisplayFunctions', 'DisplayLocations',
   * 'DisplayLocationGroups', 'DisplayForms', 'DisplayTasks', 'DisplayInfos', 'DisplayKnowledge',
   * 'DisplayDevices', 'DisplayChats', 'DisplayDocuments', 'DisplayHolidays', 'DisplayDashboard',
   * 'DisplayManual', 'DisplayAddresses', 'DisplayCompanies', 'DisplayRole',
   * 'DisplayProductSubscription', 'DisplayStereoTypes', 'DisplaySettings', 'DisplayResources',
   * 'DisplayTickets', 'DisplayStateMachines', 'DisplayDataTables', 'DisplayDataDashboard',
   * 'DisplayPriorities', 'DisplayEscalations', 'DisplayMissions',
   * 'DisplayInventoryNumberPatterns', 'DisplayContracts', 'DisplayResourceTasks',
   * 'DisplayArticles', 'DisplayAppOverviews', 'DisplayProcesses', 'DisplayCategories',
   * 'DisplayServices', 'DisplayConnectedDefinition', 'DisplayMissionReceipts', 'DisplayMailNodes',
   * 'DisplayPortalAnalytics', 'DisplayNotificationRules', 'DisplayAppDashboard',
   * 'DisplayAppInfos', 'DisplayAppTasks', 'DisplayAppTaskArchive', 'DisplayAppKnowledge',
   * 'DisplayAppDocuments', 'DisplayAppChats', 'DisplayKnowledgeExam', 'ReadCalendarMission',
   * 'CreateCalendarMission', 'UpdateCalendarMission', 'DeleteCalendarMission', 'ReadMailLog',
   * 'CreateMailLog', 'UpdateMailLog', 'DeleteMailLog', 'ReadResourcesByLocationDefinition',
   * 'CreateResourcesByLocationDefinition', 'UpdateResourcesByLocationDefinition',
   * 'DeleteResourcesByLocationDefinition', 'ReadTicketsByLocationDefinition',
   * 'CreateTicketsByLocationDefinition', 'UpdateTicketsByLocationDefinition',
   * 'DeleteTicketsByLocationDefinition', 'ReadResourceSkeleton', 'CreateResourceSkeleton',
   * 'UpdateResourceSkeleton', 'DeleteResourceSkeleton', 'ReadTicketByLocationSkeleton',
   * 'CreateTicketByLocationSkeleton', 'UpdateTicketByLocationSkeleton',
   * 'DeleteTicketByLocationSkeleton', 'ReadSafe', 'CreateSafe', 'UpdateSafe', 'DeleteSafe',
   * 'DisplaySafe', 'BypassSafe', 'ReadKnowledgePath', 'CreateKnowledgePath',
   * 'UpdateKnowledgePath', 'DeleteKnowledgePath', 'ReadQuickAccessConfiguration',
   * 'CreateQuickAccessConfiguration', 'UpdateQuickAccessConfiguration',
   * 'DeleteQuickAccessConfiguration', 'DisplayQuickAccessConfiguration',
   * 'ReadQuickAccessKnowledgeConfiguration', 'ReadQuickAccessIssueTemplateConfiguration',
   * 'ReadQuickAccessDashboardConfiguration', 'ReadHintCode', 'CreateHintCode', 'UpdateHintCode',
   * 'DeleteHintCode', 'ReadOccurredConnectedHint', 'CreateOccurredConnectedHint',
   * 'UpdateOccurredConnectedHint', 'DeleteOccurredConnectedHint', 'ReadResourceEvent',
   * 'CreateResourceEvent', 'UpdateResourceEvent', 'DeleteResourceEvent',
   * 'ReadResourceManufacturer', 'CreateResourceManufacturer', 'UpdateResourceManufacturer',
   * 'DeleteResourceManufacturer', 'DisplayResourceManufacturers', 'ReadResourceModel',
   * 'CreateResourceModel', 'UpdateResourceModel', 'DeleteResourceModel', 'MergeResourceModel',
   * 'ReadIssueTemplate', 'CreateIssueTemplate', 'UpdateIssueTemplate', 'DeleteIssueTemplate',
   * 'DisplayIssueTemplates', 'ReadIssueNotification', 'CreateIssueNotification',
   * 'UpdateIssueNotification', 'DeleteIssueNotification', 'ReadMessageIssueContent',
   * 'CreateMessageIssueContent', 'UpdateMessageIssueContent', 'DeleteMessageIssueContent',
   * 'ReadWinterhalterOrder', 'ReadWinterhalterOrderOperation', 'ReadIssue', 'CreateIssue',
   * 'UpdateIssue', 'DeleteIssue', 'ReadAllIssue', 'ReadRecentIssue', 'ReadUserIssue',
   * 'ReadWinterhalterServiceCallIssue', 'ReadWinterhalterProductOrderIssue',
   * 'ReadWinterhalterProductOrder', 'ReadOccurredConnectedHintHistory',
   * 'ReadOccurredConnectedErrorHistory', 'ReadOccurredConnectedConsumptionHistory',
   * 'UseMassImport', 'ReadInvitation', 'CreateInvitation', 'UpdateInvitation', 'DeleteInvitation',
   * 'ShowReportForMaintenance', 'ShowReportForPrime', 'ReadKnowledgeExam', 'CreateKnowledgeExam',
   * 'UpdateKnowledgeExam', 'DeleteKnowledgeExam', 'ReadKnowledgeExamAnswer',
   * 'CreateKnowledgeExamAnswer', 'UpdateKnowledgeExamAnswer', 'DeleteKnowledgeExamAnswer',
   * 'ReadTicketIssueContent', 'CreateTicketIssueContent', 'UpdateTicketIssueContent',
   * 'DeleteTicketIssueContent', 'ReadMissionIssueContent', 'CreateMissionIssueContent',
   * 'UpdateMissionIssueContent', 'DeleteMissionIssueContent',
   * 'ReadWinterhalterServiceIssueContent', 'CreateWinterhalterServiceIssueContent',
   * 'UpdateWinterhalterServiceIssueContent', 'DeleteWinterhalterServiceIssueContent',
   * 'ReadWinterhalterOrderIssueContent', 'CreateWinterhalterOrderIssueContent',
   * 'UpdateWinterhalterOrderIssueContent', 'DeleteWinterhalterOrderIssueContent',
   * 'ReadMessageControlContent', 'CreateMessageControlContent', 'UpdateMessageControlContent',
   * 'DeleteMessageControlContent', 'ReadMissionControlContent', 'CreateMissionControlContent',
   * 'UpdateMissionControlContent', 'DeleteMissionControlContent', 'ReadControl', 'CreateControl',
   * 'UpdateControl', 'DeleteControl', 'DisplayControls', 'ReadControlCondition',
   * 'CreateControlCondition', 'UpdateControlCondition', 'DeleteControlCondition',
   * 'ReadControlTrigger', 'CreateControlTrigger', 'UpdateControlTrigger', 'DeleteControlTrigger',
   * 'ReadControlNotification', 'CreateControlNotification', 'UpdateControlNotification',
   * 'DeleteControlNotification', 'ReadControlIssue', 'ReadIssueReason', 'ReadImportConfiguration',
   * 'CreateImportConfiguration', 'UpdateImportConfiguration', 'DeleteImportConfiguration',
   * 'DisplayImportConfigurations', 'ReadImportProtocol', 'CreateImportProtocol',
   * 'UpdateImportProtocol', 'DeleteImportProtocol', 'DisplayImportProtocol',
   * 'ReadWinterhalterServiceCallMailNodeContent', 'CreateWinterhalterServiceCallMailNodeContent',
   * 'UpdateWinterhalterServiceCallMailNodeContent',
   * 'DeleteWinterhalterServiceCallMailNodeContent', 'ReadMailNodeNotification',
   * 'CreateMailNodeNotification', 'UpdateMailNodeNotification', 'DeleteMailNodeNotification',
   * 'ReadKnowledgePathProgress', 'ReadMailReceiver', 'CreateMailReceiver', 'UpdateMailReceiver',
   * 'DeleteMailReceiver', 'MergeResourceManufacturer'
   */
  permission?: AppPermissions;
}

/**
 * An interface representing ChatByFormDefinitionSimpleDto.
 */
export interface ChatByFormDefinitionSimpleDto {
  chatByFormDefinitionId?: number;
  externalId?: string;
  title?: string;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing AppShortcutCreateChatByDefinitionDto.
 */
export interface AppShortcutCreateChatByDefinitionDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  chatByFormDefinition?: ChatByFormDefinitionSimpleDto;
}

/**
 * An interface representing ChatByFormDefinitionSimpleExportDto.
 */
export interface ChatByFormDefinitionSimpleExportDto {
  externalId?: string;
  title?: string;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing AppShortcutCreateChatByFormDefinitionExportDto.
 */
export interface AppShortcutCreateChatByFormDefinitionExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  chatByFormDefinition?: ChatByFormDefinitionSimpleExportDto;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing TicketByProcessDefinitionSimpleDto.
 */
export interface TicketByProcessDefinitionSimpleDto {
  externalId?: string;
  ticketByProcessDefinitionId?: number;
  name?: string;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing AppShortcutCreateChatByProcessDto.
 */
export interface AppShortcutCreateChatByProcessDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  chatByProcessDefinition?: TicketByProcessDefinitionSimpleDto;
}

/**
 * An interface representing TicketByProcessDefinitionSimpleExportDto.
 */
export interface TicketByProcessDefinitionSimpleExportDto {
  externalId?: string;
  name?: string;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing AppShortcutCreateChatByProcessExportDto.
 */
export interface AppShortcutCreateChatByProcessExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  chatByProcessDefinition?: TicketByProcessDefinitionSimpleExportDto;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing AppShortcutCreateChatDto.
 */
export interface AppShortcutCreateChatDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
}

/**
 * An interface representing AppShortcutCreateChatExportDto.
 */
export interface AppShortcutCreateChatExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing KnowledgeArticleSimpleDto.
 */
export interface KnowledgeArticleSimpleDto {
  externalId?: string;
  knowledgeArticleId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing AppShortcutOpenKnowledgeArticleDto.
 */
export interface AppShortcutOpenKnowledgeArticleDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  knowledgeArticle?: KnowledgeArticleSimpleDto;
}

/**
 * An interface representing KnowledgeArticleSimpleExportDto.
 */
export interface KnowledgeArticleSimpleExportDto {
  externalId?: string;
  title?: string;
}

/**
 * An interface representing AppShortcutOpenKnowledgeArticleExportDto.
 */
export interface AppShortcutOpenKnowledgeArticleExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  knowledgeArticle?: KnowledgeArticleSimpleExportDto;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing AppShortcutRouteDto.
 */
export interface AppShortcutRouteDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  route?: string;
}

/**
 * An interface representing AppShortcutRouteExportDto.
 */
export interface AppShortcutRouteExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  route?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing AppShortcutShowDocumentFolderDto.
 */
export interface AppShortcutShowDocumentFolderDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  path?: string;
}

/**
 * An interface representing AppShortcutShowDocumentFolderExportDto.
 */
export interface AppShortcutShowDocumentFolderExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  path?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing TaskSimpleDto.
 */
export interface TaskSimpleDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing AppShortcutShowTaskArchiveDto.
 */
export interface AppShortcutShowTaskArchiveDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  startOffset?: string;
  references?: TaskSimpleDto[];
}

/**
 * An interface representing TaskSimpleExportDto.
 */
export interface TaskSimpleExportDto {
  externalId?: string;
}

/**
 * An interface representing AppShortcutShowTaskArchiveExportDto.
 */
export interface AppShortcutShowTaskArchiveExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  startOffset?: string;
  references?: TaskSimpleExportDto[];
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing AppShortcutUrlDto.
 */
export interface AppShortcutUrlDto {
  appShortcutId?: number;
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
  tenantId?: number;
  parameters?: AppShortcutParameterDto[];
  url?: string;
}

/**
 * An interface representing AppShortcutUrlExportDto.
 */
export interface AppShortcutUrlExportDto {
  title?: string;
  position?: number;
  subTitle?: string;
  icon?: string;
  parameters?: AppShortcutParameterDto[];
  url?: string;
  /**
   * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
   * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
   */
  type?: AppShortcutType;
}

/**
 * An interface representing AppTenantDto.
 */
export interface AppTenantDto {
  tenantId?: number;
  name?: string;
  domain?: string;
  displayName?: string;
  internalCustomNumber?: string;
  /**
   * Possible values include: 'None', 'Location', 'User'
   */
  primaryBillingSource?: PrimaryBillingSource;
  /**
   * Possible values include: 'Semco'
   */
  seed?: Seeds;
  /**
   * Possible values include: 'Nexnox', 'Semco', 'Pilona', 'Winterhalter'
   */
  brand?: Brands;
  isActive?: boolean;
}

/**
 * An interface representing AppTenantListDto.
 */
export interface AppTenantListDto {
  tenantId?: number;
  name?: string;
  domain?: string;
  displayName?: string;
  isActive?: boolean;
  internalCustomNumber?: string;
  /**
   * Possible values include: 'Nexnox', 'Semco', 'Pilona', 'Winterhalter'
   */
  brand?: Brands;
  nextDemoExpirationDate?: string;
}

/**
 * An interface representing AppTenantTokenDto.
 */
export interface AppTenantTokenDto {
  tenantName?: string;
  accessToken?: string;
  tenantId?: number;
}

/**
 * An interface representing TimelineTaskDto.
 */
export interface TimelineTaskDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  tenantId?: number;
  reasonSetId?: number;
}

/**
 * An interface representing TimelineEntryTaskJobDto.
 */
export interface TimelineEntryTaskJobDto {
  taskJobId?: number;
  executionDate?: string;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  currentStatus?: TaskJobState;
  children?: TimelineEntryTaskJobDto[];
  parentId?: number;
  locationId?: number;
  task?: TimelineTaskDto;
}

/**
 * An interface representing TimelineResourceDto.
 */
export interface TimelineResourceDto {
  resourceId?: number;
  name?: string;
  inventoryNumber?: string;
  stereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing TimelineEntryMissionDto.
 */
export interface TimelineEntryMissionDto {
  missionId?: number;
  title?: string;
  plannedStart?: string;
  plannedEnd?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  lastEmailSend?: string;
  solutionContact?: ContactSimpleDto;
  stereotype?: StereotypeSimpleDto;
  resource?: TimelineResourceDto;
}

/**
 * An interface representing AppTimelineListDto.
 */
export interface AppTimelineListDto {
  date?: string;
  /**
   * Possible values include: 'Unspecified', 'Overdue', 'Escalated'
   */
  importance?: TimelineEntryImportance;
  /**
   * Possible values include: 'TaskJob', 'Mission'
   */
  type?: TimelineEntryType;
  taskJobId?: number;
  taskJob?: TimelineEntryTaskJobDto;
  missionId?: number;
  mission?: TimelineEntryMissionDto;
}

/**
 * An interface representing CategorySimpleDto.
 */
export interface CategorySimpleDto {
  categoryId?: number;
  name?: string;
  color?: string;
}

/**
 * An interface representing AppTimelineSettingDto.
 */
export interface AppTimelineSettingDto {
  categories?: CategorySimpleDto[];
  stereotypes?: StereotypeSimpleDto[];
  isActiveMissionOnly?: boolean;
}

/**
 * An interface representing AppTokenDto.
 */
export interface AppTokenDto {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly accessTokens?: { [propertyName: string]: string };
  tokens?: AppTenantTokenDto[];
  refreshToken?: string;
  token?: string;
}

/**
 * An interface representing AppliedTemplateDto.
 */
export interface AppliedTemplateDto {
  uri?: string;
}

/**
 * An interface representing ResourceSimpleDto.
 */
export interface ResourceSimpleDto {
  resourceId?: number;
  name?: string;
  inventoryNumber?: string;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  tenantId?: number;
}

/**
 * An interface representing ApplyFromResourceByLocationDto.
 */
export interface ApplyFromResourceByLocationDto {
  id?: number;
  tenantId?: number;
  name?: string;
  parent?: ResourceSimpleDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  installationDate?: string;
}

/**
 * An interface representing ApplyFromResourcesByLocationDto.
 */
export interface ApplyFromResourcesByLocationDto {
  resources?: ApplyFromResourceByLocationDto[];
}

/**
 * An interface representing StateSimpleDto.
 */
export interface StateSimpleDto {
  externalId?: string;
  stateId?: number;
  name?: string;
  color?: string;
  tenantId?: number;
}

/**
 * An interface representing PrioritySimpleDto.
 */
export interface PrioritySimpleDto {
  name?: string;
  priorityId?: number;
  tenantId?: number;
}

/**
 * An interface representing ApplyFromTicketByLocationDto.
 */
export interface ApplyFromTicketByLocationDto {
  id?: number;
  tenantId?: number;
  title?: string;
  description?: string;
  currentState?: StateSimpleDto;
  priority?: PrioritySimpleDto;
  resource?: ResourceSimpleDto;
  editor?: ContactSimpleDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing ApplyFromTicketsByLocationDto.
 */
export interface ApplyFromTicketsByLocationDto {
  tickets?: ApplyFromTicketByLocationDto[];
}

/**
 * An interface representing ApplyLocationOntoResourceQueueDto.
 */
export interface ApplyLocationOntoResourceQueueDto {
  locationId?: number;
}

/**
 * An interface representing ArchiveDto.
 */
export interface ArchiveDto {
  entityId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing ArchivedEntityQueueDto.
 */
export interface ArchivedEntityQueueDto {
  entityId?: number;
  tenantId?: number;
}

/**
 * An interface representing ArchivedTaskReasonQueueDto.
 */
export interface ArchivedTaskReasonQueueDto {
  taskId?: number;
}

/**
 * An interface representing CommonColumnDto.
 */
export interface CommonColumnDto {
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  tenantId?: number;
}

/**
 * An interface representing CommonFilterDto.
 */
export interface CommonFilterDto {
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  tenantId?: number;
}

/**
 * An interface representing RoleSimpleDto.
 */
export interface RoleSimpleDto {
  id?: number;
  name?: string;
}

/**
 * An interface representing AreaAssignmentDto.
 */
export interface AreaAssignmentDto {
  areaAssignmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Everything', 'Limited', 'Persona'
   */
  reach?: AreaAssignmentReach;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  roles?: RoleSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing AreaAssignmentBulkDto.
 */
export interface AreaAssignmentBulkDto {
  totalItems?: number;
  tenantId?: number;
  columns?: CommonColumnDto[];
  filters?: CommonFilterDto[];
  areaAssignment?: AreaAssignmentDto;
}

/**
 * An interface representing AreaAssignmentInContactDto.
 */
export interface AreaAssignmentInContactDto {
  areaAssignmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Everything', 'Limited', 'Persona'
   */
  reach?: AreaAssignmentReach;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  roles?: RoleSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing ContactSimpleListDto.
 */
export interface ContactSimpleListDto {
  contactId?: number;
  displayName?: string;
  externalId?: string;
}

/**
 * An interface representing LocationSimpleListDto.
 */
export interface LocationSimpleListDto {
  locationId?: number;
  name?: string;
  locationNumber?: string;
  externalId?: string;
}

/**
 * An interface representing LocationRelationListDto.
 */
export interface LocationRelationListDto {
  locationId?: number;
  location?: LocationSimpleListDto;
}

/**
 * An interface representing LocationGroupSimpleListDto.
 */
export interface LocationGroupSimpleListDto {
  locationGroupId?: number;
  name?: string;
  externalId?: string;
}

/**
 * An interface representing LocationGroupRelationListDto.
 */
export interface LocationGroupRelationListDto {
  locationGroupId?: number;
  locationGroup?: LocationGroupSimpleListDto;
}

/**
 * An interface representing FunctionListSimpleDto.
 */
export interface FunctionListSimpleDto {
  functionId?: number;
  tenantId?: number;
  name?: string;
  externalId?: string;
  stereotypeId?: number;
  isArchived?: boolean;
  rowVersion?: number[];
  stereotypeRowVersion?: number[];
}

/**
 * An interface representing FunctionInAreaListDto.
 */
export interface FunctionInAreaListDto {
  functionId?: number;
  functionProperty?: FunctionListSimpleDto;
}

/**
 * An interface representing RoleSimpleListDto.
 */
export interface RoleSimpleListDto {
  id?: number;
  appRoleId?: number;
  name?: string;
  title?: string;
}

/**
 * An interface representing RoleInAreaListDto.
 */
export interface RoleInAreaListDto {
  roleId?: number;
  role?: RoleSimpleListDto;
}

/**
 * An interface representing AreaAssignmentListDto.
 */
export interface AreaAssignmentListDto {
  areaAssignmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Everything', 'Limited', 'Persona'
   */
  reach?: AreaAssignmentReach;
  contact?: ContactSimpleListDto;
  locations?: LocationRelationListDto[];
  locationGroups?: LocationGroupRelationListDto[];
  functions?: FunctionInAreaListDto[];
  roles?: RoleInAreaListDto[];
}

/**
 * An interface representing ArticleKindSimpleDto.
 */
export interface ArticleKindSimpleDto {
  articleKindId?: number;
  name?: string;
}

/**
 * An interface representing ArticleDto.
 */
export interface ArticleDto {
  id?: number;
  articleId?: number;
  name?: string;
  number?: string;
  unit?: string;
  purchasingPrice?: number;
  sellingPrice?: number;
  kind?: ArticleKindSimpleDto;
  isArchived?: boolean;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing ArticleInUsageDto.
 */
export interface ArticleInUsageDto {
  id?: number;
  articleId?: number;
  name?: string;
  isArchived?: boolean;
}

/**
 * An interface representing ArticleKindDto.
 */
export interface ArticleKindDto {
  articleKindId?: number;
  name?: string;
}

/**
 * An interface representing ArticleKindListDto.
 */
export interface ArticleKindListDto {
  articleKindId?: number;
  name?: string;
}

/**
 * An interface representing ArticleKindSimpleListDto.
 */
export interface ArticleKindSimpleListDto {
  articleKindId?: number;
  name?: string;
}

/**
 * An interface representing ArticleListDto.
 */
export interface ArticleListDto {
  id?: number;
  articleId?: number;
  name?: string;
  number?: string;
  unit?: string;
  purchasingPrice?: number;
  sellingPrice?: number;
  kind?: ArticleKindSimpleListDto;
  isArchived?: boolean;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueListDto[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing ArticleUsageDto.
 */
export interface ArticleUsageDto {
  id?: number;
  articleUsageId?: number;
  count?: number;
  price?: number;
  position?: number;
  unit?: string;
  tenantId?: number;
  total?: number;
  note?: string;
  article?: ArticleInUsageDto;
}

/**
 * An interface representing LocationInArticleUsageListDto.
 */
export interface LocationInArticleUsageListDto {
  locationId?: number;
  name?: string;
  locationNumber?: string;
}

/**
 * An interface representing ResourceInArticleUsageListDto.
 */
export interface ResourceInArticleUsageListDto {
  resourceId?: number;
  name?: string;
  inventoryNumber?: string;
  location?: LocationInArticleUsageListDto;
}

/**
 * An interface representing MissionInArticleListDto.
 */
export interface MissionInArticleListDto {
  missionId?: number;
  title?: string;
  plannedStart?: string;
  plannedEnd?: string;
  actualStart?: string;
  actualEnd?: string;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  resource?: ResourceInArticleUsageListDto;
}

/**
 * An interface representing ArticleUsageListDto.
 */
export interface ArticleUsageListDto {
  articleUsageId?: number;
  count?: number;
  price?: number;
  total?: number;
  note?: string;
  mission?: MissionInArticleListDto;
}

/**
 * An interface representing ArticleUsageTtm.
 */
export interface ArticleUsageTtm {
  articleUsageId?: number;
  count?: number;
  price?: number;
  unit?: string;
  note?: string;
  total?: number;
  name?: string;
}

/**
 * An interface representing SolutionMemberDto.
 */
export interface SolutionMemberDto {
  id?: number;
  title?: string;
  /**
   * Possible values include: 'Ticket', 'Mission', 'Issue'
   */
  type?: SolutionMemberType;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  missionType?: MissionType;
}

/**
 * An interface representing AssignAttachmentToSolutionDto.
 */
export interface AssignAttachmentToSolutionDto {
  member?: SolutionMemberDto;
  attachment?: AttachmentForTechDto;
}

/**
 * An interface representing AttachmentForTechCompleteDto.
 */
export interface AttachmentForTechCompleteDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File'
   */
  type?: AttachmentTypeForTech;
  file?: FileDto;
}

/**
 * An interface representing FormDefaultDto.
 */
export interface FormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
}

/**
 * An interface representing OrgaAttachmentDto.
 */
export interface OrgaAttachmentDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
}

/**
 * An interface representing AttachmentFormFieldDto.
 */
export interface AttachmentFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  attachmentId?: number;
  attachment?: OrgaAttachmentDto;
}

/**
 * An interface representing AuditOfPropertyDto.
 */
export interface AuditOfPropertyDto {
  auditPropertyId?: number;
  name?: string;
  oldValue?: string;
  newValue?: string;
  /**
   * Possible values include: 'None', 'DateTime', 'TimeSpan', 'Double'
   */
  typeOfProperty?: AuditPropertyType;
}

/**
 * An interface representing AuditOfCollectionDto.
 */
export interface AuditOfCollectionDto {
  auditCollectionId?: number;
  name?: string;
  count?: number;
}

/**
 * An interface representing AuditDto.
 */
export interface AuditDto {
  auditId?: number;
  /**
   * Possible values include: 'Added', 'Modified', 'Deleted'
   */
  kind?: AuditKind;
  createdAt?: string;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  entityId?: number;
  createdByUserName?: string;
  createdByUserId?: number;
  tenantId?: number;
  properties?: AuditOfPropertyDto[];
  collections?: AuditOfCollectionDto[];
}

/**
 * An interface representing AuditListDto.
 */
export interface AuditListDto {
  auditId?: number;
  /**
   * Possible values include: 'Added', 'Modified', 'Deleted'
   */
  kind?: AuditKind;
  createdAt?: string;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  entityId?: number;
  createdByUserName?: string;
  createdByUserId?: number;
  tenantId?: number;
}

/**
 * An interface representing AzureLoginProviderDto.
 */
export interface AzureLoginProviderDto {
  loginProviderId?: number;
  name?: string;
  defaultRoleId?: number;
  defaultRole?: AppRoleDto;
  type?: number;
  tenantId?: number;
  azureTenantId?: string;
}

/**
 * An interface representing AzureLoginProviderListDto.
 */
export interface AzureLoginProviderListDto {
  loginProviderId?: number;
  name?: string;
  defaultRoleId?: number;
  defaultRole?: AppRoleDto;
  provider?: number;
  azureTenantId?: string;
}

/**
 * An interface representing SimpleLoginProviderDto.
 */
export interface SimpleLoginProviderDto {
  loginProviderId?: number;
  name?: string;
}

/**
 * An interface representing AzureLoginProviderRoleDto.
 */
export interface AzureLoginProviderRoleDto {
  loginProviderRoleId?: number;
  roleId?: number;
  role?: AppRoleDto;
  loginProviderId?: number;
  loginProvider?: SimpleLoginProviderDto;
  tenantId?: number;
  rowVersion?: number[];
  azureGroupName?: string;
}

/**
 * An interface representing AzureLoginProviderRoleListDto.
 */
export interface AzureLoginProviderRoleListDto {
  loginProviderRoleId?: number;
  roleId?: number;
  role?: AppRoleListDto;
  loginProviderId?: number;
  rowVersion?: number[];
  azureGroupName?: string;
}

/**
 * An interface representing DataTableColumnDto.
 */
export interface DataTableColumnDto {
  dataTableColumnId?: number;
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  tenantId?: number;
}

/**
 * An interface representing DataTableFilterDto.
 */
export interface DataTableFilterDto {
  dataTableFilterId?: number;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  label?: string;
  tenantId?: number;
}

/**
 * An interface representing CalendarDataTableDto.
 */
export interface CalendarDataTableDto {
  columns?: DataTableColumnDto[];
  filters?: DataTableFilterDto[];
  tenantId?: number;
}

/**
 * An interface representing CalendarConnectionDto.
 */
export interface CalendarConnectionDto {
  id?: number;
  calendarDataTable?: CalendarDataTableDto;
  key?: string;
  url?: string;
}

/**
 * An interface representing CalendarEventDto.
 */
export interface CalendarEventDto {
  uid?: string;
  summary?: string;
  description?: string;
  location?: string;
  url?: string;
  start?: string;
  end?: string;
}

/**
 * An interface representing CalendarPropertyDto.
 */
export interface CalendarPropertyDto {
  key?: string;
  value?: string;
}

/**
 * An interface representing CalendarListDto.
 */
export interface CalendarListDto {
  properties?: CalendarPropertyDto[];
  events?: CalendarEventDto[];
}

/**
 * An interface representing CancelJobsQueueDto.
 */
export interface CancelJobsQueueDto {
  taskId?: number;
  locationId?: number;
  tenantId?: number;
  type?: number;
}

/**
 * An interface representing CancelResourceTaskQueueDto.
 */
export interface CancelResourceTaskQueueDto {
  taskId?: number;
  resourceId?: number;
}

/**
 * An interface representing CancelSearchingEditorQueueDto.
 */
export interface CancelSearchingEditorQueueDto {
  missionId?: number;
}

/**
 * An interface representing MailInQueueDto.
 */
export interface MailInQueueDto {
  isSpam?: boolean;
  domain?: string;
  receivers?: string[];
  fileId?: number;
  filePath?: string;
}

/**
 * An interface representing CarrySolutionMailInQueueDto.
 */
export interface CarrySolutionMailInQueueDto {
  threadId?: string;
  isSuccess?: boolean;
  triggeredNodeId?: number;
  tenantId?: number;
  mailId?: number;
  mailReference?: MailInQueueDto;
}

/**
 * An interface representing CategoriesByTypeDto.
 */
export interface CategoriesByTypeDto {
  /**
   * Possible values include: 'Info', 'KnowledgeArticle', 'Task', 'KnowledgePath'
   */
  type?: CategorizedType;
  items?: CategorySimpleDto[];
}

/**
 * An interface representing CategoryInRelationListDto.
 */
export interface CategoryInRelationListDto {
  categoryId?: number;
  name?: string;
  color?: string;
}

/**
 * An interface representing CategorizedRelationDto.
 */
export interface CategorizedRelationDto {
  categoryId?: number;
  category?: CategoryInRelationListDto;
}

/**
 * An interface representing CategoryDto.
 */
export interface CategoryDto {
  categoryId?: number;
  name?: string;
  color?: string;
  /**
   * Possible values include: 'App', 'Backend', 'Everywhere'
   */
  sector?: CategorySector;
  tenantId?: number;
}

/**
 * An interface representing CategoryListDto.
 */
export interface CategoryListDto {
  categoryId?: number;
  name?: string;
  tenantId?: number;
  color?: string;
  /**
   * Possible values include: 'App', 'Backend', 'Everywhere'
   */
  sector?: CategorySector;
}

/**
 * An interface representing ChangeEmailDto.
 */
export interface ChangeEmailDto {
  email?: string;
}

/**
 * An interface representing ChangeResourceStateDto.
 */
export interface ChangeResourceStateDto {
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  state?: ResourceState;
  locationId?: number;
  isKeepingLocation?: boolean;
}

/**
 * An interface representing ChangeStateOfMissionDto.
 */
export interface ChangeStateOfMissionDto {
  missionId?: number;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  wantedState?: MissionState;
  isSendingMail?: boolean;
  startDate?: string;
  endDate?: string;
  reason?: string;
}

/**
 * An interface representing ChangeTaskJobStatusDto.
 */
export interface ChangeTaskJobStatusDto {
  taskJobId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
  tenantId?: number;
  reasonId?: number;
}

/**
 * An interface representing ChangeTicketStateWhenMissionClosedQueueDto.
 */
export interface ChangeTicketStateWhenMissionClosedQueueDto {
  ticketId?: number;
  missionId?: number;
}

/**
 * An interface representing ChangeTicketStateWhenMissionStateChangedQueueDto.
 */
export interface ChangeTicketStateWhenMissionStateChangedQueueDto {
  ticketId?: number;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  newState?: MissionState;
  missionId?: number;
}

/**
 * An interface representing ChangedAreaAssignmentQueueDto.
 */
export interface ChangedAreaAssignmentQueueDto {
  contactId?: number;
  skipDelay?: boolean;
  tenantId?: number;
}

/**
 * An interface representing ChangedEntityQueueDto.
 */
export interface ChangedEntityQueueDto {
  entityId?: number;
  skipDelay?: boolean;
  tenantId?: number;
}

/**
 * An interface representing ChangedLocationAndLocationGroupConnectionQueueDto.
 */
export interface ChangedLocationAndLocationGroupConnectionQueueDto {
  locationId?: number;
  locationGroupId?: number;
  isNew?: boolean;
}

/**
 * An interface representing ChangedLocationGroupLocationQueueDto.
 */
export interface ChangedLocationGroupLocationQueueDto {
  locationGroupId?: number;
  locationId?: number;
  skipDelay?: boolean;
  tenantId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
}

/**
 * An interface representing ChangedStateOfMissionDto.
 */
export interface ChangedStateOfMissionDto {
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  lastEmailSend?: string;
}

/**
 * An interface representing ChangedStatusConditionDto.
 */
export interface ChangedStatusConditionDto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
}

/**
 * An interface representing ChangedStatusConditionV1Dto.
 */
export interface ChangedStatusConditionV1Dto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
}

/**
 * An interface representing FormSimpleDto.
 */
export interface FormSimpleDto {
  formId?: number;
  title?: string;
}

/**
 * An interface representing ChatByFormMessagePatternDto.
 */
export interface ChatByFormMessagePatternDto {
  chatByFormMessagePatternId?: number;
  content?: string;
}

/**
 * An interface representing DocumentTemplateSimpleDto.
 */
export interface DocumentTemplateSimpleDto {
  externalId?: string;
  documentTemplateId?: number;
  title?: string;
  /**
   * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
   * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
   * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
   * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
   */
  contextType?: DocumentTemplateContextType;
  tenantId?: number;
}

/**
 * An interface representing ChatByFormLocationReceivingDto.
 */
export interface ChatByFormLocationReceivingDto {
  isOverridable?: boolean;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
}

/**
 * An interface representing ChatByFormFunctionReceivingDto.
 */
export interface ChatByFormFunctionReceivingDto {
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing ChatByFormReceivingDto.
 */
export interface ChatByFormReceivingDto {
  /**
   * Possible values include: 'Locations', 'FixedFunction'
   */
  kind?: ChatByFormReceivingKind;
  locationReceivings?: ChatByFormLocationReceivingDto;
  functionReceiving?: ChatByFormFunctionReceivingDto;
}

/**
 * An interface representing ChatByFormDefinitionDto.
 */
export interface ChatByFormDefinitionDto {
  externalId?: string;
  chatByFormDefinitionId?: number;
  title?: string;
  tenantId?: number;
  form?: FormSimpleDto;
  requiresAuthentication?: boolean;
  pattern?: ChatByFormMessagePatternDto;
  documentTemplate?: DocumentTemplateSimpleDto;
  receiving?: ChatByFormReceivingDto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
}

/**
 * An interface representing ChatByFormDefinitionListDto.
 */
export interface ChatByFormDefinitionListDto {
  chatByFormDefinitionId?: number;
  title?: string;
  externalId?: string;
  tenantId?: number;
}

/**
 * An interface representing ChatByFormReceiverDto.
 */
export interface ChatByFormReceiverDto {
  id?: number;
  name?: string;
  /**
   * Possible values include: 'Location', 'LocationGroup'
   */
  type?: ChatByFormReceiverType;
}

/**
 * An interface representing ChatByMailMessageDto.
 */
export interface ChatByMailMessageDto {
  html?: string;
  plainText?: string;
  attachments?: FileDto[];
}

/**
 * An interface representing ChatByMailOriginDto.
 */
export interface ChatByMailOriginDto {
  fileId?: number;
  filePath?: string;
}

/**
 * An interface representing ChatByMailIdentificationDto.
 */
export interface ChatByMailIdentificationDto {
  thread?: string;
  domain?: string;
}

/**
 * An interface representing ChatByMailDto.
 */
export interface ChatByMailDto {
  subject?: string;
  sender?: string;
  locationId?: number;
  message?: ChatByMailMessageDto;
  origin?: ChatByMailOriginDto;
  identification?: ChatByMailIdentificationDto;
  tenantId?: number;
}

/**
 * An interface representing ChatMemberDto.
 */
export interface ChatMemberDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberName?: string;
}

/**
 * An interface representing ChatDto.
 */
export interface ChatDto {
  chatId?: number;
  title?: string;
  isArchived?: boolean;
  chatMembers?: ChatMemberDto[];
}

/**
 * An interface representing ChatMemberListDto.
 */
export interface ChatMemberListDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  name?: string;
  concreteId?: number;
}

/**
 * An interface representing LocationChatMemberDto.
 */
export interface LocationChatMemberDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberName?: string;
  locationId?: number;
  location?: LocationSimpleDto;
}

/**
 * An interface representing ChatSourceCompleteListDto.
 */
export interface ChatSourceCompleteListDto {
  /**
   * Possible values include: 'Base', 'Mail', 'Form', 'Process'
   */
  type?: ChatSourceType;
  definitionId?: number;
  fileId?: number;
  processId?: number;
}

/**
 * An interface representing ChatListDto.
 */
export interface ChatListDto {
  chatId?: number;
  title?: string;
  unreadCount?: number;
  isArchived?: boolean;
  lastMessageDateTime?: string;
  lastMessagePreview?: string;
  chatMembers?: ChatMemberListDto[];
  locationChatMembers?: LocationChatMemberDto[];
  chatSource?: ChatSourceCompleteListDto;
}

/**
 * An interface representing ChatMemberMessageListDto.
 */
export interface ChatMemberMessageListDto {
  time?: string;
  /**
   * Possible values include: 'Read', 'Send'
   */
  status?: MessageStatus;
  member?: ChatMemberListDto;
}

/**
 * An interface representing ChatMessageDto.
 */
export interface ChatMessageDto {
  chatMessageId?: number;
  message?: string;
  sender?: ChatMemberDto;
  attachment?: OrgaAttachmentDto;
  attachments?: OrgaAttachmentDto[];
  tenantId?: number;
}

/**
 * An interface representing ChatMessageInNoteListDto.
 */
export interface ChatMessageInNoteListDto {
  message?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly time?: string;
  sender?: ChatMemberListDto;
  members?: ChatMemberMessageListDto[];
}

/**
 * An interface representing OrgaAttachmentListDto.
 */
export interface OrgaAttachmentListDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
  name?: string;
  referenceId?: number;
  url?: string;
  link?: string;
  path?: string;
  infoId?: number;
}

/**
 * An interface representing StateSimpleListDto.
 */
export interface StateSimpleListDto {
  stateId?: number;
  name?: string;
  color?: string;
  externalId?: string;
  tenantId?: number;
}

/**
 * An interface representing ChatMessageListDto.
 */
export interface ChatMessageListDto {
  chatMessageId?: number;
  message?: string;
  chatId?: number;
  /**
   * Possible values include: 'Message', 'TicketStateChange'
   */
  type?: ChatMessageType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly time?: string;
  sender?: ChatMemberListDto;
  members?: ChatMemberMessageListDto[];
  attachment?: OrgaAttachmentListDto;
  attachments?: OrgaAttachmentListDto[];
  triggerState?: StateSimpleListDto;
}

/**
 * An interface representing ChatMessageNotificationDto.
 */
export interface ChatMessageNotificationDto {
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
  chatId?: number;
  chatMessageId?: number;
}

/**
 * An interface representing ChatSkeletonDto.
 */
export interface ChatSkeletonDto {
  chatSkeletonId?: number;
  isCreating?: boolean;
  attachmentTemplate?: DocumentTemplateSimpleDto;
  tenantId?: number;
}

/**
 * An interface representing ChatSkeletonV1Dto.
 */
export interface ChatSkeletonV1Dto {
  chatSkeletonId?: number;
  attachmentTemplate?: DocumentTemplateSimpleDto;
  tenantId?: number;
}

/**
 * An interface representing ChatStarterByDefinitionDto.
 */
export interface ChatStarterByDefinitionDto {
  /**
   * Possible values include: 'Form', 'Process', 'Definition'
   */
  type?: ChatStarterType;
  name?: string;
  definitionId?: number;
}

/**
 * An interface representing ChatStarterByFormDto.
 */
export interface ChatStarterByFormDto {
  /**
   * Possible values include: 'Form', 'Process', 'Definition'
   */
  type?: ChatStarterType;
  name?: string;
  formId?: number;
}

/**
 * An interface representing ChatStarterByProcessDto.
 */
export interface ChatStarterByProcessDto {
  /**
   * Possible values include: 'Form', 'Process', 'Definition'
   */
  type?: ChatStarterType;
  name?: string;
  processId?: number;
}

/**
 * An interface representing ChatStarterDto.
 */
export interface ChatStarterDto {
  /**
   * Possible values include: 'Form', 'Process', 'Definition'
   */
  type?: ChatStarterType;
  name?: string;
}

/**
 * An interface representing ChatStartersDto.
 */
export interface ChatStartersDto {
  starters?: ChatStarterDto[];
}

/**
 * An interface representing CheckTenantActivityQueueDto.
 */
export interface CheckTenantActivityQueueDto {
  tenantId?: number;
}

/**
 * An interface representing CheckboxFormDefaultDto.
 */
export interface CheckboxFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: boolean;
}

/**
 * An interface representing CheckboxFormFieldDto.
 */
export interface CheckboxFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
}

/**
 * An interface representing CheckboxFormValueDto.
 */
export interface CheckboxFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: boolean;
}

/**
 * An interface representing ChildTaskSimpleDto.
 */
export interface ChildTaskSimpleDto {
  taskId?: number;
  title?: string;
  tenantId?: number;
  position?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
}

/**
 * An interface representing ClosedLocationQueueDto.
 */
export interface ClosedLocationQueueDto {
  locationId?: number;
}

/**
 * An interface representing ClosedLocationReasonQueueDto.
 */
export interface ClosedLocationReasonQueueDto {
  locationId?: number;
}

/**
 * An interface representing ClosingLocationOptionsDto.
 */
export interface ClosingLocationOptionsDto {
  shouldCancelOpenMissions?: boolean;
  shouldMoveResources?: boolean;
  shouldMoveResourcesTarget?: LocationSimpleDto;
}

/**
 * An interface representing ExecutionPlanV1Dto.
 */
export interface ExecutionPlanV1Dto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing LocationTaskV1Dto.
 */
export interface LocationTaskV1Dto {
  taskId?: number;
  locationId?: number;
}

/**
 * An interface representing LocationGroupTaskV1Dto.
 */
export interface LocationGroupTaskV1Dto {
  taskId?: number;
  locationGroupId?: number;
}

/**
 * An interface representing ImpactCreateV1Dto.
 */
export interface ImpactCreateV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  taskId?: number;
}

/**
 * An interface representing TaskCreateV1Dto.
 */
export interface TaskCreateV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isEnabled?: boolean;
  reasonSetId?: number;
  executionPlan?: ExecutionPlanV1Dto;
  locations?: LocationTaskV1Dto[];
  locationGroups?: LocationGroupTaskV1Dto[];
  impacts?: ImpactCreateV1Dto[];
  tenantId?: number;
}

/**
 * An interface representing CollectionTaskCreateV1Dto.
 */
export interface CollectionTaskCreateV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isEnabled?: boolean;
  reasonSetId?: number;
  executionPlan?: ExecutionPlanV1Dto;
  locations?: LocationTaskV1Dto[];
  locationGroups?: LocationGroupTaskV1Dto[];
  impacts?: ImpactCreateV1Dto[];
  tenantId?: number;
  children?: TaskCreateV1Dto[];
  hasStrictOrder?: boolean;
}

/**
 * An interface representing ImpactDto.
 */
export interface ImpactDto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionDto;
}

/**
 * An interface representing TaskJobStateReasonSetSimpleDto.
 */
export interface TaskJobStateReasonSetSimpleDto {
  taskJobStateReasonSetId?: number;
  title?: string;
}

/**
 * An interface representing ExecutionPlanDto.
 */
export interface ExecutionPlanDto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing CollectionTaskDto.
 */
export interface CollectionTaskDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  description?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  impacts?: ImpactDto[];
  documentTemplate?: DocumentTemplateSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isActive?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  executionPlan?: ExecutionPlanDto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  categories?: CategorySimpleDto[];
  children?: ChildTaskSimpleDto[];
  hasStrictOrder?: boolean;
}

/**
 * An interface representing TaskSimpleV1Dto.
 */
export interface TaskSimpleV1Dto {
  taskId?: number;
  title?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing CollectionTaskSimpleV1Dto.
 */
export interface CollectionTaskSimpleV1Dto {
  taskId?: number;
  title?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  children?: TaskSimpleV1Dto[];
}

/**
 * An interface representing ExecutionPlanViewV1Dto.
 */
export interface ExecutionPlanViewV1Dto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing ImpactV1Dto.
 */
export interface ImpactV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
}

/**
 * An interface representing TaskV1Dto.
 */
export interface TaskV1Dto {
  parentId?: number;
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  executionPlan?: ExecutionPlanViewV1Dto;
  locations?: LocationRelationDto[];
  locationGroups?: LocationGroupRelationDto[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  impacts?: ImpactV1Dto[];
}

/**
 * An interface representing CollectionTaskV1Dto.
 */
export interface CollectionTaskV1Dto {
  parentId?: number;
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  executionPlan?: ExecutionPlanViewV1Dto;
  locations?: LocationRelationDto[];
  locationGroups?: LocationGroupRelationDto[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  impacts?: ImpactV1Dto[];
  children?: TaskV1Dto[];
  hasStrictOrder?: boolean;
}

/**
 * An interface representing CommissionContactQueueDto.
 */
export interface CommissionContactQueueDto {
  missionId?: number;
  contactId?: number;
  isUnsolved?: boolean;
  actualStart?: string;
  actualEnd?: string;
}

/**
 * An interface representing CommissionEditorDto.
 */
export interface CommissionEditorDto {
  contactId?: number;
}

/**
 * An interface representing CommissionEditorInternalDto.
 */
export interface CommissionEditorInternalDto {
  contactId?: number;
  isUnsolved?: boolean;
  actualStart?: string;
  actualEnd?: string;
}

/**
 * An interface representing CommonCustomColumnDto.
 */
export interface CommonCustomColumnDto {
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  tenantId?: number;
  customPropertyId?: number;
}

/**
 * An interface representing CommonCustomFilterDto.
 */
export interface CommonCustomFilterDto {
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  tenantId?: number;
  customPropertyId?: number;
}

/**
 * An interface representing CommonDestinationDto.
 */
export interface CommonDestinationDto {
  /**
   * Possible values include: 'Email'
   */
  type?: ExportDestinationTypes;
}

/**
 * An interface representing CommonEmailDestinationDto.
 */
export interface CommonEmailDestinationDto {
  /**
   * Possible values include: 'Email'
   */
  type?: ExportDestinationTypes;
  displayName?: string;
  address?: string;
}

/**
 * An interface representing CommonGroupedFilterDto.
 */
export interface CommonGroupedFilterDto {
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  tenantId?: number;
  /**
   * Possible values include: 'And', 'Or'
   */
  combinator?: CombineOperator;
  children?: CommonFilterDto[];
}

/**
 * An interface representing CommonTransferColumnDto.
 */
export interface CommonTransferColumnDto {
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  tenantId?: number;
  property?: string;
}

/**
 * An interface representing CommonTransferFilterDto.
 */
export interface CommonTransferFilterDto {
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  tenantId?: number;
  property?: string;
}

/**
 * An interface representing CompanyDto.
 */
export interface CompanyDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
  parentCompanyId?: number;
  parentCompany?: CompanyDto;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  shippingAddress?: AddressSimpleDto;
  invoiceAddress?: AddressSimpleDto;
}

/**
 * An interface representing CompanyAddressDto.
 */
export interface CompanyAddressDto {
  companyId?: number;
  company?: CompanyDto;
  addressId?: number;
  address?: AddressDto;
}

/**
 * An interface representing CompanyListSimpleDto.
 */
export interface CompanyListSimpleDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
  parentCompanyId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
}

/**
 * An interface representing CompanyFunctionDto.
 */
export interface CompanyFunctionDto {
  companyId?: number;
  company?: CompanyListSimpleDto;
  functionId?: number;
  functionProperty?: FunctionListSimpleDto;
}

/**
 * An interface representing CompanyListDto.
 */
export interface CompanyListDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
  parentCompanyId?: number;
  stereotypeId?: number;
  customPropertyValues?: FilledCustomValueListDto[];
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing CompanyListForAddressDto.
 */
export interface CompanyListForAddressDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
  parentCompanyId?: number;
  stereotypeId?: number;
  customPropertyValues?: FilledCustomValueListDto[];
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing CompanyListForContactDto.
 */
export interface CompanyListForContactDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
  parentCompanyId?: number;
  stereotypeId?: number;
  customPropertyValues?: FilledCustomValueListDto[];
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing CompanyListForFunctionDto.
 */
export interface CompanyListForFunctionDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
  parentCompanyId?: number;
  stereotypeId?: number;
  customPropertyValues?: FilledCustomValueListDto[];
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing CompanySimpleDto.
 */
export interface CompanySimpleDto {
  companyId?: number;
  tenantId?: number;
  name?: string;
}

/**
 * An interface representing CompanyTtm.
 */
export interface CompanyTtm {
  name?: string;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  properties?: any;
}

/**
 * An interface representing CompletedKnowledgePathQueueDto.
 */
export interface CompletedKnowledgePathQueueDto {
  knowledgePathProgressId?: number;
  culture?: string;
}

/**
 * An interface representing ConfirmationTaskCreateV1Dto.
 */
export interface ConfirmationTaskCreateV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isEnabled?: boolean;
  reasonSetId?: number;
  executionPlan?: ExecutionPlanV1Dto;
  locations?: LocationTaskV1Dto[];
  locationGroups?: LocationGroupTaskV1Dto[];
  impacts?: ImpactCreateV1Dto[];
  tenantId?: number;
}

/**
 * An interface representing ConfirmationTaskDto.
 */
export interface ConfirmationTaskDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  description?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  impacts?: ImpactDto[];
  documentTemplate?: DocumentTemplateSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isActive?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  executionPlan?: ExecutionPlanDto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  categories?: CategorySimpleDto[];
  requiresAuthentication?: boolean;
}

/**
 * An interface representing ConfirmationTaskListV1Dto.
 */
export interface ConfirmationTaskListV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  parentId?: number;
  executionPlan?: ExecutionPlanViewV1Dto;
  executionPlanId?: number;
  requiresAuthentication?: boolean;
  reasonSetId?: number;
  tenantId?: number;
}

/**
 * An interface representing ConfirmationTaskSimpleV1Dto.
 */
export interface ConfirmationTaskSimpleV1Dto {
  taskId?: number;
  title?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing ConfirmationTaskV1Dto.
 */
export interface ConfirmationTaskV1Dto {
  parentId?: number;
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  executionPlan?: ExecutionPlanViewV1Dto;
  locations?: LocationRelationDto[];
  locationGroups?: LocationGroupRelationDto[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  impacts?: ImpactV1Dto[];
}

/**
 * An interface representing ConnectedConsumptionDto.
 */
export interface ConnectedConsumptionDto {
  eventId?: number;
  occurredAt?: string;
  code?: string;
  value?: string;
}

/**
 * An interface representing ConsumptionCodeSimpleDto.
 */
export interface ConsumptionCodeSimpleDto {
  consumptionCodeId?: number;
  externalId?: string;
  displayName?: string;
  unit?: string;
}

/**
 * An interface representing ErrorCodeSimpleDto.
 */
export interface ErrorCodeSimpleDto {
  errorCodeId?: number;
  externalId?: string;
  displayName?: string;
}

/**
 * An interface representing HintCodeSimpleDto.
 */
export interface HintCodeSimpleDto {
  hintCodeId?: number;
  externalId?: string;
  displayName?: string;
}

/**
 * An interface representing CustomPropertyInConnectedIdentificationDto.
 */
export interface CustomPropertyInConnectedIdentificationDto {
  customPropertyId?: number;
  name?: string;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
}

/**
 * An interface representing CustomSetInConnectedIdentificationDto.
 */
export interface CustomSetInConnectedIdentificationDto {
  properties?: CustomPropertyInConnectedIdentificationDto[];
}

/**
 * An interface representing CustomSetReferenceInConnectedIdentificationDto.
 */
export interface CustomSetReferenceInConnectedIdentificationDto {
  stereotypeId?: number;
  customPropertySetId?: number;
  customPropertySet?: CustomSetInConnectedIdentificationDto;
}

/**
 * An interface representing StereotypeInConnectedIdentificationDto.
 */
export interface StereotypeInConnectedIdentificationDto {
  stereotypeId?: number;
  name?: string;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  customPropertySets?: CustomSetReferenceInConnectedIdentificationDto[];
}

/**
 * An interface representing CustomPropertySimpleDto.
 */
export interface CustomPropertySimpleDto {
  customPropertyId?: number;
  name?: string;
}

/**
 * An interface representing ConnectedResourceIdentificationDto.
 */
export interface ConnectedResourceIdentificationDto {
  stereotype?: StereotypeInConnectedIdentificationDto;
  key?: CustomPropertySimpleDto;
}

/**
 * An interface representing ConnectedDefinitionDto.
 */
export interface ConnectedDefinitionDto {
  connectedDefinitionId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  tenantId?: number;
  consumptionCodes?: ConsumptionCodeSimpleDto[];
  errorCodes?: ErrorCodeSimpleDto[];
  hintCodes?: HintCodeSimpleDto[];
  identifications?: ConnectedResourceIdentificationDto[];
}

/**
 * An interface representing ConnectedDefinitionListDto.
 */
export interface ConnectedDefinitionListDto {
  connectedDefinitionId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  tenantId?: number;
}

/**
 * An interface representing ConnectedDefinitionSimpleDto.
 */
export interface ConnectedDefinitionSimpleDto {
  connectedDefinitionId?: number;
  externalId?: string;
  displayName?: string;
}

/**
 * An interface representing ConnectedErrorDto.
 */
export interface ConnectedErrorDto {
  eventId?: number;
  occurredAt?: string;
  code?: string;
}

/**
 * An interface representing ConnectedIdentificationDto.
 */
export interface ConnectedIdentificationDto {
  tenantId?: number;
  code?: string;
  /**
   * Possible values include: 'BySerialNumber', 'ByDirectId'
   */
  type?: ConnectedIdentificationType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly resourceIdentifier?: string;
}

/**
 * An interface representing ConnectedHintDto.
 */
export interface ConnectedHintDto {
  eventId?: number;
  occurredAt?: string;
  code?: string;
  /**
   * Possible values include: 'None', 'Warning', 'Error', 'Information'
   */
  level?: HintLevel;
}

/**
 * An interface representing ConnectedEventsDto.
 */
export interface ConnectedEventsDto {
  identification?: ConnectedIdentificationDto;
  errors?: ConnectedErrorDto[];
  consumptions?: ConnectedConsumptionDto[];
  hints?: ConnectedHintDto[];
}

/**
 * An interface representing ConnectedEventsResultDto.
 */
export interface ConnectedEventsResultDto {
  definitionId?: number;
  definitionName?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isUnidentified?: boolean;
  resourceIdentifier?: string;
  resourceId?: number;
}

/**
 * An interface representing ConnectedIdentificationByDirectIdDto.
 */
export interface ConnectedIdentificationByDirectIdDto {
  tenantId?: number;
  code?: string;
  /**
   * Possible values include: 'BySerialNumber', 'ByDirectId'
   */
  type?: ConnectedIdentificationType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
  resourceId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly resourceIdentifier?: string;
}

/**
 * An interface representing ConnectedIdentificationBySerialNumberDto.
 */
export interface ConnectedIdentificationBySerialNumberDto {
  tenantId?: number;
  code?: string;
  /**
   * Possible values include: 'BySerialNumber', 'ByDirectId'
   */
  type?: ConnectedIdentificationType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
  value?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly resourceIdentifier?: string;
}

/**
 * An interface representing ConsumptionCodeDto.
 */
export interface ConsumptionCodeDto {
  consumptionCodeId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  unit?: string;
  /**
   * Possible values include: 'String', 'Integer'
   */
  valueType?: ValueType;
  tenantId?: number;
  isVisible?: boolean;
}

/**
 * An interface representing ConsumptionCodeListDto.
 */
export interface ConsumptionCodeListDto {
  consumptionCodeId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  unit?: string;
  tenantId?: number;
}

/**
 * An interface representing ConsumptionCodeSimpleListDto.
 */
export interface ConsumptionCodeSimpleListDto {
  consumptionCodeId?: number;
  externalId?: string;
  displayName?: string;
  unit?: string;
}

/**
 * An interface representing LinkedElementDto.
 */
export interface LinkedElementDto {
  linkedElementId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
   */
  type?: LinkedElementType;
}

/**
 * An interface representing LinkDto.
 */
export interface LinkDto {
  linkId?: number;
  lastModified?: string;
  createdAt?: string;
  comment?: string;
  tenantId?: number;
  to?: LinkedElementDto;
}

/**
 * An interface representing ContactDto.
 */
export interface ContactDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  lastLoggedIn?: string;
  pin?: string;
  /**
   * Possible values include: 'Unrestricted', 'Blocking'
   */
  notificationBehaviour?: ContactNotificationBehaviour;
  badge?: string;
  culture?: string;
  timeZone?: string;
  emailAddress?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  rowVersion?: number[];
  stereotypeRowVersion?: number[];
  stereotypeId?: number;
  company?: CompanySimpleDto;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isLockedOut?: boolean;
  access?: AccessOfContactDto;
  areas?: AreaAssignmentInContactDto[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
}

/**
 * An interface representing ContactChatMemberDto.
 */
export interface ContactChatMemberDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberName?: string;
  contactId?: number;
  contact?: ContactDto;
}

/**
 * An interface representing ContactControlReceiverDto.
 */
export interface ContactControlReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: ControlReceiverType;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing ContactGroupSimpleDto.
 */
export interface ContactGroupSimpleDto {
  contactGroupId?: number;
  name?: string;
}

/**
 * An interface representing UserSimpleListDto.
 */
export interface UserSimpleListDto {
  id?: number;
  email?: string;
}

/**
 * An interface representing ContactInMissionListDto.
 */
export interface ContactInMissionListDto {
  contactId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  emailAddress?: string;
  user?: UserSimpleListDto;
  phone?: string;
  mobile?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing ContactInTicketListDto.
 */
export interface ContactInTicketListDto {
  contactId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  emailAddress?: string;
  user?: UserSimpleListDto;
  phone?: string;
  mobile?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing ContactIssueReceiverDto.
 */
export interface ContactIssueReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: IssueReceiverType;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing UserInContactListDto.
 */
export interface UserInContactListDto {
  id?: number;
  email?: string;
}

/**
 * An interface representing ContactListDto.
 */
export interface ContactListDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
}

/**
 * An interface representing ContactListForCompanyDto.
 */
export interface ContactListForCompanyDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing ContactListForFunctionDto.
 */
export interface ContactListForFunctionDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing ContactListForFunctionDtoPageOutputDto.
 */
export interface ContactListForFunctionDtoPageOutputDto {
  paging?: any;
  items?: ContactListForFunctionDto[];
}

/**
 * An interface representing ContactListForInfoDto.
 */
export interface ContactListForInfoDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing ContactListForLocationDto.
 */
export interface ContactListForLocationDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing ContactListForLocationGroupDto.
 */
export interface ContactListForLocationGroupDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing ContactListForRoleDto.
 */
export interface ContactListForRoleDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  externalId?: string;
  phone?: string;
  mobile?: string;
  pin?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  loginExpiration?: string;
  isFree?: boolean;
  emailAddress?: string;
  user?: UserInContactListDto;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  functions?: FunctionListSimpleDto[];
  roles?: RoleSimpleListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing ContactLoginMethodSettingDto.
 */
export interface ContactLoginMethodSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  /**
   * Possible values include: 'Pin', 'Badge'
   */
  value?: ContactLoginMethod;
}

/**
 * An interface representing ContactMailNodeReceiverDto.
 */
export interface ContactMailNodeReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: MailNodeReceiverType;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing ContactNotificationTargetDto.
 */
export interface ContactNotificationTargetDto {
  notificationTargetId?: number;
  /**
   * Possible values include: 'Base', 'Email', 'Contact', 'Function'
   */
  type?: NotificationTargetType;
  tenantId?: number;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing ContactRelationListDto.
 */
export interface ContactRelationListDto {
  contactId?: number;
  contact?: ContactSimpleListDto;
}

/**
 * An interface representing ContactTtm.
 */
export interface ContactTtm {
  contactId?: number;
  displayName?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  company?: CompanyTtm;
  properties?: any;
}

/**
 * An interface representing ContractDto.
 */
export interface ContractDto {
  contractId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Inactive', 'Active', 'Due'
   */
  state?: ContractState;
  startDate?: string;
  endDate?: string;
  duePeriod?: string;
  dueDate?: string;
  tenantId?: number;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  attachments?: AttachmentForTechDto[];
  links?: LinkDto[];
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  contacts?: ContactSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing ContractListDto.
 */
export interface ContractListDto {
  contractId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Inactive', 'Active', 'Due'
   */
  state?: ContractState;
  startDate?: string;
  endDate?: string;
  duePeriod?: string;
  dueDate?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  rowVersion?: number[];
}

/**
 * An interface representing ContractSimpleDto.
 */
export interface ContractSimpleDto {
  contractId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing ContractSimpleListDto.
 */
export interface ContractSimpleListDto {
  contractId?: number;
  title?: string;
}

/**
 * An interface representing ControlConditionDto.
 */
export interface ControlConditionDto {
  controlConditionId?: number;
  /**
   * Possible values include: 'Base', 'FixedTime', 'Consumption', 'Error'
   */
  type?: ControlConditionType;
  tenantId?: number;
}

/**
 * An interface representing ControlTriggerDto.
 */
export interface ControlTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange'
   */
  type?: ControlTriggerType;
}

/**
 * An interface representing ControlConsumptionConditionDto.
 */
export interface ControlConsumptionConditionDto {
  controlConditionId?: number;
  /**
   * Possible values include: 'Base', 'FixedTime', 'Consumption', 'Error'
   */
  type?: ControlConditionType;
  tenantId?: number;
  consumption?: ConsumptionCodeSimpleDto;
  trigger?: ControlTriggerDto;
}

/**
 * An interface representing DataTableDto.
 */
export interface DataTableDto {
  dataTableId?: number;
  /**
   * Possible values include: 'Table', 'Gantt'
   */
  viewType?: DataTableViewType;
  /**
   * Possible values include: 'DataTable', 'Selector', 'Restriction', 'Calendar'
   */
  usage?: DataTableUsage;
  /**
   * Possible values include: 'ContactControllerList', 'TicketControllerList',
   * 'ResourceControllerList', 'MissionControllerList', 'ContractControllerList',
   * 'LocationControllerList', 'CompanyControllerList', 'LocationGroupControllerList',
   * 'AddressControllerList', 'ArticlesControllerList', 'ResourceTaskControllerList',
   * 'InfoControllerList', 'KnowledgeArticleControllerList', 'TaskControllerList',
   * 'TicketsInResourcesControllerList', 'MissionsInResourcesControllerList',
   * 'TicketInLocationControllerId', 'ResourceManufacturerControllerId',
   * 'ResourceModelControllerId', 'IssueControllerId', 'ConsumptionHistoryForResourceList',
   * 'ErrorHistoryForResourceList', 'HintHistoryForResourceList',
   * 'ResourceModelInManufacturerControllerId', 'InfoProgressContactControllerId',
   * 'InfoProgressFunctionControllerId', 'InfoProgressLocationControllerId',
   * 'KnowledgePathControllerList', 'KnowledgePathProgressContactControllerId',
   * 'KnowledgePathProgressFunctionControllerId', 'KnowledgePathProgressLocationControllerId',
   * 'OccuredConsumptionProtocol', 'OccuredErrorProtocol', 'MessageIssueControllerId',
   * 'TicketIssueControllerId', 'MissionIssueControllerId',
   * 'WinterhalterServiceCallIssueControllerId', 'WinterhalterProductOrderIssueControllerId',
   * 'MessageControlControllerId'
   */
  pageOperation?: ControllerOperationId;
  pageSize?: number;
  name?: string;
  tenantId?: number;
  columns?: DataTableColumnDto[];
  filters?: DataTableFilterDto[];
}

/**
 * An interface representing MissionControlContentDto.
 */
export interface MissionControlContentDto {
  stereotype?: StereotypeSimpleDto;
  /**
   * Possible values include: 'None', 'Editor', 'PrioritySearch'
   */
  editorSelectorKind?: MissionEditorSelectorKind;
  fixedEditor?: ContactSimpleDto;
  priorities?: PrioritySimpleDto[];
}

/**
 * An interface representing ControlNotificationDto.
 */
export interface ControlNotificationDto {
  controlNotificationId?: number;
  /**
   * Possible values include: 'Base', 'Email'
   */
  type?: ControlNotificationType;
  tenantId?: number;
}

/**
 * An interface representing ControlDto.
 */
export interface ControlDto {
  controlId?: number;
  title?: string;
  tenantId?: number;
  startDate?: string;
  endDate?: string;
  isActivated?: boolean;
  isArchived?: boolean;
  dataTable?: DataTableDto;
  condition?: ControlConditionDto;
  rawIssueTitleTemplate?: string;
  /**
   * Possible values include: 'Message', 'Mission'
   */
  contentType?: ControlContentType;
  messageContent?: any;
  missionContent?: MissionControlContentDto;
  notifications?: ControlNotificationDto[];
}

/**
 * An interface representing ControlErrorConditionDto.
 */
export interface ControlErrorConditionDto {
  controlConditionId?: number;
  /**
   * Possible values include: 'Base', 'FixedTime', 'Consumption', 'Error'
   */
  type?: ControlConditionType;
  tenantId?: number;
  error?: ErrorCodeSimpleDto;
}

/**
 * An interface representing ControlFixedTimeConditionDto.
 */
export interface ControlFixedTimeConditionDto {
  controlConditionId?: number;
  /**
   * Possible values include: 'Base', 'FixedTime', 'Consumption', 'Error'
   */
  type?: ControlConditionType;
  tenantId?: number;
  timeOfOccurrence?: string;
  frequency?: string;
}

/**
 * An interface representing ControlListDto.
 */
export interface ControlListDto {
  controlId?: number;
  title?: string;
  endDate?: string;
  startDate?: string;
  tenantId?: number;
  isActivated?: boolean;
  isArchived?: boolean;
}

/**
 * An interface representing ControlReceiverDto.
 */
export interface ControlReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: ControlReceiverType;
}

/**
 * An interface representing ControlSimpleDto.
 */
export interface ControlSimpleDto {
  controlId?: number;
  title?: string;
}

/**
 * An interface representing ControlSimpleListDto.
 */
export interface ControlSimpleListDto {
  controlId?: number;
  title?: string;
}

/**
 * An interface representing FormValueDto.
 */
export interface FormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
}

/**
 * An interface representing FillAFormFieldDto.
 */
export interface FillAFormFieldDto {
  fieldId?: number;
  rowIndex?: number;
  values?: FormValueDto[];
}

/**
 * An interface representing FillAFormDto.
 */
export interface FillAFormDto {
  fields?: FillAFormFieldDto[];
}

/**
 * An interface representing CreateChatByFormDto.
 */
export interface CreateChatByFormDto {
  id?: number;
  externalId?: string;
  processId?: number;
  receivers?: ChatByFormReceiverDto[];
  filledForm?: FillAFormDto;
}

/**
 * An interface representing CreateFileDto.
 */
export interface CreateFileDto {
  file?: string;
  name?: string;
}

/**
 * An interface representing CreateFolderDto.
 */
export interface CreateFolderDto {
  folderName?: string;
}

/**
 * An interface representing CreateManualTaskJobDto.
 */
export interface CreateManualTaskJobDto {
  taskId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  explicitState?: TaskJobState;
}

/**
 * An interface representing TicketByProcessUsageDto.
 */
export interface TicketByProcessUsageDto {
  id?: number;
  ticketId?: number;
  title?: string;
  description?: string;
  tenantId?: number;
  currentState?: StateSimpleDto;
  resource?: ResourceSimpleDto;
  priority?: PrioritySimpleDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing CreateTicketByProcessDto.
 */
export interface CreateTicketByProcessDto {
  id?: number;
  processId?: number;
  ticket?: TicketByProcessUsageDto;
  tenantId?: number;
}

/**
 * An interface representing InitializedChatDto.
 */
export interface InitializedChatDto {
  chatId?: number;
  title?: string;
}

/**
 * An interface representing CreateTicketByProcessResponseDto.
 */
export interface CreateTicketByProcessResponseDto {
  chat?: InitializedChatDto;
  missionId?: number;
  ticketId?: number;
}

/**
 * An interface representing CreatedByDto.
 */
export interface CreatedByDto {
  id?: number;
  name?: string;
}

/**
 * An interface representing CultureSettingDto.
 */
export interface CultureSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: string;
}

/**
 * An interface representing CurrentAppOverviewDto.
 */
export interface CurrentAppOverviewDto {
  shortcuts?: AppShortcutDto[];
}

/**
 * An interface representing CustomCheckboxDefaultDto.
 */
export interface CustomCheckboxDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: boolean;
}

/**
 * An interface representing CustomCheckboxDefaultExportDto.
 */
export interface CustomCheckboxDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: boolean;
}

/**
 * An interface representing CustomDefaultDto.
 */
export interface CustomDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
}

/**
 * An interface representing CustomCheckboxDto.
 */
export interface CustomCheckboxDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  isRateable?: boolean;
  setPoint?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomDefaultExportDto.
 */
export interface CustomDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
}

/**
 * An interface representing CustomCheckboxExportDto.
 */
export interface CustomCheckboxExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  isRateable?: boolean;
  setPoint?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomCheckboxValueDto.
 */
export interface CustomCheckboxValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: boolean;
  inheritedValue?: boolean;
}

/**
 * An interface representing CustomDateDefaultDto.
 */
export interface CustomDateDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: Date;
}

/**
 * An interface representing CustomDateDefaultExportDto.
 */
export interface CustomDateDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: Date;
}

/**
 * An interface representing CustomDatePropertyDto.
 */
export interface CustomDatePropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  maxDate?: Date;
  minDate?: Date;
  setMaxDate?: Date;
  setMinDate?: Date;
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomDatePropertyExportDto.
 */
export interface CustomDatePropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  maxDate?: Date;
  minDate?: Date;
  setMaxDate?: Date;
  setMinDate?: Date;
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomDateValueDto.
 */
export interface CustomDateValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: Date;
  inheritedValue?: Date;
}

/**
 * An interface representing CustomDefaultCompleteDto.
 */
export interface CustomDefaultCompleteDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  isSelected?: boolean;
  position?: number;
  content?: string;
  /**
   * Possible values include: 'Ok', 'Error'
   */
  rating?: PropertyRating;
  value?: any;
}

/**
 * An interface representing CustomDropDownDefaultExportDto.
 */
export interface CustomDropDownDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: string;
  isSelected?: boolean;
  /**
   * Possible values include: 'Ok', 'Error'
   */
  rating?: PropertyRating;
  position?: number;
}

/**
 * An interface representing CustomDropDownDefaultValueDto.
 */
export interface CustomDropDownDefaultValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: string;
  /**
   * Possible values include: 'Ok', 'Error'
   */
  rating?: PropertyRating;
  isSelected?: boolean;
  position?: number;
}

/**
 * An interface representing CustomDropDownDto.
 */
export interface CustomDropDownDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomDropDownExportDto.
 */
export interface CustomDropDownExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomDropDownValueDto.
 */
export interface CustomDropDownValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: number;
  inheritedValue?: number;
  isSelected?: boolean;
  position?: number;
}

/**
 * An interface representing CustomEmailDefaultDto.
 */
export interface CustomEmailDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: string;
}

/**
 * An interface representing CustomEmailDefaultExportDto.
 */
export interface CustomEmailDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: string;
}

/**
 * An interface representing CustomEmailPropertyDto.
 */
export interface CustomEmailPropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
}

/**
 * An interface representing CustomEmailPropertyExportDto.
 */
export interface CustomEmailPropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
}

/**
 * An interface representing CustomEmailValueDto.
 */
export interface CustomEmailValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: string;
  inheritedValue?: string;
}

/**
 * An interface representing CustomFilterDto.
 */
export interface CustomFilterDto {
  property?: string;
  value?: string;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'DataTransferObject', 'Custom', 'Grouped'
   */
  type?: FilterTypes;
}

/**
 * An interface representing CustomInfoDto.
 */
export interface CustomInfoDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  /**
   * Possible values include: 'Warning', 'Hint'
   */
  infoKind?: CustomInfoKinds;
  content?: string;
}

/**
 * An interface representing CustomInfoExportDto.
 */
export interface CustomInfoExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  /**
   * Possible values include: 'Warning', 'Hint'
   */
  infoKind?: CustomInfoKinds;
  content?: string;
}

/**
 * An interface representing CustomInfoValueDto.
 */
export interface CustomInfoValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
}

/**
 * An interface representing CustomMultilineDefaultDto.
 */
export interface CustomMultilineDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: string;
}

/**
 * An interface representing CustomMultilineDefaultExportDto.
 */
export interface CustomMultilineDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: string;
}

/**
 * An interface representing CustomMultilinePropertyDto.
 */
export interface CustomMultilinePropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  maxLength?: number;
  minLength?: number;
}

/**
 * An interface representing CustomMultilinePropertyExportDto.
 */
export interface CustomMultilinePropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  maxLength?: number;
  minLength?: number;
}

/**
 * An interface representing CustomMultilineValueDto.
 */
export interface CustomMultilineValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: string;
  inheritedValue?: string;
}

/**
 * An interface representing CustomMultipleChoiceDefaultDto.
 */
export interface CustomMultipleChoiceDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: string;
  /**
   * Possible values include: 'Ok', 'Error'
   */
  rating?: PropertyRating;
  isSelected?: boolean;
  position?: number;
}

/**
 * An interface representing CustomMultipleChoiceDefaultExportDto.
 */
export interface CustomMultipleChoiceDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: string;
  isSelected?: boolean;
  /**
   * Possible values include: 'Ok', 'Error'
   */
  rating?: PropertyRating;
  position?: number;
}

/**
 * An interface representing CustomMultipleChoiceDto.
 */
export interface CustomMultipleChoiceDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomMultipleChoiceExportDto.
 */
export interface CustomMultipleChoiceExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomMultipleChoiceValueDto.
 */
export interface CustomMultipleChoiceValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: number;
  inheritedValue?: number;
  isSelected?: boolean;
  position?: number;
}

/**
 * An interface representing CustomNumericDefaultDto.
 */
export interface CustomNumericDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: number;
}

/**
 * An interface representing CustomNumericDefaultExportDto.
 */
export interface CustomNumericDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: number;
}

/**
 * An interface representing CustomNumericPropertyDto.
 */
export interface CustomNumericPropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  maxValue?: number;
  minValue?: number;
  setPointMin?: number;
  setPointMax?: number;
  isRateable?: boolean;
  weight?: number;
  isNumberFormatActive?: boolean;
  unit?: string;
}

/**
 * An interface representing CustomNumericPropertyExportDto.
 */
export interface CustomNumericPropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  maxValue?: number;
  minValue?: number;
  setPointMin?: number;
  setPointMax?: number;
  isRateable?: boolean;
  weight?: number;
  isNumberFormatActive?: boolean;
  unit?: string;
}

/**
 * An interface representing CustomNumericValueDto.
 */
export interface CustomNumericValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: number;
  inheritedValue?: number;
}

/**
 * An interface representing CustomPhoneDefaultDto.
 */
export interface CustomPhoneDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: string;
}

/**
 * An interface representing CustomPhoneDefaultExportDto.
 */
export interface CustomPhoneDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: string;
}

/**
 * An interface representing CustomPhoneDto.
 */
export interface CustomPhoneDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
}

/**
 * An interface representing CustomPhoneExportDto.
 */
export interface CustomPhoneExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
}

/**
 * An interface representing CustomPhoneValueDto.
 */
export interface CustomPhoneValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: string;
  inheritedValue?: string;
}

/**
 * An interface representing CustomPictureDto.
 */
export interface CustomPictureDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
}

/**
 * An interface representing CustomPictureExportDto.
 */
export interface CustomPictureExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
}

/**
 * An interface representing CustomPictureValueDto.
 */
export interface CustomPictureValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: number;
  ownValueFile?: FileDto;
  inheritedValue?: number;
  inheritedValueFile?: FileDto;
}

/**
 * An interface representing CustomPropertyCompleteDto.
 */
export interface CustomPropertyCompleteDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  isRequired?: boolean;
  isRateable?: boolean;
  weight?: number;
  maxLength?: number;
  minLength?: number;
  minValue?: number;
  maxValue?: number;
  setPointMin?: number;
  setPointMax?: number;
  unit?: string;
  isNumberFormatActive?: boolean;
  minMinutes?: number;
  maxMinutes?: number;
  setMinMinutes?: number;
  setMaxMinutes?: number;
  maxDate?: Date;
  minDate?: Date;
  setMaxDate?: Date;
  setMinDate?: Date;
  content?: string;
  /**
   * Possible values include: 'Warning', 'Hint'
   */
  infoKind?: CustomInfoKinds;
  setPoint?: boolean;
  defaultValues?: CustomDefaultCompleteDto[];
}

/**
 * An interface representing CustomPropertyDto.
 */
export interface CustomPropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
}

/**
 * An interface representing CustomPropertyExportDto.
 */
export interface CustomPropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
}

/**
 * An interface representing CustomPropertySetDto.
 */
export interface CustomPropertySetDto {
  customPropertySetId?: number;
  name?: string;
  tenantId?: number;
  properties?: CustomPropertyDto[];
}

/**
 * An interface representing CustomPropertySetExportDto.
 */
export interface CustomPropertySetExportDto {
  name?: string;
  properties?: CustomPropertyExportDto[];
}

/**
 * An interface representing CustomPropertySetListDto.
 */
export interface CustomPropertySetListDto {
  customPropertySetId?: number;
  name?: string;
  tenantId?: number;
  properties?: CustomPropertyCompleteDto[];
}

/**
 * An interface representing CustomPropertyTtm.
 */
export interface CustomPropertyTtm {
  value?: string;
  name?: string;
  unit?: string;
  html?: string;
}

/**
 * An interface representing CustomPropertyViewSetDto.
 */
export interface CustomPropertyViewSetDto {
  customPropertySetId?: number;
  name?: string;
  properties?: CustomPropertyDto[];
  tenantId?: number;
  stereotypeId?: number;
}

/**
 * An interface representing CustomSetReferenceDto.
 */
export interface CustomSetReferenceDto {
  stereotypeId: number;
  customPropertySetId?: number;
  customPropertySet?: CustomPropertySetDto;
  isInherited?: boolean;
  position?: number;
}

/**
 * An interface representing CustomSetReferenceExportDto.
 */
export interface CustomSetReferenceExportDto {
  customPropertySet?: CustomPropertySetExportDto;
}

/**
 * An interface representing CustomSetReferenceListDto.
 */
export interface CustomSetReferenceListDto {
  stereotypeId?: number;
  customPropertySetId?: number;
  customPropertySet?: CustomPropertySetListDto;
  position?: number;
  isInherited?: boolean;
}

/**
 * An interface representing CustomSignatureDto.
 */
export interface CustomSignatureDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
}

/**
 * An interface representing CustomSignatureExportDto.
 */
export interface CustomSignatureExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
}

/**
 * An interface representing CustomSignatureValueDto.
 */
export interface CustomSignatureValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: number;
  ownValueFile?: FileDto;
  inheritedValue?: number;
  inheritedValueFile?: FileDto;
}

/**
 * An interface representing CustomTextDefaultDto.
 */
export interface CustomTextDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: string;
}

/**
 * An interface representing CustomTextDefaultExportDto.
 */
export interface CustomTextDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: string;
}

/**
 * An interface representing CustomTextPropertyDto.
 */
export interface CustomTextPropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  maxLength?: number;
  minLength?: number;
}

/**
 * An interface representing CustomTextPropertyExportDto.
 */
export interface CustomTextPropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  maxLength?: number;
  minLength?: number;
}

/**
 * An interface representing CustomTextValueDto.
 */
export interface CustomTextValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: string;
  inheritedValue?: string;
}

/**
 * An interface representing CustomTimeDefaultDto.
 */
export interface CustomTimeDefaultDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  customPropertyId?: number;
  tenantId?: number;
  value?: number;
}

/**
 * An interface representing CustomTimeDefaultExportDto.
 */
export interface CustomTimeDefaultExportDto {
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  value?: number;
}

/**
 * An interface representing CustomTimePropertyDto.
 */
export interface CustomTimePropertyDto {
  id?: number;
  customPropertyId?: number;
  name?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  position?: number;
  defaultValues?: CustomDefaultDto[];
  maxMinutes?: number;
  minMinutes?: number;
  setMaxMinutes?: number;
  setMinMinutes?: number;
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomTimePropertyExportDto.
 */
export interface CustomTimePropertyExportDto {
  name?: string;
  isRequired?: boolean;
  position?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  defaultValues?: CustomDefaultExportDto[];
  maxMinutes?: number;
  minMinutes?: number;
  setMaxMinutes?: number;
  setMinMinutes?: number;
  isRateable?: boolean;
  weight?: number;
}

/**
 * An interface representing CustomTimeValueDto.
 */
export interface CustomTimeValueDto {
  customValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
   * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
   */
  type?: CustomPropertyType;
  tenantId?: number;
  isInherited?: boolean;
  ownValue?: number;
  inheritedValue?: number;
}

/**
 * An interface representing PlannedTimeDto.
 */
export interface PlannedTimeDto {
  plannedTimeId?: number;
  time?: string;
}

/**
 * An interface representing DailyDateTimePatternDto.
 */
export interface DailyDateTimePatternDto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeDto[];
  startDate?: string;
  endDate?: string;
  useOpeningDays?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

/**
 * An interface representing PlannedTimeV1Dto.
 */
export interface PlannedTimeV1Dto {
  plannedTimeId?: number;
  time?: string;
  datetimePatternId?: number;
}

/**
 * An interface representing DailyDateTimePatternV1Dto.
 */
export interface DailyDateTimePatternV1Dto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeV1Dto[];
  startDate?: string;
  endDate?: string;
  useOpeningDays?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

/**
 * An interface representing DashboardItemDto.
 */
export interface DashboardItemDto {
  position?: number;
  /**
   * Possible values include: 'Base', 'DataTable'
   */
  type?: DashboardItemTypes;
}

/**
 * An interface representing DashboardDto.
 */
export interface DashboardDto {
  dashboardId?: number;
  name?: string;
  tenantId?: number;
  items?: DashboardItemDto[];
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  contacts?: ContactSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing DashboardListDto.
 */
export interface DashboardListDto {
  dashboardId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing DashboardTableReferenceDto.
 */
export interface DashboardTableReferenceDto {
  position?: number;
  /**
   * Possible values include: 'Base', 'DataTable'
   */
  type?: DashboardItemTypes;
  dashboardId?: number;
  dataTableId?: number;
  dataTable?: DataTableDto;
}

/**
 * An interface representing DataTableCustomColumnDto.
 */
export interface DataTableCustomColumnDto {
  dataTableColumnId?: number;
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  tenantId?: number;
  customPropertyId?: number;
}

/**
 * An interface representing DataTableCustomFilterDto.
 */
export interface DataTableCustomFilterDto {
  dataTableFilterId?: number;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  label?: string;
  tenantId?: number;
  customPropertyId?: number;
}

/**
 * An interface representing DataTableGroupedFilterDto.
 */
export interface DataTableGroupedFilterDto {
  dataTableFilterId?: number;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  label?: string;
  tenantId?: number;
  /**
   * Possible values include: 'And', 'Or'
   */
  combinator?: CombineOperator;
  children?: DataTableFilterDto[];
}

/**
 * An interface representing DataTableListDto.
 */
export interface DataTableListDto {
  dataTableId?: number;
  /**
   * Possible values include: 'DataTable', 'Selector', 'Restriction', 'Calendar'
   */
  usage?: DataTableUsage;
  /**
   * Possible values include: 'Table', 'Gantt'
   */
  viewType?: DataTableViewType;
  name?: string;
  /**
   * Possible values include: 'ContactControllerList', 'TicketControllerList',
   * 'ResourceControllerList', 'MissionControllerList', 'ContractControllerList',
   * 'LocationControllerList', 'CompanyControllerList', 'LocationGroupControllerList',
   * 'AddressControllerList', 'ArticlesControllerList', 'ResourceTaskControllerList',
   * 'InfoControllerList', 'KnowledgeArticleControllerList', 'TaskControllerList',
   * 'TicketsInResourcesControllerList', 'MissionsInResourcesControllerList',
   * 'TicketInLocationControllerId', 'ResourceManufacturerControllerId',
   * 'ResourceModelControllerId', 'IssueControllerId', 'ConsumptionHistoryForResourceList',
   * 'ErrorHistoryForResourceList', 'HintHistoryForResourceList',
   * 'ResourceModelInManufacturerControllerId', 'InfoProgressContactControllerId',
   * 'InfoProgressFunctionControllerId', 'InfoProgressLocationControllerId',
   * 'KnowledgePathControllerList', 'KnowledgePathProgressContactControllerId',
   * 'KnowledgePathProgressFunctionControllerId', 'KnowledgePathProgressLocationControllerId',
   * 'OccuredConsumptionProtocol', 'OccuredErrorProtocol', 'MessageIssueControllerId',
   * 'TicketIssueControllerId', 'MissionIssueControllerId',
   * 'WinterhalterServiceCallIssueControllerId', 'WinterhalterProductOrderIssueControllerId',
   * 'MessageControlControllerId'
   */
  pageOperation?: ControllerOperationId;
  tenantId?: number;
}

/**
 * An interface representing DataTableSimpleDto.
 */
export interface DataTableSimpleDto {
  dataTableId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing DataTableTransferColumnDto.
 */
export interface DataTableTransferColumnDto {
  dataTableColumnId?: number;
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  tenantId?: number;
  property?: string;
}

/**
 * An interface representing DataTableTransferFilterDto.
 */
export interface DataTableTransferFilterDto {
  dataTableFilterId?: number;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  label?: string;
  tenantId?: number;
  property?: string;
}

/**
 * An interface representing DateFormDefaultDto.
 */
export interface DateFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing DateFormFieldDto.
 */
export interface DateFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  maxValue?: string;
  minValue?: string;
}

/**
 * An interface representing DateFormValueDto.
 */
export interface DateFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing DateTimePatternDto.
 */
export interface DateTimePatternDto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeDto[];
}

/**
 * An interface representing DateTimePatternV1Dto.
 */
export interface DateTimePatternV1Dto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeV1Dto[];
}

/**
 * An interface representing DeactivateResourceTaskQueueDto.
 */
export interface DeactivateResourceTaskQueueDto {
  taskId?: number;
}

/**
 * An interface representing DeclineEditorRequestDto.
 */
export interface DeclineEditorRequestDto {
  contactId?: number;
}

/**
 * An interface representing PermissionAreaDto.
 */
export interface PermissionAreaDto {
  /**
   * Possible values include: 'Global', 'Location', 'Tenant', 'LocationGroup', 'User'
   */
  type?: ContextKind;
  permissions?: AppPermissions[];
  contexts?: AllowedContextDto[];
}

/**
 * An interface representing DecodedTokenDto.
 */
export interface DecodedTokenDto {
  permissions?: PermissionAreaDto[];
}

/**
 * An interface representing DeletedAreaAssignmentQueueDto.
 */
export interface DeletedAreaAssignmentQueueDto {
  contactId?: number;
  tenantId?: number;
}

/**
 * An interface representing DeletedEntityQueueDto.
 */
export interface DeletedEntityQueueDto {
  entityId?: number;
  tenantId?: number;
}

/**
 * An interface representing DeletedLocationGroupLocationQueueDto.
 */
export interface DeletedLocationGroupLocationQueueDto {
  locationGroupId?: number;
  locationId?: number;
  tenantId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
}

/**
 * An interface representing DeviceActionNotificationDto.
 */
export interface DeviceActionNotificationDto {
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
  /**
   * Possible values include: 'Logout', 'Ping', 'Restart'
   */
  action?: DeviceActionTypes;
}

/**
 * An interface representing DeviceCodeChallengeDto.
 */
export interface DeviceCodeChallengeDto {
  loginChallengeId?: number;
  pin?: string;
  expiryDate?: string;
}

/**
 * An interface representing DeviceDto.
 */
export interface DeviceDto {
  id?: number;
  isRegisteredForPush?: boolean;
  /**
   * Possible values include: 'iOS', 'Android'
   */
  platform?: Platforms;
  name?: string;
  tenantId?: number;
  rowVersion?: number[];
  locationId?: number;
  location?: LocationSimpleDto;
  challenges?: DeviceCodeChallengeDto[];
}

/**
 * An interface representing DeviceListDto.
 */
export interface DeviceListDto {
  id?: number;
  /**
   * Possible values include: 'iOS', 'Android'
   */
  platform?: Platforms;
  name?: string;
  hardwareId?: string;
  locationId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly locationName?: string;
  location?: LocationSimpleListDto;
}

/**
 * An interface representing DeviceMetaEntryDto.
 */
export interface DeviceMetaEntryDto {
  /**
   * Possible values include: 'Device'
   */
  type?: MetaEntryType;
  appVersion?: string;
}

/**
 * An interface representing DeviceMetaListDto.
 */
export interface DeviceMetaListDto {
  deviceMetadataId?: number;
  version?: string;
  lastModified?: string;
  createdAt?: string;
}

/**
 * An interface representing FormInDocumentTaskDto.
 */
export interface FormInDocumentTaskDto {
  formId?: number;
  title?: string;
}

/**
 * An interface representing DocumentChildTaskDto.
 */
export interface DocumentChildTaskDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  description?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  impacts?: ImpactDto[];
  documentTemplate?: DocumentTemplateSimpleDto;
  form?: FormInDocumentTaskDto;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing DocumentChildTaskSimpleDto.
 */
export interface DocumentChildTaskSimpleDto {
  taskId?: number;
  title?: string;
  tenantId?: number;
  position?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  form?: FormInDocumentTaskDto;
}

/**
 * An interface representing DocumentRequestDto.
 */
export interface DocumentRequestDto {
  path?: string;
  domain?: string;
  tenantId?: number;
}

/**
 * An interface representing DocumentSearchRequestDto.
 */
export interface DocumentSearchRequestDto {
  path?: string;
  searchString?: string;
  domain?: string;
  tenantId?: number;
}

/**
 * An interface representing DocumentTaskCreateV1Dto.
 */
export interface DocumentTaskCreateV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isEnabled?: boolean;
  reasonSetId?: number;
  executionPlan?: ExecutionPlanV1Dto;
  locations?: LocationTaskV1Dto[];
  locationGroups?: LocationGroupTaskV1Dto[];
  impacts?: ImpactCreateV1Dto[];
  tenantId?: number;
  formId?: number;
  parentId?: number;
  position?: number;
}

/**
 * An interface representing DocumentTaskDto.
 */
export interface DocumentTaskDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  description?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  impacts?: ImpactDto[];
  documentTemplate?: DocumentTemplateSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isActive?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  executionPlan?: ExecutionPlanDto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  categories?: CategorySimpleDto[];
  form?: FormInDocumentTaskDto;
  position?: number;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing DocumentTaskListV1Dto.
 */
export interface DocumentTaskListV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  parentId?: number;
  executionPlan?: ExecutionPlanViewV1Dto;
  executionPlanId?: number;
  requiresAuthentication?: boolean;
  reasonSetId?: number;
  tenantId?: number;
  position?: number;
}

/**
 * An interface representing DocumentTaskSimpleV1Dto.
 */
export interface DocumentTaskSimpleV1Dto {
  taskId?: number;
  title?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  formId?: number;
}

/**
 * An interface representing DocumentTaskV1Dto.
 */
export interface DocumentTaskV1Dto {
  parentId?: number;
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  requiresAuthentication?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  executionPlan?: ExecutionPlanViewV1Dto;
  locations?: LocationRelationDto[];
  locationGroups?: LocationGroupRelationDto[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  impacts?: ImpactV1Dto[];
  form?: FormSimpleDto;
  formId?: number;
  position?: number;
}

/**
 * An interface representing DocumentTemplateDto.
 */
export interface DocumentTemplateDto {
  documentTemplateId?: number;
  title?: string;
  externalId?: string;
  template?: FileDto;
  tenantId?: number;
  isDefault?: boolean;
  culture?: string;
  /**
   * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
   * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
   * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
   * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
   */
  contextType?: DocumentTemplateContextType;
}

/**
 * An interface representing EmbeddedFileExportDto.
 */
export interface EmbeddedFileExportDto {
  name?: string;
  /**
   * Possible values include: 'AAB', 'AAC', 'AAM', 'AAS', 'ABW', 'AC', 'ACC', 'ACE', 'ACU',
   * 'ACUTC', 'ADP', 'AEP', 'AFM', 'AFP', 'AHEAD', 'AI', 'AIF', 'AIFC', 'AIFF', 'AIR', 'AIT',
   * 'AMI', 'APK', 'APPCACHE', 'APPLICATION', 'APR', 'ARC', 'ASC', 'ASF', 'ASM', 'ASO', 'ASX',
   * 'ATC', 'ATOM', 'ATOMCAT', 'ATOMSVC', 'ATX', 'AU', 'AVI', 'AW', 'AZF', 'AZS', 'AZW', 'BAT',
   * 'BCPIO', 'BDF', 'BDM', 'BED', 'BH2', 'BIN', 'BLB', 'BLORB', 'BMI', 'BMP', 'BOOK', 'BOX',
   * 'BOZ', 'BPK', 'BTIF', 'BZ', 'BZ2', 'C', 'C11AMC', 'C11AMZ', 'C4D', 'C4F', 'C4G', 'C4P', 'C4U',
   * 'CAB', 'CAF', 'CAP', 'CAR', 'CAT', 'CB7', 'CBA', 'CBR', 'CBT', 'CBZ', 'CC', 'CCT', 'CCXML',
   * 'CDBCMSG', 'CDF', 'CDKEY', 'CDMIA', 'CDMIC', 'CDMID', 'CDMIO', 'CDMIQ', 'CDX', 'CDXML', 'CDY',
   * 'CER', 'CFS', 'CGM', 'CHAT', 'CHM', 'CHRT', 'CIF', 'CII', 'CIL', 'CLA', 'CLASS', 'CLKK',
   * 'CLKP', 'CLKT', 'CLKW', 'CLKX', 'CLP', 'CMC', 'CMDF', 'CML', 'CMP', 'CMX', 'COD', 'COM',
   * 'CONF', 'CPIO', 'CPP', 'CPT', 'CRD', 'CRL', 'CRT', 'CRYPTONOTE', 'CSH', 'CSML', 'CSP', 'CSS',
   * 'CST', 'CSV', 'CU', 'CURL', 'CWW', 'CXT', 'CXX', 'DAE', 'DAF', 'DART', 'DATALESS', 'DAVMOUNT',
   * 'DBK', 'DCR', 'DCURL', 'DD2', 'DDD', 'DEB', 'DEF', 'DEPLOY', 'DER', 'DFAC', 'DGC', 'DIC',
   * 'DIF', 'DIR', 'DIS', 'DIST', 'DISTZ', 'DJV', 'DJVU', 'DLL', 'DMG', 'DMP', 'DMS', 'DNA', 'DOC',
   * 'DOCM', 'DOCX', 'DOT', 'DOTM', 'DOTX', 'DP', 'DPG', 'DRA', 'DSC', 'DSSC', 'DTB', 'DTD', 'DTS',
   * 'DTSHD', 'DUMP', 'DV', 'DVB', 'DVI', 'DWF', 'DWG', 'DXF', 'DXP', 'DXR', 'ECELP4800',
   * 'ECELP7470', 'ECELP9600', 'ECMA', 'EDM', 'EDX', 'EFIF', 'EI6', 'ELC', 'EMF', 'EML', 'EMMA',
   * 'EMZ', 'EOL', 'EOT', 'EPS', 'EPUB', 'ES3', 'ESA', 'ESF', 'ET3', 'ETX', 'EVA', 'EVY', 'EXE',
   * 'EXI', 'EXT', 'EXTENSIONS', 'EZ', 'EZ2', 'EZ3', 'F', 'F4V', 'F77', 'F90', 'FBS', 'FCDT',
   * 'FCS', 'FDF', 'FE_LAUNCH', 'FG5', 'FGD', 'FH', 'FH4', 'FH5', 'FH7', 'FHC', 'FIG', 'FLAC',
   * 'FLI', 'FLO', 'FLV', 'FLW', 'FLX', 'FLY', 'FM', 'FNC', 'FOR', 'FPX', 'FRAME', 'FSC', 'FST',
   * 'FTC', 'FTI', 'FVT', 'FXP', 'FXPL', 'FZS', 'G2W', 'G3', 'G3W', 'GAC', 'GAM', 'GBR', 'GCA',
   * 'GDL', 'GEO', 'GEX', 'GGB', 'GGT', 'GHF', 'GIF', 'GIM', 'GML', 'GMX', 'GNUMERIC', 'GPH',
   * 'GPX', 'GQF', 'GQS', 'GRAM', 'GRAMPS', 'GRE', 'GRV', 'GRXML', 'GSF', 'GTAR', 'GTM', 'GTW',
   * 'GV', 'GXF', 'GXT', 'H', 'H261', 'H263', 'H264', 'HAL', 'HBCI', 'HDF', 'HH', 'HLP', 'HPGL',
   * 'HPID', 'HPS', 'HQX', 'HTKE', 'HTM', 'HTML', 'HVD', 'HVP', 'HVS', 'I2G', 'IC', 'ICC', 'ICE',
   * 'ICM', 'ICO', 'ICS', 'IEF', 'IFB', 'IFM', 'IGES', 'IGL', 'IGM', 'IGS', 'IGX', 'IIF', 'IMP',
   * 'IMS', 'IN', 'INK', 'INKML', 'INSTALL', 'IOTA', 'IPFIX', 'IPK', 'IRM', 'IRP', 'ISO', 'ITP',
   * 'IVP', 'IVU', 'JAD', 'JAM', 'JAR', 'JAVA', 'JISP', 'JLT', 'JNLP', 'JODA', 'JP2', 'JPEG',
   * 'JPG', 'JPGM', 'JPGV', 'JPM', 'JS', 'JSON', 'JSONML', 'KAR', 'KARBON', 'KFO', 'KIA', 'KML',
   * 'KMZ', 'KNE', 'KNP', 'KON', 'KPR', 'KPT', 'KPXX', 'KSP', 'KTR', 'KTX', 'KTZ', 'KWD', 'KWT',
   * 'LASXML', 'LATEX', 'LBD', 'LBE', 'LES', 'LHA', 'LINK66', 'LIST', 'LIST3820', 'LISTAFP', 'LNK',
   * 'LOG', 'LOSTXML', 'LRF', 'LRM', 'LTF', 'LVP', 'LWP', 'LZH', 'M13', 'M14', 'M1V', 'M21', 'M2A',
   * 'M2V', 'M3A', 'M3U', 'M3U8', 'M4A', 'M4B', 'M4P', 'M4U', 'M4V', 'MA', 'MAC', 'MADS', 'MAG',
   * 'MAKER', 'MAN', 'MAR', 'MATHML', 'MB', 'MBK', 'MBOX', 'MC1', 'MCD', 'MCURL', 'MDB', 'MDI',
   * 'ME', 'MESH', 'META4', 'METALINK', 'METS', 'MFM', 'MFT', 'MGP', 'MGZ', 'MID', 'MIDI', 'MIE',
   * 'MIF', 'MIME', 'MJ2', 'MJP2', 'MK3D', 'MKA', 'MKS', 'MKV', 'MLP', 'MMD', 'MMF', 'MMR', 'MNG',
   * 'MNY', 'MOBI', 'MODS', 'MOV', 'MOVIE', 'MP2', 'MP21', 'MP2A', 'MP3', 'MP4', 'MP4A', 'MP4S',
   * 'MP4V', 'MPC', 'MPE', 'MPEG', 'MPG', 'MPG4', 'MPGA', 'MPKG', 'MPM', 'MPN', 'MPP', 'MPT',
   * 'MPY', 'MQY', 'MRC', 'MRCX', 'MS', 'MSCML', 'MSEED', 'MSEQ', 'MSF', 'MSH', 'MSI', 'MSL',
   * 'MSTY', 'MTS', 'MUS', 'MUSICXML', 'MVB', 'MWF', 'MXF', 'MXL', 'MXML', 'MXS', 'MXU', 'N3',
   * 'NB', 'NBP', 'NC', 'NCX', 'NFO', 'NGDAT', 'NITF', 'NLU', 'NML', 'NND', 'NNS', 'NNW', 'NPX',
   * 'NSC', 'NSF', 'NTF', 'NZB', 'OA2', 'OA3', 'OAS', 'OBD', 'OBJ', 'ODA', 'ODB', 'ODC', 'ODF',
   * 'ODFT', 'ODG', 'ODI', 'ODM', 'ODP', 'ODS', 'ODT', 'OGA', 'OGG', 'OGV', 'OGX', 'OMDOC',
   * 'ONEPKG', 'ONETMP', 'ONETOC', 'ONETOC2', 'OPF', 'OPML', 'OPRC', 'ORG', 'OSF', 'OSFPVG', 'OTC',
   * 'OTF', 'OTG', 'OTH', 'OTI', 'OTP', 'OTS', 'OTT', 'OXPS', 'OXT', 'P', 'P10', 'P12', 'P7B',
   * 'P7C', 'P7M', 'P7R', 'P7S', 'P8', 'PAS', 'PAW', 'PBD', 'PBM', 'PCAP', 'PCF', 'PCL', 'PCLXL',
   * 'PCT', 'PCURL', 'PCX', 'PDB', 'PDF', 'PFA', 'PFB', 'PFM', 'PFR', 'PFX', 'PGM', 'PGN', 'PGP',
   * 'PIC', 'PICT', 'PKG', 'PKI', 'PKIPATH', 'PLB', 'PLC', 'PLF', 'PLS', 'PML', 'PNG', 'PNM',
   * 'PNT', 'PNTG', 'PORTPKG', 'POT', 'POTM', 'POTX', 'PPAM', 'PPD', 'PPM', 'PPS', 'PPSM', 'PPSX',
   * 'PPT', 'PPTM', 'PPTX', 'PQA', 'PRC', 'PRE', 'PRF', 'PS', 'PSB', 'PSD', 'PSF', 'PSKCXML',
   * 'PTID', 'PUB', 'PVB', 'PWN', 'PYA', 'PYV', 'QAM', 'QBO', 'QFX', 'QPS', 'QT', 'QTI', 'QTIF',
   * 'QWD', 'QWT', 'QXB', 'QXD', 'QXL', 'QXT', 'RA', 'RAM', 'RAR', 'RAS', 'RCPROFILE', 'RDF',
   * 'RDZ', 'REP', 'RES', 'RGB', 'RIF', 'RIP', 'RIS', 'RL', 'RLC', 'RLD', 'RM', 'RMI', 'RMP',
   * 'RMS', 'RMVB', 'RNC', 'ROA', 'ROFF', 'RP9', 'RPSS', 'RPST', 'RQ', 'RS', 'RSD', 'RSS', 'RTF',
   * 'RTX', 'S', 'S3M', 'SAF', 'SBML', 'SC', 'SCD', 'SCM', 'SCQ', 'SCS', 'SCURL', 'SDA', 'SDC',
   * 'SDD', 'SDKD', 'SDKM', 'SDP', 'SDW', 'SEE', 'SEED', 'SEMA', 'SEMD', 'SEMF', 'SER', 'SETPAY',
   * 'SETREG', 'SFS', 'SFV', 'SGI', 'SGL', 'SGM', 'SGML', 'SH', 'SHAR', 'SHF', 'SID', 'SIG', 'SIL',
   * 'SILO', 'SIS', 'SISX', 'SIT', 'SITX', 'SKD', 'SKM', 'SKP', 'SKT', 'SLDM', 'SLDX', 'SLT', 'SM',
   * 'SMF', 'SMI', 'SMIL', 'SMV', 'SMZIP', 'SND', 'SNF', 'SO', 'SPC', 'SPF', 'SPL', 'SPOT', 'SPP',
   * 'SPQ', 'SPX', 'SQL', 'SRC', 'SRT', 'SRU', 'SRX', 'SSDL', 'SSE', 'SSF', 'SSML', 'ST', 'STC',
   * 'STD', 'STF', 'STI', 'STK', 'STL', 'STR', 'STW', 'SUB', 'SUS', 'SUSP', 'SV4CPIO', 'SV4CRC',
   * 'SVC', 'SVD', 'SVG', 'SVGZ', 'SWA', 'SWF', 'SWI', 'SXC', 'SXD', 'SXG', 'SXI', 'SXM', 'SXW',
   * 'T', 'T3', 'TAGLET', 'TAO', 'TAR', 'TCAP', 'TCL', 'TEACHER', 'TEI', 'TEICORPUS', 'TEX',
   * 'TEXI', 'TEXINFO', 'TEXT', 'TFI', 'TFM', 'TGA', 'THMX', 'TIF', 'TIFF', 'TMO', 'TORRENT',
   * 'TPL', 'TPT', 'TR', 'TRA', 'TRM', 'TSD', 'TSV', 'TTC', 'TTF', 'TTL', 'TWD', 'TWDS', 'TXD',
   * 'TXF', 'TXT', 'U32', 'UDEB', 'UFD', 'UFDL', 'ULX', 'UMJ', 'UNITYWEB', 'UOML', 'URI', 'URIS',
   * 'URLS', 'USTAR', 'UTZ', 'UU', 'UVA', 'UVD', 'UVF', 'UVG', 'UVH', 'UVI', 'UVM', 'UVP', 'UVS',
   * 'UVT', 'UVU', 'UVV', 'UVVA', 'UVVD', 'UVVF', 'UVVG', 'UVVH', 'UVVI', 'UVVM', 'UVVP', 'UVVS',
   * 'UVVT', 'UVVU', 'UVVV', 'UVVX', 'UVVZ', 'UVX', 'UVZ', 'VCARD', 'VCD', 'VCF', 'VCG', 'VCS',
   * 'VCX', 'VIS', 'VIV', 'VOB', 'VOR', 'VOX', 'VRML', 'VSD', 'VSF', 'VSS', 'VST', 'VSW', 'VTU',
   * 'VXML', 'W3D', 'WAD', 'WAV', 'WAX', 'WBMP', 'WBMXL', 'WBS', 'WBXML', 'WCM', 'WDB', 'WDP',
   * 'WEBA', 'WEBM', 'WEBP', 'WG', 'WGT', 'WKS', 'WM', 'WMA', 'WMD', 'WMF', 'WML', 'WMLC', 'WMLS',
   * 'WMLSC', 'WMV', 'WMX', 'WMZ', 'WOFF', 'WPD', 'WPL', 'WPS', 'WQD', 'WRI', 'WRL', 'WSDL',
   * 'WSPOLICY', 'WTB', 'WVX', 'X32', 'X3D', 'X3DB', 'X3DBZ', 'X3DV', 'X3DVZ', 'X3DZ', 'XAML',
   * 'XAP', 'XAR', 'XBAP', 'XBD', 'XBM', 'XDF', 'XDM', 'XDP', 'XDSSC', 'XDW', 'XENC', 'XER',
   * 'XFDF', 'XFDL', 'XHT', 'XHTML', 'XHVML', 'XIF', 'XLA', 'XLAM', 'XLC', 'XLF', 'XLM', 'XLS',
   * 'XLSB', 'XLSM', 'XLSX', 'XLT', 'XLTM', 'XLTX', 'XLW', 'XM', 'XML', 'XO', 'XOP', 'XPI', 'XPL',
   * 'XPM', 'XPR', 'XPS', 'XPW', 'XPX', 'XSL', 'XSLT', 'XSM', 'XSPF', 'XUL', 'XVM', 'XVML', 'XWD',
   * 'XYZ', 'XZ', 'YANG', 'YIN', 'Z1', 'Z2', 'Z3', 'Z4', 'Z5', 'Z6', 'Z7', 'Z8', 'ZAZ', 'ZIP',
   * 'ZIR', 'ZIRZ', 'ZMM', 'DEFAULT'
   */
  contentType?: ContentType;
  content?: string;
}

/**
 * An interface representing DocumentTemplateExportDto.
 */
export interface DocumentTemplateExportDto {
  externalId?: string;
  title?: string;
  isDefault?: boolean;
  /**
   * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
   * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
   * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
   * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
   */
  contextType?: DocumentTemplateContextType;
  culture?: string;
  template?: EmbeddedFileExportDto;
}

/**
 * An interface representing DocumentTemplateListDto.
 */
export interface DocumentTemplateListDto {
  documentTemplateId?: number;
  title?: string;
  externalId?: string;
  /**
   * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
   * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
   * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
   * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
   */
  contextType?: DocumentTemplateContextType;
  isDefault?: boolean;
  tenantId?: number;
}

/**
 * An interface representing DocumentTemplateSimpleExportDto.
 */
export interface DocumentTemplateSimpleExportDto {
  externalId?: string;
  /**
   * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
   * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
   * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
   * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
   */
  contextType?: DocumentTemplateContextType;
}

/**
 * An interface representing DropDownFormDefaultDto.
 */
export interface DropDownFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
  /**
   * Possible values include: 'Ok', 'Error', 'Tolerant'
   */
  rating?: FieldRating;
  position?: number;
  isSelected?: boolean;
}

/**
 * An interface representing DropDownFormFieldDto.
 */
export interface DropDownFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  isRateable?: boolean;
  /**
   * Possible values include: 'Default', 'ChooseList'
   */
  style?: DropDownStyles;
}

/**
 * An interface representing DropDownFormValueDto.
 */
export interface DropDownFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
}

/**
 * An interface representing DropDownFormValueForCompleteDto.
 */
export interface DropDownFormValueForCompleteDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing DynamicFieldDto.
 */
export interface DynamicFieldDto {
  customPropertySets?: CustomSetReferenceDto[];
  rowVersion?: number[];
}

/**
 * An interface representing EditorDto.
 */
export interface EditorDto {
  contactId?: number;
  displayName?: string;
  phone?: string;
  mobile?: string;
  email?: string;
  userId?: number;
}

/**
 * An interface representing EditorRequestDto.
 */
export interface EditorRequestDto {
  contact?: ContactSimpleDto;
  completeAt?: string;
  /**
   * Possible values include: 'Untouched', 'Accepted', 'Declined', 'Missed'
   */
  state?: EditorRequestState;
}

/**
 * An interface representing EditorQuestDto.
 */
export interface EditorQuestDto {
  functionProperty?: FunctionSimpleDto;
  /**
   * Possible values include: 'Suggested', 'InProcess', 'Completed', 'Failed', 'Canceled'
   */
  state?: EditorQuestState;
  requests?: EditorRequestDto[];
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
}

/**
 * An interface representing EditorSuggestionBaseDto.
 */
export interface EditorSuggestionBaseDto {
  id?: number;
  target?: EditorSuggestionTargetDto;
}

/**
 * An interface representing EditorSuggestionByContactDto.
 */
export interface EditorSuggestionByContactDto {
  /**
   * Possible values include: 'Base', 'ByContact', 'ByFunction'
   */
  type?: EditorSuggestionTargetType;
  processingTime?: string;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing EditorSuggestionByFunctionDto.
 */
export interface EditorSuggestionByFunctionDto {
  /**
   * Possible values include: 'Base', 'ByContact', 'ByFunction'
   */
  type?: EditorSuggestionTargetType;
  processingTime?: string;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing EmailAddressDto.
 */
export interface EmailAddressDto {
  address?: string;
  name?: string;
}

/**
 * An interface representing EmailAddressTtm.
 */
export interface EmailAddressTtm {
  address?: string;
  displayName?: string;
}

/**
 * An interface representing EmailButtonTemplateDto.
 */
export interface EmailButtonTemplateDto {
  text?: string;
  url?: string;
}

/**
 * An interface representing EmailChatMessageDto.
 */
export interface EmailChatMessageDto {
  message?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing TemplateSimpleDto.
 */
export interface TemplateSimpleDto {
  templateId?: number;
  title?: string;
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  context?: TemplateContextType;
  tenantId?: number;
}

/**
 * An interface representing EmailControlNotificationDto.
 */
export interface EmailControlNotificationDto {
  controlNotificationId?: number;
  /**
   * Possible values include: 'Base', 'Email'
   */
  type?: ControlNotificationType;
  tenantId?: number;
  receiver?: ControlReceiverDto;
  emailSubjectTemplate?: TemplateSimpleDto;
  emailContentTemplate?: TemplateSimpleDto;
  attachments?: DocumentTemplateSimpleDto[];
}

/**
 * An interface representing EmailControlReceiverDto.
 */
export interface EmailControlReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: ControlReceiverType;
  email?: string;
}

/**
 * An interface representing EmailFailedMailDeliveryDto.
 */
export interface EmailFailedMailDeliveryDto {
  reason?: string;
  title?: string;
  receiver?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing EmailHitNotificationRuleDto.
 */
export interface EmailHitNotificationRuleDto {
  entityLink?: string;
  entityName?: string;
  body?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing EmailImportMessageDto.
 */
export interface EmailImportMessageDto {
  message?: string;
  /**
   * Possible values include: 'Info', 'Error', 'Warning'
   */
  level?: ReportLevel;
}

/**
 * An interface representing EmailImportDto.
 */
export interface EmailImportDto {
  message?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
  messages?: EmailImportMessageDto[];
  messagesAsTemplate?: string;
}

/**
 * An interface representing IssueReceiverDto.
 */
export interface IssueReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: IssueReceiverType;
}

/**
 * An interface representing EmailIssueNotificationDto.
 */
export interface EmailIssueNotificationDto {
  issueNotificationId?: number;
  /**
   * Possible values include: 'Base', 'Email'
   */
  type?: IssueNotificationType;
  tenantId?: number;
  receiver?: IssueReceiverDto;
  emailSubjectTemplate?: TemplateSimpleDto;
  emailContentTemplate?: TemplateSimpleDto;
  attachments?: DocumentTemplateSimpleDto[];
}

/**
 * An interface representing EmailIssueReceiverDto.
 */
export interface EmailIssueReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: IssueReceiverType;
  email?: string;
}

/**
 * An interface representing MailNodeReceiverDto.
 */
export interface MailNodeReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: MailNodeReceiverType;
}

/**
 * An interface representing EmailMailNodeNotificationDto.
 */
export interface EmailMailNodeNotificationDto {
  mailNodeNotificationId?: number;
  /**
   * Possible values include: 'Base', 'Email'
   */
  type?: MailNodeNotificationType;
  tenantId?: number;
  receiver?: MailNodeReceiverDto;
  emailSubjectTemplate?: TemplateSimpleDto;
  emailContentTemplate?: TemplateSimpleDto;
  attachments?: DocumentTemplateSimpleDto[];
}

/**
 * An interface representing EmailMailNodeReceiverDto.
 */
export interface EmailMailNodeReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: MailNodeReceiverType;
  email?: string;
}

/**
 * An interface representing EmailMissionActivationMailDto.
 */
export interface EmailMissionActivationMailDto {
  description?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing EmailNotificationTargetDto.
 */
export interface EmailNotificationTargetDto {
  notificationTargetId?: number;
  /**
   * Possible values include: 'Base', 'Email', 'Contact', 'Function'
   */
  type?: NotificationTargetType;
  tenantId?: number;
  email?: string;
}

/**
 * An interface representing EmailReportDataTableMailDto.
 */
export interface EmailReportDataTableMailDto {
  description?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing EmailResetPasswordDto.
 */
export interface EmailResetPasswordDto {
  message?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
  resetButtonHtml?: string;
}

/**
 * An interface representing EmailSolutionNoteMailDto.
 */
export interface EmailSolutionNoteMailDto {
  description?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing EmailTaskJobChangedStatusAlertDto.
 */
export interface EmailTaskJobChangedStatusAlertDto {
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
  locationName?: string;
  taskName?: string;
  targetState?: string;
}

/**
 * An interface representing EmailTaskJobPercentageDto.
 */
export interface EmailTaskJobPercentageDto {
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
  locationName?: string;
  taskName?: string;
  percentage?: number;
}

/**
 * An interface representing EmailTaskJobTimeAlertDto.
 */
export interface EmailTaskJobTimeAlertDto {
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
  locationName?: string;
  taskName?: string;
  minutes?: number;
}

/**
 * An interface representing EmailTemplateDto.
 */
export interface EmailTemplateDto {
  content?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
  preview?: string;
}

/**
 * An interface representing EmailTextLineTemplateDto.
 */
export interface EmailTextLineTemplateDto {
  text?: string;
}

/**
 * An interface representing EmbeddedPowerBiDto.
 */
export interface EmbeddedPowerBiDto {
  reportId?: string;
  reportName?: string;
  url?: string;
  token?: string;
}

/**
 * An interface representing EmptyContextDto.
 */
export interface EmptyContextDto {
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  type?: TemplateContextType;
}

/**
 * An interface representing EntityIdContextDto.
 */
export interface EntityIdContextDto {
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  type?: TemplateContextType;
  id?: number;
}

/**
 * An interface representing ErrorCodeDto.
 */
export interface ErrorCodeDto {
  errorCodeId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  tenantId?: number;
  isVisible?: boolean;
}

/**
 * An interface representing ErrorCodeListDto.
 */
export interface ErrorCodeListDto {
  errorCodeId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  tenantId?: number;
}

/**
 * An interface representing ErrorCodeSimpleListDto.
 */
export interface ErrorCodeSimpleListDto {
  errorCodeId?: number;
  externalId?: string;
  displayName?: string;
}

/**
 * An interface representing ErrorDescriptionDto.
 */
export interface ErrorDescriptionDto {
  code?: string;
  member?: string;
  parameters?: { [propertyName: string]: string };
  description?: string;
}

/**
 * An interface representing ErrorResultDto.
 */
export interface ErrorResultDto {
  traceId?: string;
  errors?: ErrorDescriptionDto[];
}

/**
 * An interface representing EscalationLevelDto.
 */
export interface EscalationLevelDto {
  id?: number;
  escalationLevelId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing EscalationLevelListDto.
 */
export interface EscalationLevelListDto {
  id?: number;
  escalationLevelId?: number;
  name?: string;
  tenantId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing EscalationLevelSimpleDto.
 */
export interface EscalationLevelSimpleDto {
  escalationLevelId?: number;
  name?: string;
  tenantId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing EscalationLevelSimpleListDto.
 */
export interface EscalationLevelSimpleListDto {
  escalationLevelId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing EscalationLevelTtm.
 */
export interface EscalationLevelTtm {
  escalationLevelId?: number;
  name?: string;
}

/**
 * An interface representing EscalationRuleDto.
 */
export interface EscalationRuleDto {
  escalationRuleId?: number;
  isArchived?: boolean;
  isInherited?: boolean;
  isOffsetInherited?: boolean;
  ownOffset?: string;
  inheritedOffset?: string;
  levelId?: number;
  level?: EscalationLevelSimpleDto;
  priorityId?: number;
  priority?: PrioritySimpleDto;
  stateId?: number;
  state?: StateSimpleDto;
  tenantId?: number;
}

/**
 * An interface representing PrioritySimpleListDto.
 */
export interface PrioritySimpleListDto {
  priorityId?: number;
  name?: string;
  tenantId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing EscalationRulesInEventListDto.
 */
export interface EscalationRulesInEventListDto {
  escalationRuleId?: number;
  isArchived?: boolean;
  isOffsetInherited?: boolean;
  ownOffset?: string;
  inheritedOffset?: string;
  levelId?: number;
  level?: EscalationLevelSimpleListDto;
  priorityId?: number;
  priority?: PrioritySimpleListDto;
  stateId?: number;
  state?: StateSimpleListDto;
}

/**
 * An interface representing ExecuteableTaskDto.
 */
export interface ExecuteableTaskDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  description?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  impacts?: ImpactDto[];
  documentTemplate?: DocumentTemplateSimpleDto;
  generatePdf?: boolean;
  generateCsv?: boolean;
  isActive?: boolean;
  reasonSet?: TaskJobStateReasonSetSimpleDto;
  executionPlan?: ExecutionPlanDto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  categories?: CategorySimpleDto[];
}

/**
 * An interface representing ExecutionPlanCompleteListDto.
 */
export interface ExecutionPlanCompleteListDto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing ExportDashboardItemDto.
 */
export interface ExportDashboardItemDto {
  position?: number;
  /**
   * Possible values include: 'Base', 'DataTable'
   */
  type?: DashboardItemTypes;
}

/**
 * An interface representing LocationExportSimpleDto.
 */
export interface LocationExportSimpleDto {
  externalId?: string;
  name?: string;
}

/**
 * An interface representing LocationGroupExportSimpleDto.
 */
export interface LocationGroupExportSimpleDto {
  externalId?: string;
  name?: string;
}

/**
 * An interface representing FunctionExportSimpleDto.
 */
export interface FunctionExportSimpleDto {
  externalId?: string;
  name?: string;
}

/**
 * An interface representing ExportDashboardDto.
 */
export interface ExportDashboardDto {
  name?: string;
  items?: ExportDashboardItemDto[];
  locations?: LocationExportSimpleDto[];
  locationGroups?: LocationGroupExportSimpleDto[];
  functions?: FunctionExportSimpleDto[];
}

/**
 * An interface representing ExportDataTableColumnDto.
 */
export interface ExportDataTableColumnDto {
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
}

/**
 * An interface representing ExportDataTableCustomColumnDto.
 */
export interface ExportDataTableCustomColumnDto {
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  customProperty?: string;
}

/**
 * An interface representing ExportDataTableCustomFilterDto.
 */
export interface ExportDataTableCustomFilterDto {
  label?: string;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  customProperty?: string;
}

/**
 * An interface representing ExportDataTableFilterDto.
 */
export interface ExportDataTableFilterDto {
  label?: string;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
}

/**
 * An interface representing ExportDataTableDto.
 */
export interface ExportDataTableDto {
  name?: string;
  /**
   * Possible values include: 'ContactControllerList', 'TicketControllerList',
   * 'ResourceControllerList', 'MissionControllerList', 'ContractControllerList',
   * 'LocationControllerList', 'CompanyControllerList', 'LocationGroupControllerList',
   * 'AddressControllerList', 'ArticlesControllerList', 'ResourceTaskControllerList',
   * 'InfoControllerList', 'KnowledgeArticleControllerList', 'TaskControllerList',
   * 'TicketsInResourcesControllerList', 'MissionsInResourcesControllerList',
   * 'TicketInLocationControllerId', 'ResourceManufacturerControllerId',
   * 'ResourceModelControllerId', 'IssueControllerId', 'ConsumptionHistoryForResourceList',
   * 'ErrorHistoryForResourceList', 'HintHistoryForResourceList',
   * 'ResourceModelInManufacturerControllerId', 'InfoProgressContactControllerId',
   * 'InfoProgressFunctionControllerId', 'InfoProgressLocationControllerId',
   * 'KnowledgePathControllerList', 'KnowledgePathProgressContactControllerId',
   * 'KnowledgePathProgressFunctionControllerId', 'KnowledgePathProgressLocationControllerId',
   * 'OccuredConsumptionProtocol', 'OccuredErrorProtocol', 'MessageIssueControllerId',
   * 'TicketIssueControllerId', 'MissionIssueControllerId',
   * 'WinterhalterServiceCallIssueControllerId', 'WinterhalterProductOrderIssueControllerId',
   * 'MessageControlControllerId'
   */
  pageOperation?: ControllerOperationId;
  pageSize?: number;
  columns?: ExportDataTableColumnDto[];
  filters?: ExportDataTableFilterDto[];
}

/**
 * An interface representing ExportDataTableDashboardReferenceDto.
 */
export interface ExportDataTableDashboardReferenceDto {
  position?: number;
  /**
   * Possible values include: 'Base', 'DataTable'
   */
  type?: DashboardItemTypes;
  dataTable?: ExportDataTableDto;
}

/**
 * An interface representing ExportDataTableGroupedFilterDto.
 */
export interface ExportDataTableGroupedFilterDto {
  label?: string;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  /**
   * Possible values include: 'And', 'Or'
   */
  combinator?: CombineOperator;
  children?: ExportDataTableFilterDto[];
}

/**
 * An interface representing ExportDataTableTransferColumnDto.
 */
export interface ExportDataTableTransferColumnDto {
  /**
   * Possible values include: 'Ascending', 'Descending'
   */
  sortOrder?: Orders;
  position?: number;
  title?: string;
  /**
   * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
   */
  type?: DataTableColumnType;
  property?: string;
}

/**
 * An interface representing ExportDataTableTransferFilterDto.
 */
export interface ExportDataTableTransferFilterDto {
  label?: string;
  /**
   * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
   */
  type?: DataTableFilterType;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  value?: string;
  property?: string;
}

/**
 * An interface representing ExportMetadata.
 */
export interface ExportMetadata {
  version?: string;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  type?: AppEntityType;
  dateTime?: string;
  defaultFileName?: string;
}

/**
 * An interface representing ExportTaskJobCsvQueueDto.
 */
export interface ExportTaskJobCsvQueueDto {
  filledFormId?: number;
  fileName?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
}

/**
 * An interface representing ExternalAppLoginDto.
 */
export interface ExternalAppLoginDto {
  /**
   * Possible values include: 'SemcoOrgaManual'
   */
  externalApp?: ExternalApp;
  redirectUrl?: string;
}

/**
 * An interface representing ExternalChatMemberDto.
 */
export interface ExternalChatMemberDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberName?: string;
  emailAddress?: string;
}

/**
 * An interface representing ExternalTokenDto.
 */
export interface ExternalTokenDto {
  token?: string;
  redirectUrl?: string;
}

/**
 * An interface representing FailedMailDeliveryQueueDto.
 */
export interface FailedMailDeliveryQueueDto {
  mailReceiverId?: number;
}

/**
 * An interface representing FileAttachmentForTechDto.
 */
export interface FileAttachmentForTechDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File'
   */
  type?: AttachmentTypeForTech;
  file?: FileDto;
}

/**
 * An interface representing FileOrgaAttachmentDto.
 */
export interface FileOrgaAttachmentDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
  fileId?: number;
  file?: FileDto;
}

/**
 * An interface representing FillLabelGapsQueueDto.
 */
export interface FillLabelGapsQueueDto {
  ticketStereotypeId?: number;
  missionStereotypeId?: number;
  resourceStereotypeId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Mission'
   */
  type?: LabelDescriptorType;
  tenantId?: number;
}

/**
 * An interface representing FilledFormFieldCreateDto.
 */
export interface FilledFormFieldCreateDto {
  filledFormFieldId?: number;
  fieldId?: number;
  rowIndex?: number;
  values?: FormValueDto[];
}

/**
 * An interface representing FilledFormCreateDto.
 */
export interface FilledFormCreateDto {
  filledFormId?: number;
  formId?: number;
  tenantId?: number;
  fields?: FilledFormFieldCreateDto[];
}

/**
 * An interface representing FormFieldCreateDto.
 */
export interface FormFieldCreateDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
}

/**
 * An interface representing FormRowCreateDto.
 */
export interface FormRowCreateDto {
  formRowId?: number;
  title?: string;
  position?: number;
  /**
   * Possible values include: 'Base', 'Repeatable', 'Single'
   */
  type?: FormRowTypes;
  fields?: FormFieldCreateDto[];
}

/**
 * An interface representing FormDto.
 */
export interface FormDto {
  formId?: number;
  tenantId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
  activeFormId?: number;
  locations?: LocationRelationDto[];
  locationGroups?: LocationGroupRelationDto[];
  rows?: FormRowCreateDto[];
}

/**
 * An interface representing FilledFormDto.
 */
export interface FilledFormDto {
  filledFormId?: number;
  form?: FormDto;
  ratingInPercent?: number;
  isArchived?: boolean;
  tenantId?: number;
  file?: FileDto;
  fields?: FilledFormFieldCreateDto[];
}

/**
 * An interface representing FilledFormOrgaAttachmentDto.
 */
export interface FilledFormOrgaAttachmentDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
  filledFormId?: number;
}

/**
 * An interface representing FinishStatusTaskJobDto.
 */
export interface FinishStatusTaskJobDto {
  taskJobId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
  tenantId?: number;
  reasonId?: number;
}

/**
 * An interface representing FinishTaskJobQueueDto.
 */
export interface FinishTaskJobQueueDto {
  taskJobId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
}

/**
 * An interface representing SafeDto.
 */
export interface SafeDto {
  contacts?: ContactSimpleDto[];
}

/**
 * An interface representing ResourceSafetySimpleDto.
 */
export interface ResourceSafetySimpleDto {
  resourceId?: number;
  name?: string;
  inventoryNumber?: string;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  tenantId?: number;
  safe?: SafeDto;
}

/**
 * An interface representing FixedResourceTicketSkeletonSelectorDto.
 */
export interface FixedResourceTicketSkeletonSelectorDto {
  id?: number;
  /**
   * Possible values include: 'Base', 'All', 'RestrictedByType', 'Whitelisted', 'Fixed'
   */
  type?: ResourceTicketSkeletonSelectorType;
  resource?: ResourceSafetySimpleDto;
}

/**
 * An interface representing FolderAccessContactDto.
 */
export interface FolderAccessContactDto {
  contactId?: number;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing FolderAccessContactListDto.
 */
export interface FolderAccessContactListDto {
  contactId?: number;
  contact?: ContactSimpleListDto;
}

/**
 * An interface representing FolderAccessDto.
 */
export interface FolderAccessDto {
  folderAccessId?: number;
  path?: string;
  isInherited?: boolean;
  tenantId?: number;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  contacts?: ContactSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing FolderAccessFunctionDto.
 */
export interface FolderAccessFunctionDto {
  functionId?: number;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing FolderAccessFunctionListDto.
 */
export interface FolderAccessFunctionListDto {
  functionId?: number;
  functionProperty?: FunctionListSimpleDto;
}

/**
 * An interface representing FolderAccessLocationListDto.
 */
export interface FolderAccessLocationListDto {
  locationId?: number;
  location?: LocationSimpleListDto;
}

/**
 * An interface representing FolderAccessLocationGroupListDto.
 */
export interface FolderAccessLocationGroupListDto {
  locationGroupId?: number;
  locationGroup?: LocationGroupSimpleListDto;
}

/**
 * An interface representing FolderAccessListDto.
 */
export interface FolderAccessListDto {
  folderAccessId?: number;
  path?: string;
  isInherited?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly fellows?: string;
  locations?: FolderAccessLocationListDto[];
  locationGroups?: FolderAccessLocationGroupListDto[];
  contacts?: FolderAccessContactListDto[];
  functions?: FolderAccessFunctionListDto[];
}

/**
 * An interface representing FolderAccessLocationDto.
 */
export interface FolderAccessLocationDto {
  locationId?: number;
  location?: LocationSimpleDto;
}

/**
 * An interface representing FolderAccessLocationGroupDto.
 */
export interface FolderAccessLocationGroupDto {
  locationGroupId?: number;
  locationGroup?: LocationGroupSimpleDto;
}

/**
 * An interface representing FolderOrgaAttachmentDto.
 */
export interface FolderOrgaAttachmentDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
  path?: string;
}

/**
 * An interface representing FollowUpMissionDto.
 */
export interface FollowUpMissionDto {
  title?: string;
  description?: string;
  plannedStart?: string;
  plannedEnd?: string;
}

/**
 * An interface representing FollowUpMissionsDto.
 */
export interface FollowUpMissionsDto {
  items?: FollowUpMissionDto[];
}

/**
 * An interface representing FollowUpTicketDto.
 */
export interface FollowUpTicketDto {
  state?: StateSimpleDto;
  triggersAt?: string;
}

/**
 * An interface representing MissionSimpleDto.
 */
export interface MissionSimpleDto {
  missionId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing FollowedUpMissionsDto.
 */
export interface FollowedUpMissionsDto {
  items?: MissionSimpleDto[];
}

/**
 * An interface representing ForcePasswordDto.
 */
export interface ForcePasswordDto {
  passwordBase64?: string;
}

/**
 * An interface representing FormLocationDto.
 */
export interface FormLocationDto {
  locationId?: number;
  formId?: number;
}

/**
 * An interface representing FormLocationGroupDto.
 */
export interface FormLocationGroupDto {
  locationGroupId?: number;
  formId?: number;
}

/**
 * An interface representing FormCreateDto.
 */
export interface FormCreateDto {
  formId?: number;
  title?: string;
  description?: string;
  tenantId?: number;
  rows?: FormRowCreateDto[];
  locations?: FormLocationDto[];
  locationGroups?: FormLocationGroupDto[];
}

/**
 * An interface representing FormCsvCreatedByContactDto.
 */
export interface FormCsvCreatedByContactDto {
  contactId?: number;
  pin?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

/**
 * An interface representing ShareIdentifierDto.
 */
export interface ShareIdentifierDto {
  identifier?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly shareIdentifier?: string;
}

/**
 * An interface representing FormTextValueCsvDto.
 */
export interface FormTextValueCsvDto {
  value?: string;
}

/**
 * An interface representing FormFieldCsvDto.
 */
export interface FormFieldCsvDto {
  id?: number;
  title?: string;
  position?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly value?: string;
  valueToDisplay?: FormTextValueCsvDto;
}

/**
 * An interface representing FormRowCsvDto.
 */
export interface FormRowCsvDto {
  id?: number;
  position?: number;
  title?: string;
  fields?: FormFieldCsvDto[];
}

/**
 * An interface representing FormCsvDto.
 */
export interface FormCsvDto {
  filledFormId?: number;
  fileName?: string;
  title?: string;
  share?: ShareIdentifierDto;
  createdByLocation?: string;
  createdByUser?: string;
  createdByContact?: FormCsvCreatedByContactDto;
  createdAt?: any;
  isRated?: boolean;
  ratingInPercent?: string;
  rows?: FormRowCsvDto[];
}

/**
 * An interface representing FormFieldTtm.
 */
export interface FormFieldTtm {
  value?: string;
  name?: string;
  unit?: string;
}

/**
 * An interface representing FormListDto.
 */
export interface FormListDto {
  formId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
}

/**
 * An interface representing FormTtm.
 */
export interface FormTtm {
  formId?: number;
  title?: string;
  fields?: any;
}

/**
 * An interface representing FunctionChatMemberDto.
 */
export interface FunctionChatMemberDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberName?: string;
  functionId?: number;
  functionProperty?: FunctionSimpleDto;
  contactGroupId?: number;
  contactGroup?: ContactGroupSimpleDto;
}

/**
 * An interface representing FunctionControlReceiverDto.
 */
export interface FunctionControlReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: ControlReceiverType;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing FunctionDto.
 */
export interface FunctionDto {
  functionId?: number;
  tenantId?: number;
  name?: string;
  externalId?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  isArchived?: boolean;
  customPropertyValues?: FilledCustomValueDto[];
  stereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing StereotypeSimpleExportDto.
 */
export interface StereotypeSimpleExportDto {
  externalId?: string;
}

/**
 * An interface representing FunctionExportDto.
 */
export interface FunctionExportDto {
  externalId?: string;
  name?: string;
  stereotype?: StereotypeSimpleExportDto;
}

/**
 * An interface representing FunctionIssueReceiverDto.
 */
export interface FunctionIssueReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: IssueReceiverType;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing FunctionListDto.
 */
export interface FunctionListDto {
  functionId?: number;
  tenantId?: number;
  name?: string;
  externalId?: string;
  stereotypeId?: number;
  isArchived?: boolean;
  customPropertyValues?: FilledCustomValueListDto[];
  rowVersion?: number[];
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing FunctionListForCompanyDto.
 */
export interface FunctionListForCompanyDto {
  functionId?: number;
  tenantId?: number;
  name?: string;
  externalId?: string;
  stereotypeId?: number;
  isArchived?: boolean;
  customPropertyValues?: FilledCustomValueListDto[];
  rowVersion?: number[];
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing FunctionListForContactDto.
 */
export interface FunctionListForContactDto {
  functionId?: number;
  tenantId?: number;
  name?: string;
  externalId?: string;
  stereotypeId?: number;
  isArchived?: boolean;
  customPropertyValues?: FilledCustomValueListDto[];
  rowVersion?: number[];
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing FunctionListForContactDtoPageOutputDto.
 */
export interface FunctionListForContactDtoPageOutputDto {
  paging?: any;
  items?: FunctionListForContactDto[];
}

/**
 * An interface representing FunctionMailNodeReceiverDto.
 */
export interface FunctionMailNodeReceiverDto {
  /**
   * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
   */
  type?: MailNodeReceiverType;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing FunctionNotificationTargetDto.
 */
export interface FunctionNotificationTargetDto {
  notificationTargetId?: number;
  /**
   * Possible values include: 'Base', 'Email', 'Contact', 'Function'
   */
  type?: NotificationTargetType;
  tenantId?: number;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing FunctionRelationListDto.
 */
export interface FunctionRelationListDto {
  functionId?: number;
  functionProperty?: FunctionListSimpleDto;
}

/**
 * An interface representing FunctionTtm.
 */
export interface FunctionTtm {
  functionId?: number;
  name?: string;
}

/**
 * An interface representing PictureSettingDto.
 */
export interface PictureSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: number;
  file?: FileDto;
}

/**
 * An interface representing HexColorSettingDto.
 */
export interface HexColorSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: string;
}

/**
 * An interface representing GeneralSettingDto.
 */
export interface GeneralSettingDto {
  settingId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'General', 'Ticket', 'Mission'
   */
  type?: SettingType;
  isClockTileDisabled?: boolean;
  /**
   * Possible values include: 'Internal', 'External'
   */
  pdfViewBehaviour?: PdfViewBehaviour;
  logoId?: number;
  logo?: PictureSettingDto;
  primaryColorId?: number;
  primaryColor?: HexColorSettingDto;
  defaultLanguage?: CultureSettingDto;
  defaultLoginMethod?: ContactLoginMethodSettingDto;
  availableLoginMethod?: ContactLoginMethodSettingDto;
  adminEmailAddress?: string;
}

/**
 * An interface representing GenerateIssueReasonDto.
 */
export interface GenerateIssueReasonDto {
  eventId?: string;
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
}

/**
 * An interface representing GenerateIssueQueueDto.
 */
export interface GenerateIssueQueueDto {
  controlId?: number;
  /**
   * Possible values include: 'Message', 'Mission'
   */
  controlContentType?: ControlContentType;
  resourceId?: number;
  reason?: GenerateIssueReasonDto;
}

/**
 * An interface representing GenerateMissionByTaskQueueDto.
 */
export interface GenerateMissionByTaskQueueDto {
  taskId?: number;
  resourceId?: number;
  start?: string;
  end?: string;
  culture?: string;
  senderOfQueue?: string;
  errorCodeId?: number;
  consumptionCodeId?: number;
}

/**
 * An interface representing GroupedFilterDto.
 */
export interface GroupedFilterDto {
  property?: string;
  value?: string;
  /**
   * Possible values include: 'Default', 'Relative', 'Grouped'
   */
  kind?: FilterKind;
  /**
   * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
   * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
   */
  operator?: FilterOperators;
  /**
   * Possible values include: 'DataTransferObject', 'Custom', 'Grouped'
   */
  type?: FilterTypes;
  children?: FilterDto[];
  /**
   * Possible values include: 'And', 'Or'
   */
  combinedAs?: CombineOperator;
}

/**
 * An interface representing HintCodeDto.
 */
export interface HintCodeDto {
  hintCodeId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  tenantId?: number;
  isVisible?: boolean;
}

/**
 * An interface representing HintCodeListDto.
 */
export interface HintCodeListDto {
  hintCodeId?: number;
  externalId?: string;
  displayName?: string;
  description?: string;
  tenantId?: number;
}

/**
 * An interface representing HintCodeSimpleListDto.
 */
export interface HintCodeSimpleListDto {
  hintCodeId?: number;
  externalId?: string;
  displayName?: string;
}

/**
 * An interface representing HitNotificationRuleQueueDto.
 */
export interface HitNotificationRuleQueueDto {
  /**
   * Possible values include: 'TicketChangedResource', 'TicketChangedState', 'TicketChangedEditor',
   * 'MissionChangedResource', 'MissionChangedState', 'MissionChangedEditor',
   * 'MissionChangedCompletion', 'ContractChangedState', 'ActivatedInfo'
   */
  trigger?: NotificationTrigger;
  subjectId?: number;
  tenantId?: number;
  timeOfOccurence?: string;
}

/**
 * An interface representing HolidayDto.
 */
export interface HolidayDto {
  holidayId?: number;
  name?: string;
  date?: string;
}

/**
 * An interface representing HolidaySetDto.
 */
export interface HolidaySetDto {
  holidaySetId?: number;
  name?: string;
  tenantId?: number;
  holidays?: HolidayDto[];
}

/**
 * An interface representing HolidaySetListDto.
 */
export interface HolidaySetListDto {
  holidaySetId?: number;
  name?: string;
}

/**
 * An interface representing INotificationDto.
 */
export interface INotificationDto {
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
}

/**
 * An interface representing IStereotypedDto.
 */
export interface IStereotypedDto {
  customPropertyValues?: FilledCustomValueDto[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
}

/**
 * An interface representing ImportConfigurationDto.
 */
export interface ImportConfigurationDto {
  importConfigurationId?: number;
  title?: string;
  data?: string;
  /**
   * Possible values include: 'User', 'Receipt', 'Resource', 'ResourceManufacturer'
   */
  importType?: ImportType;
  tenantId?: number;
}

/**
 * An interface representing ImportConfigurationListDto.
 */
export interface ImportConfigurationListDto {
  importConfigurationId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing ImportProtocolDto.
 */
export interface ImportProtocolDto {
  importProtocolId?: number;
  report?: string;
  isColdRun?: boolean;
  /**
   * Possible values include: 'Processing', 'Successful', 'Failed'
   */
  status?: ImportProtocolStatus;
  importConfigurationId?: number;
  title?: string;
  importContentBlobPath?: string;
  tenantId?: number;
  createdAt?: string;
  lastModified?: string;
}

/**
 * An interface representing ImportProtocolListDto.
 */
export interface ImportProtocolListDto {
  importProtocolId?: number;
  isColdRun?: boolean;
  /**
   * Possible values include: 'Processing', 'Successful', 'Failed'
   */
  status?: ImportProtocolStatus;
  importConfigurationId?: number;
  title?: string;
  importContentBlobPath?: string;
  createdAt?: string;
  lastModified?: string;
  tenantId?: number;
}

/**
 * An interface representing ImportQueueDto.
 */
export interface ImportQueueDto {
  tenantId?: number;
  blobFileId?: string;
  /**
   * Possible values include: 'User', 'Receipt', 'Resource', 'ResourceManufacturer'
   */
  importType?: ImportType;
  isColdRun?: boolean;
  importConfigurationId?: number;
  importProtocolId?: number;
}

/**
 * An interface representing MailInDto.
 */
export interface MailInDto {
  originalFileId?: number;
  tenantId?: number;
  isSpam?: boolean;
  from?: EmailAddressDto;
  to?: EmailAddressDto[];
  cc?: EmailAddressDto[];
  bcc?: EmailAddressDto[];
  subject?: string;
  text?: string;
  content?: string;
  attachments?: FileDto[];
  droppedAttachments?: string[];
}

/**
 * An interface representing IncomingEmailRequestDto.
 */
export interface IncomingEmailRequestDto {
  domain?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly tenantId?: number;
  mail?: MailInDto;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly originalMailId?: number;
  entry?: MailInQueueDto;
}

/**
 * An interface representing InfoContactDto.
 */
export interface InfoContactDto {
  contactId?: number;
  infoId?: number;
  createdAt?: string;
}

/**
 * An interface representing InfoDto.
 */
export interface InfoDto {
  infoId?: number;
  dateTime?: string;
  expiryDate?: string;
  /**
   * Possible values include: 'Pending', 'Active', 'Expired'
   */
  state?: InfoState;
  title?: string;
  content?: string;
  answerEnabled?: boolean;
  requestRead?: boolean;
  tenantId?: number;
  sender?: FunctionSimpleDto;
  attachments?: OrgaAttachmentDto[];
  categories?: CategorySimpleDto[];
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  contacts?: ContactSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing InfoFormFieldDto.
 */
export interface InfoFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  content?: string;
}

/**
 * An interface representing InfoFormValueDto.
 */
export interface InfoFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing InfoListDto.
 */
export interface InfoListDto {
  infoId?: number;
  dateTime?: string;
  contentAsText?: string;
  content?: string;
  isActive?: boolean;
  isExpired?: boolean;
  /**
   * Possible values include: 'Pending', 'Active', 'Expired'
   */
  state?: InfoState;
  isRead?: boolean;
  readAt?: string;
  expiryDate?: string;
  title?: string;
  answerEnabled?: boolean;
  requestRead?: boolean;
  sender?: FunctionListSimpleDto;
  attachments?: OrgaAttachmentListDto[];
  categoryRelations?: CategorizedRelationDto[];
  tenantId?: number;
}

/**
 * An interface representing InfoMembershipByLocationListDto.
 */
export interface InfoMembershipByLocationListDto {
  location?: LocationSimpleListDto;
  displayName?: string;
  contact?: ContactSimpleListDto;
  createdAt?: string;
}

/**
 * An interface representing InfoMembershipListDto.
 */
export interface InfoMembershipListDto {
  displayName?: string;
  contact?: ContactSimpleListDto;
  createdAt?: string;
}

/**
 * An interface representing InfoNotificationRuleTtm.
 */
export interface InfoNotificationRuleTtm {
  infoId?: number;
  title?: string;
  content?: string;
  showTime?: string;
  expiryDate?: string;
  functionProperty?: FunctionTtm;
  link?: string;
}

/**
 * An interface representing InfoProgressContactListDto.
 */
export interface InfoProgressContactListDto {
  name?: string;
  contactId?: number;
  contact?: ContactSimpleListDto;
  isExpected?: boolean;
  readWhen?: string;
  readLocation?: LocationSimpleListDto;
  locations?: LocationSimpleListDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing InfoProgressDetailingFilterDto.
 */
export interface InfoProgressDetailingFilterDto {
  locationIds?: number[];
  functionIds?: number[];
}

/**
 * An interface representing InfoProgressFunctionListDto.
 */
export interface InfoProgressFunctionListDto {
  name?: string;
  functionId?: number;
  functionProperty?: FunctionListSimpleDto;
  currentReaderCount?: number;
  expectedReaderCount?: number;
}

/**
 * An interface representing InfoProgressLocationListDto.
 */
export interface InfoProgressLocationListDto {
  name?: string;
  locationId?: number;
  location?: LocationSimpleListDto;
  currentReaderCount?: number;
  expectedReaderCount?: number;
}

/**
 * An interface representing InfoTtm.
 */
export interface InfoTtm {
  infoId?: number;
  title?: string;
  content?: string;
  showTime?: string;
  expiryDate?: string;
  functionProperty?: FunctionTtm;
}

/**
 * An interface representing InheritResourceQueueDto.
 */
export interface InheritResourceQueueDto {
  resourceId?: number;
  newParentId?: number;
  tenantId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly scopeId?: string;
}

/**
 * An interface representing InheritedResourceQueueDto.
 */
export interface InheritedResourceQueueDto {
  resourceId?: number;
  newParentId?: number;
}

/**
 * An interface representing InitChatDto.
 */
export interface InitChatDto {
  chatId?: number;
  title?: string;
  initializer?: ChatMemberDto;
  additionalChatMembers?: ChatMemberDto[];
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing InitLabelsQueueDto.
 */
export interface InitLabelsQueueDto {
  entityId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Mission'
   */
  type?: LabelDescriptorType;
  tenantId?: number;
}

/**
 * An interface representing InitLearningLabelsQueueDto.
 */
export interface InitLearningLabelsQueueDto {
  tenantId?: number;
}

/**
 * An interface representing InitializeAccessesByTenantQueueDto.
 */
export interface InitializeAccessesByTenantQueueDto {
  tenantId?: number;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
}

/**
 * An interface representing InitializeAccessesQueueDto.
 */
export interface InitializeAccessesQueueDto {
  features?: AppEntityType[];
}

/**
 * An interface representing InitializedChatResultDto.
 */
export interface InitializedChatResultDto {
  chat?: InitializedChatDto;
}

/**
 * An interface representing InternalErrorDto.
 */
export interface InternalErrorDto {
  id?: string;
  createdAt?: string;
  stacktrace?: string;
  message?: string;
  body?: string;
  method?: string;
  query?: string;
  path?: string;
  header?: { [propertyName: string]: string };
}

/**
 * An interface representing InterpretChangedAreaAssignmentQueueDto.
 */
export interface InterpretChangedAreaAssignmentQueueDto {
  contactId?: number;
}

/**
 * An interface representing InventoryNumberListDto.
 */
export interface InventoryNumberListDto {
  inventoryNumberId?: number;
  count?: number;
  prefix?: string;
  suffix?: string;
  patternId?: number;
}

/**
 * An interface representing InventoryNumberPatternDto.
 */
export interface InventoryNumberPatternDto {
  externalId?: string;
  inventoryNumberPatternId?: number;
  prefix?: string;
  suffix?: string;
  title?: string;
  length?: number;
  minimum?: number;
  maximum?: number;
  tenantId?: number;
  nextNumber?: string;
  nextNumberCount?: number;
  isExhausted?: boolean;
  rowVersion?: number[];
}

/**
 * An interface representing InventoryNumberPatternExportDto.
 */
export interface InventoryNumberPatternExportDto {
  externalId?: string;
  prefix?: string;
  suffix?: string;
  title?: string;
  length?: number;
  minimum?: number;
  maximum?: number;
}

/**
 * An interface representing InventoryNumberPatternListDto.
 */
export interface InventoryNumberPatternListDto {
  inventoryNumberPatternId?: number;
  title?: string;
  externalId?: string;
  prefix?: string;
  suffix?: string;
  tenantId?: number;
}

/**
 * An interface representing InventoryNumberPatternSimpleDto.
 */
export interface InventoryNumberPatternSimpleDto {
  inventoryNumberPatternId?: number;
  title?: string;
  externalId?: string;
}

/**
 * An interface representing InventoryNumberPatternSimpleExportDto.
 */
export interface InventoryNumberPatternSimpleExportDto {
  externalId?: string;
}

/**
 * An interface representing InventoryNumberPatternSimpleListDto.
 */
export interface InventoryNumberPatternSimpleListDto {
  inventoryNumberPatternId?: number;
  title?: string;
  prefix?: string;
  suffix?: string;
  length?: number;
  minimum?: number;
  maximum?: number;
  tenantId?: number;
}

/**
 * An interface representing InvitationCheckDto.
 */
export interface InvitationCheckDto {
  invitationId?: number;
  code?: string;
  isRevoked?: boolean;
  /**
   * Possible values include: 'Identify'
   */
  type?: InvitationType;
}

/**
 * An interface representing InvitationDto.
 */
export interface InvitationDto {
  invitationId?: number;
  name?: string;
  code?: string;
  isRevoked?: boolean;
  tenantId?: number;
  /**
   * Possible values include: 'Identify'
   */
  type?: InvitationType;
  /**
   * Possible values include: 'Team'
   */
  purpose?: InvitationPurpose;
}

/**
 * An interface representing InvitationListDto.
 */
export interface InvitationListDto {
  invitationId?: number;
  name?: string;
  code?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Team'
   */
  purpose?: InvitationPurpose;
  isRevoked?: boolean;
  createdAt?: string;
  lastModified?: string;
}

/**
 * An interface representing ResourcePathPartSimpleListDto.
 */
export interface ResourcePathPartSimpleListDto {
  index?: number;
  id?: number;
  name?: string;
}

/**
 * An interface representing ResourceSimpleListDto.
 */
export interface ResourceSimpleListDto {
  resourceId?: number;
  name?: string;
  inventoryNumberCount?: number;
  inventoryNumber?: string;
  locationId?: number;
  parentId?: number;
  location?: LocationSimpleListDto;
  stereotype?: StereotypeSimpleListDto;
  externalId?: string;
  isArchived?: boolean;
  path?: ResourcePathPartSimpleListDto[];
}

/**
 * An interface representing IssueDetailsListDto.
 */
export interface IssueDetailsListDto {
  issueId?: number;
  title?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  tenantId?: number;
  createdAt?: string;
  lastModified?: string;
  createdByName?: string;
  location?: LocationSimpleListDto;
  resource?: ResourceSimpleListDto;
}

/**
 * An interface representing IssueListDto.
 */
export interface IssueListDto {
  issueId?: number;
  title?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  tenantId?: number;
  createdAt?: string;
  lastModified?: string;
  createdByName?: string;
  location?: LocationSimpleListDto;
  resource?: ResourceSimpleListDto;
}

/**
 * An interface representing IssueNotificationDto.
 */
export interface IssueNotificationDto {
  issueNotificationId?: number;
  /**
   * Possible values include: 'Base', 'Email'
   */
  type?: IssueNotificationType;
  tenantId?: number;
}

/**
 * An interface representing IssueReasonProtocolListDto.
 */
export interface IssueReasonProtocolListDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
}

/**
 * An interface representing IssueProtocolListDto.
 */
export interface IssueProtocolListDto {
  issueId?: number;
  title?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  reason?: IssueReasonProtocolListDto;
}

/**
 * An interface representing IssueReasonDto.
 */
export interface IssueReasonDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
}

/**
 * An interface representing IssueSimpleDto.
 */
export interface IssueSimpleDto {
  issueId?: number;
  title?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
}

/**
 * An interface representing IssueSimpleListDto.
 */
export interface IssueSimpleListDto {
  issueId?: number;
  title?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
}

/**
 * An interface representing MessageIssueContentDto.
 */
export interface MessageIssueContentDto {
  issueStereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing TicketIssueContentDto.
 */
export interface TicketIssueContentDto {
  /**
   * Possible values include: 'Selection', 'None'
   */
  resourceSelectorKind?: IssueResourceSelectorKind;
  issueStereotype?: StereotypeSimpleDto;
  stereotype?: StereotypeSimpleDto;
  state?: StateSimpleDto;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing MissionIssueContentDto.
 */
export interface MissionIssueContentDto {
  issueStereotype?: StereotypeSimpleDto;
  stereotype?: StereotypeSimpleDto;
  /**
   * Possible values include: 'None', 'Editor', 'PrioritySearch'
   */
  editorSelectorKind?: MissionEditorSelectorKind;
  fixedEditor?: ContactSimpleDto;
  priorities?: PrioritySimpleDto[];
}

/**
 * An interface representing IssueTemplateDto.
 */
export interface IssueTemplateDto {
  issueTemplateId?: number;
  name?: string;
  tenantId?: number;
  externalId?: string;
  publishedResources?: ResourceSimpleDto[];
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  contacts?: ContactSimpleDto[];
  functions?: FunctionSimpleDto[];
  notifications?: IssueNotificationDto[];
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
  messageContent?: MessageIssueContentDto;
  ticketContent?: TicketIssueContentDto;
  missionContent?: MissionIssueContentDto;
  winterhalterServiceContent?: any;
  winterhalterOrderContent?: any;
}

/**
 * An interface representing IssueTemplateListDto.
 */
export interface IssueTemplateListDto {
  issueTemplateId?: number;
  name?: string;
  externalId?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
}

/**
 * An interface representing IssueTemplateSimpleDto.
 */
export interface IssueTemplateSimpleDto {
  issueTemplateId?: number;
  name?: string;
  externalId?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
}

/**
 * An interface representing LocationTtm.
 */
export interface LocationTtm {
  locationId?: number;
  name?: string;
  locationNumber?: string;
  phoneNumber?: string;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  properties?: any;
}

/**
 * An interface representing ResourceTtm.
 */
export interface ResourceTtm {
  resourceId?: number;
  name?: string;
  installationDate?: string;
  manufacturer?: string;
  model?: string;
  serialNumber?: string;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  currentState?: ResourceState;
  currentStateName?: string;
  location?: LocationTtm;
  properties?: any;
}

/**
 * An interface representing MailTtm.
 */
export interface MailTtm {
  subject?: string;
  content?: string;
  from?: EmailAddressTtm;
  to?: EmailAddressTtm[];
  cc?: EmailAddressTtm[];
  bcc?: EmailAddressTtm[];
}

/**
 * An interface representing IssueTtm.
 */
export interface IssueTtm {
  issueId?: number;
  title?: string;
  link?: string;
  resource?: ResourceTtm;
  location?: LocationTtm;
  mail?: MailTtm;
}

/**
 * An interface representing KnowledgePathSimpleDto.
 */
export interface KnowledgePathSimpleDto {
  knowledgePathId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing KnowledgeArticleDto.
 */
export interface KnowledgeArticleDto {
  knowledgeArticleId?: number;
  title?: string;
  externalId?: string;
  tags?: string;
  content?: string;
  tenantId?: number;
  attachments?: OrgaAttachmentDto[];
  hasPaths?: boolean;
  oldPath?: KnowledgePathSimpleDto;
}

/**
 * An interface representing KnowledgeArticleInPathDto.
 */
export interface KnowledgeArticleInPathDto {
  knowledgeArticleId?: number;
  title?: string;
  oldKnowledgePathId?: number;
  position?: number;
}

/**
 * An interface representing KnowledgeArticleListDto.
 */
export interface KnowledgeArticleListDto {
  knowledgeArticleId?: number;
  title?: string;
  externalId?: string;
  oldKnowledgePathId?: number;
  tags?: string;
  tenantId?: number;
}

/**
 * An interface representing KnowledgeArticleOrgaAttachmentDto.
 */
export interface KnowledgeArticleOrgaAttachmentDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
  knowledgeArticleId?: number;
  knowledgeArticle?: KnowledgeArticleSimpleDto;
}

/**
 * An interface representing KnowledgeArticleStartDto.
 */
export interface KnowledgeArticleStartDto {
  knowledgeArticleId?: number;
  title?: string;
  content?: string;
  position?: number;
  tenantId?: number;
  attachments?: OrgaAttachmentDto[];
}

/**
 * An interface representing KnowledgeArticleV2Dto.
 */
export interface KnowledgeArticleV2Dto {
  knowledgeArticleId?: number;
  title?: string;
  externalId?: string;
  tags?: string;
  content?: string;
  tenantId?: number;
  attachments?: OrgaAttachmentDto[];
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  categories?: CategorySimpleDto[];
}

/**
 * An interface representing KnowledgeArticleV2ListDto.
 */
export interface KnowledgeArticleV2ListDto {
  knowledgeArticleId?: number;
  title?: string;
  tenantId?: number;
  categoryRelations?: CategorizedRelationDto[];
}

/**
 * An interface representing KnowledgeExamDto.
 */
export interface KnowledgeExamDto {
  knowledgeExamId?: number;
  title?: string;
  description?: string;
  tenantId?: number;
  customPropertySets?: CustomSetReferenceDto[];
  isArchived?: boolean;
  rowVersion?: number[];
}

/**
 * An interface representing KnowledgeExamAnswerDto.
 */
export interface KnowledgeExamAnswerDto {
  knowledgeExamAnswerId?: number;
  knowledgePath?: KnowledgePathSimpleDto;
  exam?: KnowledgeExamDto;
  tenantId?: number;
  customPropertyValues?: FilledCustomValueDto[];
  submitDate?: string;
  isArchived?: boolean;
  score?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
}

/**
 * An interface representing KnowledgeExamInPathDto.
 */
export interface KnowledgeExamInPathDto {
  knowledgeExamId?: number;
  title?: string;
  position?: number;
}

/**
 * An interface representing KnowledgeExamListDto.
 */
export interface KnowledgeExamListDto {
  knowledgeExamId?: number;
  title?: string;
  tenantId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing KnowledgeExamStartDto.
 */
export interface KnowledgeExamStartDto {
  knowledgeExamId?: number;
  title?: string;
  description?: string;
  position?: number;
  tenantId?: number;
  submitDate?: string;
}

/**
 * An interface representing KnowledgePathDto.
 */
export interface KnowledgePathDto {
  knowledgePathId?: number;
  title?: string;
  tenantId?: number;
  minimumScore?: number;
  certificateTemplate?: DocumentTemplateSimpleDto;
  articles?: KnowledgeArticleInPathDto[];
  exams?: KnowledgeExamInPathDto[];
  categories?: CategorySimpleDto[];
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  contacts?: ContactSimpleDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing KnowledgePathListDto.
 */
export interface KnowledgePathListDto {
  knowledgePathId?: number;
  title?: string;
  tenantId?: number;
  locations?: LocationRelationListDto[];
  locationGroups?: LocationGroupRelationListDto[];
  functions?: FunctionRelationListDto[];
  contacts?: ContactRelationListDto[];
  categoryRelations?: CategorizedRelationDto[];
}

/**
 * An interface representing KnowledgePathListForKnowledgeArticleDto.
 */
export interface KnowledgePathListForKnowledgeArticleDto {
  knowledgePathId?: number;
  title?: string;
  tenantId?: number;
  locations?: LocationRelationListDto[];
  locationGroups?: LocationGroupRelationListDto[];
  functions?: FunctionRelationListDto[];
  contacts?: ContactRelationListDto[];
  categoryRelations?: CategorizedRelationDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing KnowledgePathProgressContactListDto.
 */
export interface KnowledgePathProgressContactListDto {
  name?: string;
  contactId?: number;
  contact?: ContactSimpleListDto;
  isExpected?: boolean;
  isCompleted?: boolean;
  completedWhen?: string;
  locations?: LocationSimpleListDto[];
  functions?: FunctionSimpleDto[];
}

/**
 * An interface representing KnowledgePathProgressDetailingFilterDto.
 */
export interface KnowledgePathProgressDetailingFilterDto {
  locationIds?: number[];
  functionIds?: number[];
}

/**
 * An interface representing KnowledgePathProgressFunctionListDto.
 */
export interface KnowledgePathProgressFunctionListDto {
  name?: string;
  functionId?: number;
  functionProperty?: FunctionListSimpleDto;
  currentReaderCount?: number;
  expectedReaderCount?: number;
}

/**
 * An interface representing KnowledgePathProgressLocationListDto.
 */
export interface KnowledgePathProgressLocationListDto {
  name?: string;
  locationId?: number;
  location?: LocationSimpleListDto;
  currentReaderCount?: number;
  expectedReaderCount?: number;
}

/**
 * An interface representing KnowledgePathStartDto.
 */
export interface KnowledgePathStartDto {
  knowledgePathId?: number;
  title?: string;
  tenantId?: number;
  minimumScore?: number;
  /**
   * Possible values include: 'Started', 'CompletedUnsuccessfully', 'CompletedSuccessfully'
   */
  status?: KnowledgePathProgressStatus;
  certificate?: FileDto;
  completionDate?: string;
  articles?: KnowledgeArticleStartDto[];
  exams?: KnowledgeExamStartDto[];
}

/**
 * An interface representing LabelDto.
 */
export interface LabelDto {
  labelId?: number;
  content?: string;
  tenantId?: number;
}

/**
 * An interface representing LabelListDto.
 */
export interface LabelListDto {
  labelId?: number;
  content?: string;
  tenantId?: number;
}

/**
 * An interface representing LabelSimpleListDto.
 */
export interface LabelSimpleListDto {
  labelId?: number;
  content?: string;
}

/**
 * An interface representing LabelRelationListDto.
 */
export interface LabelRelationListDto {
  labelId?: number;
  label?: LabelSimpleListDto;
}

/**
 * An interface representing LabelSimpleDto.
 */
export interface LabelSimpleDto {
  labelId?: number;
  content?: string;
}

/**
 * An interface representing LabeledEntityDto.
 */
export interface LabeledEntityDto {
  entityId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Mission'
   */
  type?: LabelDescriptorType;
  name?: string;
}

/**
 * An interface representing LabelSummaryDto.
 */
export interface LabelSummaryDto {
  labels?: LabelSimpleDto[];
  similarEntities?: LabeledEntityDto[];
}

/**
 * An interface representing LearnLabelsQueueDto.
 */
export interface LearnLabelsQueueDto {
  ticketStereotypeId?: number;
  missionStereotypeId?: number;
  resourceStereotypeId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Mission'
   */
  type?: LabelDescriptorType;
  tenantId?: number;
}

/**
 * An interface representing LinkOrgaAttachmentDto.
 */
export interface LinkOrgaAttachmentDto {
  attachmentId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
   */
  type?: AttachmentTypes;
  link?: string;
  name?: string;
}

/**
 * An interface representing LinkToContactDto.
 */
export interface LinkToContactDto {
  linkedElementId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
   */
  type?: LinkedElementType;
  element?: ContactSimpleDto;
}

/**
 * An interface representing LinkToContractDto.
 */
export interface LinkToContractDto {
  linkedElementId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
   */
  type?: LinkedElementType;
  element?: ContractSimpleDto;
}

/**
 * An interface representing LinkToMissionDto.
 */
export interface LinkToMissionDto {
  linkedElementId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
   */
  type?: LinkedElementType;
  element?: MissionSimpleDto;
}

/**
 * An interface representing LinkToResourceDto.
 */
export interface LinkToResourceDto {
  linkedElementId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
   */
  type?: LinkedElementType;
  element?: ResourceSimpleDto;
}

/**
 * An interface representing TicketSimpleDto.
 */
export interface TicketSimpleDto {
  ticketId?: number;
  title?: string;
  isArchived?: boolean;
}

/**
 * An interface representing LinkToTicketDto.
 */
export interface LinkToTicketDto {
  linkedElementId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
   */
  type?: LinkedElementType;
  element?: TicketSimpleDto;
}

/**
 * An interface representing LocationGroupDto.
 */
export interface LocationGroupDto {
  locationGroupId?: number;
  name?: string;
  isSystem?: boolean;
  rowVersion?: number[];
  tenantId?: number;
  externalId?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing LocationGroupInfoDto.
 */
export interface LocationGroupInfoDto {
  infoId?: number;
  locationGroupId?: number;
}

/**
 * An interface representing LocationGroupListDto.
 */
export interface LocationGroupListDto {
  locationGroupId?: number;
  name?: string;
  isSystem?: boolean;
  tenantId?: number;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
}

/**
 * An interface representing LocationGroupListForContactDto.
 */
export interface LocationGroupListForContactDto {
  locationGroupId?: number;
  name?: string;
  isSystem?: boolean;
  tenantId?: number;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing LocationGroupListForLocationDto.
 */
export interface LocationGroupListForLocationDto {
  locationGroupId?: number;
  name?: string;
  isSystem?: boolean;
  tenantId?: number;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  isInRelation?: boolean;
}

/**
 * An interface representing LocationGroupLocationDto.
 */
export interface LocationGroupLocationDto {
  locationId?: number;
  location?: LocationDto;
  locationGroupId?: number;
  locationGroup?: LocationGroupDto;
}

/**
 * An interface representing LocationInMissionDto.
 */
export interface LocationInMissionDto {
  locationId?: number;
  name?: string;
  locationNumber?: string;
  phoneNumber?: string;
  info?: string;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  stereotype?: StereotypeSimpleDto;
  addresses?: AddressInMissionDto[];
}

/**
 * An interface representing LocationInfoDto.
 */
export interface LocationInfoDto {
  locationId?: number;
  infoId?: number;
}

/**
 * An interface representing LocationListDto.
 */
export interface LocationListDto {
  locationId?: number;
  tenantId?: number;
  name?: string;
  /**
   * Possible values include: 'Open', 'Closed'
   */
  state?: LocationState;
  phoneNumber?: string;
  locationNumber?: string;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  shippingAddress?: AddressSimpleListDto;
  invoiceAddress?: AddressSimpleListDto;
}

/**
 * An interface representing LocationListForAddressDto.
 */
export interface LocationListForAddressDto {
  locationId?: number;
  tenantId?: number;
  name?: string;
  /**
   * Possible values include: 'Open', 'Closed'
   */
  state?: LocationState;
  phoneNumber?: string;
  locationNumber?: string;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  shippingAddress?: AddressSimpleListDto;
  invoiceAddress?: AddressSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing LocationListForContactDto.
 */
export interface LocationListForContactDto {
  locationId?: number;
  tenantId?: number;
  name?: string;
  /**
   * Possible values include: 'Open', 'Closed'
   */
  state?: LocationState;
  phoneNumber?: string;
  locationNumber?: string;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  shippingAddress?: AddressSimpleListDto;
  invoiceAddress?: AddressSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing LocationListForContactDtoPageOutputDto.
 */
export interface LocationListForContactDtoPageOutputDto {
  paging?: any;
  items?: LocationListForContactDto[];
}

/**
 * An interface representing LocationListForLocationGroupDto.
 */
export interface LocationListForLocationGroupDto {
  locationId?: number;
  tenantId?: number;
  name?: string;
  /**
   * Possible values include: 'Open', 'Closed'
   */
  state?: LocationState;
  phoneNumber?: string;
  locationNumber?: string;
  externalId?: string;
  rowVersion?: number[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  shippingAddress?: AddressSimpleListDto;
  invoiceAddress?: AddressSimpleListDto;
  isInRelation?: boolean;
}

/**
 * An interface representing LocationListForResourcesDto.
 */
export interface LocationListForResourcesDto {
  locationId?: number;
  name?: string;
  /**
   * Possible values include: 'Open', 'Closed'
   */
  state?: LocationState;
  locationNumber?: string;
  stereotype?: StereotypeSimpleListDto;
  addresses?: AddressSimpleListDto[];
}

/**
 * An interface representing LoginByBadgeDto.
 */
export interface LoginByBadgeDto {
  badge?: string;
}

/**
 * An interface representing LoginByPinDto.
 */
export interface LoginByPinDto {
  pin?: string;
}

/**
 * An interface representing LoginByQuickAccessDto.
 */
export interface LoginByQuickAccessDto {
  key?: string;
  resourceId?: number;
}

/**
 * An interface representing LoginChallengeDto.
 */
export interface LoginChallengeDto {
  loginChallengeId?: number;
}

/**
 * An interface representing LoginProviderDto.
 */
export interface LoginProviderDto {
  loginProviderId?: number;
  name?: string;
  defaultRoleId?: number;
  defaultRole?: AppRoleDto;
  type?: number;
  tenantId?: number;
}

/**
 * An interface representing LoginProviderListDto.
 */
export interface LoginProviderListDto {
  loginProviderId?: number;
  name?: string;
  defaultRoleId?: number;
  defaultRole?: AppRoleDto;
  provider?: number;
}

/**
 * An interface representing LoginProviderRoleDto.
 */
export interface LoginProviderRoleDto {
  loginProviderRoleId?: number;
  roleId?: number;
  role?: AppRoleDto;
  loginProviderId?: number;
  loginProvider?: SimpleLoginProviderDto;
  tenantId?: number;
  rowVersion?: number[];
}

/**
 * An interface representing LoginProviderRoleListDto.
 */
export interface LoginProviderRoleListDto {
  loginProviderRoleId?: number;
  roleId?: number;
  role?: AppRoleListDto;
  loginProviderId?: number;
  rowVersion?: number[];
}

/**
 * An interface representing LookBackOnResourceConsumptionQueueDto.
 */
export interface LookBackOnResourceConsumptionQueueDto {
  resourceTaskId?: number;
}

/**
 * An interface representing LookBackResourceTaskConsumptionTriggerDto.
 */
export interface LookBackResourceTaskConsumptionTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange', 'LookBack'
   */
  type?: ResourceTaskConsumptionTriggerType;
  upperLimit?: number;
  lowerLimit?: number;
  interval?: string;
}

/**
 * An interface representing MailBoxDto.
 */
export interface MailBoxDto {
  mailBoxId?: number;
  displayName?: string;
  address?: string;
  tenantId?: number;
}

/**
 * An interface representing MailBoxListDto.
 */
export interface MailBoxListDto {
  mailBoxId?: number;
  displayName?: string;
  address?: string;
  tenantId?: number;
}

/**
 * An interface representing MailReceiverListDto.
 */
export interface MailReceiverListDto {
  address?: string;
  id?: number;
  /**
   * Possible values include: 'To', 'Cc', 'Bcc'
   */
  type?: NotificationReceiverType;
  /**
   * Possible values include: 'Unknown', 'Delivered', 'Blocked', 'Bounced', 'Dropped'
   */
  status?: NotificationReceiverStatus;
  statusReason?: string;
  statusCreatedAt?: string;
  tenantId?: number;
}

/**
 * An interface representing MailLogListDto.
 */
export interface MailLogListDto {
  tenantId?: number;
  id?: number;
  subject?: string;
  createdAt?: string;
  receivers?: MailReceiverListDto[];
}

/**
 * An interface representing TicketMailNodeContentDto.
 */
export interface TicketMailNodeContentDto {
  stereotype?: StereotypeSimpleDto;
  priority?: PrioritySimpleDto;
  state?: StateSimpleDto;
  defaultLocation?: LocationSimpleDto;
  defaultResource?: ResourceSimpleDto;
  /**
   * Possible values include: 'Unrestricted', 'Resource', 'Location'
   */
  subjectType?: MailNodeSubjectType;
}

/**
 * An interface representing WinterhalterServiceCallMailNodeContentDto.
 */
export interface WinterhalterServiceCallMailNodeContentDto {
  defaultContactFirstName?: string;
  defaultContactLastName?: string;
  defaultCategory?: string;
  defaultProblem?: string;
  defaultLocation?: LocationSimpleDto;
  defaultResource?: ResourceSimpleDto;
  /**
   * Possible values include: 'Unrestricted', 'Resource', 'Location'
   */
  subjectType?: MailNodeSubjectType;
}

/**
 * An interface representing MailNodeNotificationDto.
 */
export interface MailNodeNotificationDto {
  mailNodeNotificationId?: number;
  /**
   * Possible values include: 'Base', 'Email'
   */
  type?: MailNodeNotificationType;
  tenantId?: number;
}

/**
 * An interface representing MailNodeDto.
 */
export interface MailNodeDto {
  mailNodeId?: number;
  title?: string;
  isDefault?: boolean;
  tenantId?: number;
  emailAddress?: string;
  ticketContent?: TicketMailNodeContentDto;
  winterhalterServiceCallContent?: WinterhalterServiceCallMailNodeContentDto;
  /**
   * Possible values include: 'Ticket', 'WinterhalterServiceCall'
   */
  contentType?: MailNodeContentType;
  notifications?: MailNodeNotificationDto[];
}

/**
 * An interface representing MailNodeListDto.
 */
export interface MailNodeListDto {
  mailNodeId?: number;
  title?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Ticket', 'WinterhalterServiceCall'
   */
  contentType?: MailNodeContentType;
}

/**
 * An interface representing MailOutDto.
 */
export interface MailOutDto {
  from?: EmailAddressDto;
  to?: EmailAddressDto[];
  cc?: EmailAddressDto[];
  bcc?: EmailAddressDto[];
  subject?: string;
  text?: string;
  html?: string;
  attachments?: FileDto[];
  suppressAutoRespond?: boolean;
}

/**
 * An interface representing MailOutQueueDto.
 */
export interface MailOutQueueDto {
  tenantId?: number;
  subject?: string;
  from?: EmailAddressDto;
  to?: EmailAddressDto[];
  cc?: EmailAddressDto[];
  templateName?: string;
  templateContext?: any;
  attachments?: FileDto[];
  suppressAutoRespond?: boolean;
}

/**
 * An interface representing MailStatusDto.
 */
export interface MailStatusDto {
  smtpId?: string;
  email?: string;
  /**
   * Possible values include: 'Unknown', 'Delivered', 'Blocked', 'Bounced', 'Dropped'
   */
  status?: NotificationReceiverStatus;
  reason?: string;
}

/**
 * An interface representing ManualExecutionDto.
 */
export interface ManualExecutionDto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing ManualExecutionPlanViewV1Dto.
 */
export interface ManualExecutionPlanViewV1Dto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing ManualExecutionV1Dto.
 */
export interface ManualExecutionV1Dto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
}

/**
 * An interface representing MarkItemAsSolutionDto.
 */
export interface MarkItemAsSolutionDto {
  itemId?: number;
  isSolution?: boolean;
}

/**
 * An interface representing MassImportDto.
 */
export interface MassImportDto {
  tenantId?: number;
  csvContent?: string;
  importConfigurationId?: number;
}

/**
 * An interface representing ProductSubscriptionSimpleDto.
 */
export interface ProductSubscriptionSimpleDto {
  expiresIn?: string;
  isActive?: boolean;
  /**
   * Possible values include: 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
   * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
   * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
   * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon',
   * 'ClassificationAddon', 'SyncLocationAddon', 'OrgaTechClassicModule', 'OrgaTechModule',
   * 'SyncReceiptsClassicAddon', 'SyncContactsClassicAddon', 'SyncDriveClassicAddon',
   * 'SyncReceiptsAddon', 'SyncContactsAddon', 'SyncDriveAddon', 'NotificationClassicAddon',
   * 'NotificationAddon', 'TeamModule', 'MdmClassicModule', 'PrimeReportAddon',
   * 'MaintenanceReportAddon'
   */
  edition?: ProductEditions;
}

/**
 * An interface representing TenantInfoDto.
 */
export interface TenantInfoDto {
  tenantId?: number;
  name?: string;
  domain?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly displayName?: string;
  subscriptions?: ProductSubscriptionSimpleDto[];
  roles?: AppRoleInfoDto[];
  names?: ContactSimpleDto[];
}

/**
 * An interface representing MeDto.
 */
export interface MeDto {
  userId?: number;
  isGuest?: boolean;
  userTimeZone?: string;
  userCulture?: string;
  tenants?: TenantInfoDto[];
}

/**
 * An interface representing MessageControlDto.
 */
export interface MessageControlDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  resource?: ResourceSimpleDto;
  location?: LocationSimpleDto;
  reason?: IssueReasonDto;
}

/**
 * An interface representing MessageControlListDto.
 */
export interface MessageControlListDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  issue?: IssueDetailsListDto;
}

/**
 * An interface representing MessageIssueDto.
 */
export interface MessageIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  resource?: ResourceSimpleDto;
  location?: LocationSimpleDto;
}

/**
 * An interface representing MessageIssueListDto.
 */
export interface MessageIssueListDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  issue?: IssueDetailsListDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
}

/**
 * An interface representing StereotypeUsageDto.
 */
export interface StereotypeUsageDto {
  stereotypeId?: number;
  name?: string;
  tenantId?: number;
  isDefault?: boolean;
  externalId?: string;
  position?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  customPropertySets?: CustomSetReferenceDto[];
}

/**
 * An interface representing MessageIssuePreviewDto.
 */
export interface MessageIssuePreviewDto {
  issueTemplateId?: number;
  name?: string;
  issueStereotype?: StereotypeUsageDto;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
}

/**
 * An interface representing MetaEntryDto.
 */
export interface MetaEntryDto {
  /**
   * Possible values include: 'Device'
   */
  type?: MetaEntryType;
}

/**
 * An interface representing MissionStateTransitionDto.
 */
export interface MissionStateTransitionDto {
  occuredAt?: string;
  description?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
}

/**
 * An interface representing MissionAppDto.
 */
export interface MissionAppDto {
  missionId?: number;
  title?: string;
  description?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  tenantId?: number;
  rowVersion?: number[];
  solutionContact?: EditorDto;
  stereotype?: StereotypeUsageDto;
  customPropertyValues?: FilledCustomValueDto[];
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  stateTransitions?: MissionStateTransitionDto[];
}

/**
 * An interface representing MissionSolutionDto.
 */
export interface MissionSolutionDto {
  solutionId?: number;
  members?: SolutionMemberDto[];
  attachments?: AttachmentForTechDto[];
  threadId?: string;
  threadMailAddress?: string;
}

/**
 * An interface representing ResourcePathPartDto.
 */
export interface ResourcePathPartDto {
  index?: number;
  id?: number;
  name?: string;
}

/**
 * An interface representing ResourceInMissionDto.
 */
export interface ResourceInMissionDto {
  resourceId?: number;
  name?: string;
  inventoryNumber?: string;
  path?: ResourcePathPartDto[];
  location?: LocationInMissionDto;
  stereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing QuestInMissionDto.
 */
export interface QuestInMissionDto {
  functionProperty?: FunctionSimpleDto;
  /**
   * Possible values include: 'Suggested', 'InProcess', 'Completed', 'Failed', 'Canceled'
   */
  state?: EditorQuestState;
}

/**
 * An interface representing MissionSourceDto.
 */
export interface MissionSourceDto {
  primaryIssue?: IssueSimpleDto;
}

/**
 * An interface representing MissionByManualDto.
 */
export interface MissionByManualDto {
  missionId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
  isSendingMail?: boolean;
  lastEmailSend?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  isCompleted?: boolean;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  solution?: MissionSolutionDto;
  reportId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  labels?: LabelSimpleDto[];
  similarEntities?: LabeledEntityDto[];
  solutionContact?: EditorDto;
  quest?: QuestInMissionDto;
  precursor?: MissionSimpleDto;
  successors?: MissionSimpleDto[];
  origin?: MissionSourceDto;
}

/**
 * An interface representing StereotypeTtm.
 */
export interface StereotypeTtm {
  stereotypeId?: number;
  name?: string;
}

/**
 * An interface representing MissionReportGroupTtm.
 */
export interface MissionReportGroupTtm {
  missionReportGroupId?: number;
  total?: number;
  name?: string;
  articles?: ArticleUsageTtm[];
}

/**
 * An interface representing MissionReportTtm.
 */
export interface MissionReportTtm {
  missionReportId?: number;
  total?: number;
  travelDurationInHours?: number;
  travelDurationCost?: number;
  workDurationInHours?: number;
  workDurationCost?: number;
  properties?: any;
  groups?: MissionReportGroupTtm[];
}

/**
 * An interface representing ResourceTaskModelDto.
 */
export interface ResourceTaskModelDto {
  resourceTaskId?: number;
  title?: string;
}

/**
 * An interface representing MissionByTaskDescriptionTtm.
 */
export interface MissionByTaskDescriptionTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  currentUser?: ContactTtm;
  task?: ResourceTaskModelDto;
}

/**
 * An interface representing ResourceTaskSimpleDto.
 */
export interface ResourceTaskSimpleDto {
  resourceTaskId?: number;
  title?: string;
  isActivated?: boolean;
  isArchived?: boolean;
  tenantId?: number;
}

/**
 * An interface representing MissionByTaskDto.
 */
export interface MissionByTaskDto {
  missionId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
  isSendingMail?: boolean;
  lastEmailSend?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  isCompleted?: boolean;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  solution?: MissionSolutionDto;
  reportId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  labels?: LabelSimpleDto[];
  similarEntities?: LabeledEntityDto[];
  solutionContact?: EditorDto;
  quest?: QuestInMissionDto;
  precursor?: MissionSimpleDto;
  successors?: MissionSimpleDto[];
  origin?: MissionSourceDto;
  task?: ResourceTaskSimpleDto;
}

/**
 * An interface representing PriorityModelTtm.
 */
export interface PriorityModelTtm {
  priorityId?: number;
  name?: string;
}

/**
 * An interface representing TicketTtm.
 */
export interface TicketTtm {
  ticketId?: number;
  title?: string;
  link?: string;
  description?: string;
  resource?: ResourceTtm;
  priority?: PriorityModelTtm;
  escalationLevel?: EscalationLevelTtm;
  editor?: ContactTtm;
  stereotype?: StereotypeTtm;
  properties?: any;
}

/**
 * An interface representing MissionByTicketDescriptionTtm.
 */
export interface MissionByTicketDescriptionTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  currentUser?: ContactTtm;
  ticket?: TicketTtm;
}

/**
 * An interface representing TicketInMissionDto.
 */
export interface TicketInMissionDto {
  ticketId?: number;
  title?: string;
}

/**
 * An interface representing MissionByTicketDto.
 */
export interface MissionByTicketDto {
  missionId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
  isSendingMail?: boolean;
  lastEmailSend?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  isCompleted?: boolean;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  solution?: MissionSolutionDto;
  reportId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  labels?: LabelSimpleDto[];
  similarEntities?: LabeledEntityDto[];
  solutionContact?: EditorDto;
  quest?: QuestInMissionDto;
  precursor?: MissionSimpleDto;
  successors?: MissionSimpleDto[];
  origin?: MissionSourceDto;
  ticket?: TicketInMissionDto;
}

/**
 * An interface representing QuestInMissionListDto.
 */
export interface QuestInMissionListDto {
  functionProperty?: FunctionListSimpleDto;
  /**
   * Possible values include: 'Suggested', 'InProcess', 'Completed', 'Failed', 'Canceled'
   */
  state?: EditorQuestState;
}

/**
 * An interface representing ResourceManufacturerSimpleListDto.
 */
export interface ResourceManufacturerSimpleListDto {
  resourceManufacturerId?: number;
  name?: string;
}

/**
 * An interface representing ResourceModelSimpleListDto.
 */
export interface ResourceModelSimpleListDto {
  resourceModelId?: number;
  name?: string;
  resourceManufacturerId?: number;
}

/**
 * An interface representing ResourceInMissionListDto.
 */
export interface ResourceInMissionListDto {
  resourceId?: number;
  name?: string;
  isArchived?: boolean;
  inventoryNumber?: string;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  currentState?: ResourceState;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  manufacturer?: ResourceManufacturerSimpleListDto;
  model?: ResourceModelSimpleListDto;
  location?: LocationListDto;
  path?: ResourcePathPartSimpleListDto[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing TicketInMissionListDto.
 */
export interface TicketInMissionListDto {
  ticketId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
  currentState?: StateSimpleListDto;
  editor?: ContactInMissionListDto;
  priority?: PrioritySimpleListDto;
  resource?: ResourceInMissionListDto;
  escalationLevel?: EscalationLevelSimpleListDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing ResourceTaskSimpleListDto.
 */
export interface ResourceTaskSimpleListDto {
  resourceTaskId?: number;
  title?: string;
  endOffset?: string;
  endDate?: string;
  startOffSet?: string;
  startDate?: string;
  tenantId?: number;
  isActivated?: boolean;
  isArchived?: boolean;
  isCancelingObsoleteMissions?: boolean;
}

/**
 * An interface representing MissionCompleteDto.
 */
export interface MissionCompleteDto {
  missionId?: number;
  title?: string;
  description?: string;
  cost?: number;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  createdAt?: string;
  lastModified?: string;
  isArchived?: boolean;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  isCompleted?: boolean;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  solutionId?: number;
  ticketId?: number;
  tenantId?: number;
  labelRelations?: LabelRelationListDto[];
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  solutionContact?: ContactInMissionListDto;
  quest?: QuestInMissionListDto;
  locationNumber?: string;
  location?: LocationListDto;
  sourceTicket?: TicketInMissionListDto;
  sourceTask?: ResourceTaskSimpleListDto;
  sourceResource?: ResourceInMissionListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  invoiceAddress?: AddressInMissionListDto;
  shippingAddress?: AddressInMissionListDto;
}

/**
 * An interface representing MissionDescriptionContextDto.
 */
export interface MissionDescriptionContextDto {
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  type?: TemplateContextType;
  missionId?: number;
  tenantId?: number;
  title?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  reportId?: number;
  resourceId?: number;
  invoiceAddressId?: number;
  shippingAddressId?: number;
  stereotypeId?: number;
  solutionContactId?: number;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  taskId?: number;
  ticketId?: number;
  controlPointId?: number;
  inspectionId?: number;
}

/**
 * An interface representing MissionDescriptionTtm.
 */
export interface MissionDescriptionTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  currentUser?: ContactTtm;
}

/**
 * An interface representing MissionDistanceListDto.
 */
export interface MissionDistanceListDto {
  missionId?: number;
  title?: string;
  isArchived?: boolean;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  location?: LocationSimpleDto;
  solutionContact?: ContactInMissionListDto;
  sourceResource?: ResourceInMissionListDto;
  shippingAddress?: AddressInMissionListDto;
}

/**
 * An interface representing MissionDto.
 */
export interface MissionDto {
  missionId?: number;
  title?: string;
  description?: string;
  isArchived?: boolean;
  isSendingMail?: boolean;
  lastEmailSend?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  isCompleted?: boolean;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  solution?: MissionSolutionDto;
  reportId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  labels?: LabelSimpleDto[];
  similarEntities?: LabeledEntityDto[];
  solutionContact?: EditorDto;
  quest?: QuestInMissionDto;
  precursor?: MissionSimpleDto;
  successors?: MissionSimpleDto[];
  origin?: MissionSourceDto;
}

/**
 * An interface representing MissionIssueDetailsDto.
 */
export interface MissionIssueDetailsDto {
  missionId?: number;
  title?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
}

/**
 * An interface representing MissionIssueDto.
 */
export interface MissionIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  mission?: MissionIssueDetailsDto;
  resource?: ResourceSimpleDto;
  location?: LocationSimpleDto;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing MissionSimpleListDto.
 */
export interface MissionSimpleListDto {
  missionId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  title?: string;
  isArchived?: boolean;
  tenantId?: number;
}

/**
 * An interface representing MissionIssueListDto.
 */
export interface MissionIssueListDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  issue?: IssueDetailsListDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  mission?: MissionSimpleListDto;
}

/**
 * An interface representing MissionIssuePreviewDto.
 */
export interface MissionIssuePreviewDto {
  issueTemplateId?: number;
  name?: string;
  issueStereotype?: StereotypeUsageDto;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
  /**
   * Possible values include: 'None', 'Editor', 'PrioritySearch'
   */
  editorSelectorKind?: MissionEditorSelectorKind;
  priorities?: PrioritySimpleDto[];
}

/**
 * An interface representing MissionNoteTtm.
 */
export interface MissionNoteTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  currentUser?: ContactTtm;
}

/**
 * An interface representing MissionNotificationRuleTtm.
 */
export interface MissionNotificationRuleTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
}

/**
 * An interface representing MissionReceiptDto.
 */
export interface MissionReceiptDto {
  missionReceiptId?: number;
  title?: string;
  bookedAt?: string;
  debit?: number;
  credit?: number;
  taxInPercent?: number;
}

/**
 * An interface representing MissionReportGroupDto.
 */
export interface MissionReportGroupDto {
  id?: number;
  missionReportGroupId?: number;
  total?: number;
  position?: number;
  name?: string;
  tenantId?: number;
  usedArticles?: ArticleUsageDto[];
}

/**
 * An interface representing MissionReportDto.
 */
export interface MissionReportDto {
  id?: number;
  missionReportId?: number;
  total?: number;
  tenantId?: number;
  travelDurationInHours?: number;
  travelDurationCost?: number;
  workDurationInHours?: number;
  workDurationCost?: number;
  missionId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  groups?: MissionReportGroupDto[];
  usedArticles?: ArticleUsageDto[];
}

/**
 * An interface representing MissionReportPreviewDto.
 */
export interface MissionReportPreviewDto {
  defaultTravelDurationCost?: number;
  defaultWorkDurationCost?: number;
}

/**
 * An interface representing MissionReportReceiptDto.
 */
export interface MissionReportReceiptDto {
  afterTax?: number;
  beforeTax?: number;
  items?: MissionReceiptDto[];
}

/**
 * An interface representing MissionSettingDto.
 */
export interface MissionSettingDto {
  settingId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'General', 'Ticket', 'Mission'
   */
  type?: SettingType;
  defaultTravelDurationCost?: number;
  defaultWorkDurationCost?: number;
}

/**
 * An interface representing MissionSkeletonDto.
 */
export interface MissionSkeletonDto {
  missionSkeletonId?: number;
  stereotype?: StereotypeSimpleDto;
  isCreating?: boolean;
  isActivating?: boolean;
  tenantId?: number;
}

/**
 * An interface representing MissionSkeletonV1Dto.
 */
export interface MissionSkeletonV1Dto {
  missionSkeletonId?: number;
  stereotype?: StereotypeSimpleDto;
  isCreating?: boolean;
  isActivating?: boolean;
  tenantId?: number;
}

/**
 * An interface representing MissionTtm.
 */
export interface MissionTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
}

/**
 * An interface representing MissionsPerLocationByMonthDto.
 */
export interface MissionsPerLocationByMonthDto {
  month?: number;
  monthName?: string;
  locationId?: number;
  locationName?: string;
  missionCost?: number;
  missionCount?: number;
  missionWithCostCount?: number;
}

/**
 * An interface representing MissionsPerLocationByTimeDto.
 */
export interface MissionsPerLocationByTimeDto {
  datePoint?: string;
  locationId?: number;
  locationName?: string;
  missionCost?: number;
  missionCount?: number;
  missionWithCostCount?: number;
}

/**
 * An interface representing MissionsPerLocationPerTypeByMonthDto.
 */
export interface MissionsPerLocationPerTypeByMonthDto {
  month?: number;
  monthName?: string;
  locationId?: number;
  locationName?: string;
  missionCost?: number;
  missionCount?: number;
  missionWithCostCount?: number;
  stereotypeName?: string;
  stereotypeId?: number;
}

/**
 * An interface representing MissionsPerLocationPerTypeByTimeDto.
 */
export interface MissionsPerLocationPerTypeByTimeDto {
  datePoint?: string;
  locationId?: number;
  locationName?: string;
  missionCost?: number;
  missionCount?: number;
  missionWithCostCount?: number;
  stereotypeName?: string;
  stereotypeId?: number;
}

/**
 * An interface representing MissionsPerResourceDto.
 */
export interface MissionsPerResourceDto {
  missionCount?: number;
  missionWithCostCount?: number;
  missionCost?: number;
  resourceStereotypeName?: string;
  resourceStereotypeId?: number;
  manufacturer?: string;
  constructionDate?: string;
  model?: string;
  resourceName?: string;
  inventoryNumber?: string;
  installationDateSystem?: string;
  locationId?: number;
  locationNumber?: string;
  locationName?: string;
}

/**
 * An interface representing MissionsPerResourceManufacturerDto.
 */
export interface MissionsPerResourceManufacturerDto {
  resourceCount?: number;
  missionCount?: number;
  missionWithCostCount?: number;
  missionCost?: number;
  manufacturer?: string;
  resourceStereotypeName?: string;
  resourceStereotypeId?: number;
}

/**
 * An interface representing MissionsPerSolutionContactByMonthDto.
 */
export interface MissionsPerSolutionContactByMonthDto {
  month?: number;
  monthName?: string;
  solutionContactId?: number;
  solutionContactName?: string;
  solutionContactStereotypeName?: string;
  missionCost?: number;
  missionCount?: number;
  missionWithCostCount?: number;
  missionInTimeCount?: number;
}

/**
 * An interface representing MissionsPerSolutionContactByTimeDto.
 */
export interface MissionsPerSolutionContactByTimeDto {
  datePoint?: string;
  solutionContactId?: number;
  solutionContactName?: string;
  solutionContactStereotypeName?: string;
  missionCost?: number;
  missionCount?: number;
  missionWithCostCount?: number;
  missionInTimeCount?: number;
}

/**
 * An interface representing MissionsPerSolutionContactDto.
 */
export interface MissionsPerSolutionContactDto {
  missionCount?: number;
  missionWithCostCount?: number;
  missionInTimeCount?: number;
  missionCost?: number;
  solutionContactName?: string;
  solutionContactStereotypeName?: string;
  solutionContactId?: number;
  timeWorking?: number;
  timeTravel?: number;
}

/**
 * An interface representing MissionsPerTypeDto.
 */
export interface MissionsPerTypeDto {
  resourceCount?: number;
  missionCount?: number;
  missionFinishCount?: number;
  missionWithCostCount?: number;
  missionCost?: number;
  stereotypeName?: string;
  stereotypeId?: number;
}

/**
 * An interface representing MonthlyDateTimePatternDto.
 */
export interface MonthlyDateTimePatternDto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeDto[];
  startDate?: string;
  endDate?: string;
  day?: number;
  repeat?: number;
}

/**
 * An interface representing MonthlyDateTimePatternV1Dto.
 */
export interface MonthlyDateTimePatternV1Dto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeV1Dto[];
  startDate?: string;
  endDate?: string;
  day?: number;
  repeat?: number;
}

/**
 * An interface representing MonthlyWeekdayDateTimePatternDto.
 */
export interface MonthlyWeekdayDateTimePatternDto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeDto[];
  startDate?: string;
  endDate?: string;
  weekday?: number;
  weekdayCount?: number;
  repeat?: number;
}

/**
 * An interface representing MonthlyWeekdayDateTimePatternV1Dto.
 */
export interface MonthlyWeekdayDateTimePatternV1Dto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeV1Dto[];
  startDate?: string;
  endDate?: string;
  weekday?: number;
  weekdayCount?: number;
  repeat?: number;
}

/**
 * An interface representing MoveResourceQueueDto.
 */
export interface MoveResourceQueueDto {
  oldLocationId?: number;
  newLocationId?: number;
}

/**
 * An interface representing MultilineFormDefaultDto.
 */
export interface MultilineFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing MultilineFormFieldDto.
 */
export interface MultilineFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  minLength?: number;
  maxLength?: number;
}

/**
 * An interface representing MultilineFormValueDto.
 */
export interface MultilineFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing MuteHintDto.
 */
export interface MuteHintDto {
  muteUntil?: string;
}

/**
 * An interface representing NoteCallerDto.
 */
export interface NoteCallerDto {
  noteId?: number;
  description?: string;
  /**
   * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
   * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
   * 'ChangedResource', 'FinalizedMission', 'Base'
   */
  type?: NoteType;
  tenantId?: number;
  rowVersion?: number[];
  attachments?: AttachmentForTechDto[];
  phoneNumber?: string;
  /**
   * Possible values include: 'None', 'In', 'Out'
   */
  direction?: SystemDirections;
}

/**
 * An interface representing NoteChatMessageDto.
 */
export interface NoteChatMessageDto {
  noteId?: number;
  description?: string;
  /**
   * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
   * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
   * 'ChangedResource', 'FinalizedMission', 'Base'
   */
  type?: NoteType;
  tenantId?: number;
  rowVersion?: number[];
  attachments?: AttachmentForTechDto[];
}

/**
 * An interface representing NoteMailDroppedAttachmentListDto.
 */
export interface NoteMailDroppedAttachmentListDto {
  fileName?: string;
}

/**
 * An interface representing NoteMailBoxListDto.
 */
export interface NoteMailBoxListDto {
  mailBox?: MailBoxListDto;
  /**
   * Possible values include: 'From', 'To', 'Cc', 'Bcc'
   */
  relation?: MailRelationTypes;
  mailBoxId?: number;
  noteMailId?: number;
}

/**
 * An interface representing ResourceInNoteListDto.
 */
export interface ResourceInNoteListDto {
  resourceId?: number;
  name?: string;
}

/**
 * An interface representing StateListDto.
 */
export interface StateListDto {
  stateId?: number;
  name?: string;
  color?: string;
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing NoteCompleteDto.
 */
export interface NoteCompleteDto {
  noteId?: number;
  /**
   * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
   * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
   * 'ChangedResource', 'FinalizedMission', 'Base'
   */
  type?: NoteType;
  description?: string;
  rowVersion?: number[];
  tenantId?: number;
  attachments?: AttachmentForTechCompleteDto[];
  chatMessage?: ChatMessageInNoteListDto;
  phoneNumber?: string;
  /**
   * Possible values include: 'None', 'In', 'Out'
   */
  direction?: SystemDirections;
  subject?: string;
  isSpam?: boolean;
  droppedAttachments?: NoteMailDroppedAttachmentListDto[];
  to?: NoteMailBoxListDto[];
  cc?: NoteMailBoxListDto[];
  bcc?: NoteMailBoxListDto[];
  from?: NoteMailBoxListDto[];
  isMissionCompleted?: boolean;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  missionState?: MissionState;
  reason?: string;
  previousResource?: ResourceInNoteListDto;
  currentResource?: ResourceInNoteListDto;
  stateId?: number;
  changedToState?: StateListDto;
  isChangedByEmail?: boolean;
  causedMissionId?: number;
  causedMission?: MissionSimpleListDto;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  stateOfCausedMission?: MissionState;
  causedContract?: ContractSimpleListDto;
  cancelLevelId?: number;
  cancelLevel?: EscalationLevelListDto;
  offsetForCanceledLevel?: string;
  offsetForEscalatedByEvent?: string;
  escalatedLevelIdByEvent?: number;
  escalatedLevelByEvent?: EscalationLevelListDto;
  isChangedByUser?: boolean;
  escalatedLevelIdByUser?: number;
  escalatedLevelByUser?: EscalationLevelListDto;
}

/**
 * An interface representing NoteDto.
 */
export interface NoteDto {
  noteId?: number;
  description?: string;
  /**
   * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
   * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
   * 'ChangedResource', 'FinalizedMission', 'Base'
   */
  type?: NoteType;
  tenantId?: number;
  rowVersion?: number[];
  attachments?: AttachmentForTechDto[];
}

/**
 * An interface representing NoteForMissionByTaskTtm.
 */
export interface NoteForMissionByTaskTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  currentUser?: ContactTtm;
  task?: ResourceTaskModelDto;
}

/**
 * An interface representing NoteForMissionByTicketTtm.
 */
export interface NoteForMissionByTicketTtm {
  title?: string;
  link?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedEnd?: string;
  plannedStart?: string;
  stereotype?: StereotypeTtm;
  solutionContact?: ContactTtm;
  stateName?: string;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  state?: MissionState;
  report?: MissionReportTtm;
  resource?: ResourceTtm;
  invoiceAddress?: AddressTtm;
  shippingAddress?: AddressTtm;
  currentUser?: ContactTtm;
  ticket?: TicketTtm;
}

/**
 * An interface representing NoteForTicketTtm.
 */
export interface NoteForTicketTtm {
  ticketId?: number;
  title?: string;
  link?: string;
  description?: string;
  resource?: ResourceTtm;
  priority?: PriorityModelTtm;
  escalationLevel?: EscalationLevelTtm;
  editor?: ContactTtm;
  stereotype?: StereotypeTtm;
  properties?: any;
  currentUser?: ContactTtm;
}

/**
 * An interface representing NoteMailBoxDto.
 */
export interface NoteMailBoxDto {
  mailBox?: MailBoxDto;
  /**
   * Possible values include: 'From', 'To', 'Cc', 'Bcc'
   */
  relation?: MailRelationTypes;
  mailBoxId?: number;
  noteMailId?: number;
}

/**
 * An interface representing NoteMailDto.
 */
export interface NoteMailDto {
  noteId?: number;
  description?: string;
  /**
   * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
   * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
   * 'ChangedResource', 'FinalizedMission', 'Base'
   */
  type?: NoteType;
  tenantId?: number;
  rowVersion?: number[];
  attachments?: AttachmentForTechDto[];
  /**
   * Possible values include: 'None', 'In', 'Out'
   */
  direction?: SystemDirections;
  to?: NoteMailBoxDto[];
  from?: NoteMailBoxDto[];
  cc?: NoteMailBoxDto[];
}

/**
 * An interface representing NoteTextDto.
 */
export interface NoteTextDto {
  noteId?: number;
  description?: string;
  /**
   * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
   * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
   * 'ChangedResource', 'FinalizedMission', 'Base'
   */
  type?: NoteType;
  tenantId?: number;
  rowVersion?: number[];
  attachments?: AttachmentForTechDto[];
}

/**
 * An interface representing NotificationTargetDto.
 */
export interface NotificationTargetDto {
  notificationTargetId?: number;
  /**
   * Possible values include: 'Base', 'Email', 'Contact', 'Function'
   */
  type?: NotificationTargetType;
  tenantId?: number;
}

/**
 * An interface representing NotificationRuleDto.
 */
export interface NotificationRuleDto {
  notificationRuleId?: number;
  title?: string;
  /**
   * Possible values include: 'TicketChangedResource', 'TicketChangedState', 'TicketChangedEditor',
   * 'MissionChangedResource', 'MissionChangedState', 'MissionChangedEditor',
   * 'MissionChangedCompletion', 'ContractChangedState', 'ActivatedInfo'
   */
  trigger?: NotificationTrigger;
  restriction?: DataTableDto;
  targets?: NotificationTargetDto[];
  emailSubjectTemplate?: TemplateSimpleDto;
  emailContentTemplate?: TemplateSimpleDto;
  attachments?: DocumentTemplateSimpleDto[];
  tenantId?: number;
}

/**
 * An interface representing NotificationRuleListDto.
 */
export interface NotificationRuleListDto {
  notificationRuleId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing NumberFormDefaultDto.
 */
export interface NumberFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
}

/**
 * An interface representing NumberFormFieldDto.
 */
export interface NumberFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  maxValue?: number;
  minValue?: number;
  positiveTolerance?: number;
  negativeTolerance?: number;
  setPointMin?: number;
  setPointMax?: number;
  isRateable?: boolean;
  unit?: string;
}

/**
 * An interface representing NumberFormValueDto.
 */
export interface NumberFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
}

/**
 * An interface representing OccuredEventQueueDto.
 */
export interface OccuredEventQueueDto {
  eventId?: string;
  skipDelay?: boolean;
  tenantId?: number;
}

/**
 * An interface representing OccurredConsumptionHistoryListDto.
 */
export interface OccurredConsumptionHistoryListDto {
  occurredConnectedConsumptionHistoryId?: number;
  occuredAt?: string;
  code?: string;
  consumption?: ConsumptionCodeSimpleListDto;
  value?: string;
  resource?: ResourceSimpleListDto;
}

/**
 * An interface representing OccurredConsumptionListDto.
 */
export interface OccurredConsumptionListDto {
  occurredConsumptionId?: number;
  resource?: ResourceSimpleListDto;
  consumption?: ConsumptionCodeSimpleListDto;
  value?: string;
  lastModified?: string;
}

/**
 * An interface representing OccurredConsumptionProtocolListDto.
 */
export interface OccurredConsumptionProtocolListDto {
  occurredConnectedConsumptionHistoryId?: number;
  occuredAt?: string;
  eventId?: string;
  code?: string;
  consumption?: ConsumptionCodeSimpleListDto;
  value?: string;
  resource?: ResourceSimpleListDto;
  issues?: IssueProtocolListDto[];
}

/**
 * An interface representing OccurredConsumptionQueueDto.
 */
export interface OccurredConsumptionQueueDto {
  eventId?: string;
  tenantId?: number;
  eventTime?: string;
  occuredAt?: string;
  lastOccuredAt?: string;
  code?: string;
  codeId?: number;
  resourceName?: string;
  resourceId?: number;
  newValue?: string;
  oldValue?: string;
  hasChanged?: boolean;
  unit?: string;
}

/**
 * An interface representing OccurredErrorHistoryListDto.
 */
export interface OccurredErrorHistoryListDto {
  occurredConnectedErrorHistoryId?: number;
  occuredAt?: string;
  code?: string;
  error?: ErrorCodeSimpleListDto;
  isActive?: boolean;
  resource?: ResourceSimpleListDto;
}

/**
 * An interface representing OccurredErrorListDto.
 */
export interface OccurredErrorListDto {
  occurredErrorId?: number;
  resource?: ResourceSimpleListDto;
  error?: ErrorCodeSimpleListDto;
  isActive?: boolean;
  lastModified?: string;
}

/**
 * An interface representing OccurredErrorProtocolListDto.
 */
export interface OccurredErrorProtocolListDto {
  occurredConnectedErrorHistoryId?: number;
  occuredAt?: string;
  eventId?: string;
  code?: string;
  error?: ErrorCodeSimpleListDto;
  isActive?: boolean;
  resource?: ResourceSimpleListDto;
  issues?: IssueProtocolListDto[];
}

/**
 * An interface representing OccurredErrorQueueDto.
 */
export interface OccurredErrorQueueDto {
  eventId?: string;
  tenantId?: number;
  eventTime?: string;
  occuredAt?: string;
  lastOccuredAt?: string;
  code?: string;
  codeId?: number;
  resourceName?: string;
  resourceId?: number;
  newValue?: boolean;
  oldValue?: boolean;
  hasChanged?: boolean;
}

/**
 * An interface representing OccurredHintHistoryListDto.
 */
export interface OccurredHintHistoryListDto {
  occurredConnectedHintHistoryId?: number;
  occuredAt?: string;
  code?: string;
  hint?: HintCodeSimpleListDto;
  isActive?: boolean;
  resource?: ResourceSimpleListDto;
}

/**
 * An interface representing OccurredHintListDto.
 */
export interface OccurredHintListDto {
  occurredHintId?: number;
  resource?: ResourceSimpleListDto;
  hint?: HintCodeSimpleListDto;
  isActive?: boolean;
  lastModified?: string;
}

/**
 * An interface representing OccurredHintQueueDto.
 */
export interface OccurredHintQueueDto {
  eventId?: string;
  tenantId?: number;
  eventTime?: string;
  occuredAt?: string;
  lastOccuredAt?: string;
  code?: string;
  codeId?: number;
  resourceName?: string;
  resourceId?: number;
  newValue?: boolean;
  oldValue?: boolean;
  /**
   * Possible values include: 'None', 'Warning', 'Error', 'Information'
   */
  newLevel?: HintLevel;
  /**
   * Possible values include: 'None', 'Warning', 'Error', 'Information'
   */
  oldLevel?: HintLevel;
  hasChanged?: boolean;
}

/**
 * An interface representing OpenEditorRequestOnContactListDto.
 */
export interface OpenEditorRequestOnContactListDto {
  contactId?: number;
  missionId?: number;
  createdAt?: string;
  mission?: MissionSimpleListDto;
}

/**
 * An interface representing OrgaDocumentAccessDto.
 */
export interface OrgaDocumentAccessDto {
  canAccessPdfMail?: boolean;
  canAccessPdfPrint?: boolean;
  canAccessPdfShare?: boolean;
}

/**
 * An interface representing OrgaFolderItemDto.
 */
export interface OrgaFolderItemDto {
  name?: string;
  path?: string;
  /**
   * Possible values include: 'Document', 'Folder'
   */
  type?: OrgaFolderItemType;
  file?: FileDto;
  access?: OrgaDocumentAccessDto;
}

/**
 * An interface representing OutOfRangeControlTriggerDto.
 */
export interface OutOfRangeControlTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange'
   */
  type?: ControlTriggerType;
  upperLimit?: number;
  lowerLimit?: number;
}

/**
 * An interface representing OutOfRangeResourceTaskConsumptionTriggerDto.
 */
export interface OutOfRangeResourceTaskConsumptionTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange', 'LookBack'
   */
  type?: ResourceTaskConsumptionTriggerType;
  upperLimit?: number;
  lowerLimit?: number;
}

/**
 * An interface representing OutboxReferenceQueueDto.
 */
export interface OutboxReferenceQueueDto {
  path?: string;
}

/**
 * An interface representing OverviewDto.
 */
export interface OverviewDto {
  locationId?: number;
  missionsOpenCount?: number;
  infosTodayCount?: number;
  infosWeekCount?: number;
  tasksOpenCount?: number;
  tasksOverdueCount?: number;
  tasksEscalatedCount?: number;
  chatUnreadCount?: number;
  chatsTodayCount?: number;
  locationName?: string;
}

/**
 * An interface representing OverviewTaskDto.
 */
export interface OverviewTaskDto {
  id?: number;
  name?: string;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  tenantId?: number;
  countPending?: number;
  countOpen?: number;
  countOverdue?: number;
  countEscalated?: number;
  countDone?: number;
  countCanceled?: number;
  isAny?: boolean;
}

/**
 * An interface representing OverviewTaskRequestDto.
 */
export interface OverviewTaskRequestDto {
  filter?: FilterDto[];
  restrictedLocations?: number[];
  restrictedTasks?: number[];
}

/**
 * An interface representing PasswordLoginDto.
 */
export interface PasswordLoginDto {
  password?: string;
  newPassword?: string;
  passwordBase64?: string;
  newPasswordBase64?: string;
  email?: string;
}

/**
 * An interface representing PercentageConditionDto.
 */
export interface PercentageConditionDto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
  percent?: number;
}

/**
 * An interface representing PercentageConditionV1Dto.
 */
export interface PercentageConditionV1Dto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
  percent?: number;
}

/**
 * An interface representing PermissionPackageDto.
 */
export interface PermissionPackageDto {
  /**
   * Possible values include: 'EditTenant', 'ReadTenantUsage', 'ReadFile', 'EditFile',
   * 'ManipulateFile', 'ShowAppDocuments', 'ShowBackendDocuments', 'SynchronizeMasterData',
   * 'ManipulateMasterData', 'ReadContact', 'EditContact', 'DisplayContact', 'DeleteContact',
   * 'EditSystemContact', 'ReadLocation', 'EditLocation', 'DisplayLocation', 'DeleteLocation',
   * 'ReadAddress', 'EditAddress', 'DisplayAddress', 'DeleteAddress', 'ReadCompany', 'EditCompany',
   * 'DisplayCompany', 'DeleteCompany', 'DisplayInfo', 'ReadInfo', 'EditInfo', 'ReadDevice',
   * 'EditDevice', 'RegisterDevice', 'EditPing', 'EditTask', 'EditTaskJob', 'EditForm',
   * 'EditFilledForm', 'ManipulateFilledForm', 'DisplayKnowledge', 'ReadKnowledge',
   * 'EditKnowledge', 'SubmitAnswer', 'DisplayKnowledgeExam', 'ReadHoliday', 'EditHoliday',
   * 'EditChat', 'ShowAppChat', 'ShowBackendChat', 'AllowChatPerEmail', 'ShowAllChatsPackage',
   * 'ReadFolderAccess', 'EditFolderAccess', 'EditRole', 'EditHistory', 'ReadLoginChallenge',
   * 'EditLoginChallenge', 'ReadLoginProvider', 'EditLoginProvider', 'EditProductSubscription',
   * 'ReadStereotype', 'EditStereotype', 'ManipulateStereotype', 'DisplayStereotype', 'ReadTicket',
   * 'EditTicket', 'ForceTicketState', 'AssignTicketToMe', 'AssignTicketToEditor', 'DisplayTicket',
   * 'DeleteTicket', 'AssignTicketResource', 'EditSettings', 'ReadSettings', 'ReadGraph',
   * 'ShowDashboard', 'ShowAppOverview', 'ReadExternalDocument', 'EditExternalDocument',
   * 'ReadExternalAllDocument', 'ReadResource', 'EditResource', 'DisplayResource',
   * 'DeleteResource', 'ReadQuickAccess', 'EditQuickAccess', 'DisplayQuickAccess',
   * 'ReadStateMachine', 'EditStateMachine', 'DisplayStateMachine', 'ReadDataTable',
   * 'EditDataTable', 'DisplayDataTable', 'ExportDataTable', 'ReadDashboard', 'EditDashboard',
   * 'DisplayDashboard', 'EditAllDashboard', 'ReadPriority', 'EditPriority', 'DisplayPriority',
   * 'ReadEscalation', 'UseEscalation', 'EditEscalation', 'DisplayEscalation', 'ReadAudit',
   * 'AssignMissionToMe', 'AssignMissionToEditor', 'AssignMissionResource', 'ReadMission',
   * 'EditMission', 'EditActualMissionDate', 'EditPlannedMissionDate', 'EditClosedMission',
   * 'DisplayMission', 'DeleteMission', 'DisplayMissionMap', 'DisplayMissionPlan', 'ReadLink',
   * 'EditLink', 'ReadAttachmentForTech', 'EditAttachmentForTech', 'ReadTemplate', 'EditTemplate',
   * 'ReadDocumentTemplate', 'EditDocumentTemplate', 'EditBetaDocumentTemplates',
   * 'ReadInventoryNumberPattern', 'EditInventoryNumberPattern', 'DisplayInventoryNumberPattern',
   * 'ReadContract', 'EditContract', 'DisplayContract', 'DeleteContract', 'ReadResourceTask',
   * 'EditResourceTask', 'DisplayResourceTask', 'DeleteResourceTask', 'ReadArticle', 'EditArticle',
   * 'DisplayArticle', 'DeleteArticle', 'ReadMissionReport', 'EditMissionReport', 'ReadSolution',
   * 'EditSolution', 'ReadAppOverview', 'EditAppOverview', 'DisplayAppOverview',
   * 'DeleteAppOverview', 'ReadTicketByProcessDefinition', 'EditTicketByProcessDefinition',
   * 'DisplayTicketByProcessDefinition', 'DeleteTicketByProcessDefinition',
   * 'CreateTicketByProcess', 'ReadCategory', 'EditCategory', 'DisplayCategory', 'DeleteCategory',
   * 'ReadChatByFormDefinition', 'EditChatByFormDefinition', 'DisplayChatByFormDefinition',
   * 'DeleteChatByFormDefinition', 'StartChatByForm', 'ReadAreaAssignment', 'EditAreaAssignment',
   * 'DisplayServicePackage', 'ReadSubscriptionPackage', 'ReadAnalytic', 'ReadPortalAnalytic',
   * 'ReadApiKey', 'EditApiKey', 'ReadMissionReceipt', 'EditMissionReceipt',
   * 'DisplayMissionReceipt', 'ReadConnectedDefinition', 'EditConnectedDefinition',
   * 'DisplayConnectedDefinition', 'DeleteConnectedDefinition', 'ReadTimelineSetting',
   * 'EditTimelineSetting', 'DeleteTimelineSetting', 'ReadMeTimelineSetting', 'ReadMailNode',
   * 'EditMailNode', 'DeleteMailNode', 'DisplayMailNode', 'ReadNotificationRule',
   * 'EditNotificationRule', 'ReadEditorQuest', 'EditEditorQuest', 'ImportExport',
   * 'ReadSearchList', 'EditSearchList', 'DisplaySearchList', 'DeleteSearchList',
   * 'ReadResourcesByLocationDefinition', 'EditResourcesByLocationDefinition',
   * 'ReadTicketsByLocationDefinition', 'EditTicketsByLocationDefinition', 'UseSetPassword',
   * 'ReadMailLog', 'UseClassification', 'ReadLabel', 'EditLabel', 'DisplayLabel', 'DeleteLabel',
   * 'ModifyLabelManually', 'IsOrgaUser', 'Manager', 'ShowsExpiredSubscription', 'IsAdmin',
   * 'IsSystemAdmin', 'CanUseExpiredSubscription', 'Admin', 'IsOrgaUserInTech',
   * 'IsQuickAccessIssueHistory', 'IsServiceEmployee', 'IsQuickAccessUser',
   * 'IsQuickAccessKnowledge', 'IsQuickAccessIssueTemplate', 'IsQuickAccessDashboard', 'TeamUser',
   * 'AnalystUser', 'TechUser', 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
   * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
   * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
   * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon',
   * 'ClassificationAddon', 'CalendarMission', 'SyncLocationAddon', 'ReadCalendar', 'ReadSafe',
   * 'MaintainSafe', 'AdministrateSafe', 'ReadResourceManufacturer', 'EditResourceManufacturer',
   * 'DisplayResourceManufacturer', 'DeleteResourceManufacturer', 'OrgaTechClassicModule',
   * 'SyncReceiptsClassicAddon', 'SyncContactsClassicAddon', 'SyncDriveClassicAddon',
   * 'NotificationClassicAddon', 'OrgaTechModule', 'SyncReceiptsAddon', 'SyncContactsAddon',
   * 'SyncDriveAddon', 'NotificationAddon', 'TeamModule', 'MdmClassicModule',
   * 'MaintenanceReportAddon', 'PrimeReportAddon', 'ReadIssueTemplate', 'EditIssueTemplate',
   * 'DisplayIssueTemplate', 'DeleteIssueTemplate', 'DeleteWinterhalterIssueTemplate',
   * 'ReadMessageIssueContent', 'EditMessageIssueContent', 'ReadTicketIssueContent',
   * 'EditTicketIssueContent', 'ReadMissionIssueContent', 'EditMissionIssueContent',
   * 'ReadWinterhalterServiceCallIssueContent', 'EditWinterhalterServiceCallIssueContent',
   * 'ReadWinterhalterProductOrderIssueContent', 'EditWinterhalterProductOrderIssueContent',
   * 'EditIssue', 'DeleteIssue', 'SendIssue', 'ReadRecentIssue', 'ReadAllIssue', 'ReadIssue',
   * 'UseMassImport', 'EditInvitation', 'ShowReportForMaintenance', 'ShowReportForPrime',
   * 'ReadControl', 'EditControl', 'DisplayControl', 'DeleteControl', 'ReadImportConfiguration',
   * 'EditImportConfiguration', 'DeleteImportConfiguration', 'DisplayImportConfiguration',
   * 'ReadImportProtocol', 'EditImportProtocol', 'DeleteImportProtocol', 'DisplayImportProtocol'
   */
  package?: AppFunctions;
  permissions?: AppPermissions[];
}

/**
 * An interface representing PictureFormDefaultDto.
 */
export interface PictureFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
  file?: FileDto;
}

/**
 * An interface representing PictureFormFieldDto.
 */
export interface PictureFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
}

/**
 * An interface representing PictureFormValueDto.
 */
export interface PictureFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
  file?: FileDto;
}

/**
 * An interface representing PingDeviceActionNotificationDto.
 */
export interface PingDeviceActionNotificationDto {
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
  /**
   * Possible values include: 'Logout', 'Ping', 'Restart'
   */
  action?: DeviceActionTypes;
  pingId?: number;
}

/**
 * An interface representing PingDto.
 */
export interface PingDto {
  pingId?: number;
  appVersion?: string;
  deviceId?: number;
  hardwareId?: string;
  tenantId?: number;
  sendAt?: string;
  arrivedAt?: string;
}

/**
 * An interface representing PingListDto.
 */
export interface PingListDto {
  pingId?: number;
  deviceId?: number;
  sendAt?: string;
  arrivedAt?: string;
  appVersion?: string;
}

/**
 * An interface representing PlannedExecutionDto.
 */
export interface PlannedExecutionDto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
  startMinuteOffset?: string;
  patterns?: DateTimePatternDto[];
}

/**
 * An interface representing PlannedExecutionPlanViewV1Dto.
 */
export interface PlannedExecutionPlanViewV1Dto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
  startMinuteOffset?: string;
  patterns?: DateTimePatternV1Dto[];
}

/**
 * An interface representing PlannedExecutionV1Dto.
 */
export interface PlannedExecutionV1Dto {
  executionId?: number;
  /**
   * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
   */
  type?: ExecutionTypes;
  startMinuteOffset?: string;
  patterns?: DateTimePatternV1Dto[];
}

/**
 * An interface representing PreviewDocumentTemplateDto.
 */
export interface PreviewDocumentTemplateDto {
  /**
   * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
   * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
   * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
   * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
   */
  contextType?: DocumentTemplateContextType;
  fileId?: number;
}

/**
 * An interface representing PreviewMissionInputDto.
 */
export interface PreviewMissionInputDto {
  resourceId?: number;
  ticketId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
}

/**
 * An interface representing PreviewRequestDto.
 */
export interface PreviewRequestDto {
  parentId?: number;
  stereotypeId: number;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entity: AppEntityType;
}

/**
 * An interface representing PreviewResourceByLocationDto.
 */
export interface PreviewResourceByLocationDto {
  name?: string;
  parent?: ResourceSimpleDto;
  stereotype?: StereotypeSimpleDto;
  tenantId?: number;
}

/**
 * An interface representing PreviewResourcesByLocationDefinitionDto.
 */
export interface PreviewResourcesByLocationDefinitionDto {
  resourcesByLocationDefinitionId?: number;
  title?: string;
  resources?: PreviewResourceByLocationDto[];
}

/**
 * An interface representing PreviewResourcesByLocationDto.
 */
export interface PreviewResourcesByLocationDto {
  definitions?: PreviewResourcesByLocationDefinitionDto[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly resources?: PreviewResourceByLocationDto[];
}

/**
 * An interface representing PreviewTemplateDto.
 */
export interface PreviewTemplateDto {
  template?: string;
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  context?: TemplateContextType;
}

/**
 * An interface representing PreviewTicketByLocationDto.
 */
export interface PreviewTicketByLocationDto {
  tenantId?: number;
  title?: string;
  currentState?: StateSimpleDto;
  priority?: PrioritySimpleDto;
  resource?: ResourceSimpleDto;
  editor?: ContactSimpleDto;
  stereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing PreviewTicketsByLocationDefinitionDto.
 */
export interface PreviewTicketsByLocationDefinitionDto {
  ticketsByLocationDefinitionId?: number;
  title?: string;
  tickets?: PreviewTicketByLocationDto[];
}

/**
 * An interface representing PreviewTicketsByLocationDto.
 */
export interface PreviewTicketsByLocationDto {
  definitions?: PreviewTicketsByLocationDefinitionDto[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly tickets?: PreviewTicketByLocationDto[];
}

/**
 * An interface representing PreviewedChatByFormLocationReceivingDto.
 */
export interface PreviewedChatByFormLocationReceivingDto {
  isOverridable?: boolean;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
}

/**
 * An interface representing PreviewedChatByFormFunctionReceivingDto.
 */
export interface PreviewedChatByFormFunctionReceivingDto {
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing PreviewedChatByFormReceivingDto.
 */
export interface PreviewedChatByFormReceivingDto {
  /**
   * Possible values include: 'Locations', 'FixedFunction'
   */
  kind?: ChatByFormReceivingKind;
  locationReceivings?: PreviewedChatByFormLocationReceivingDto;
  functionReceiving?: PreviewedChatByFormFunctionReceivingDto;
}

/**
 * An interface representing PreviewedChatByFormDto.
 */
export interface PreviewedChatByFormDto {
  chatByFormDefinitionId?: number;
  title?: string;
  tenantId?: number;
  form?: FormSimpleDto;
  requiresAuthentication?: boolean;
  receiving?: PreviewedChatByFormReceivingDto;
}

/**
 * An interface representing PrimaryEditorSuggestionDto.
 */
export interface PrimaryEditorSuggestionDto {
  id?: number;
  target?: EditorSuggestionTargetDto;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing PriorityDto.
 */
export interface PriorityDto {
  id?: number;
  priorityId?: number;
  name?: string;
  tenantId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing PriorityExportDto.
 */
export interface PriorityExportDto {
  name?: string;
}

/**
 * An interface representing PriorityListDto.
 */
export interface PriorityListDto {
  id?: number;
  priorityId?: number;
  name?: string;
  tenantId?: number;
  prioritySetId?: number;
  isArchived?: boolean;
}

/**
 * An interface representing PrioritySetDto.
 */
export interface PrioritySetDto {
  id?: number;
  prioritySetId?: number;
  name?: string;
  tenantId?: number;
  externalId?: string;
  priorities?: PriorityDto[];
}

/**
 * An interface representing PrioritySetExportDto.
 */
export interface PrioritySetExportDto {
  externalId?: string;
  name?: string;
  priorities?: PriorityExportDto[];
}

/**
 * An interface representing PrioritySetListDto.
 */
export interface PrioritySetListDto {
  id?: number;
  prioritySetId?: number;
  name?: string;
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing PrioritySetSimpleDto.
 */
export interface PrioritySetSimpleDto {
  name?: string;
  prioritySetId?: number;
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing PrioritySetSimpleExportDto.
 */
export interface PrioritySetSimpleExportDto {
  externalId?: string;
}

/**
 * An interface representing PrioritySettingDto.
 */
export interface PrioritySettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: number;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing PrioritySimpleDtoPreSelectedReferenceDto.
 */
export interface PrioritySimpleDtoPreSelectedReferenceDto {
  isOverridable?: boolean;
  value?: PrioritySimpleDto;
}

/**
 * An interface representing ProductSubscriptionDto.
 */
export interface ProductSubscriptionDto {
  productSubscriptionId?: number;
  expiresIn?: string;
  /**
   * Possible values include: 'Internal', 'Demo', 'Customer'
   */
  kind?: ProductKind;
  dealCode?: string;
  amount?: number;
  createdAt?: string;
  lastModified?: string;
  tenantId?: number;
  /**
   * Possible values include: 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
   * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
   * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
   * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon',
   * 'ClassificationAddon', 'SyncLocationAddon', 'OrgaTechClassicModule', 'OrgaTechModule',
   * 'SyncReceiptsClassicAddon', 'SyncContactsClassicAddon', 'SyncDriveClassicAddon',
   * 'SyncReceiptsAddon', 'SyncContactsAddon', 'SyncDriveAddon', 'NotificationClassicAddon',
   * 'NotificationAddon', 'TeamModule', 'MdmClassicModule', 'PrimeReportAddon',
   * 'MaintenanceReportAddon'
   */
  edition?: ProductEditions;
}

/**
 * An interface representing ProductSubscriptionListDto.
 */
export interface ProductSubscriptionListDto {
  productSubscriptionId?: number;
  expiresIn?: string;
  createdAt?: string;
  lastModified?: string;
  dealCode?: string;
  amount?: number;
  /**
   * Possible values include: 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
   * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
   * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
   * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon',
   * 'ClassificationAddon', 'SyncLocationAddon', 'OrgaTechClassicModule', 'OrgaTechModule',
   * 'SyncReceiptsClassicAddon', 'SyncContactsClassicAddon', 'SyncDriveClassicAddon',
   * 'SyncReceiptsAddon', 'SyncContactsAddon', 'SyncDriveAddon', 'NotificationClassicAddon',
   * 'NotificationAddon', 'TeamModule', 'MdmClassicModule', 'PrimeReportAddon',
   * 'MaintenanceReportAddon'
   */
  edition?: ProductEditions;
  /**
   * Possible values include: 'Internal', 'Demo', 'Customer'
   */
  kind?: ProductKind;
  tenantId?: number;
}

/**
 * An interface representing PublishIssueQueueDto.
 */
export interface PublishIssueQueueDto {
  issueId?: number;
}

/**
 * An interface representing PublishIssuesEmailDto.
 */
export interface PublishIssuesEmailDto {
  description?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  subject?: string;
}

/**
 * An interface representing PurgedInventoryNumberPatternDto.
 */
export interface PurgedInventoryNumberPatternDto {
  count?: number;
}

/**
 * An interface representing PushNotificationQueueDto.
 */
export interface PushNotificationQueueDto {
  receiverIdentifier?: string;
  /**
   * Possible values include: 'iOS', 'Android'
   */
  platform?: Platforms;
  notification?: INotificationDto;
}

/**
 * An interface representing PushRegisterDto.
 */
export interface PushRegisterDto {
  token?: string;
  hardwareId?: string;
  /**
   * Possible values include: 'iOS', 'Android'
   */
  platform?: Platforms;
}

/**
 * An interface representing PushStartedMissionQueueDto.
 */
export interface PushStartedMissionQueueDto {
  missionId?: number;
}

/**
 * An interface representing PushedInfoNotificationDto.
 */
export interface PushedInfoNotificationDto {
  infoId?: number;
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
}

/**
 * An interface representing PushedInfoQueueDto.
 */
export interface PushedInfoQueueDto {
  infoId?: number;
  culture?: string;
}

/**
 * An interface representing PushedStartedMissionNotificationDto.
 */
export interface PushedStartedMissionNotificationDto {
  missionId?: number;
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
}

/**
 * An interface representing QuickAccessKnowledgeConfigurationDto.
 */
export interface QuickAccessKnowledgeConfigurationDto {
  quickAccessKnowledgeConfigurationId?: number;
  /**
   * Possible values include: 'None', 'Own', 'Explicit'
   */
  visibility?: QuickAccessKnowledgeVisibility;
  knowledgeArticles?: KnowledgeArticleSimpleDto[];
}

/**
 * An interface representing QuickAccessIssueTemplateConfigurationDto.
 */
export interface QuickAccessIssueTemplateConfigurationDto {
  quickAccessIssueTemplateConfigurationId?: number;
  /**
   * Possible values include: 'None', 'Own', 'Explicit'
   */
  visibility?: QuickAccessIssueTemplateVisibility;
  /**
   * Possible values include: 'Recent', 'Hide'
   */
  historyVisibility?: QuickAccessIssueHistoryVisibility;
  issueTemplates?: IssueTemplateSimpleDto[];
}

/**
 * An interface representing QuickAccessDashboardConfigurationDto.
 */
export interface QuickAccessDashboardConfigurationDto {
  quickAccessDashboardConfigurationId?: number;
  /**
   * Possible values include: 'None', 'Own'
   */
  visibility?: QuickAccessDashboardVisibility;
}

/**
 * An interface representing QuickAccessConfigurationDto.
 */
export interface QuickAccessConfigurationDto {
  quickAccessConfigurationId?: number;
  isActivated?: boolean;
  isShowingProperties?: boolean;
  knowledgeConfiguration?: QuickAccessKnowledgeConfigurationDto;
  issueTemplateConfiguration?: QuickAccessIssueTemplateConfigurationDto;
  dashboardConfiguration?: QuickAccessDashboardConfigurationDto;
}

/**
 * An interface representing QuickAccessConfigurationSimpleListDto.
 */
export interface QuickAccessConfigurationSimpleListDto {
  quickAccessConfigurationId?: number;
  isActivated?: boolean;
}

/**
 * An interface representing QuickAccessDto.
 */
export interface QuickAccessDto {
  link?: string;
  key?: string;
}

/**
 * An interface representing ReachedConsumptionGenerateIssueReasonDto.
 */
export interface ReachedConsumptionGenerateIssueReasonDto {
  eventId?: string;
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  consumptionCodeId?: number;
  triggerValue?: string;
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange'
   */
  triggerType?: ControlTriggerType;
  lowerLimit?: number;
  upperLimit?: number;
  limit?: number;
}

/**
 * An interface representing ReachedConsumptionIssueReasonDto.
 */
export interface ReachedConsumptionIssueReasonDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  consumptionCode?: ConsumptionCodeSimpleDto;
  triggerValue?: string;
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange'
   */
  triggerType?: ControlTriggerType;
  lowerLimit?: number;
  upperLimit?: number;
  limit?: number;
}

/**
 * An interface representing ReachedConsumptionIssueReasonProtocolListDto.
 */
export interface ReachedConsumptionIssueReasonProtocolListDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  consumptionCode?: ConsumptionCodeSimpleDto;
  triggerValue?: string;
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange'
   */
  triggerType?: ControlTriggerType;
  lowerLimit?: number;
  upperLimit?: number;
  limit?: number;
}

/**
 * An interface representing ReachedErrorGenerateIssueReasonDto.
 */
export interface ReachedErrorGenerateIssueReasonDto {
  eventId?: string;
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  errorCodeId?: number;
}

/**
 * An interface representing ReachedErrorIssueReasonDto.
 */
export interface ReachedErrorIssueReasonDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  errorCode?: ErrorCodeSimpleDto;
}

/**
 * An interface representing ReachedErrorIssueReasonProtocolListDto.
 */
export interface ReachedErrorIssueReasonProtocolListDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  errorCode?: ErrorCodeSimpleDto;
}

/**
 * An interface representing ReachedFixedTimeGenerateIssueReasonDto.
 */
export interface ReachedFixedTimeGenerateIssueReasonDto {
  eventId?: string;
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  frequency?: string;
}

/**
 * An interface representing ReachedFixedTimeIssueReasonDto.
 */
export interface ReachedFixedTimeIssueReasonDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  frequency?: string;
}

/**
 * An interface representing ReachedFixedTimeIssueReasonProtocolListDto.
 */
export interface ReachedFixedTimeIssueReasonProtocolListDto {
  /**
   * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
   * 'ReceivedMail'
   */
  type?: IssueReasonType;
  frequency?: string;
}

/**
 * An interface representing ReceiptImportDto.
 */
export interface ReceiptImportDto {
  index?: number;
  missionId?: number;
  title?: string;
  bookedAt?: string;
  debit?: number;
  credit?: number;
  taxInPercent?: number;
}

/**
 * An interface representing ReceivedIssueQueueDto.
 */
export interface ReceivedIssueQueueDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
}

/**
 * An interface representing ReevaluateAccessQueueDto.
 */
export interface ReevaluateAccessQueueDto {
  entityId?: number;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
}

/**
 * An interface representing ReevaluateAccessChunkQueueDto.
 */
export interface ReevaluateAccessChunkQueueDto {
  items?: ReevaluateAccessQueueDto[];
}

/**
 * An interface representing RefreshTokenDto.
 */
export interface RefreshTokenDto {
  meta?: MetaEntryDto;
  refreshToken?: string;
  roleIds?: number[];
  locationIds?: number[];
}

/**
 * An interface representing RefreshTokenV3Dto.
 */
export interface RefreshTokenV3Dto {
  meta?: MetaEntryDto;
  refreshToken?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Portal', 'Tech', 'Orga', 'Team', 'Link'
   */
  client?: Clients;
}

/**
 * An interface representing RefreshWinterhalterProductOrderQueueDto.
 */
export interface RefreshWinterhalterProductOrderQueueDto {
  issueId?: number;
}

/**
 * An interface representing RefreshWinterhalterServiceCallQueueDto.
 */
export interface RefreshWinterhalterServiceCallQueueDto {
  issueId?: number;
}

/**
 * An interface representing RegisterDeviceByLocationDto.
 */
export interface RegisterDeviceByLocationDto {
  hardwareId?: string;
  deviceName?: string;
  locationId?: number;
}

/**
 * An interface representing RegisterDeviceDto.
 */
export interface RegisterDeviceDto {
  hardwareId?: string;
  code?: string;
}

/**
 * An interface representing RegisterUserDto.
 */
export interface RegisterUserDto {
  pinFromEmail?: string;
  passwordBase64?: string;
  email?: string;
}

/**
 * An interface representing RegistrationEmailDto.
 */
export interface RegistrationEmailDto {
  code?: string;
  tenantName?: string;
  domain?: string;
  senderName?: string;
  brandName?: string;
  subject?: string;
}

/**
 * An interface representing RemoveLabelDto.
 */
export interface RemoveLabelDto {
  entityId?: number;
  /**
   * Possible values include: 'Base', 'Ticket', 'Mission'
   */
  type?: LabelDescriptorType;
}

/**
 * An interface representing RenderDocumentTemplateDto.
 */
export interface RenderDocumentTemplateDto {
  templateId?: number;
}

/**
 * An interface representing TextTemplateContextDto.
 */
export interface TextTemplateContextDto {
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  type?: TemplateContextType;
}

/**
 * An interface representing RenderTemplateDto.
 */
export interface RenderTemplateDto {
  context?: TextTemplateContextDto;
}

/**
 * An interface representing RenderedTemplateDto.
 */
export interface RenderedTemplateDto {
  content?: string;
}

/**
 * An interface representing RepairCacheFeatureQueueDto.
 */
export interface RepairCacheFeatureQueueDto {
  tenantId?: number;
  feature?: string;
}

/**
 * An interface representing RepeatableFormRowCreateDto.
 */
export interface RepeatableFormRowCreateDto {
  formRowId?: number;
  title?: string;
  position?: number;
  /**
   * Possible values include: 'Base', 'Repeatable', 'Single'
   */
  type?: FormRowTypes;
  fields?: FormFieldCreateDto[];
  maxRepeat?: number;
  minRepeat?: number;
  repeatLabel?: string;
}

/**
 * An interface representing ReportDataTableDto.
 */
export interface ReportDataTableDto {
  totalItems?: number;
  tenantId?: number;
  timezone?: string;
  culture?: string;
  /**
   * Possible values include: 'ContactControllerList', 'TicketControllerList',
   * 'ResourceControllerList', 'MissionControllerList', 'ContractControllerList',
   * 'LocationControllerList', 'CompanyControllerList', 'LocationGroupControllerList',
   * 'AddressControllerList', 'ArticlesControllerList', 'ResourceTaskControllerList',
   * 'InfoControllerList', 'KnowledgeArticleControllerList', 'TaskControllerList',
   * 'TicketsInResourcesControllerList', 'MissionsInResourcesControllerList',
   * 'TicketInLocationControllerId', 'ResourceManufacturerControllerId',
   * 'ResourceModelControllerId', 'IssueControllerId', 'ConsumptionHistoryForResourceList',
   * 'ErrorHistoryForResourceList', 'HintHistoryForResourceList',
   * 'ResourceModelInManufacturerControllerId', 'InfoProgressContactControllerId',
   * 'InfoProgressFunctionControllerId', 'InfoProgressLocationControllerId',
   * 'KnowledgePathControllerList', 'KnowledgePathProgressContactControllerId',
   * 'KnowledgePathProgressFunctionControllerId', 'KnowledgePathProgressLocationControllerId',
   * 'OccuredConsumptionProtocol', 'OccuredErrorProtocol', 'MessageIssueControllerId',
   * 'TicketIssueControllerId', 'MissionIssueControllerId',
   * 'WinterhalterServiceCallIssueControllerId', 'WinterhalterProductOrderIssueControllerId',
   * 'MessageControlControllerId'
   */
  pageOperation?: ControllerOperationId;
  descendantId?: number;
  /**
   * Possible values include: 'Csv'
   */
  fileFormat?: FileFormat;
  destination?: CommonDestinationDto;
  columns?: CommonColumnDto[];
  filters?: CommonFilterDto[];
}

/**
 * An interface representing ReportDataTableQueueDto.
 */
export interface ReportDataTableQueueDto {
  content?: ReportDataTableDto;
  token?: any;
}

/**
 * An interface representing RequestPasswordResetEmailDto.
 */
export interface RequestPasswordResetEmailDto {
  email?: string;
  /**
   * Possible values include: 'Nexnox', 'Semco', 'Pilona', 'Winterhalter'
   */
  brand?: Brands;
}

/**
 * An interface representing RequestUserRegistrationDto.
 */
export interface RequestUserRegistrationDto {
  email?: string;
  /**
   * Possible values include: 'Nexnox', 'Semco', 'Pilona', 'Winterhalter'
   */
  brand?: Brands;
  culture?: string;
  timeZone?: string;
}

/**
 * An interface representing ResetPasswordDto.
 */
export interface ResetPasswordDto {
  token?: string;
  newPassword?: string;
  newPasswordBase64?: string;
  email?: string;
}

/**
 * An interface representing ResourceManufacturerSimpleDto.
 */
export interface ResourceManufacturerSimpleDto {
  resourceManufacturerId?: number;
  name?: string;
}

/**
 * An interface representing ResourceModelSimpleDto.
 */
export interface ResourceModelSimpleDto {
  resourceModelId?: number;
  name?: string;
}

/**
 * An interface representing ResourceDto.
 */
export interface ResourceDto {
  resourceId?: number;
  name?: string;
  externalId?: string;
  parent?: ResourceSimpleDto;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  currentState?: ResourceState;
  serialNumber?: string;
  installationDate?: string;
  inventoryNumber?: string;
  inventoryNumberCount?: number;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  location?: LocationSimpleDto;
  locationGroup?: LocationGroupSimpleDto;
  inheritsManufacturer?: boolean;
  manufacturer?: ResourceManufacturerSimpleDto;
  inheritsModel?: boolean;
  model?: ResourceModelSimpleDto;
  isInProgressSince?: string;
  hasChildren?: boolean;
  tenantId?: number;
  path?: ResourcePathPartDto[];
  rowVersion?: number[];
  stereotypeId?: number;
  isArchived?: boolean;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  inheritsQuickAccessConfiguration?: boolean;
  quickAccessConfiguration?: QuickAccessConfigurationDto;
  quickAccess?: QuickAccessDto;
  inheritsSuggestions?: boolean;
  primarySuggestions?: PrimaryEditorSuggestionDto[];
  alternativeSuggestions?: AlternativeEditorSuggestionDto[];
  inheritsKnowledge?: boolean;
  knowledgeArticles?: KnowledgeArticleSimpleDto[];
  inheritsIssueTemplate?: boolean;
  issueTemplates?: IssueTemplateSimpleDto[];
  rules?: EscalationRuleDto[];
  attachments?: AttachmentForTechDto[];
  links?: LinkDto[];
  safe?: SafeDto;
  lastModified?: string;
  createdAt?: string;
}

/**
 * An interface representing ResourceEventListDto.
 */
export interface ResourceEventListDto {
  resourceEventId?: number;
  /**
   * Possible values include: 'Base', 'ChangedState', 'ChangedLocation'
   */
  type?: ResourceEventType;
  tenantId?: number;
  lastModified?: string;
  createdAt?: string;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  oldState?: ResourceState;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  newState?: ResourceState;
  oldLocation?: LocationSimpleDto;
  newLocation?: LocationSimpleDto;
}

/**
 * An interface representing ResourceImportDto.
 */
export interface ResourceImportDto {
  index?: number;
  externalId?: any;
  name?: string;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  serialNumber?: string;
  inventoryNumber?: string;
  inheritsManufacturer?: boolean;
  manufacturer?: string;
  inheritsModel?: boolean;
  model?: string;
  installationDate?: string;
  stereotypeId?: any;
  parentId?: any;
  locationId?: any;
  locationGroupId?: any;
  customValues?: any[];
}

/**
 * An interface representing ResourceInTicketDto.
 */
export interface ResourceInTicketDto {
  resourceId?: number;
  name?: string;
  locationId?: number;
  location?: LocationSimpleDto;
  path?: ResourcePathPartDto[];
  inventoryNumber?: string;
  tenantId?: number;
  hasChildren?: boolean;
}

/**
 * An interface representing ResourceInTicketListDto.
 */
export interface ResourceInTicketListDto {
  resourceId?: number;
  name?: string;
  location?: LocationListDto;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  currentState?: ResourceState;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  path?: ResourcePathPartSimpleListDto[];
  isArchived?: boolean;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing ResourceQuickAccessListDto.
 */
export interface ResourceQuickAccessListDto {
  link?: string;
}

/**
 * An interface representing ResourceListDto.
 */
export interface ResourceListDto {
  resourceId?: number;
  name?: string;
  installationDate?: string;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  currentState?: ResourceState;
  parentId?: number;
  locationId?: number;
  inventoryNumberCount?: number;
  inventoryNumber?: string;
  serialNumber?: string;
  isInProgressSince?: string;
  externalId?: string;
  manufacturer?: ResourceManufacturerSimpleListDto;
  model?: ResourceModelSimpleListDto;
  quickAccess?: ResourceQuickAccessListDto;
  quickAccessConfiguration?: QuickAccessConfigurationSimpleListDto;
  location?: LocationListForResourcesDto;
  locationGroup?: LocationGroupSimpleListDto;
  path?: ResourcePathPartSimpleListDto[];
  tenantId?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  hasChildren?: boolean;
}

/**
 * An interface representing ResourceLocationProfileDto.
 */
export interface ResourceLocationProfileDto {
  locationId?: number;
  name?: string;
  externalId?: string;
  locationNumber?: string;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
}

/**
 * An interface representing ResourceManufacturerDto.
 */
export interface ResourceManufacturerDto {
  resourceManufacturerId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing ResourceManufacturerImportDto.
 */
export interface ResourceManufacturerImportDto {
  index?: number;
  manufacturer?: string;
  model?: string;
}

/**
 * An interface representing ResourceManufacturerListDto.
 */
export interface ResourceManufacturerListDto {
  resourceManufacturerId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing ResourceManufacturerMergeDto.
 */
export interface ResourceManufacturerMergeDto {
  newManufacturerId?: number;
}

/**
 * An interface representing ResourceModelDto.
 */
export interface ResourceModelDto {
  resourceModelId?: number;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing ResourceModelListDto.
 */
export interface ResourceModelListDto {
  resourceModelId?: number;
  name?: string;
  resourceManufacturerName?: string;
  resourceManufacturer?: ResourceManufacturerSimpleListDto;
  tenantId?: number;
}

/**
 * An interface representing ResourceModelMergeDto.
 */
export interface ResourceModelMergeDto {
  newModelId?: number;
}

/**
 * An interface representing ResourceModelMoveDto.
 */
export interface ResourceModelMoveDto {
  newManufacturerId?: number;
}

/**
 * An interface representing ResourceProfileConsumptionDto.
 */
export interface ResourceProfileConsumptionDto {
  consumptionId?: number;
  displayName?: string;
  description?: string;
  code?: string;
  value?: string;
  unit?: string;
}

/**
 * An interface representing ResourceProfileHintDto.
 */
export interface ResourceProfileHintDto {
  hintId?: number;
  displayName?: string;
  code?: string;
  description?: string;
  /**
   * Possible values include: 'None', 'Warning', 'Error', 'Information'
   */
  level?: HintLevel;
}

/**
 * An interface representing ResourceProfileErrorDto.
 */
export interface ResourceProfileErrorDto {
  errorId?: number;
  displayName?: string;
  code?: string;
  description?: string;
}

/**
 * An interface representing ResourceProfileStatisticsDto.
 */
export interface ResourceProfileStatisticsDto {
  lifeTime?: string;
  lifeTimeMonths?: number;
  totalMissions?: number;
  missionPerMonth?: number;
  totalCost?: number;
  averageCost?: number;
  costsPerMonth?: number;
  totalWorkHours?: number;
  workPerMission?: number;
  workPerMonth?: number;
  totalFaultyHours?: number;
  availabilityInPercent?: number;
  faultyHoursPerMission?: number;
  faultyHoursPerMonth?: number;
}

/**
 * An interface representing ResourceProfileDashboardDto.
 */
export interface ResourceProfileDashboardDto {
  consumptions?: ResourceProfileConsumptionDto[];
  hints?: ResourceProfileHintDto[];
  errors?: ResourceProfileErrorDto[];
  statistics?: ResourceProfileStatisticsDto;
}

/**
 * An interface representing ResourceProfileDto.
 */
export interface ResourceProfileDto {
  resourceId?: number;
  name?: string;
  inventoryNumber?: string;
  installationDate?: string;
  manufacturer?: ResourceManufacturerSimpleDto;
  model?: ResourceModelSimpleDto;
  parent?: ResourceSimpleDto;
  location?: ResourceLocationProfileDto;
  stereotype?: StereotypeUsageDto;
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing ResourceProfileIssueTemplateShortCutDto.
 */
export interface ResourceProfileIssueTemplateShortCutDto {
  issueTemplateId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
}

/**
 * An interface representing ResourceProfileFeatureDto.
 */
export interface ResourceProfileFeatureDto {
  isShowingProperties?: boolean;
  isIssueTemplateVisible?: boolean;
  isIssueHistoryVisible?: boolean;
  issueTemplateShortcutId?: number;
  issueTemplateShortcut?: ResourceProfileIssueTemplateShortCutDto;
  isKnowledgeVisible?: boolean;
  knowledgeShortcutId?: number;
  isDashboardVisible?: boolean;
}

/**
 * An interface representing ResourceProfileKnowledgeDto.
 */
export interface ResourceProfileKnowledgeDto {
  knowledgeArticles?: KnowledgeArticleSimpleDto[];
}

/**
 * An interface representing ResourceProfileOverviewDto.
 */
export interface ResourceProfileOverviewDto {
  resource?: ResourceProfileDto;
  features?: ResourceProfileFeatureDto;
}

/**
 * An interface representing ResourceSafeRequestDto.
 */
export interface ResourceSafeRequestDto {
  contacts?: ContactSimpleDto[];
}

/**
 * An interface representing ResourceSettingDto.
 */
export interface ResourceSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: number;
  resource?: ResourceSimpleDto;
}

/**
 * An interface representing ResourceSkeletonDto.
 */
export interface ResourceSkeletonDto {
  resourceSkeletonId?: number;
  name?: string;
  stereotype: StereotypeSimpleDto;
  parent?: ResourceSimpleDto;
  tenantId?: number;
}

/**
 * An interface representing ResourceStereotypeUsageDto.
 */
export interface ResourceStereotypeUsageDto {
  stereotypeId?: number;
  name?: string;
  tenantId?: number;
  isDefault?: boolean;
  externalId?: string;
  position?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  customPropertySets?: CustomSetReferenceDto[];
}

/**
 * An interface representing ResourceTaskConsumptionTriggerDto.
 */
export interface ResourceTaskConsumptionTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange', 'LookBack'
   */
  type?: ResourceTaskConsumptionTriggerType;
}

/**
 * An interface representing ResourceTaskConsumptionScheduleDto.
 */
export interface ResourceTaskConsumptionScheduleDto {
  resourceTaskConditionId?: number;
  /**
   * Possible values include: 'Base', 'Relative', 'Fixed', 'Consumption', 'Error'
   */
  type?: ResourceTaskScheduleType;
  tenantId?: number;
  consumption?: ConsumptionCodeSimpleDto;
  trigger?: ResourceTaskConsumptionTriggerDto;
}

/**
 * An interface representing ResourceTaskDtm.
 */
export interface ResourceTaskDtm {
  resourceTaskId?: number;
  title?: string;
}

/**
 * An interface representing ResourceTaskSelectorDto.
 */
export interface ResourceTaskSelectorDto {
  resourceTaskSelectorId?: number;
  view?: DataTableDto;
  tenantId?: number;
}

/**
 * An interface representing ResourceTaskPatternDto.
 */
export interface ResourceTaskPatternDto {
  resourceTaskPatternId?: number;
  /**
   * Possible values include: 'Base', 'Offset'
   */
  type?: ResourceTaskPatternType;
  tenantId?: number;
}

/**
 * An interface representing ResourceTaskScheduleDto.
 */
export interface ResourceTaskScheduleDto {
  resourceTaskConditionId?: number;
  /**
   * Possible values include: 'Base', 'Relative', 'Fixed', 'Consumption', 'Error'
   */
  type?: ResourceTaskScheduleType;
  tenantId?: number;
}

/**
 * An interface representing ResourceTaskDto.
 */
export interface ResourceTaskDto {
  resourceTaskId?: number;
  title?: string;
  endOffset?: string;
  endDate?: string;
  startOffSet?: string;
  startDate?: string;
  isCancelingObsoleteMissions?: boolean;
  isAutoInstructing?: boolean;
  isActivated?: boolean;
  isArchived?: boolean;
  tenantId?: number;
  missionDescription?: TemplateSimpleDto;
  missionStereotype?: StereotypeSimpleDto;
  priority?: PrioritySimpleDto;
  selector?: ResourceTaskSelectorDto;
  pattern?: ResourceTaskPatternDto;
  schedule?: ResourceTaskScheduleDto;
}

/**
 * An interface representing ResourceTaskErrorScheduleDto.
 */
export interface ResourceTaskErrorScheduleDto {
  resourceTaskConditionId?: number;
  /**
   * Possible values include: 'Base', 'Relative', 'Fixed', 'Consumption', 'Error'
   */
  type?: ResourceTaskScheduleType;
  tenantId?: number;
  error?: ErrorCodeSimpleDto;
}

/**
 * An interface representing ResourceTaskFixedScheduleDto.
 */
export interface ResourceTaskFixedScheduleDto {
  resourceTaskConditionId?: number;
  /**
   * Possible values include: 'Base', 'Relative', 'Fixed', 'Consumption', 'Error'
   */
  type?: ResourceTaskScheduleType;
  tenantId?: number;
  nextOccurrence?: string;
  timeOfOccurrence?: string;
}

/**
 * An interface representing ResourceTaskListDto.
 */
export interface ResourceTaskListDto {
  resourceTaskId?: number;
  title?: string;
  endOffset?: string;
  endDate?: string;
  startOffSet?: string;
  startDate?: string;
  tenantId?: number;
  isActivated?: boolean;
  isArchived?: boolean;
  isCancelingObsoleteMissions?: boolean;
}

/**
 * An interface representing ResourceTaskOffsetPatternDto.
 */
export interface ResourceTaskOffsetPatternDto {
  resourceTaskPatternId?: number;
  /**
   * Possible values include: 'Base', 'Offset'
   */
  type?: ResourceTaskPatternType;
  tenantId?: number;
  offset?: string;
}

/**
 * An interface representing ResourceTaskRelativeScheduleDto.
 */
export interface ResourceTaskRelativeScheduleDto {
  resourceTaskConditionId?: number;
  /**
   * Possible values include: 'Base', 'Relative', 'Fixed', 'Consumption', 'Error'
   */
  type?: ResourceTaskScheduleType;
  tenantId?: number;
  timeOfOccurrence?: string;
}

/**
 * An interface representing ResourceTicketSkeletonSelectorDto.
 */
export interface ResourceTicketSkeletonSelectorDto {
  id?: number;
  /**
   * Possible values include: 'Base', 'All', 'RestrictedByType', 'Whitelisted', 'Fixed'
   */
  type?: ResourceTicketSkeletonSelectorType;
}

/**
 * An interface representing ResourceTreeListDto.
 */
export interface ResourceTreeListDto {
  resourceId?: number;
  name?: string;
  parentId?: number;
  /**
   * Possible values include: 'Operation', 'Maintenance', 'Retired'
   */
  currentState?: ResourceState;
  /**
   * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
   */
  type?: ResourceType;
  isInProgressSince?: string;
  tenantId?: number;
  inventoryNumber?: string;
  path?: ResourcePathPartSimpleListDto[];
  location?: LocationSimpleListDto;
  hasChildren?: boolean;
  stereotypeId?: number;
}

/**
 * An interface representing ResourcesByLocationDefinitionDto.
 */
export interface ResourcesByLocationDefinitionDto {
  resourcesByLocationDefinitionId?: number;
  stereotypeId?: number;
  title?: string;
  tenantId?: number;
  resources?: ResourceSkeletonDto[];
}

/**
 * An interface representing ResourcesByLocationDefinitionListDto.
 */
export interface ResourcesByLocationDefinitionListDto {
  resourcesByLocationDefinitionId?: number;
  title?: string;
  tenantId?: number;
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing ResourcesByLocationDefinitionSimpleDto.
 */
export interface ResourcesByLocationDefinitionSimpleDto {
  resourcesByLocationDefinitionId?: number;
  title?: string;
}

/**
 * An interface representing RestartConnectedMissionQueueDto.
 */
export interface RestartConnectedMissionQueueDto {
  missionId?: number;
}

/**
 * An interface representing RestartRelativeMissionByTaskQueueDto.
 */
export interface RestartRelativeMissionByTaskQueueDto {
  missionId?: number;
}

/**
 * An interface representing RestrictedByTypeResourceTicketSkeletonSelectorDto.
 */
export interface RestrictedByTypeResourceTicketSkeletonSelectorDto {
  id?: number;
  /**
   * Possible values include: 'Base', 'All', 'RestrictedByType', 'Whitelisted', 'Fixed'
   */
  type?: ResourceTicketSkeletonSelectorType;
  stereotypes?: StereotypeSimpleDto[];
}

/**
 * An interface representing SanctionImpactCreateV1Dto.
 */
export interface SanctionImpactCreateV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  taskId?: number;
  sanctionId?: number;
  sanctionOffset?: string;
}

/**
 * An interface representing TaskAsSanctionDto.
 */
export interface TaskAsSanctionDto {
  taskId?: number;
  title?: string;
}

/**
 * An interface representing SanctionImpactDto.
 */
export interface SanctionImpactDto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionDto;
  sanction?: TaskAsSanctionDto;
  sanctionOffset?: string;
}

/**
 * An interface representing SanctionImpactV1Dto.
 */
export interface SanctionImpactV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  sanction?: TaskSimpleV1Dto;
  sanctionId?: number;
  sanctionOffset?: string;
}

/**
 * An interface representing SearchListDto.
 */
export interface SearchListDto {
  searchListId?: number;
  title?: string;
  tenantId?: number;
  items?: string[];
  isArchived?: boolean;
}

/**
 * An interface representing SearchListFormDefaultDto.
 */
export interface SearchListFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing SearchListSimpleDto.
 */
export interface SearchListSimpleDto {
  searchListId?: number;
  title?: string;
  isArchived?: boolean;
}

/**
 * An interface representing SearchListFormFieldDto.
 */
export interface SearchListFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  searchListId?: number;
  searchList?: SearchListSimpleDto;
}

/**
 * An interface representing SearchListFormValueDto.
 */
export interface SearchListFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing SearchListListDto.
 */
export interface SearchListListDto {
  searchListId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing SearchWeight.
 */
export interface SearchWeight {
  weight?: number;
  property?: string;
}

/**
 * An interface representing SecuredResourceQueueDto.
 */
export interface SecuredResourceQueueDto {
  resourceId?: number;
}

/**
 * An interface representing SelectResourcesByControlQueueDto.
 */
export interface SelectResourcesByControlQueueDto {
  controlId?: number;
}

/**
 * An interface representing SelectResourcesByTaskQueueDto.
 */
export interface SelectResourcesByTaskQueueDto {
  taskId?: number;
}

/**
 * An interface representing SendChatMessageNoteQueueDto.
 */
export interface SendChatMessageNoteQueueDto {
  noteId?: number;
  culture?: string;
}

/**
 * An interface representing SendChatMessageToSolutionQueueDto.
 */
export interface SendChatMessageToSolutionQueueDto {
  chatMessageId?: number;
  isInitialMessage?: boolean;
}

/**
 * An interface representing SendGridMailDto.
 */
export interface SendGridMailDto {
  email?: string;
  subject?: string;
  envelope?: string;
  spf?: string;
  senderIp?: string;
  spamScore?: string;
  to?: string;
  from?: string;
  spamReport?: string;
  dkim?: string;
  charsets?: string;
}

/**
 * An interface representing SendGridStatusDto.
 */
export interface SendGridStatusDto {
  email?: string;
  event?: string;
  type?: string;
  reason?: string;
  smtpId?: string;
}

/**
 * An interface representing SendMailNoteQueueDto.
 */
export interface SendMailNoteQueueDto {
  noteId?: number;
}

/**
 * An interface representing SendMissionActivationEmailQueueDto.
 */
export interface SendMissionActivationEmailQueueDto {
  contactId?: number;
  missionId?: number;
  executedByUserId?: number;
}

/**
 * An interface representing SendMissionMailDto.
 */
export interface SendMissionMailDto {
  lastEmailSend?: string;
}

/**
 * An interface representing SendStateToChatQueueDto.
 */
export interface SendStateToChatQueueDto {
  ticketId?: number;
  stateId?: number;
}

/**
 * An interface representing SettingValueDto.
 */
export interface SettingValueDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
}

/**
 * An interface representing ShortContactDto.
 */
export interface ShortContactDto {
  contactId?: number;
  tenantId?: number;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  emailAddress?: string;
  password?: string;
}

/**
 * An interface representing SignatureFormDefaultDto.
 */
export interface SignatureFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
  file?: FileDto;
}

/**
 * An interface representing SignatureFormFieldDto.
 */
export interface SignatureFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
}

/**
 * An interface representing SignatureFormValueDto.
 */
export interface SignatureFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: number;
  file?: FileDto;
}

/**
 * An interface representing SimpleChatDto.
 */
export interface SimpleChatDto {
  chatId?: number;
  title?: string;
  isArchived?: boolean;
  externalId?: string;
}

/**
 * An interface representing SimpleIdDto.
 */
export interface SimpleIdDto {
  id?: number;
}

/**
 * An interface representing SingleDateTimePatternDto.
 */
export interface SingleDateTimePatternDto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeDto[];
  date?: string;
}

/**
 * An interface representing SingleDateTimePatternV1Dto.
 */
export interface SingleDateTimePatternV1Dto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeV1Dto[];
  date?: string;
}

/**
 * An interface representing SingleFormRowCreateDto.
 */
export interface SingleFormRowCreateDto {
  formRowId?: number;
  title?: string;
  position?: number;
  /**
   * Possible values include: 'Base', 'Repeatable', 'Single'
   */
  type?: FormRowTypes;
  fields?: FormFieldCreateDto[];
}

/**
 * An interface representing SolutionAttachmentDto.
 */
export interface SolutionAttachmentDto {
  solutionItemId?: number;
  member?: SolutionMemberDto;
  attachment?: AttachmentForTechDto;
}

/**
 * An interface representing SolutionAttachmentsDto.
 */
export interface SolutionAttachmentsDto {
  attachments?: SolutionAttachmentDto[];
}

/**
 * An interface representing SolutionDetailsDto.
 */
export interface SolutionDetailsDto {
  solutionId?: number;
  members?: SolutionMemberDto[];
  attachments?: AttachmentForTechDto[];
  threadId?: string;
  threadMailAddress?: string;
}

/**
 * An interface representing SolutionDto.
 */
export interface SolutionDto {
  solutionId?: number;
  tenantId?: number;
  members?: SolutionMemberDto[];
  attachments?: SolutionAttachmentDto[];
}

/**
 * An interface representing UserSimpleDto.
 */
export interface UserSimpleDto {
  id?: number;
  email?: string;
}

/**
 * An interface representing SolutionItemDto.
 */
export interface SolutionItemDto {
  solutionItemId?: number;
  tenantId?: number;
  solutionId?: number;
  lastModified?: string;
  createdAt?: string;
  ticketId?: number;
  missionId?: number;
  isSolution?: boolean;
  createdByUser?: UserSimpleDto;
  note?: NoteDto;
}

/**
 * An interface representing TicketSimpleListDto.
 */
export interface TicketSimpleListDto {
  ticketId?: number;
  title?: string;
  isArchived?: boolean;
}

/**
 * An interface representing SolutionItemListDto.
 */
export interface SolutionItemListDto {
  solutionItemId?: number;
  tenantId?: number;
  solutionId?: number;
  lastModified?: string;
  createdAt?: string;
  isSolution?: boolean;
  ticketId?: number;
  ticket?: TicketSimpleListDto;
  missionId?: number;
  mission?: MissionSimpleListDto;
  createdByName?: string;
  createdByUser?: UserSimpleListDto;
  note?: NoteCompleteDto;
}

/**
 * An interface representing SolutionMailInQueueDto.
 */
export interface SolutionMailInQueueDto {
  threadId?: string;
  isSuccess?: boolean;
  triggeredNodeId?: number;
  tenantId?: number;
  mail?: MailInDto;
}

/**
 * An interface representing StartSearchingEditorDto.
 */
export interface StartSearchingEditorDto {
  functionId?: number;
}

/**
 * An interface representing StartSearchingEditorQueueDto.
 */
export interface StartSearchingEditorQueueDto {
  missionId?: number;
  functionId?: number;
}

/**
 * An interface representing StartingMissionEditorDto.
 */
export interface StartingMissionEditorDto {
  /**
   * Possible values include: 'Contact', 'Function'
   */
  type?: StartingMissionEditorType;
}

/**
 * An interface representing StartingMissionByManualDto.
 */
export interface StartingMissionByManualDto {
  missionId?: number;
  title?: string;
  description?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  offsetByTemplate?: string;
  /**
   * Possible values include: 'Created', 'Instructed'
   */
  state?: MissionStartingState;
  editor?: StartingMissionEditorDto;
}

/**
 * An interface representing StartingMissionByTicketDto.
 */
export interface StartingMissionByTicketDto {
  missionId?: number;
  title?: string;
  description?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  offsetByTemplate?: string;
  /**
   * Possible values include: 'Created', 'Instructed'
   */
  state?: MissionStartingState;
  editor?: StartingMissionEditorDto;
  ticket?: TicketInMissionDto;
}

/**
 * An interface representing StartingMissionContactEditorDto.
 */
export interface StartingMissionContactEditorDto {
  /**
   * Possible values include: 'Contact', 'Function'
   */
  type?: StartingMissionEditorType;
  contact?: ContactSimpleDto;
}

/**
 * An interface representing StartingMissionDto.
 */
export interface StartingMissionDto {
  missionId?: number;
  title?: string;
  description?: string;
  actualStart?: string;
  actualEnd?: string;
  plannedStart?: string;
  plannedEnd?: string;
  /**
   * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
   */
  type?: MissionType;
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  invoiceAddress?: AddressSimpleDto;
  shippingAddress?: AddressSimpleDto;
  solutionId?: number;
  tenantId?: number;
  location?: LocationInMissionDto;
  resource?: ResourceInMissionDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  rowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  links?: LinkDto[];
  attachments?: AttachmentForTechDto[];
  offsetByTemplate?: string;
  /**
   * Possible values include: 'Created', 'Instructed'
   */
  state?: MissionStartingState;
  editor?: StartingMissionEditorDto;
}

/**
 * An interface representing StartingMissionFunctionEditorDto.
 */
export interface StartingMissionFunctionEditorDto {
  /**
   * Possible values include: 'Contact', 'Function'
   */
  type?: StartingMissionEditorType;
  functionProperty?: FunctionSimpleDto;
}

/**
 * An interface representing StateChangeRequestDto.
 */
export interface StateChangeRequestDto {
  ticketId?: number;
  stateId?: number;
  reason?: string;
}

/**
 * An interface representing StateDto.
 */
export interface StateDto {
  externalId?: string;
  stateId?: number;
  color?: string;
  name?: string;
  tenantId?: number;
}

/**
 * An interface representing StateExportDto.
 */
export interface StateExportDto {
  externalId?: string;
  color?: string;
  name?: string;
}

/**
 * An interface representing StateTransitionDto.
 */
export interface StateTransitionDto {
  stateTransitionId?: number;
  tenantId?: number;
  inStateId?: number;
  inState?: StateDto;
  outStateId?: number;
  outState?: StateDto;
}

/**
 * An interface representing StateMachineDto.
 */
export interface StateMachineDto {
  stateMachineId?: number;
  name?: string;
  externalId?: string;
  tenantId?: number;
  stateAfterReceivedChat?: StateSimpleDto;
  stateAfterRejectedMission?: StateSimpleDto;
  stateAfterCanceledMission?: StateSimpleDto;
  stateAfterLastMissionDone?: StateSimpleDto;
  stateAfterClosedMission?: StateSimpleDto;
  stateAfterReceivedEmail?: StateSimpleDto;
  transitions?: StateTransitionDto[];
}

/**
 * An interface representing StateSimpleExportDto.
 */
export interface StateSimpleExportDto {
  externalId?: string;
}

/**
 * An interface representing StateTransitionExportDto.
 */
export interface StateTransitionExportDto {
  inState?: StateExportDto;
  outState?: StateExportDto;
}

/**
 * An interface representing StateMachineExportDto.
 */
export interface StateMachineExportDto {
  externalId?: string;
  name?: string;
  stateAfterReceivedChat?: StateSimpleExportDto;
  stateAfterRejectedMission?: StateSimpleExportDto;
  stateAfterCanceledMission?: StateSimpleExportDto;
  stateAfterLastMissionDone?: StateSimpleExportDto;
  stateAfterClosedMission?: StateSimpleExportDto;
  stateAfterReceivedEmail?: StateSimpleExportDto;
  transitions?: StateTransitionExportDto[];
}

/**
 * An interface representing StateMachineListDto.
 */
export interface StateMachineListDto {
  stateMachineId?: number;
  name?: string;
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing StateMachineSimpleDto.
 */
export interface StateMachineSimpleDto {
  stateMachineId?: number;
  name?: string;
  externalId?: string;
}

/**
 * An interface representing StateMachineSimpleExportDto.
 */
export interface StateMachineSimpleExportDto {
  externalId?: string;
}

/**
 * An interface representing StateMachineSimpleListDto.
 */
export interface StateMachineSimpleListDto {
  stateMachineId?: number;
  name?: string;
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing StatePreviewRequestDto.
 */
export interface StatePreviewRequestDto {
  stateMachineId?: number;
  stateId?: number;
}

/**
 * An interface representing StatePreviewResponseDto.
 */
export interface StatePreviewResponseDto {
  nextStates?: StateSimpleDto[];
}

/**
 * An interface representing StateSimpleDtoPreSelectedReferenceDto.
 */
export interface StateSimpleDtoPreSelectedReferenceDto {
  isOverridable?: boolean;
  value?: StateSimpleDto;
}

/**
 * An interface representing StatusImpactCreateV1Dto.
 */
export interface StatusImpactCreateV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  taskId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
}

/**
 * An interface representing StatusImpactDto.
 */
export interface StatusImpactDto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionDto;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
}

/**
 * An interface representing StatusImpactV1Dto.
 */
export interface StatusImpactV1Dto {
  impactId?: number;
  /**
   * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
   */
  type?: ImpactTypes;
  condition?: ConditionV1Dto;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  targetState?: TaskJobState;
}

/**
 * An interface representing SteppedControlTriggerDto.
 */
export interface SteppedControlTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange'
   */
  type?: ControlTriggerType;
  limit?: number;
}

/**
 * An interface representing SteppedResourceTaskConsumptionTriggerDto.
 */
export interface SteppedResourceTaskConsumptionTriggerDto {
  /**
   * Possible values include: 'Base', 'Stepped', 'OutOfRange', 'LookBack'
   */
  type?: ResourceTaskConsumptionTriggerType;
  limit?: number;
}

/**
 * An interface representing TicketByContractDefinitionSimpleDto.
 */
export interface TicketByContractDefinitionSimpleDto {
  id?: number;
  definitionId?: number;
}

/**
 * An interface representing TicketsByLocationDefinitionSimpleDto.
 */
export interface TicketsByLocationDefinitionSimpleDto {
  ticketsByLocationDefinitionId?: number;
  title?: string;
}

/**
 * An interface representing StereotypeDto.
 */
export interface StereotypeDto {
  stereotypeId?: number;
  name?: string;
  tenantId?: number;
  isDefault?: boolean;
  externalId?: string;
  position?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  parentId?: number;
  parent?: StereotypeSimpleDto;
  hasChildren?: boolean;
  ticketByContractDefinition?: TicketByContractDefinitionSimpleDto;
  inventoryNumberPattern?: InventoryNumberPatternSimpleDto;
  prioritySet?: PrioritySetSimpleDto;
  stateMachine?: StateMachineSimpleDto;
  missionByTaskActivationTemplate?: DocumentTemplateSimpleDto;
  missionByTicketActivationTemplate?: DocumentTemplateSimpleDto;
  missionByManualActivationTemplate?: DocumentTemplateSimpleDto;
  ticketRestrictions?: StereotypeSimpleDto[];
  resourcesByLocationDefinitions?: ResourcesByLocationDefinitionSimpleDto[];
  ticketsByLocationDefinitions?: TicketsByLocationDefinitionSimpleDto[];
  customPropertySets?: CustomSetReferenceDto[];
}

/**
 * An interface representing StereotypeExportDto.
 */
export interface StereotypeExportDto {
  name?: string;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  externalId?: string;
  position?: number;
  parent?: StereotypeSimpleExportDto;
  prioritySet?: PrioritySetSimpleExportDto;
  inventoryNumberPattern?: InventoryNumberPatternSimpleExportDto;
  missionByTaskActivationTemplate?: DocumentTemplateSimpleExportDto;
  missionByTicketActivationTemplate?: DocumentTemplateSimpleExportDto;
  missionByManualActivationTemplate?: DocumentTemplateSimpleExportDto;
  ticketRestrictions?: StereotypeSimpleExportDto[];
  customPropertySets?: CustomSetReferenceExportDto[];
  isDefault?: boolean;
  stateMachine?: StateMachineSimpleExportDto;
}

/**
 * An interface representing StereotypeListDto.
 */
export interface StereotypeListDto {
  stereotypeId?: number;
  name?: string;
  position?: number;
  tenantId?: number;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  hasChildren?: boolean;
  isDefault?: boolean;
  isArchived?: boolean;
  externalId?: string;
  inventoryNumberPattern?: InventoryNumberPatternSimpleListDto;
  stateMachineId?: number;
  stateMachine?: StateMachineListDto;
  prioritySetId?: number;
  prioritySet?: PrioritySetListDto;
  missionRestrictions?: StereotypeSimpleListDto[];
  customPropertySets?: CustomSetReferenceListDto[];
}

/**
 * An interface representing StereotypeSettingDto.
 */
export interface StereotypeSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: number;
  stereotype?: StereotypeSimpleDto;
}

/**
 * An interface representing StereotypeSimpleDtoPreSelectedReferenceDto.
 */
export interface StereotypeSimpleDtoPreSelectedReferenceDto {
  isOverridable?: boolean;
  value?: StereotypeSimpleDto;
}

/**
 * An interface representing StereotypedShadowDto.
 */
export interface StereotypedShadowDto {
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing StringPreSelectedDto.
 */
export interface StringPreSelectedDto {
  isOverridable?: boolean;
  value?: string;
}

/**
 * An interface representing SubmitMessageIssueDto.
 */
export interface SubmitMessageIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing SubmitMissionIssueDto.
 */
export interface SubmitMissionIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  resource?: ResourceSimpleDto;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing SubmitTicketIssueDto.
 */
export interface SubmitTicketIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  resource?: ResourceSimpleDto;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing SubmitUserIssueDto.
 */
export interface SubmitUserIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing SubmitWinterhalterProductDto.
 */
export interface SubmitWinterhalterProductDto {
  productId?: number;
  quantity?: number;
}

/**
 * An interface representing SubmitWinterhalterProductOrderIssueDto.
 */
export interface SubmitWinterhalterProductOrderIssueDto {
  issueId?: number;
  tenantId?: number;
  title?: string;
  resource?: ResourceSimpleDto;
  products?: SubmitWinterhalterProductDto[];
}

/**
 * An interface representing SubmitWinterhalterServiceCallIssueDto.
 */
export interface SubmitWinterhalterServiceCallIssueDto {
  issueId?: number;
  tenantId?: number;
  title?: string;
  resource?: ResourceSimpleDto;
  problem?: string;
  category?: string;
  message?: string;
  contactFirstName?: string;
  contactLastName?: string;
}

/**
 * An interface representing SubmitWinterhalterServiceCallMailDto.
 */
export interface SubmitWinterhalterServiceCallMailDto {
  issueId?: number;
  tenantId?: number;
  title?: string;
  resource?: ResourceSimpleDto;
  problem?: string;
  category?: string;
  message?: string;
  contactFirstName?: string;
  contactLastName?: string;
  mail?: MailInDto;
  solutionId?: number;
}

/**
 * An interface representing SuggestedContactDto.
 */
export interface SuggestedContactDto {
  /**
   * Possible values include: 'Base', 'ByContact', 'ByFunction'
   */
  type?: EditorSuggestionTargetType;
  name?: string;
  contactId?: number;
}

/**
 * An interface representing SuggestedEditorDto.
 */
export interface SuggestedEditorDto {
  /**
   * Possible values include: 'Base', 'ByContact', 'ByFunction'
   */
  type?: EditorSuggestionTargetType;
  name?: string;
}

/**
 * An interface representing SuggestedEditorsDto.
 */
export interface SuggestedEditorsDto {
  editors?: SuggestedEditorDto[];
}

/**
 * An interface representing SuggestedFunctionDto.
 */
export interface SuggestedFunctionDto {
  /**
   * Possible values include: 'Base', 'ByContact', 'ByFunction'
   */
  type?: EditorSuggestionTargetType;
  name?: string;
  functionId?: number;
}

/**
 * An interface representing SynchronizeAddressInLocationDto.
 */
export interface SynchronizeAddressInLocationDto {
  street?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  countryCode?: string;
  stereotypeId?: string;
}

/**
 * An interface representing SynchronizeContactInLocationDto.
 */
export interface SynchronizeContactInLocationDto {
  externalId?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  emailAddress?: string;
  phone?: string;
  mobile?: string;
  stereotypeId?: string;
  functions?: string[];
}

/**
 * An interface representing SynchronizeLocationDto.
 */
export interface SynchronizeLocationDto {
  externalId?: string;
  name?: string;
  tenantId?: number;
  locationNumber?: string;
  stereotypeId?: string;
  addresses?: SynchronizeAddressInLocationDto[];
  contacts?: SynchronizeContactInLocationDto[];
}

/**
 * An interface representing SynchronizedLocationDto.
 */
export interface SynchronizedLocationDto {
  locationId?: number;
  locationName?: string;
}

/**
 * An interface representing SystemContactDto.
 */
export interface SystemContactDto {
  contactId?: number;
  firstName?: string;
  lastName?: string;
  culture?: string;
  timeZone?: string;
  displayName?: string;
  emailAddress?: string;
}

/**
 * An interface representing TenantInContactListDto.
 */
export interface TenantInContactListDto {
  domain?: string;
}

/**
 * An interface representing SystemContactListDto.
 */
export interface SystemContactListDto {
  contactId?: number;
  firstName?: string;
  displayName?: string;
  emailAddress?: string;
  lastName?: string;
  tenant?: TenantInContactListDto;
}

/**
 * An interface representing TaskDto.
 */
export interface TaskDto {
  taskId?: number;
  title?: string;
  externalId?: string;
  description?: string;
  tenantId?: number;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
   */
  type?: ConcreteTaskTypes;
  impacts?: ImpactDto[];
  documentTemplate?: DocumentTemplateSimpleDto;
}

/**
 * An interface representing TaskFormFilledFormCreateDto.
 */
export interface TaskFormFilledFormCreateDto {
  filledFormId?: number;
  formId?: number;
  tenantId?: number;
  fields?: FilledFormFieldCreateDto[];
  taskJobId?: number;
  taskId?: number;
}

/**
 * An interface representing TaskGenerationQueueDto.
 */
export interface TaskGenerationQueueDto {
  taskId?: number;
}

/**
 * An interface representing TaskSimpleListDto.
 */
export interface TaskSimpleListDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  children?: TaskSimpleListDto[];
  formId?: number;
  requiresAuthentication?: boolean;
  hasStrictOrder?: boolean;
  position?: number;
  reasonSetId?: number;
}

/**
 * An interface representing TaskInJobListDto.
 */
export interface TaskInJobListDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  children?: TaskSimpleListDto[];
  formId?: number;
  requiresAuthentication?: boolean;
  hasStrictOrder?: boolean;
  position?: number;
  reasonSetId?: number;
  categoryRelations?: CategorizedRelationDto[];
}

/**
 * An interface representing TaskJobCountListDto.
 */
export interface TaskJobCountListDto {
  taskId?: number;
  taskTitle?: string;
  locationId?: number;
  locationName?: string;
  countStart?: number;
  countOverdue?: number;
  countEscalated?: number;
  countDone?: number;
  countCanceled?: number;
  identifier?: string;
  date?: string;
}

/**
 * An interface representing TaskJobDataRequestDto.
 */
export interface TaskJobDataRequestDto {
  start?: string;
  end?: string;
  locationId?: number;
  filter?: FilterDto[];
  /**
   * Possible values include: 'Year', 'Month', 'Week', 'Day'
   */
  graphKind?: TimeGraphKind;
}

/**
 * An interface representing TaskJobStateReasonDto.
 */
export interface TaskJobStateReasonDto {
  taskJobStateReasonId?: number;
  content?: string;
  tenantId?: number;
}

/**
 * An interface representing TaskJobStateDto.
 */
export interface TaskJobStateDto {
  taskJobStatusId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  status?: TaskJobState;
  reason?: TaskJobStateReasonDto;
  createdByContactName?: string;
}

/**
 * An interface representing TaskJobDto.
 */
export interface TaskJobDto {
  taskJobId?: number;
  executionDate?: string;
  parentId?: number;
  task?: TaskV1Dto;
  attachment?: OrgaAttachmentListDto;
  jobStatus?: TaskJobStateDto[];
  children?: TaskJobDto[];
}

/**
 * An interface representing TaskJobGraphDto.
 */
export interface TaskJobGraphDto {
  dataPoints?: TaskJobCountListDto[];
}

/**
 * An interface representing TaskJobStateListDto.
 */
export interface TaskJobStateListDto {
  taskJobStatusId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  status?: TaskJobState;
  reason?: TaskJobStateReasonDto;
  createdByContactName?: string;
}

/**
 * An interface representing TaskJobListDto.
 */
export interface TaskJobListDto {
  taskJobId?: number;
  executionDate?: string;
  taskId?: number;
  task?: TaskInJobListDto;
  jobStatus?: TaskJobStateListDto[];
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  currentStatus?: TaskJobState;
  editorName?: string;
  currentJobStatus?: TaskJobStateListDto;
  children?: TaskJobListDto[];
  parentId?: number;
  locationId?: number;
  location?: LocationSimpleListDto;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly locationName?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly filledFormId?: number;
  attachment?: OrgaAttachmentListDto;
}

/**
 * An interface representing TaskJobLocationRequestDto.
 */
export interface TaskJobLocationRequestDto {
  start?: string;
  end?: string;
  filter?: FilterDto[];
}

/**
 * An interface representing TaskJobStateReasonSetDto.
 */
export interface TaskJobStateReasonSetDto {
  taskJobStateReasonSetId?: number;
  tenantId?: number;
  title?: string;
  reasons?: TaskJobStateReasonDto[];
}

/**
 * An interface representing TaskJobStateReasonSetListDto.
 */
export interface TaskJobStateReasonSetListDto {
  taskJobStateReasonSetId?: number;
  title?: string;
  tenantId?: number;
}

/**
 * An interface representing TaskListDto.
 */
export interface TaskListDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  externalId?: string;
  isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  parentId?: number;
  executionPlanId?: number;
  requiresAuthentication?: boolean;
  reasonSetId?: number;
  executionPlan?: ExecutionPlanCompleteListDto;
  tenantId?: number;
  categoryRelations?: CategorizedRelationDto[];
}

/**
 * An interface representing TaskListV1Dto.
 */
export interface TaskListV1Dto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  isEnabled?: boolean;
  /**
   * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
   */
  state?: TaskState;
  parentId?: number;
  executionPlan?: ExecutionPlanViewV1Dto;
  executionPlanId?: number;
  requiresAuthentication?: boolean;
  reasonSetId?: number;
  tenantId?: number;
}

/**
 * An interface representing TaskStateNotificationDto.
 */
export interface TaskStateNotificationDto {
  title?: string;
  message?: string;
  /**
   * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
   * 'PushedStartedMission'
   */
  type?: NotificationTypes;
  taskId?: number;
  taskJobId?: number;
  /**
   * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
   * 'Escalated'
   */
  taskJobState?: TaskJobState;
}

/**
 * An interface representing TemplateDto.
 */
export interface TemplateDto {
  templateId?: number;
  title?: string;
  externalId?: string;
  content?: string;
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  context?: TemplateContextType;
  tenantId?: number;
}

/**
 * An interface representing TemplateExportDto.
 */
export interface TemplateExportDto {
  externalId?: string;
  title?: string;
  content?: string;
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  context?: TemplateContextType;
}

/**
 * An interface representing TemplateListDto.
 */
export interface TemplateListDto {
  templateId?: number;
  title?: string;
  /**
   * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
   * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
   * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
   * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
   * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent',
   * 'MessageIssueEmailSubject', 'TicketIssueEmailSubject', 'MissionIssueEmailSubject',
   * 'WinterhalterIssueEmailSubject', 'TicketMailNodeEmailSubject',
   * 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent', 'TicketIssueEmailContent',
   * 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent', 'TicketMailNodeEmailContent',
   * 'WinterhalterMailNodeEmailContent'
   */
  context?: TemplateContextType;
  tenantId?: number;
  externalId?: string;
}

/**
 * An interface representing TenantSettingDto.
 */
export interface TenantSettingDto {
  settingId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'General', 'Ticket', 'Mission'
   */
  type?: SettingType;
}

/**
 * An interface representing TenantUsageBookingDto.
 */
export interface TenantUsageBookingDto {
  /**
   * Possible values include: 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
   * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
   * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
   * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon',
   * 'ClassificationAddon', 'SyncLocationAddon', 'OrgaTechClassicModule', 'OrgaTechModule',
   * 'SyncReceiptsClassicAddon', 'SyncContactsClassicAddon', 'SyncDriveClassicAddon',
   * 'SyncReceiptsAddon', 'SyncContactsAddon', 'SyncDriveAddon', 'NotificationClassicAddon',
   * 'NotificationAddon', 'TeamModule', 'MdmClassicModule', 'PrimeReportAddon',
   * 'MaintenanceReportAddon'
   */
  edition?: ProductEditions;
  amount?: number;
  usage?: number;
}

/**
 * An interface representing TenantUsageDto.
 */
export interface TenantUsageDto {
  tenantId?: number;
  usages?: TenantUsageBookingDto[];
}

/**
 * An interface representing TextFormDefaultDto.
 */
export interface TextFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing TextFormFieldDto.
 */
export interface TextFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  maxLength?: number;
  minLength?: number;
}

/**
 * An interface representing TextFormValueDto.
 */
export interface TextFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing TextSettingDto.
 */
export interface TextSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
}

/**
 * An interface representing TextTemplateTtm.
 */
export interface TextTemplateTtm {
  currentUser?: ContactTtm;
}

/**
 * An interface representing TicketStateTransitionDto.
 */
export interface TicketStateTransitionDto {
  occuredAt?: string;
  description?: string;
  state?: StateSimpleDto;
}

/**
 * An interface representing TicketAppDto.
 */
export interface TicketAppDto {
  id?: number;
  ticketId?: number;
  title?: string;
  description?: string;
  tenantId?: number;
  rowVersion?: number[];
  isArchived?: boolean;
  priority?: PrioritySimpleDto;
  currentState?: StateSimpleDto;
  stateTransitions?: TicketStateTransitionDto[];
  stereotype?: StereotypeUsageDto;
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing TicketByContractDefinitionDto.
 */
export interface TicketByContractDefinitionDto {
  id?: number;
  tenantId?: number;
  definitionId?: number;
  ticketStereotype: StereotypeSimpleDto;
  priority: PrioritySimpleDto;
  state: StateSimpleDto;
  contractStereotypeId: number;
}

/**
 * An interface representing TicketByLocationSkeletonDto.
 */
export interface TicketByLocationSkeletonDto {
  resourceSkeletonId?: number;
  title?: string;
  tenantId?: number;
  ticketStereotype: StereotypeSimpleDto;
  priority: PrioritySimpleDto;
  state: StateSimpleDto;
  editor?: ContactSimpleDto;
  resource?: ResourceSimpleDto;
}

/**
 * An interface representing TicketSkeletonDto.
 */
export interface TicketSkeletonDto {
  ticketSkeletonId?: number;
  tenantId?: number;
  title?: StringPreSelectedDto;
  description?: StringPreSelectedDto;
  state?: StateSimpleDtoPreSelectedReferenceDto;
  priority?: PrioritySimpleDtoPreSelectedReferenceDto;
  stereotype?: StereotypeSimpleDtoPreSelectedReferenceDto;
  resourceSelector?: ResourceTicketSkeletonSelectorDto;
}

/**
 * An interface representing TicketByProcessDefinitionDto.
 */
export interface TicketByProcessDefinitionDto {
  externalId?: string;
  ticketByProcessDefinitionId?: number;
  tenantId?: number;
  name?: string;
  /**
   * Possible values include: 'Default', 'Linkable'
   */
  kind?: TicketByProcessDefinitionKind;
  requiresAuthentication?: boolean;
  ticket?: TicketSkeletonDto;
  mission?: MissionSkeletonDto;
  chat?: ChatSkeletonDto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
}

/**
 * An interface representing TicketByProcessDefinitionListDto.
 */
export interface TicketByProcessDefinitionListDto {
  ticketByProcessDefinitionId?: number;
  name?: string;
  /**
   * Possible values include: 'Default', 'Linkable'
   */
  kind?: TicketByProcessDefinitionKind;
  externalId?: string;
  tenantId?: number;
}

/**
 * An interface representing TicketProcessPreviewDto.
 */
export interface TicketProcessPreviewDto {
  ticketSkeletonId?: number;
  tenantId?: number;
  title?: StringPreSelectedDto;
  description?: StringPreSelectedDto;
  state?: StateSimpleDtoPreSelectedReferenceDto;
  priority?: PrioritySimpleDtoPreSelectedReferenceDto;
  stereotype?: StereotypeSimpleDtoPreSelectedReferenceDto;
  resourceSelector?: ResourceTicketSkeletonSelectorDto;
}

/**
 * An interface representing TicketStereotypeUsageDto.
 */
export interface TicketStereotypeUsageDto {
  stereotypeId?: number;
  name?: string;
  tenantId?: number;
  isDefault?: boolean;
  externalId?: string;
  position?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  customPropertySets?: CustomSetReferenceDto[];
  prioritySet?: PrioritySetSimpleDto;
  stateMachine?: StateMachineSimpleDto;
}

/**
 * An interface representing TicketByProcessDefinitionPreviewDto.
 */
export interface TicketByProcessDefinitionPreviewDto {
  ticketByProcessDefinitionId?: number;
  name?: string;
  requiresAuthentication?: boolean;
  ticket?: TicketProcessPreviewDto;
  ticketStereotype?: TicketStereotypeUsageDto;
}

/**
 * An interface representing TicketSkeletonV1Dto.
 */
export interface TicketSkeletonV1Dto {
  ticketSkeletonId?: number;
  tenantId?: number;
  title?: StringPreSelectedDto;
  description?: StringPreSelectedDto;
  state?: StateSimpleDtoPreSelectedReferenceDto;
  priority?: PrioritySimpleDtoPreSelectedReferenceDto;
  stereotype?: StereotypeSimpleDtoPreSelectedReferenceDto;
}

/**
 * An interface representing TicketByProcessDefinitionV1Dto.
 */
export interface TicketByProcessDefinitionV1Dto {
  externalId?: string;
  ticketByProcessDefinitionId?: number;
  tenantId?: number;
  name?: string;
  requiresAuthentication?: boolean;
  ticket?: TicketSkeletonV1Dto;
  mission?: MissionSkeletonV1Dto;
  chat?: ChatSkeletonV1Dto;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  resourceFilters?: StereotypeSimpleDto[];
}

/**
 * An interface representing TicketChatMemberDto.
 */
export interface TicketChatMemberDto {
  chatMemberId?: number;
  /**
   * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
   */
  type?: ChatMemberType;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberId?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly concreteMemberName?: string;
  ticketId?: number;
  ticket?: TicketSimpleDto;
}

/**
 * An interface representing TicketSolutionDto.
 */
export interface TicketSolutionDto {
  solutionId?: number;
  members?: SolutionMemberDto[];
  attachments?: AttachmentForTechDto[];
  threadId?: string;
  threadMailAddress?: string;
}

/**
 * An interface representing TicketSourceDto.
 */
export interface TicketSourceDto {
  primaryIssue?: IssueSimpleDto;
  primaryMission?: MissionSimpleDto;
}

/**
 * An interface representing TicketDto.
 */
export interface TicketDto {
  id?: number;
  ticketId?: number;
  title?: string;
  description?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  tenantId?: number;
  rowVersion?: number[];
  lastModified?: string;
  createdAt?: string;
  creator?: CreatedByDto;
  isArchived?: boolean;
  hasChat?: boolean;
  solutionId?: number;
  solution?: TicketSolutionDto;
  currentState?: StateSimpleDto;
  editor?: EditorDto;
  location?: LocationSimpleDto;
  resource?: ResourceInTicketDto;
  escalationLevel?: EscalationLevelSimpleDto;
  origin?: TicketSourceDto;
  priority?: PrioritySimpleDto;
  links?: LinkDto[];
  labels?: LabelSimpleDto[];
  similarEntities?: LabeledEntityDto[];
  attachments?: AttachmentForTechDto[];
  customPropertyValues?: FilledCustomValueDto[];
}

/**
 * An interface representing TicketEventCompleteDto.
 */
export interface TicketEventCompleteDto {
  ticketEventId?: number;
  /**
   * Possible values include: 'Base', 'Escalation', 'FollowUp'
   */
  type?: TicketEventType;
  ticketId?: number;
  tenantId?: number;
  triggersAt?: string;
  ruleId?: number;
  ruleToExpress?: EscalationRulesInEventListDto;
  followUpState?: StateSimpleListDto;
}

/**
 * An interface representing TicketFromMailDto.
 */
export interface TicketFromMailDto {
  id?: number;
  ticketId?: number;
  mail?: MailInDto;
  tenantId?: number;
  resourceId?: number;
  currentStateId?: number;
  priorityId?: number;
  stereotypeId?: number;
  solutionId?: number;
  issuerNodeId?: number;
  lastModified?: string;
  createdAt?: string;
}

/**
 * An interface representing TicketIssueDetailsDto.
 */
export interface TicketIssueDetailsDto {
  ticketId?: number;
  title?: string;
  currentState?: StateSimpleDto;
}

/**
 * An interface representing TicketIssueDto.
 */
export interface TicketIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  customPropertyValues?: FilledCustomValueDto[];
  ticket?: TicketIssueDetailsDto;
  resource?: ResourceSimpleDto;
  location?: LocationSimpleDto;
  priority?: PrioritySimpleDto;
}

/**
 * An interface representing TicketIssueListDto.
 */
export interface TicketIssueListDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  issue?: IssueDetailsListDto;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  stereotype?: StereotypeSimpleListDto;
  customPropertyValues?: FilledCustomValueListDto[];
  priority?: PrioritySimpleListDto;
  ticket?: TicketSimpleListDto;
}

/**
 * An interface representing TicketIssuePreviewDto.
 */
export interface TicketIssuePreviewDto {
  issueTemplateId?: number;
  name?: string;
  issueStereotype?: StereotypeUsageDto;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
  /**
   * Possible values include: 'Selection', 'None'
   */
  resourceSelectorKind?: IssueResourceSelectorKind;
  priorities?: PrioritySimpleDto[];
}

/**
 * An interface representing TicketListDto.
 */
export interface TicketListDto {
  ticketId?: number;
  title?: string;
  description?: string;
  stereotypeId?: number;
  stereotypeRowVersion?: number[];
  isArchived?: boolean;
  tenantId?: number;
  resourceId?: number;
  currentStateId?: number;
  currentState?: StateSimpleListDto;
  editor?: ContactInTicketListDto;
  priority?: PrioritySimpleListDto;
  locationNumber?: string;
  location?: LocationListDto;
  resource?: ResourceInTicketListDto;
  escalationLevelId?: number;
  escalationLevel?: EscalationLevelSimpleListDto;
  stereotype?: StereotypeSimpleListDto;
  labelRelations?: LabelRelationListDto[];
  createdAt?: string;
  lastModified?: string;
  customPropertyValues?: FilledCustomValueListDto[];
}

/**
 * An interface representing TicketNotificationRuleTtm.
 */
export interface TicketNotificationRuleTtm {
  ticketId?: number;
  title?: string;
  link?: string;
  description?: string;
  resource?: ResourceTtm;
  priority?: PriorityModelTtm;
  escalationLevel?: EscalationLevelTtm;
  editor?: ContactTtm;
  stereotype?: StereotypeTtm;
  properties?: any;
}

/**
 * An interface representing TicketStateSettingDto.
 */
export interface TicketStateSettingDto {
  settingValueId?: number;
  /**
   * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
   * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
   */
  type?: SettingValueType;
  tenantId?: number;
  value?: number;
  state?: StateSimpleDto;
}

/**
 * An interface representing TicketSettingDto.
 */
export interface TicketSettingDto {
  settingId?: number;
  tenantId?: number;
  /**
   * Possible values include: 'Base', 'General', 'Ticket', 'Mission'
   */
  type?: SettingType;
  newMailStereotype?: StereotypeSettingDto;
  newMailPriority?: PrioritySettingDto;
  newMailResource?: ResourceSettingDto;
  newMailTicketState?: TicketStateSettingDto;
}

/**
 * An interface representing TicketStateChangeByChangedMissionStateRequestDto.
 */
export interface TicketStateChangeByChangedMissionStateRequestDto {
  ticketId?: number;
  /**
   * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
   */
  missionState?: MissionState;
  missionId?: number;
}

/**
 * An interface representing TicketStateChangeByClosedMissionRequestDto.
 */
export interface TicketStateChangeByClosedMissionRequestDto {
  ticketId?: number;
  missionId?: number;
}

/**
 * An interface representing TicketStateChangeRequestDto.
 */
export interface TicketStateChangeRequestDto {
  ticketId?: number;
}

/**
 * An interface representing TicketsByLocationDefinitionDto.
 */
export interface TicketsByLocationDefinitionDto {
  ticketsByLocationDefinitionId?: number;
  stereotypeId?: number;
  title?: string;
  tenantId?: number;
  tickets?: TicketByLocationSkeletonDto[];
}

/**
 * An interface representing TicketsByLocationDefinitionListDto.
 */
export interface TicketsByLocationDefinitionListDto {
  ticketsByLocationDefinitionId?: number;
  title?: string;
  tenantId?: number;
  stereotype?: StereotypeSimpleListDto;
}

/**
 * An interface representing TicketsPerLocationByMonthDto.
 */
export interface TicketsPerLocationByMonthDto {
  month?: number;
  monthName?: string;
  locationId?: number;
  locationName?: string;
  ticketCount?: number;
}

/**
 * An interface representing TicketsPerLocationByTimeDto.
 */
export interface TicketsPerLocationByTimeDto {
  datePoint?: string;
  locationId?: number;
  locationName?: string;
  ticketCount?: number;
}

/**
 * An interface representing TicketsPerLocationPerTypeByMonthDto.
 */
export interface TicketsPerLocationPerTypeByMonthDto {
  month?: number;
  monthName?: string;
  locationId?: number;
  locationName?: string;
  ticketCount?: number;
  stereotypeName?: string;
  stereotypeId?: number;
}

/**
 * An interface representing TicketsPerLocationPerTypeByTimeDto.
 */
export interface TicketsPerLocationPerTypeByTimeDto {
  datePoint?: string;
  locationId?: number;
  locationName?: string;
  ticketCount?: number;
  stereotypeName?: string;
  stereotypeId?: number;
}

/**
 * An interface representing TicketsPerResourceManufacturerDto.
 */
export interface TicketsPerResourceManufacturerDto {
  resourceCount?: number;
  ticketCount?: number;
  manufacturer?: string;
  resourceStereotypeName?: string;
  resourceStereotypeId?: number;
}

/**
 * An interface representing TicketsPerTypeDto.
 */
export interface TicketsPerTypeDto {
  resourceCount?: number;
  ticketCount?: number;
  stereotypeName?: string;
  stereotypeId?: number;
  withChat?: number;
  withChatProcess?: number;
}

/**
 * An interface representing TimeConditionDto.
 */
export interface TimeConditionDto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
  conditionOffset?: string;
}

/**
 * An interface representing TimeConditionV1Dto.
 */
export interface TimeConditionV1Dto {
  conditionId?: number;
  /**
   * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
   */
  type?: ConditionTypes;
  conditionOffset?: string;
}

/**
 * An interface representing TimeFormDefaultDto.
 */
export interface TimeFormDefaultDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing TimeFormFieldDto.
 */
export interface TimeFormFieldDto {
  formFieldId?: number;
  title?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly technicalName?: string;
  width?: number;
  isRequired?: boolean;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  position?: number;
  defaultValues?: FormDefaultDto[];
  localFieldId?: string;
  minValue?: string;
  maxValue?: string;
}

/**
 * An interface representing TimeFormValueDto.
 */
export interface TimeFormValueDto {
  fieldValueId?: number;
  /**
   * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
   * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
   */
  type?: FormFieldTypes;
  value?: string;
}

/**
 * An interface representing TimelineCollectionTaskDto.
 */
export interface TimelineCollectionTaskDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  tenantId?: number;
  reasonSetId?: number;
  children?: TimelineTaskDto[];
  hasStrictOrder?: boolean;
}

/**
 * An interface representing TimelineConfirmationTaskDto.
 */
export interface TimelineConfirmationTaskDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  tenantId?: number;
  reasonSetId?: number;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing TimelineDocumentTaskDto.
 */
export interface TimelineDocumentTaskDto {
  taskId?: number;
  title?: string;
  description?: string;
  /**
   * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
   */
  type?: TaskTypes;
  tenantId?: number;
  reasonSetId?: number;
  formId?: number;
  position?: number;
  requiresAuthentication?: boolean;
}

/**
 * An interface representing TimelineSettingStereotypeDto.
 */
export interface TimelineSettingStereotypeDto {
  stereotypeId?: number;
  name?: string;
  tenantId?: number;
  isArchived?: boolean;
  /**
   * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
   * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
   * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
   * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
   * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
   * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
   * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
   * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
   */
  entityType?: AppEntityType;
  rowVersion?: number[];
  externalId?: string;
  /**
   * Possible values include: 'App', 'Backend', 'Everywhere'
   */
  sector?: CategorySector;
}

/**
 * An interface representing TimelineSettingDto.
 */
export interface TimelineSettingDto {
  tenantId?: number;
  timelineSettingId?: number;
  isInstructedMissionsOnly?: boolean;
  isActiveMissionOnly?: boolean;
  locations?: LocationSimpleDto[];
  locationGroups?: LocationGroupSimpleDto[];
  stereotypes?: TimelineSettingStereotypeDto[];
}

/**
 * An interface representing TimelineSettingListDto.
 */
export interface TimelineSettingListDto {
  timelineSettingId?: number;
  isInstructedMissionsOnly?: boolean;
  locations?: LocationSimpleListDto[];
  locationGroups?: LocationGroupSimpleListDto[];
  stereotypes?: StereotypeSimpleListDto[];
}

/**
 * An interface representing TokenDto.
 */
export interface TokenDto {
  refreshToken?: string;
  token?: string;
  restrictedRoleIds?: number[];
  restrictedLocationIds?: number[];
  info?: MeDto;
}

/**
 * An interface representing UninformedMemberListDto.
 */
export interface UninformedMemberListDto {
  displayName?: string;
  infoId?: number;
  contact?: ContactSimpleListDto;
}

/**
 * An interface representing UserImportDto.
 */
export interface UserImportDto {
  pin?: string;
  email?: string;
  badge?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  validUntil?: string;
  index?: number;
  locations?: string;
  functions?: string;
  /**
   * Possible values include: 'Ignore', 'Update', 'Patch'
   */
  locationsBehaviour?: ImportRelationBehaviour;
  locationGroups?: string;
  /**
   * Possible values include: 'Ignore', 'Update', 'Patch'
   */
  locationGroupsBehaviour?: ImportRelationBehaviour;
}

/**
 * An interface representing UserIssueEmailTtm.
 */
export interface UserIssueEmailTtm {
  issueId?: number;
  title?: string;
  link?: string;
  resource?: ResourceTtm;
  location?: LocationTtm;
  mail?: MailTtm;
  stereotype?: StereotypeTtm;
  properties?: any;
  ticket?: TicketTtm;
  mission?: MissionTtm;
}

/**
 * An interface representing UserRoleDto.
 */
export interface UserRoleDto {
  userId?: number;
  roleId?: number;
  contactId?: number;
}

/**
 * An interface representing VirtualizeResourceQueueDto.
 */
export interface VirtualizeResourceQueueDto {
  resourceId?: number;
}

/**
 * An interface representing WeeklyDateTimePatternDto.
 */
export interface WeeklyDateTimePatternDto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeDto[];
  startDate?: string;
  endDate?: string;
  repeat?: number;
  useOpeningDays?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

/**
 * An interface representing WeeklyDateTimePatternV1Dto.
 */
export interface WeeklyDateTimePatternV1Dto {
  dateTimePatternId?: number;
  /**
   * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
   */
  type?: TimePatterns;
  useNextOpeningDay?: boolean;
  lastModified?: string;
  plannedTimes?: PlannedTimeV1Dto[];
  startDate?: string;
  endDate?: string;
  repeat?: number;
  useOpeningDays?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
}

/**
 * An interface representing WhitelistedResourceTicketSkeletonSelectorDto.
 */
export interface WhitelistedResourceTicketSkeletonSelectorDto {
  id?: number;
  /**
   * Possible values include: 'Base', 'All', 'RestrictedByType', 'Whitelisted', 'Fixed'
   */
  type?: ResourceTicketSkeletonSelectorType;
  whitelist?: ResourceSafetySimpleDto[];
}

/**
 * An interface representing WinterhalterIssueEmailTtm.
 */
export interface WinterhalterIssueEmailTtm {
  issueId?: number;
  title?: string;
  link?: string;
  resource?: ResourceTtm;
  location?: LocationTtm;
  mail?: MailTtm;
}

/**
 * An interface representing WinterhalterOrderOperationDto.
 */
export interface WinterhalterOrderOperationDto {
  /**
   * Possible values include: 'Created', 'Instructed', 'Canceled', 'Done', 'Transition'
   */
  status?: WinterhalterOperationStatus;
}

/**
 * An interface representing WinterhalterOrderDto.
 */
export interface WinterhalterOrderDto {
  orderId?: string;
  orderRefreshAt?: string;
  operations?: WinterhalterOrderOperationDto[];
}

/**
 * An interface representing WinterhalterProductDetailDto.
 */
export interface WinterhalterProductDetailDto {
  productId?: number;
  title?: string;
  materialNumber?: string;
  productCode?: string;
  orderPriority?: number;
  minimumQuantity?: number;
  maxQuantity?: number;
  salesQuantity?: number;
  containerSize?: string;
  urlSafetyFile?: string;
  urlImage?: string;
}

/**
 * An interface representing WinterhalterProductCatalogDto.
 */
export interface WinterhalterProductCatalogDto {
  products?: WinterhalterProductDetailDto[];
}

/**
 * An interface representing WinterhalterProductOrderDto.
 */
export interface WinterhalterProductOrderDto {
  productId?: number;
  title?: string;
  quantity?: number;
  materialNumber?: string;
  productCode?: string;
  containerSize?: string;
  urlImage?: string;
}

/**
 * An interface representing WinterhalterProductOrderIssueDto.
 */
export interface WinterhalterProductOrderIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  products?: WinterhalterProductOrderDto[];
  winterhalterOrder?: WinterhalterOrderDto;
  resource?: ResourceSimpleDto;
  location?: LocationSimpleDto;
}

/**
 * An interface representing WinterhalterProductOrderIssueListDto.
 */
export interface WinterhalterProductOrderIssueListDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  issue?: IssueDetailsListDto;
}

/**
 * An interface representing WinterhalterProductOrderIssuePreviewDto.
 */
export interface WinterhalterProductOrderIssuePreviewDto {
  issueTemplateId?: number;
  name?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
}

/**
 * An interface representing WinterhalterServiceCallProblemPreviewDto.
 */
export interface WinterhalterServiceCallProblemPreviewDto {
  key?: string;
  translation?: string;
}

/**
 * An interface representing WinterhalterServiceCallCategoryPreviewDto.
 */
export interface WinterhalterServiceCallCategoryPreviewDto {
  key?: string;
  translation?: string;
  problems?: WinterhalterServiceCallProblemPreviewDto[];
}

/**
 * An interface representing WinterhalterServiceCallIssueDto.
 */
export interface WinterhalterServiceCallIssueDto {
  issueId?: number;
  title?: string;
  tenantId?: number;
  problem?: string;
  category?: string;
  message?: string;
  contactFirstName?: string;
  contactLastName?: string;
  winterhalterOrder?: WinterhalterOrderDto;
  resource?: ResourceSimpleDto;
  location?: LocationSimpleDto;
}

/**
 * An interface representing WinterhalterServiceCallIssueListDto.
 */
export interface WinterhalterServiceCallIssueListDto {
  issueId?: number;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
   */
  issueType?: IssueType;
  issue?: IssueDetailsListDto;
  problem?: string;
  category?: string;
  message?: string;
  contactFirstName?: string;
  contactLastName?: string;
}

/**
 * An interface representing WinterhalterServiceCallIssuePreviewDto.
 */
export interface WinterhalterServiceCallIssuePreviewDto {
  issueTemplateId?: number;
  name?: string;
  /**
   * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
   * 'WinterhalterProductOrder'
   */
  contentType?: IssueContentType;
  categories?: WinterhalterServiceCallCategoryPreviewDto[];
}

/**
 * An interface representing APIv1Options.
 */
export interface APIv1Options extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Defines values for FilterKind.
 * Possible values include: 'Default', 'Relative', 'Grouped'
 * @readonly
 * @enum {string}
 */
export enum FilterKind {
  Default = 0,
  Relative = 1,
  Grouped = 2,
}

/**
 * Defines values for FilterOperators.
 * Possible values include: 'Equal', 'GreaterThan', 'LessThan', 'NotEqual', 'Contains',
 * 'LessThanOrEqual', 'GreaterThanOrEqual', 'Default'
 * @readonly
 * @enum {string}
 */
export enum FilterOperators {
  Equal = 0,
  GreaterThan = 1,
  LessThan = 2,
  NotEqual = 3,
  Contains = 4,
  LessThanOrEqual = 5,
  GreaterThanOrEqual = 6,
  Default = -1,
}

/**
 * Defines values for FilterTypes.
 * Possible values include: 'DataTransferObject', 'Custom', 'Grouped'
 * @readonly
 * @enum {string}
 */
export enum FilterTypes {
  DataTransferObject = 0,
  Custom = 1,
  Grouped = 2,
}

/**
 * Defines values for InvitationType.
 * Possible values include: 'Identify'
 * @readonly
 * @enum {string}
 */
export enum InvitationType {
  Identify = 1,
}

/**
 * Defines values for ControlContentType.
 * Possible values include: 'Message', 'Mission'
 * @readonly
 * @enum {string}
 */
export enum ControlContentType {
  Message = 1,
  Mission = 3,
}

/**
 * Defines values for LabelDescriptorType.
 * Possible values include: 'Base', 'Ticket', 'Mission'
 * @readonly
 * @enum {string}
 */
export enum LabelDescriptorType {
  Base = 0,
  Ticket = 1,
  Mission = 2,
}

/**
 * Defines values for CustomPropertyType.
 * Possible values include: 'Base', 'Text', 'Multiline', 'Numeric', 'Email', 'Dropdown',
 * 'Checkbox', 'Date', 'TimeOfDay', 'Phone', 'Signature', 'Picture', 'Info', 'MultipleChoice'
 * @readonly
 * @enum {string}
 */
export enum CustomPropertyType {
  Base = 0,
  Text = 1,
  Multiline = 2,
  Numeric = 3,
  Email = 4,
  Dropdown = 5,
  Checkbox = 6,
  Date = 7,
  TimeOfDay = 8,
  Phone = 9,
  Signature = 10,
  Picture = 11,
  Info = 12,
  MultipleChoice = 13,
}

/**
 * Defines values for AppEntityType.
 * Possible values include: 'None', 'Ticket', 'Resource', 'Address', 'Company', 'Contact',
 * 'Function', 'Location', 'Stereotype', 'Role', 'LocationGroup', 'Setting', 'User', 'Info',
 * 'KnowledgeArticle', 'Form', 'Task', 'TaskJob', 'Mission', 'Contract', 'Article',
 * 'MissionReport', 'ArticleKind', 'MissionInspectionReport', 'ControlTag', 'FilledForm',
 * 'DocumentTemplate', 'AppOverview', 'InventoryNumberPattern', 'PrioritySet', 'State',
 * 'StateMachine', 'Template', 'FormHistory', 'Dashboard', 'FolderAccess', 'Device',
 * 'ChatByFormDefinition', 'TicketByProcessDefinition', 'TimelineSetting', 'KnowledgePath',
 * 'IssueTemplate', 'Issue', 'KnowledgeExam', 'KnowledgePathProgress'
 * @readonly
 * @enum {string}
 */
export enum AppEntityType {
  None = 0,
  Ticket = 1,
  Resource = 3,
  Address = 4,
  Company = 5,
  Contact = 6,
  Function = 7,
  Location = 8,
  Stereotype = 9,
  Role = 10,
  LocationGroup = 11,
  Setting = 12,
  User = 14,
  Info = 15,
  KnowledgeArticle = 16,
  Form = 17,
  Task = 18,
  TaskJob = 19,
  Mission = 20,
  Contract = 21,
  Article = 22,
  MissionReport = 23,
  ArticleKind = 24,
  MissionInspectionReport = 25,
  ControlTag = 26,
  FilledForm = 27,
  DocumentTemplate = 28,
  AppOverview = 29,
  InventoryNumberPattern = 30,
  PrioritySet = 31,
  State = 32,
  StateMachine = 33,
  Template = 34,
  FormHistory = 35,
  Dashboard = 36,
  FolderAccess = 37,
  Device = 38,
  ChatByFormDefinition = 39,
  TicketByProcessDefinition = 40,
  TimelineSetting = 41,
  KnowledgePath = 42,
  IssueTemplate = 43,
  Issue = 44,
  KnowledgeExam = 45,
  KnowledgePathProgress = 46,
}

/**
 * Defines values for LocationState.
 * Possible values include: 'Open', 'Closed'
 * @readonly
 * @enum {string}
 */
export enum LocationState {
  Open = 0,
  Closed = 1,
}

/**
 * Defines values for AttachmentTypeForTech.
 * Possible values include: 'Base', 'File'
 * @readonly
 * @enum {string}
 */
export enum AttachmentTypeForTech {
  Base = 0,
  File = 1,
}

/**
 * Defines values for ImpactTypes.
 * Possible values include: 'Base', 'Sanction', 'Alert', 'Status'
 * @readonly
 * @enum {string}
 */
export enum ImpactTypes {
  Base = 0,
  Sanction = 1,
  Alert = 2,
  Status = 3,
}

/**
 * Defines values for ConditionTypes.
 * Possible values include: 'Base', 'Percentage', 'Time', 'ChangedStatus'
 * @readonly
 * @enum {string}
 */
export enum ConditionTypes {
  Base = 0,
  Percentage = 1,
  Time = 2,
  ChangedStatus = 3,
}

/**
 * Defines values for ResourceTicketSkeletonSelectorType.
 * Possible values include: 'Base', 'All', 'RestrictedByType', 'Whitelisted', 'Fixed'
 * @readonly
 * @enum {string}
 */
export enum ResourceTicketSkeletonSelectorType {
  Base = 0,
  All = 1,
  RestrictedByType = 2,
  Whitelisted = 3,
  Fixed = 4,
}

/**
 * Defines values for ContextKind.
 * Possible values include: 'Global', 'Location', 'Tenant', 'LocationGroup', 'User'
 * @readonly
 * @enum {string}
 */
export enum ContextKind {
  Global = 1,
  Location = 2,
  Tenant = 3,
  LocationGroup = 4,
  User = 8,
}

/**
 * Defines values for EditorSuggestionTargetType.
 * Possible values include: 'Base', 'ByContact', 'ByFunction'
 * @readonly
 * @enum {string}
 */
export enum EditorSuggestionTargetType {
  Base = 0,
  ByContact = 1,
  ByFunction = 2,
}

/**
 * Defines values for TimeGraphKind.
 * Possible values include: 'Year', 'Month', 'Week', 'Day'
 * @readonly
 * @enum {string}
 */
export enum TimeGraphKind {
  Year = 1,
  Month = 2,
  Week = 3,
  Day = 4,
}

/**
 * Defines values for AppFunctions.
 * Possible values include: 'EditTenant', 'ReadTenantUsage', 'ReadFile', 'EditFile',
 * 'ManipulateFile', 'ShowAppDocuments', 'ShowBackendDocuments', 'SynchronizeMasterData',
 * 'ManipulateMasterData', 'ReadContact', 'EditContact', 'DisplayContact', 'DeleteContact',
 * 'EditSystemContact', 'ReadLocation', 'EditLocation', 'DisplayLocation', 'DeleteLocation',
 * 'ReadAddress', 'EditAddress', 'DisplayAddress', 'DeleteAddress', 'ReadCompany', 'EditCompany',
 * 'DisplayCompany', 'DeleteCompany', 'DisplayInfo', 'ReadInfo', 'EditInfo', 'ReadDevice',
 * 'EditDevice', 'RegisterDevice', 'EditPing', 'EditTask', 'EditTaskJob', 'EditForm',
 * 'EditFilledForm', 'ManipulateFilledForm', 'DisplayKnowledge', 'ReadKnowledge', 'EditKnowledge',
 * 'SubmitAnswer', 'DisplayKnowledgeExam', 'ReadHoliday', 'EditHoliday', 'EditChat', 'ShowAppChat',
 * 'ShowBackendChat', 'AllowChatPerEmail', 'ShowAllChatsPackage', 'ReadFolderAccess',
 * 'EditFolderAccess', 'EditRole', 'EditHistory', 'ReadLoginChallenge', 'EditLoginChallenge',
 * 'ReadLoginProvider', 'EditLoginProvider', 'EditProductSubscription', 'ReadStereotype',
 * 'EditStereotype', 'ManipulateStereotype', 'DisplayStereotype', 'ReadTicket', 'EditTicket',
 * 'ForceTicketState', 'AssignTicketToMe', 'AssignTicketToEditor', 'DisplayTicket', 'DeleteTicket',
 * 'AssignTicketResource', 'EditSettings', 'ReadSettings', 'ReadGraph', 'ShowDashboard',
 * 'ShowAppOverview', 'ReadExternalDocument', 'EditExternalDocument', 'ReadExternalAllDocument',
 * 'ReadResource', 'EditResource', 'DisplayResource', 'DeleteResource', 'ReadQuickAccess',
 * 'EditQuickAccess', 'DisplayQuickAccess', 'ReadStateMachine', 'EditStateMachine',
 * 'DisplayStateMachine', 'ReadDataTable', 'EditDataTable', 'DisplayDataTable', 'ExportDataTable',
 * 'ReadDashboard', 'EditDashboard', 'DisplayDashboard', 'EditAllDashboard', 'ReadPriority',
 * 'EditPriority', 'DisplayPriority', 'ReadEscalation', 'UseEscalation', 'EditEscalation',
 * 'DisplayEscalation', 'ReadAudit', 'AssignMissionToMe', 'AssignMissionToEditor',
 * 'AssignMissionResource', 'ReadMission', 'EditMission', 'EditActualMissionDate',
 * 'EditPlannedMissionDate', 'EditClosedMission', 'DisplayMission', 'DeleteMission',
 * 'DisplayMissionMap', 'DisplayMissionPlan', 'ReadLink', 'EditLink', 'ReadAttachmentForTech',
 * 'EditAttachmentForTech', 'ReadTemplate', 'EditTemplate', 'ReadDocumentTemplate',
 * 'EditDocumentTemplate', 'EditBetaDocumentTemplates', 'ReadInventoryNumberPattern',
 * 'EditInventoryNumberPattern', 'DisplayInventoryNumberPattern', 'ReadContract', 'EditContract',
 * 'DisplayContract', 'DeleteContract', 'ReadResourceTask', 'EditResourceTask',
 * 'DisplayResourceTask', 'DeleteResourceTask', 'ReadArticle', 'EditArticle', 'DisplayArticle',
 * 'DeleteArticle', 'ReadMissionReport', 'EditMissionReport', 'ReadSolution', 'EditSolution',
 * 'ReadAppOverview', 'EditAppOverview', 'DisplayAppOverview', 'DeleteAppOverview',
 * 'ReadTicketByProcessDefinition', 'EditTicketByProcessDefinition',
 * 'DisplayTicketByProcessDefinition', 'DeleteTicketByProcessDefinition', 'CreateTicketByProcess',
 * 'ReadCategory', 'EditCategory', 'DisplayCategory', 'DeleteCategory', 'ReadChatByFormDefinition',
 * 'EditChatByFormDefinition', 'DisplayChatByFormDefinition', 'DeleteChatByFormDefinition',
 * 'StartChatByForm', 'ReadAreaAssignment', 'EditAreaAssignment', 'DisplayServicePackage',
 * 'ReadSubscriptionPackage', 'ReadAnalytic', 'ReadPortalAnalytic', 'ReadApiKey', 'EditApiKey',
 * 'ReadMissionReceipt', 'EditMissionReceipt', 'DisplayMissionReceipt', 'ReadConnectedDefinition',
 * 'EditConnectedDefinition', 'DisplayConnectedDefinition', 'DeleteConnectedDefinition',
 * 'ReadTimelineSetting', 'EditTimelineSetting', 'DeleteTimelineSetting', 'ReadMeTimelineSetting',
 * 'ReadMailNode', 'EditMailNode', 'DeleteMailNode', 'DisplayMailNode', 'ReadNotificationRule',
 * 'EditNotificationRule', 'ReadEditorQuest', 'EditEditorQuest', 'ImportExport', 'ReadSearchList',
 * 'EditSearchList', 'DisplaySearchList', 'DeleteSearchList', 'ReadResourcesByLocationDefinition',
 * 'EditResourcesByLocationDefinition', 'ReadTicketsByLocationDefinition',
 * 'EditTicketsByLocationDefinition', 'UseSetPassword', 'ReadMailLog', 'UseClassification',
 * 'ReadLabel', 'EditLabel', 'DisplayLabel', 'DeleteLabel', 'ModifyLabelManually', 'IsOrgaUser',
 * 'Manager', 'ShowsExpiredSubscription', 'IsAdmin', 'IsSystemAdmin', 'CanUseExpiredSubscription',
 * 'Admin', 'IsOrgaUserInTech', 'IsQuickAccessIssueHistory', 'IsServiceEmployee',
 * 'IsQuickAccessUser', 'IsQuickAccessKnowledge', 'IsQuickAccessIssueTemplate',
 * 'IsQuickAccessDashboard', 'TeamUser', 'AnalystUser', 'TechUser', 'OrgaClassicModule',
 * 'TechClassicModule', 'OrgaModule', 'MissionClassicAddon', 'ConnectedClassicAddon',
 * 'SafeClassicAddon', 'LinkClassicAddon', 'TechModule', 'DataClassicModule',
 * 'ClassificationClassicAddon', 'SyncLocationClassicAddon', 'DataModule', 'MissionAddon',
 * 'ConnectedAddon', 'SafeAddon', 'LinkAddon', 'ClassificationAddon', 'CalendarMission',
 * 'SyncLocationAddon', 'ReadCalendar', 'ReadSafe', 'MaintainSafe', 'AdministrateSafe',
 * 'ReadResourceManufacturer', 'EditResourceManufacturer', 'DisplayResourceManufacturer',
 * 'DeleteResourceManufacturer', 'OrgaTechClassicModule', 'SyncReceiptsClassicAddon',
 * 'SyncContactsClassicAddon', 'SyncDriveClassicAddon', 'NotificationClassicAddon',
 * 'OrgaTechModule', 'SyncReceiptsAddon', 'SyncContactsAddon', 'SyncDriveAddon',
 * 'NotificationAddon', 'TeamModule', 'MdmClassicModule', 'MaintenanceReportAddon',
 * 'PrimeReportAddon', 'ReadIssueTemplate', 'EditIssueTemplate', 'DisplayIssueTemplate',
 * 'DeleteIssueTemplate', 'DeleteWinterhalterIssueTemplate', 'ReadMessageIssueContent',
 * 'EditMessageIssueContent', 'ReadTicketIssueContent', 'EditTicketIssueContent',
 * 'ReadMissionIssueContent', 'EditMissionIssueContent', 'ReadWinterhalterServiceCallIssueContent',
 * 'EditWinterhalterServiceCallIssueContent', 'ReadWinterhalterProductOrderIssueContent',
 * 'EditWinterhalterProductOrderIssueContent', 'EditIssue', 'DeleteIssue', 'SendIssue',
 * 'ReadRecentIssue', 'ReadAllIssue', 'ReadIssue', 'UseMassImport', 'EditInvitation',
 * 'ShowReportForMaintenance', 'ShowReportForPrime', 'ReadControl', 'EditControl',
 * 'DisplayControl', 'DeleteControl', 'ReadImportConfiguration', 'EditImportConfiguration',
 * 'DeleteImportConfiguration', 'DisplayImportConfiguration', 'ReadImportProtocol',
 * 'EditImportProtocol', 'DeleteImportProtocol', 'DisplayImportProtocol'
 * @readonly
 * @enum {string}
 */
export enum AppFunctions {
  EditTenant = 12,
  ReadTenantUsage = 13,
  ReadFile = 20,
  EditFile = 21,
  ManipulateFile = 22,
  ShowAppDocuments = 23,
  ShowBackendDocuments = 24,
  SynchronizeMasterData = 28,
  ManipulateMasterData = 29,
  ReadContact = 30,
  EditContact = 31,
  DisplayContact = 32,
  DeleteContact = 33,
  EditSystemContact = 34,
  ReadLocation = 40,
  EditLocation = 41,
  DisplayLocation = 42,
  DeleteLocation = 43,
  ReadAddress = 50,
  EditAddress = 51,
  DisplayAddress = 52,
  DeleteAddress = 53,
  ReadCompany = 60,
  EditCompany = 61,
  DisplayCompany = 62,
  DeleteCompany = 63,
  DisplayInfo = 69,
  ReadInfo = 70,
  EditInfo = 71,
  ReadDevice = 80,
  EditDevice = 81,
  RegisterDevice = 82,
  EditPing = 85,
  EditTask = 90,
  EditTaskJob = 91,
  EditForm = 101,
  EditFilledForm = 102,
  ManipulateFilledForm = 103,
  DisplayKnowledge = 119,
  ReadKnowledge = 120,
  EditKnowledge = 121,
  SubmitAnswer = 122,
  DisplayKnowledgeExam = 123,
  ReadHoliday = 130,
  EditHoliday = 131,
  EditChat = 141,
  ShowAppChat = 142,
  ShowBackendChat = 143,
  AllowChatPerEmail = 144,
  ShowAllChatsPackage = 145,
  ReadFolderAccess = 150,
  EditFolderAccess = 151,
  EditRole = 161,
  EditHistory = 171,
  ReadLoginChallenge = 180,
  EditLoginChallenge = 181,
  ReadLoginProvider = 182,
  EditLoginProvider = 183,
  EditProductSubscription = 184,
  ReadStereotype = 200,
  EditStereotype = 201,
  ManipulateStereotype = 202,
  DisplayStereotype = 203,
  ReadTicket = 210,
  EditTicket = 211,
  ForceTicketState = 212,
  AssignTicketToMe = 213,
  AssignTicketToEditor = 214,
  DisplayTicket = 215,
  DeleteTicket = 216,
  AssignTicketResource = 217,
  EditSettings = 220,
  ReadSettings = 221,
  ReadGraph = 225,
  ShowDashboard = 226,
  ShowAppOverview = 227,
  ReadExternalDocument = 230,
  EditExternalDocument = 231,
  ReadExternalAllDocument = 232,
  ReadResource = 240,
  EditResource = 241,
  DisplayResource = 242,
  DeleteResource = 243,
  ReadQuickAccess = 244,
  EditQuickAccess = 245,
  DisplayQuickAccess = 246,
  ReadStateMachine = 250,
  EditStateMachine = 251,
  DisplayStateMachine = 252,
  ReadDataTable = 260,
  EditDataTable = 261,
  DisplayDataTable = 262,
  ExportDataTable = 263,
  ReadDashboard = 270,
  EditDashboard = 271,
  DisplayDashboard = 272,
  EditAllDashboard = 273,
  ReadPriority = 280,
  EditPriority = 281,
  DisplayPriority = 282,
  ReadEscalation = 300,
  UseEscalation = 301,
  EditEscalation = 302,
  DisplayEscalation = 303,
  ReadAudit = 310,
  AssignMissionToMe = 317,
  AssignMissionToEditor = 318,
  AssignMissionResource = 319,
  ReadMission = 320,
  EditMission = 321,
  EditActualMissionDate = 322,
  EditPlannedMissionDate = 323,
  EditClosedMission = 324,
  DisplayMission = 325,
  DeleteMission = 327,
  DisplayMissionMap = 328,
  DisplayMissionPlan = 329,
  ReadLink = 330,
  EditLink = 331,
  ReadAttachmentForTech = 340,
  EditAttachmentForTech = 341,
  ReadTemplate = 350,
  EditTemplate = 351,
  ReadDocumentTemplate = 360,
  EditDocumentTemplate = 361,
  EditBetaDocumentTemplates = 362,
  ReadInventoryNumberPattern = 370,
  EditInventoryNumberPattern = 371,
  DisplayInventoryNumberPattern = 372,
  ReadContract = 380,
  EditContract = 381,
  DisplayContract = 382,
  DeleteContract = 383,
  ReadResourceTask = 390,
  EditResourceTask = 391,
  DisplayResourceTask = 392,
  DeleteResourceTask = 393,
  ReadArticle = 400,
  EditArticle = 401,
  DisplayArticle = 402,
  DeleteArticle = 403,
  ReadMissionReport = 410,
  EditMissionReport = 411,
  ReadSolution = 433,
  EditSolution = 434,
  ReadAppOverview = 440,
  EditAppOverview = 441,
  DisplayAppOverview = 442,
  DeleteAppOverview = 443,
  ReadTicketByProcessDefinition = 450,
  EditTicketByProcessDefinition = 451,
  DisplayTicketByProcessDefinition = 452,
  DeleteTicketByProcessDefinition = 453,
  CreateTicketByProcess = 454,
  ReadCategory = 460,
  EditCategory = 461,
  DisplayCategory = 462,
  DeleteCategory = 463,
  ReadChatByFormDefinition = 470,
  EditChatByFormDefinition = 471,
  DisplayChatByFormDefinition = 472,
  DeleteChatByFormDefinition = 473,
  StartChatByForm = 474,
  ReadAreaAssignment = 480,
  EditAreaAssignment = 481,
  DisplayServicePackage = 490,
  ReadSubscriptionPackage = 491,
  ReadAnalytic = 500,
  ReadPortalAnalytic = 501,
  ReadApiKey = 510,
  EditApiKey = 511,
  ReadMissionReceipt = 520,
  EditMissionReceipt = 521,
  DisplayMissionReceipt = 522,
  ReadConnectedDefinition = 550,
  EditConnectedDefinition = 551,
  DisplayConnectedDefinition = 552,
  DeleteConnectedDefinition = 553,
  ReadTimelineSetting = 560,
  EditTimelineSetting = 561,
  DeleteTimelineSetting = 563,
  ReadMeTimelineSetting = 564,
  ReadMailNode = 570,
  EditMailNode = 571,
  DeleteMailNode = 573,
  DisplayMailNode = 574,
  ReadNotificationRule = 580,
  EditNotificationRule = 581,
  ReadEditorQuest = 590,
  EditEditorQuest = 591,
  ImportExport = 600,
  ReadSearchList = 610,
  EditSearchList = 611,
  DisplaySearchList = 612,
  DeleteSearchList = 613,
  ReadResourcesByLocationDefinition = 620,
  EditResourcesByLocationDefinition = 621,
  ReadTicketsByLocationDefinition = 625,
  EditTicketsByLocationDefinition = 626,
  UseSetPassword = 630,
  ReadMailLog = 640,
  UseClassification = 650,
  ReadLabel = 651,
  EditLabel = 652,
  DisplayLabel = 653,
  DeleteLabel = 654,
  ModifyLabelManually = 655,
  IsOrgaUser = 1000,
  Manager = 1001,
  ShowsExpiredSubscription = 1008,
  IsAdmin = 1009,
  IsSystemAdmin = 1010,
  CanUseExpiredSubscription = 1011,
  Admin = 1012,
  IsOrgaUserInTech = 1016,
  IsQuickAccessIssueHistory = 1022,
  IsServiceEmployee = 1023,
  IsQuickAccessUser = 1024,
  IsQuickAccessKnowledge = 1025,
  IsQuickAccessIssueTemplate = 1026,
  IsQuickAccessDashboard = 1027,
  TeamUser = 1028,
  AnalystUser = 1029,
  TechUser = 1030,
  OrgaClassicModule = 2002,
  TechClassicModule = 2011,
  OrgaModule = 2012,
  MissionClassicAddon = 2014,
  ConnectedClassicAddon = 2015,
  SafeClassicAddon = 2016,
  LinkClassicAddon = 2017,
  TechModule = 2018,
  DataClassicModule = 2020,
  ClassificationClassicAddon = 2021,
  SyncLocationClassicAddon = 2022,
  DataModule = 2023,
  MissionAddon = 2025,
  ConnectedAddon = 2026,
  SafeAddon = 2027,
  LinkAddon = 2028,
  ClassificationAddon = 2029,
  CalendarMission = 2030,
  SyncLocationAddon = 2031,
  ReadCalendar = 2040,
  ReadSafe = 2050,
  MaintainSafe = 2052,
  AdministrateSafe = 2053,
  ReadResourceManufacturer = 2060,
  EditResourceManufacturer = 2061,
  DisplayResourceManufacturer = 2062,
  DeleteResourceManufacturer = 2063,
  OrgaTechClassicModule = 2070,
  SyncReceiptsClassicAddon = 2071,
  SyncContactsClassicAddon = 2072,
  SyncDriveClassicAddon = 2073,
  NotificationClassicAddon = 2075,
  OrgaTechModule = 2080,
  SyncReceiptsAddon = 2081,
  SyncContactsAddon = 2082,
  SyncDriveAddon = 2083,
  NotificationAddon = 2085,
  TeamModule = 2086,
  MdmClassicModule = 2087,
  MaintenanceReportAddon = 2088,
  PrimeReportAddon = 2089,
  ReadIssueTemplate = 2090,
  EditIssueTemplate = 2091,
  DisplayIssueTemplate = 2092,
  DeleteIssueTemplate = 2093,
  DeleteWinterhalterIssueTemplate = 2094,
  ReadMessageIssueContent = 2100,
  EditMessageIssueContent = 2101,
  ReadTicketIssueContent = 2102,
  EditTicketIssueContent = 2103,
  ReadMissionIssueContent = 2104,
  EditMissionIssueContent = 2105,
  ReadWinterhalterServiceCallIssueContent = 2106,
  EditWinterhalterServiceCallIssueContent = 2107,
  ReadWinterhalterProductOrderIssueContent = 2108,
  EditWinterhalterProductOrderIssueContent = 2109,
  EditIssue = 2111,
  DeleteIssue = 2113,
  SendIssue = 2114,
  ReadRecentIssue = 2115,
  ReadAllIssue = 2116,
  ReadIssue = 2117,
  UseMassImport = 2120,
  EditInvitation = 2131,
  ShowReportForMaintenance = 2140,
  ShowReportForPrime = 2141,
  ReadControl = 2151,
  EditControl = 2152,
  DisplayControl = 2153,
  DeleteControl = 2154,
  ReadImportConfiguration = 2160,
  EditImportConfiguration = 2161,
  DeleteImportConfiguration = 2162,
  DisplayImportConfiguration = 2163,
  ReadImportProtocol = 2170,
  EditImportProtocol = 2171,
  DeleteImportProtocol = 2172,
  DisplayImportProtocol = 2173,
}

/**
 * Defines values for AppShortcutType.
 * Possible values include: 'Base', 'Url', 'Route', 'CreateChat', 'CreateChatByProcess',
 * 'CreateChatByDefinition', 'OpenKnowledgeArticle', 'ShowDocumentFolder', 'ShowTaskArchive'
 * @readonly
 * @enum {string}
 */
export enum AppShortcutType {
  Base = 0,
  Url = 1,
  Route = 2,
  CreateChat = 3,
  CreateChatByProcess = 4,
  CreateChatByDefinition = 5,
  OpenKnowledgeArticle = 6,
  ShowDocumentFolder = 7,
  ShowTaskArchive = 8,
}

/**
 * Defines values for AppShortcutValueType.
 * Possible values include: 'Text', 'RelativeDate', 'Entity'
 * @readonly
 * @enum {string}
 */
export enum AppShortcutValueType {
  Text = 1,
  RelativeDate = 2,
  Entity = 3,
}

/**
 * Defines values for AppPermissions.
 * Possible values include: 'ReadTicket', 'CreateTicket', 'UpdateTicket', 'DeleteTicket',
 * 'ForceTicketState', 'AssignTicketEditor', 'AssignTicketToMe', 'AssignTicketResource',
 * 'ReadResource', 'CreateResource', 'UpdateResource', 'DeleteResource', 'ReadCustomProperty',
 * 'CreateCustomProperty', 'UpdateCustomProperty', 'DeleteCustomProperty', 'ReadCustomPropertySet',
 * 'CreateCustomPropertySet', 'UpdateCustomPropertySet', 'DeleteCustomPropertySet',
 * 'ReadStereotype', 'CreateStereotype', 'UpdateStereotype', 'DeleteStereotype', 'ReadSetting',
 * 'UpdateSetting', 'ReadCompany', 'CreateCompany', 'UpdateCompany', 'DeleteCompany',
 * 'ReadLocation', 'CreateLocation', 'UpdateLocation', 'DeleteLocation', 'ReadContact',
 * 'CreateContact', 'UpdateContact', 'DeleteContact', 'CreateSystemContact', 'ReadSystemContact',
 * 'UpdateSystemContact', 'DeleteSystemContact', 'ReadFunction', 'CreateFunction',
 * 'UpdateFunction', 'DeleteFunction', 'ReadAddress', 'CreateAddress', 'UpdateAddress',
 * 'DeleteAddress', 'ReadNote', 'CreateNote', 'UpdateNote', 'DeleteNote',
 * 'ReadNoteMailDroppedAttachment', 'ReadRole', 'CreateRole', 'UpdateRole', 'DeleteRole',
 * 'ReadAppPermissions', 'ReadTenant', 'CreateTenant', 'UpdateTenant', 'DeleteTenant',
 * 'ReadTenantUsage', 'ReadLoginProvider', 'CreateLoginProvider', 'UpdateLoginProvider',
 * 'DeleteLoginProvider', 'ReadDevice', 'CreateDevice', 'UpdateDevice', 'DeleteDevice',
 * 'UsePinLogin', 'UsePushRegister', 'UseDeviceRegister', 'UseExternalAppLogin', 'UseBadgeLogin',
 * 'ReadLocationGroup', 'CreateLocationGroup', 'UpdateLocationGroup', 'DeleteLocationGroup',
 * 'ReadInfo', 'CreateInfo', 'UpdateInfo', 'DeleteInfo', 'ReadTask', 'CreateTask', 'UpdateTask',
 * 'DeleteTask', 'ReadForm', 'CreateForm', 'UpdateForm', 'DeleteForm', 'ReadSensitiveForm',
 * 'ReadFilledForm', 'CreateFilledForm', 'UpdateFilledForm', 'DeleteFilledForm', 'ReadChat',
 * 'CreateChat', 'UpdateChat', 'DeleteChat', 'UseEmailPerChat', 'UseChatWithoutCloak',
 * 'ReadOverview', 'ReadKnowledgeArticle', 'CreateKnowledgeArticle', 'UpdateKnowledgeArticle',
 * 'DeleteKnowledgeArticle', 'ReadHolidaySet', 'CreateHolidaySet', 'UpdateHolidaySet',
 * 'DeleteHolidaySet', 'ReadFile', 'CreateFile', 'UpdateFile', 'DeleteFile', 'ReadLoginChallenge',
 * 'CreateLoginChallenge', 'UpdateLoginChallenge', 'DeleteLoginChallenge', 'ReadFilledFormField',
 * 'CreateFilledFormField', 'UpdateFilledFormField', 'DeleteFilledFormField', 'ReadFormRow',
 * 'CreateFormRow', 'UpdateFormRow', 'DeleteFormRow', 'ReadFormField', 'CreateFormField',
 * 'UpdateFormField', 'DeleteFormField', 'ReadAttachment', 'CreateAttachment', 'UpdateAttachment',
 * 'DeleteAttachment', 'ReadExecution', 'CreateExecution', 'UpdateExecution', 'DeleteExecution',
 * 'ReadImpact', 'CreateImpact', 'UpdateImpact', 'DeleteImpact', 'ReadCondition',
 * 'CreateCondition', 'UpdateCondition', 'DeleteCondition', 'ReadChatMessage', 'CreateChatMessage',
 * 'UpdateChatMessage', 'DeleteChatMessage', 'ReadChatMember', 'CreateChatMember',
 * 'UpdateChatMember', 'DeleteChatMember', 'ReadChatMemberMessage', 'CreateChatMemberMessage',
 * 'UpdateChatMemberMessage', 'DeleteChatMemberMessage', 'ReadHoliday', 'CreateHoliday',
 * 'UpdateHoliday', 'DeleteHoliday', 'ReadHistory', 'CreateHistory', 'UpdateHistory',
 * 'DeleteHistory', 'ReadTaskJob', 'CreateTaskJob', 'UpdateTaskJob', 'DeleteTaskJob',
 * 'ReadTaskJobStatus', 'CreateTaskJobStatus', 'UpdateTaskJobStatus', 'DeleteTaskJobStatus',
 * 'ReadFolderAccess', 'CreateFolderAccess', 'UpdateFolderAccess', 'DeleteFolderAccess',
 * 'UseAllFolderAccess', 'ReadTaskJobStateReasonSet', 'CreateTaskJobStateReasonSet',
 * 'UpdateTaskJobStateReasonSet', 'DeleteTaskJobStateReasonSet', 'ReadTaskJobStateReason',
 * 'CreateTaskJobStateReason', 'UpdateTaskJobStateReason', 'DeleteTaskJobStateReason', 'ReadPing',
 * 'CreatePing', 'UpdatePing', 'DeletePing', 'ReadMe', 'ReadProductSubscription',
 * 'CreateProductSubscription', 'UpdateProductSubscription', 'DeleteProductSubscription',
 * 'UseExpiredSubscription', 'ShowExpiredSubscription', 'UseAdmin', 'ReadGraph',
 * 'ReadDateTimePattern', 'CreateDateTimePattern', 'UpdateDateTimePattern',
 * 'DeleteDateTimePattern', 'ReadFormValue', 'CreateFormValue', 'UpdateFormValue',
 * 'DeleteFormValue', 'ReadPlannedTime', 'CreatePlannedTime', 'UpdatePlannedTime',
 * 'DeletePlannedTime', 'ReadTaskJobCondition', 'CreateTaskJobCondition', 'UpdateTaskJobCondition',
 * 'DeleteTaskJobCondition', 'ReadCustomValue', 'CreateCustomValue', 'UpdateCustomValue',
 * 'DeleteCustomValue', 'ReadCustomPropertyValue', 'CreateCustomPropertyValue',
 * 'UpdateCustomPropertyValue', 'DeleteCustomPropertyValue', 'ReadSettingValue',
 * 'CreateSettingValue', 'UpdateSettingValue', 'DeleteSettingValue', 'ReadState', 'CreateState',
 * 'UpdateState', 'DeleteState', 'ReadStateMachine', 'CreateStateMachine', 'UpdateStateMachine',
 * 'DeleteStateMachine', 'ReadStateTransition', 'CreateStateTransition', 'UpdateStateTransition',
 * 'DeleteStateTransition', 'ReadDataTable', 'CreateDataTable', 'UpdateDataTable',
 * 'DeleteDataTable', 'ExportDataTable', 'ReadDataTableColumn', 'CreateDataTableColumn',
 * 'UpdateDataTableColumn', 'DeleteDataTableColumn', 'ReadDashboard', 'CreateDashboard',
 * 'UpdateDashboard', 'DeleteDashboard', 'UseAllDashboard', 'ReadSolution', 'CreateSolution',
 * 'UpdateSolution', 'DeleteSolution', 'ReadSolutionItem', 'CreateSolutionItem',
 * 'UpdateSolutionItem', 'DeleteSolutionItem', 'ReadDataTableFilter', 'CreateDataTableFilter',
 * 'UpdateDataTableFilter', 'DeleteDataTableFilter', 'ReadPrioritySet', 'CreatePrioritySet',
 * 'UpdatePrioritySet', 'DeletePrioritySet', 'ReadPriority', 'CreatePriority', 'UpdatePriority',
 * 'DeletePriority', 'ReadEscalationLevel', 'CreateEscalationLevel', 'UpdateEscalationLevel',
 * 'DeleteEscalationLevel', 'ReadEscalationRule', 'CreateEscalationRule', 'UpdateEscalationRule',
 * 'DeleteEscalationRule', 'ReadTicketEvent', 'CreateTicketEvent', 'UpdateTicketEvent',
 * 'DeleteTicketEvent', 'ReadAudit', 'ReadMission', 'CreateMission', 'UpdateMission',
 * 'DeleteMission', 'UpdateActualMissionDates', 'UpdatePlannedMissionDates', 'UpdateClosedMission',
 * 'DisplayMissionMap', 'DisplayMissionPlan', 'AssignMissionResource', 'AssignMissionEditor',
 * 'AssignMissionToMe', 'ReadLink', 'CreateLink', 'UpdateLink', 'DeleteLink', 'ReadLinkedElement',
 * 'CreateLinkedElement', 'UpdateLinkedElement', 'DeleteLinkedElement', 'ReadAttachmentForTech',
 * 'CreateAttachmentForTech', 'UpdateAttachmentForTech', 'DeleteAttachmentForTech', 'ReadMailBox',
 * 'CreateMailBox', 'UpdateMailBox', 'DeleteMailBox', 'ReadTemplate', 'CreateTemplate',
 * 'UpdateTemplate', 'DeleteTemplate', 'ReadDocumentTemplate', 'CreateDocumentTemplate',
 * 'UpdateDocumentTemplate', 'DeleteDocumentTemplate', 'EditBetaDocumentTemplates',
 * 'ReadInventoryNumberPattern', 'CreateInventoryNumberPattern', 'UpdateInventoryNumberPattern',
 * 'DeleteInventoryNumberPattern', 'ReadInventoryNumber', 'CreateInventoryNumber',
 * 'UpdateInventoryNumber', 'DeleteInventoryNumber', 'ReadContract', 'CreateContract',
 * 'UpdateContract', 'DeleteContract', 'ReadResourceTaskSelector', 'CreateResourceTaskSelector',
 * 'UpdateResourceTaskSelector', 'DeleteResourceTaskSelector', 'ReadResourceTaskPattern',
 * 'CreateResourceTaskPattern', 'UpdateResourceTaskPattern', 'DeleteResourceTaskPattern',
 * 'ReadResourceTaskCondition', 'CreateResourceTaskCondition', 'UpdateResourceTaskCondition',
 * 'DeleteResourceTaskCondition', 'ReadResourceTask', 'CreateResourceTask', 'UpdateResourceTask',
 * 'DeleteResourceTask', 'ReadArticleKind', 'CreateArticleKind', 'UpdateArticleKind',
 * 'DeleteArticleKind', 'ReadMissionReport', 'CreateMissionReport', 'UpdateMissionReport',
 * 'DeleteMissionReport', 'ReadMissionReportGroup', 'CreateMissionReportGroup',
 * 'UpdateMissionReportGroup', 'DeleteMissionReportGroup', 'ReadArticleUsage',
 * 'CreateArticleUsage', 'UpdateArticleUsage', 'DeleteArticleUsage', 'ReadArticle',
 * 'CreateArticle', 'UpdateArticle', 'DeleteArticle', 'ReadAppOverview', 'CreateAppOverview',
 * 'UpdateAppOverview', 'DeleteAppOverview', 'ReadAppShortcut', 'CreateAppShortcut',
 * 'UpdateAppShortcut', 'DeleteAppShortcut', 'ReadAppShortcutParameter',
 * 'CreateAppShortcutParameter', 'UpdateAppShortcutParameter', 'DeleteAppShortcutParameter',
 * 'ReadAppShortcutValue', 'CreateAppShortcutValue', 'UpdateAppShortcutValue',
 * 'DeleteAppShortcutValue', 'ReadTicketByProcessDefinition', 'CreateTicketByProcessDefinition',
 * 'UpdateTicketByProcessDefinition', 'DeleteTicketByProcessDefinition', 'CreateTicketByProcess',
 * 'ReadChatSkeleton', 'CreateChatSkeleton', 'UpdateChatSkeleton', 'DeleteChatSkeleton',
 * 'ReadTicketSkeleton', 'CreateTicketSkeleton', 'UpdateTicketSkeleton', 'DeleteTicketSkeleton',
 * 'ReadResourceTicketSkeletonSelector', 'CreateResourceTicketSkeletonSelector',
 * 'UpdateResourceTicketSkeletonSelector', 'DeleteResourceTicketSkeletonSelector',
 * 'ReadMissionSkeleton', 'CreateMissionSkeleton', 'UpdateMissionSkeleton',
 * 'DeleteMissionSkeleton', 'ReadAuditOfCollection', 'ReadAuditOfProperty', 'ReadCategory',
 * 'CreateCategory', 'UpdateCategory', 'DeleteCategory', 'ReadChatByFormDefinition',
 * 'CreateChatByFormDefinition', 'UpdateChatByFormDefinition', 'DeleteChatByFormDefinition',
 * 'ReadChatByFormMessagePattern', 'CreateChatByFormMessagePattern',
 * 'UpdateChatByFormMessagePattern', 'DeleteChatByFormMessagePattern', 'ReadAreaAssignment',
 * 'CreateAreaAssignment', 'UpdateAreaAssignment', 'DeleteAreaAssignment', 'ReadDeviceMetaEntry',
 * 'CreateDeviceMetaEntry', 'UpdateDeviceMetaEntry', 'DeleteDeviceMetaEntry',
 * 'ReadMissionsPerLocationByMonth', 'ReadMissionsPerSolutionContactByMonth',
 * 'ReadMissionsPerLocationPerTypeByMonth', 'ReadTicketsPerLocationByMonth',
 * 'ReadTicketsPerLocationPerTypeByMonth', 'ReadMissionsPerType', 'ReadMissionsPerResource',
 * 'ReadMissionsPerSolutionContact', 'ReadMissionsPerResourceManufacturer',
 * 'ReadTicketsPerResourceManufacturer', 'ReadTicketsPerType', 'ReadMissionsPerLocationByTime',
 * 'ReadMissionsPerSolutionContactByTime', 'ReadMissionsPerLocationPerTypeByTime',
 * 'ReadTicketsPerLocationByTime', 'ReadTicketsPerLocationPerTypeByTime', 'ReadMissionReceipt',
 * 'CreateMissionReceipt', 'UpdateMissionReceipt', 'DeleteMissionReceipt',
 * 'ReadMissionReceiptReport', 'CreateMissionReceiptReport', 'UpdateMissionReceiptReport',
 * 'DeleteMissionReceiptReport', 'ReadApiKey', 'CreateApiKey', 'UpdateApiKey', 'DeleteApiKey',
 * 'ReadErrorCode', 'CreateErrorCode', 'UpdateErrorCode', 'DeleteErrorCode', 'ReadConsumptionCode',
 * 'CreateConsumptionCode', 'UpdateConsumptionCode', 'DeleteConsumptionCode',
 * 'ReadConnectedDefinition', 'CreateConnectedDefinition', 'UpdateConnectedDefinition',
 * 'DeleteConnectedDefinition', 'ReadOccurredConnectedError', 'CreateOccurredConnectedError',
 * 'UpdateOccurredConnectedError', 'DeleteOccurredConnectedError',
 * 'ReadOccurredConnectedConsumption', 'CreateOccurredConnectedConsumption',
 * 'UpdateOccurredConnectedConsumption', 'DeleteOccurredConnectedConsumption',
 * 'ReadTimelineSetting', 'CreateTimelineSetting', 'UpdateTimelineSetting',
 * 'DeleteTimelineSetting', 'UseGetMeTimeline', 'ReadMailNode', 'CreateMailNode', 'UpdateMailNode',
 * 'DeleteMailNode', 'ReadTicketMailNodeContent', 'CreateTicketMailNodeContent',
 * 'UpdateTicketMailNodeContent', 'DeleteTicketMailNodeContent',
 * 'CreateTicketByContractDefinition', 'ReadTicketByContractDefinition',
 * 'UpdateTicketByContractDefinition', 'DeleteTicketByContractDefinition', 'ReadEditorSuggestion',
 * 'CreateEditorSuggestion', 'UpdateEditorSuggestion', 'DeleteEditorSuggestion',
 * 'ReadEditorSuggestionTarget', 'CreateEditorSuggestionTarget', 'UpdateEditorSuggestionTarget',
 * 'DeleteEditorSuggestionTarget', 'ReadEditorQuest', 'CreateEditorQuest', 'UpdateEditorQuest',
 * 'DeleteEditorQuest', 'ReadEditorRequest', 'CreateEditorRequest', 'UpdateEditorRequest',
 * 'DeleteEditorRequest', 'DisplayEditorRequest', 'ReadResourceTaskConsumptionTrigger',
 * 'CreateResourceTaskConsumptionTrigger', 'UpdateResourceTaskConsumptionTrigger',
 * 'DeleteResourceTaskConsumptionTrigger', 'ReadOccurredConnectedConsumptionEntry',
 * 'CreateOccurredConnectedConsumptionEntry', 'UpdateOccurredConnectedConsumptionEntry',
 * 'DeleteOccurredConnectedConsumptionEntry', 'CreateNotificationRule', 'ReadNotificationRule',
 * 'UpdateNotificationRule', 'DeleteNotificationRule', 'CreateNotificationTarget',
 * 'ReadNotificationTarget', 'UpdateNotificationTarget', 'DeleteNotificationTarget',
 * 'ImportEntity', 'ExportEntity', 'ReadSearchList', 'CreateSearchList', 'UpdateSearchList',
 * 'DeleteSearchList', 'DisplaySearchLists', 'UseSetPassword', 'UseClassification', 'ReadLabel',
 * 'CreateLabel', 'UpdateLabel', 'DeleteLabel', 'DisplayLabels', 'ModifyLabelManually',
 * 'DisplayBackend', 'DisplayMasterData', 'DisplayContacts', 'DisplayFunctions',
 * 'DisplayLocations', 'DisplayLocationGroups', 'DisplayForms', 'DisplayTasks', 'DisplayInfos',
 * 'DisplayKnowledge', 'DisplayDevices', 'DisplayChats', 'DisplayDocuments', 'DisplayHolidays',
 * 'DisplayDashboard', 'DisplayManual', 'DisplayAddresses', 'DisplayCompanies', 'DisplayRole',
 * 'DisplayProductSubscription', 'DisplayStereoTypes', 'DisplaySettings', 'DisplayResources',
 * 'DisplayTickets', 'DisplayStateMachines', 'DisplayDataTables', 'DisplayDataDashboard',
 * 'DisplayPriorities', 'DisplayEscalations', 'DisplayMissions', 'DisplayInventoryNumberPatterns',
 * 'DisplayContracts', 'DisplayResourceTasks', 'DisplayArticles', 'DisplayAppOverviews',
 * 'DisplayProcesses', 'DisplayCategories', 'DisplayServices', 'DisplayConnectedDefinition',
 * 'DisplayMissionReceipts', 'DisplayMailNodes', 'DisplayPortalAnalytics',
 * 'DisplayNotificationRules', 'DisplayAppDashboard', 'DisplayAppInfos', 'DisplayAppTasks',
 * 'DisplayAppTaskArchive', 'DisplayAppKnowledge', 'DisplayAppDocuments', 'DisplayAppChats',
 * 'DisplayKnowledgeExam', 'ReadCalendarMission', 'CreateCalendarMission', 'UpdateCalendarMission',
 * 'DeleteCalendarMission', 'ReadMailLog', 'CreateMailLog', 'UpdateMailLog', 'DeleteMailLog',
 * 'ReadResourcesByLocationDefinition', 'CreateResourcesByLocationDefinition',
 * 'UpdateResourcesByLocationDefinition', 'DeleteResourcesByLocationDefinition',
 * 'ReadTicketsByLocationDefinition', 'CreateTicketsByLocationDefinition',
 * 'UpdateTicketsByLocationDefinition', 'DeleteTicketsByLocationDefinition',
 * 'ReadResourceSkeleton', 'CreateResourceSkeleton', 'UpdateResourceSkeleton',
 * 'DeleteResourceSkeleton', 'ReadTicketByLocationSkeleton', 'CreateTicketByLocationSkeleton',
 * 'UpdateTicketByLocationSkeleton', 'DeleteTicketByLocationSkeleton', 'ReadSafe', 'CreateSafe',
 * 'UpdateSafe', 'DeleteSafe', 'DisplaySafe', 'BypassSafe', 'ReadKnowledgePath',
 * 'CreateKnowledgePath', 'UpdateKnowledgePath', 'DeleteKnowledgePath',
 * 'ReadQuickAccessConfiguration', 'CreateQuickAccessConfiguration',
 * 'UpdateQuickAccessConfiguration', 'DeleteQuickAccessConfiguration',
 * 'DisplayQuickAccessConfiguration', 'ReadQuickAccessKnowledgeConfiguration',
 * 'ReadQuickAccessIssueTemplateConfiguration', 'ReadQuickAccessDashboardConfiguration',
 * 'ReadHintCode', 'CreateHintCode', 'UpdateHintCode', 'DeleteHintCode',
 * 'ReadOccurredConnectedHint', 'CreateOccurredConnectedHint', 'UpdateOccurredConnectedHint',
 * 'DeleteOccurredConnectedHint', 'ReadResourceEvent', 'CreateResourceEvent',
 * 'UpdateResourceEvent', 'DeleteResourceEvent', 'ReadResourceManufacturer',
 * 'CreateResourceManufacturer', 'UpdateResourceManufacturer', 'DeleteResourceManufacturer',
 * 'DisplayResourceManufacturers', 'ReadResourceModel', 'CreateResourceModel',
 * 'UpdateResourceModel', 'DeleteResourceModel', 'MergeResourceModel', 'ReadIssueTemplate',
 * 'CreateIssueTemplate', 'UpdateIssueTemplate', 'DeleteIssueTemplate', 'DisplayIssueTemplates',
 * 'ReadIssueNotification', 'CreateIssueNotification', 'UpdateIssueNotification',
 * 'DeleteIssueNotification', 'ReadMessageIssueContent', 'CreateMessageIssueContent',
 * 'UpdateMessageIssueContent', 'DeleteMessageIssueContent', 'ReadWinterhalterOrder',
 * 'ReadWinterhalterOrderOperation', 'ReadIssue', 'CreateIssue', 'UpdateIssue', 'DeleteIssue',
 * 'ReadAllIssue', 'ReadRecentIssue', 'ReadUserIssue', 'ReadWinterhalterServiceCallIssue',
 * 'ReadWinterhalterProductOrderIssue', 'ReadWinterhalterProductOrder',
 * 'ReadOccurredConnectedHintHistory', 'ReadOccurredConnectedErrorHistory',
 * 'ReadOccurredConnectedConsumptionHistory', 'UseMassImport', 'ReadInvitation',
 * 'CreateInvitation', 'UpdateInvitation', 'DeleteInvitation', 'ShowReportForMaintenance',
 * 'ShowReportForPrime', 'ReadKnowledgeExam', 'CreateKnowledgeExam', 'UpdateKnowledgeExam',
 * 'DeleteKnowledgeExam', 'ReadKnowledgeExamAnswer', 'CreateKnowledgeExamAnswer',
 * 'UpdateKnowledgeExamAnswer', 'DeleteKnowledgeExamAnswer', 'ReadTicketIssueContent',
 * 'CreateTicketIssueContent', 'UpdateTicketIssueContent', 'DeleteTicketIssueContent',
 * 'ReadMissionIssueContent', 'CreateMissionIssueContent', 'UpdateMissionIssueContent',
 * 'DeleteMissionIssueContent', 'ReadWinterhalterServiceIssueContent',
 * 'CreateWinterhalterServiceIssueContent', 'UpdateWinterhalterServiceIssueContent',
 * 'DeleteWinterhalterServiceIssueContent', 'ReadWinterhalterOrderIssueContent',
 * 'CreateWinterhalterOrderIssueContent', 'UpdateWinterhalterOrderIssueContent',
 * 'DeleteWinterhalterOrderIssueContent', 'ReadMessageControlContent',
 * 'CreateMessageControlContent', 'UpdateMessageControlContent', 'DeleteMessageControlContent',
 * 'ReadMissionControlContent', 'CreateMissionControlContent', 'UpdateMissionControlContent',
 * 'DeleteMissionControlContent', 'ReadControl', 'CreateControl', 'UpdateControl', 'DeleteControl',
 * 'DisplayControls', 'ReadControlCondition', 'CreateControlCondition', 'UpdateControlCondition',
 * 'DeleteControlCondition', 'ReadControlTrigger', 'CreateControlTrigger', 'UpdateControlTrigger',
 * 'DeleteControlTrigger', 'ReadControlNotification', 'CreateControlNotification',
 * 'UpdateControlNotification', 'DeleteControlNotification', 'ReadControlIssue', 'ReadIssueReason',
 * 'ReadImportConfiguration', 'CreateImportConfiguration', 'UpdateImportConfiguration',
 * 'DeleteImportConfiguration', 'DisplayImportConfigurations', 'ReadImportProtocol',
 * 'CreateImportProtocol', 'UpdateImportProtocol', 'DeleteImportProtocol', 'DisplayImportProtocol',
 * 'ReadWinterhalterServiceCallMailNodeContent', 'CreateWinterhalterServiceCallMailNodeContent',
 * 'UpdateWinterhalterServiceCallMailNodeContent', 'DeleteWinterhalterServiceCallMailNodeContent',
 * 'ReadMailNodeNotification', 'CreateMailNodeNotification', 'UpdateMailNodeNotification',
 * 'DeleteMailNodeNotification', 'ReadKnowledgePathProgress', 'ReadMailReceiver',
 * 'CreateMailReceiver', 'UpdateMailReceiver', 'DeleteMailReceiver', 'MergeResourceManufacturer'
 * @readonly
 * @enum {string}
 */
export enum AppPermissions {
  ReadTicket = 10,
  CreateTicket = 11,
  UpdateTicket = 12,
  DeleteTicket = 13,
  ForceTicketState = 14,
  AssignTicketEditor = 15,
  AssignTicketToMe = 16,
  AssignTicketResource = 17,
  ReadResource = 20,
  CreateResource = 21,
  UpdateResource = 22,
  DeleteResource = 23,
  ReadCustomProperty = 40,
  CreateCustomProperty = 41,
  UpdateCustomProperty = 42,
  DeleteCustomProperty = 43,
  ReadCustomPropertySet = 50,
  CreateCustomPropertySet = 51,
  UpdateCustomPropertySet = 52,
  DeleteCustomPropertySet = 53,
  ReadStereotype = 60,
  CreateStereotype = 61,
  UpdateStereotype = 62,
  DeleteStereotype = 63,
  ReadSetting = 70,
  UpdateSetting = 71,
  ReadCompany = 80,
  CreateCompany = 81,
  UpdateCompany = 82,
  DeleteCompany = 83,
  ReadLocation = 90,
  CreateLocation = 91,
  UpdateLocation = 92,
  DeleteLocation = 93,
  ReadContact = 100,
  CreateContact = 101,
  UpdateContact = 102,
  DeleteContact = 103,
  CreateSystemContact = 104,
  ReadSystemContact = 105,
  UpdateSystemContact = 106,
  DeleteSystemContact = 107,
  ReadFunction = 110,
  CreateFunction = 111,
  UpdateFunction = 112,
  DeleteFunction = 113,
  ReadAddress = 120,
  CreateAddress = 121,
  UpdateAddress = 123,
  DeleteAddress = 124,
  ReadNote = 130,
  CreateNote = 131,
  UpdateNote = 132,
  DeleteNote = 133,
  ReadNoteMailDroppedAttachment = 134,
  ReadRole = 140,
  CreateRole = 141,
  UpdateRole = 142,
  DeleteRole = 143,
  ReadAppPermissions = 150,
  ReadTenant = 160,
  CreateTenant = 161,
  UpdateTenant = 162,
  DeleteTenant = 163,
  ReadTenantUsage = 164,
  ReadLoginProvider = 170,
  CreateLoginProvider = 171,
  UpdateLoginProvider = 172,
  DeleteLoginProvider = 173,
  ReadDevice = 190,
  CreateDevice = 191,
  UpdateDevice = 192,
  DeleteDevice = 193,
  UsePinLogin = 200,
  UsePushRegister = 201,
  UseDeviceRegister = 202,
  UseExternalAppLogin = 203,
  UseBadgeLogin = 204,
  ReadLocationGroup = 210,
  CreateLocationGroup = 211,
  UpdateLocationGroup = 212,
  DeleteLocationGroup = 213,
  ReadInfo = 230,
  CreateInfo = 231,
  UpdateInfo = 232,
  DeleteInfo = 233,
  ReadTask = 240,
  CreateTask = 241,
  UpdateTask = 242,
  DeleteTask = 243,
  ReadForm = 250,
  CreateForm = 251,
  UpdateForm = 252,
  DeleteForm = 253,
  ReadSensitiveForm = 254,
  ReadFilledForm = 260,
  CreateFilledForm = 261,
  UpdateFilledForm = 262,
  DeleteFilledForm = 263,
  ReadChat = 270,
  CreateChat = 271,
  UpdateChat = 272,
  DeleteChat = 273,
  UseEmailPerChat = 274,
  UseChatWithoutCloak = 275,
  ReadOverview = 280,
  ReadKnowledgeArticle = 290,
  CreateKnowledgeArticle = 291,
  UpdateKnowledgeArticle = 292,
  DeleteKnowledgeArticle = 293,
  ReadHolidaySet = 300,
  CreateHolidaySet = 301,
  UpdateHolidaySet = 302,
  DeleteHolidaySet = 303,
  ReadFile = 310,
  CreateFile = 311,
  UpdateFile = 312,
  DeleteFile = 313,
  ReadLoginChallenge = 320,
  CreateLoginChallenge = 321,
  UpdateLoginChallenge = 322,
  DeleteLoginChallenge = 323,
  ReadFilledFormField = 330,
  CreateFilledFormField = 331,
  UpdateFilledFormField = 332,
  DeleteFilledFormField = 333,
  ReadFormRow = 340,
  CreateFormRow = 341,
  UpdateFormRow = 342,
  DeleteFormRow = 343,
  ReadFormField = 350,
  CreateFormField = 351,
  UpdateFormField = 352,
  DeleteFormField = 353,
  ReadAttachment = 360,
  CreateAttachment = 361,
  UpdateAttachment = 362,
  DeleteAttachment = 363,
  ReadExecution = 370,
  CreateExecution = 371,
  UpdateExecution = 372,
  DeleteExecution = 373,
  ReadImpact = 380,
  CreateImpact = 381,
  UpdateImpact = 382,
  DeleteImpact = 383,
  ReadCondition = 390,
  CreateCondition = 391,
  UpdateCondition = 392,
  DeleteCondition = 393,
  ReadChatMessage = 400,
  CreateChatMessage = 401,
  UpdateChatMessage = 402,
  DeleteChatMessage = 403,
  ReadChatMember = 410,
  CreateChatMember = 411,
  UpdateChatMember = 412,
  DeleteChatMember = 413,
  ReadChatMemberMessage = 420,
  CreateChatMemberMessage = 421,
  UpdateChatMemberMessage = 422,
  DeleteChatMemberMessage = 423,
  ReadHoliday = 430,
  CreateHoliday = 431,
  UpdateHoliday = 432,
  DeleteHoliday = 433,
  ReadHistory = 440,
  CreateHistory = 441,
  UpdateHistory = 442,
  DeleteHistory = 443,
  ReadTaskJob = 450,
  CreateTaskJob = 451,
  UpdateTaskJob = 452,
  DeleteTaskJob = 453,
  ReadTaskJobStatus = 460,
  CreateTaskJobStatus = 461,
  UpdateTaskJobStatus = 462,
  DeleteTaskJobStatus = 463,
  ReadFolderAccess = 470,
  CreateFolderAccess = 471,
  UpdateFolderAccess = 472,
  DeleteFolderAccess = 473,
  UseAllFolderAccess = 474,
  ReadTaskJobStateReasonSet = 480,
  CreateTaskJobStateReasonSet = 481,
  UpdateTaskJobStateReasonSet = 482,
  DeleteTaskJobStateReasonSet = 483,
  ReadTaskJobStateReason = 490,
  CreateTaskJobStateReason = 491,
  UpdateTaskJobStateReason = 492,
  DeleteTaskJobStateReason = 493,
  ReadPing = 500,
  CreatePing = 501,
  UpdatePing = 502,
  DeletePing = 503,
  ReadMe = 520,
  ReadProductSubscription = 530,
  CreateProductSubscription = 531,
  UpdateProductSubscription = 532,
  DeleteProductSubscription = 533,
  UseExpiredSubscription = 535,
  ShowExpiredSubscription = 536,
  UseAdmin = 540,
  ReadGraph = 550,
  ReadDateTimePattern = 580,
  CreateDateTimePattern = 581,
  UpdateDateTimePattern = 582,
  DeleteDateTimePattern = 583,
  ReadFormValue = 590,
  CreateFormValue = 591,
  UpdateFormValue = 592,
  DeleteFormValue = 593,
  ReadPlannedTime = 600,
  CreatePlannedTime = 601,
  UpdatePlannedTime = 602,
  DeletePlannedTime = 603,
  ReadTaskJobCondition = 610,
  CreateTaskJobCondition = 611,
  UpdateTaskJobCondition = 612,
  DeleteTaskJobCondition = 613,
  ReadCustomValue = 620,
  CreateCustomValue = 621,
  UpdateCustomValue = 622,
  DeleteCustomValue = 623,
  ReadCustomPropertyValue = 630,
  CreateCustomPropertyValue = 631,
  UpdateCustomPropertyValue = 632,
  DeleteCustomPropertyValue = 633,
  ReadSettingValue = 640,
  CreateSettingValue = 641,
  UpdateSettingValue = 642,
  DeleteSettingValue = 643,
  ReadState = 650,
  CreateState = 651,
  UpdateState = 652,
  DeleteState = 653,
  ReadStateMachine = 660,
  CreateStateMachine = 661,
  UpdateStateMachine = 662,
  DeleteStateMachine = 663,
  ReadStateTransition = 670,
  CreateStateTransition = 671,
  UpdateStateTransition = 672,
  DeleteStateTransition = 673,
  ReadDataTable = 680,
  CreateDataTable = 681,
  UpdateDataTable = 682,
  DeleteDataTable = 683,
  ExportDataTable = 684,
  ReadDataTableColumn = 690,
  CreateDataTableColumn = 691,
  UpdateDataTableColumn = 692,
  DeleteDataTableColumn = 693,
  ReadDashboard = 700,
  CreateDashboard = 701,
  UpdateDashboard = 702,
  DeleteDashboard = 703,
  UseAllDashboard = 704,
  ReadSolution = 710,
  CreateSolution = 711,
  UpdateSolution = 712,
  DeleteSolution = 713,
  ReadSolutionItem = 720,
  CreateSolutionItem = 721,
  UpdateSolutionItem = 722,
  DeleteSolutionItem = 723,
  ReadDataTableFilter = 730,
  CreateDataTableFilter = 731,
  UpdateDataTableFilter = 732,
  DeleteDataTableFilter = 733,
  ReadPrioritySet = 740,
  CreatePrioritySet = 741,
  UpdatePrioritySet = 742,
  DeletePrioritySet = 743,
  ReadPriority = 750,
  CreatePriority = 751,
  UpdatePriority = 752,
  DeletePriority = 753,
  ReadEscalationLevel = 790,
  CreateEscalationLevel = 791,
  UpdateEscalationLevel = 792,
  DeleteEscalationLevel = 793,
  ReadEscalationRule = 800,
  CreateEscalationRule = 801,
  UpdateEscalationRule = 802,
  DeleteEscalationRule = 803,
  ReadTicketEvent = 810,
  CreateTicketEvent = 811,
  UpdateTicketEvent = 812,
  DeleteTicketEvent = 813,
  ReadAudit = 820,
  ReadMission = 830,
  CreateMission = 831,
  UpdateMission = 832,
  DeleteMission = 833,
  UpdateActualMissionDates = 834,
  UpdatePlannedMissionDates = 835,
  UpdateClosedMission = 836,
  DisplayMissionMap = 838,
  DisplayMissionPlan = 839,
  AssignMissionResource = 840,
  AssignMissionEditor = 841,
  AssignMissionToMe = 842,
  ReadLink = 850,
  CreateLink = 851,
  UpdateLink = 852,
  DeleteLink = 853,
  ReadLinkedElement = 860,
  CreateLinkedElement = 861,
  UpdateLinkedElement = 862,
  DeleteLinkedElement = 863,
  ReadAttachmentForTech = 870,
  CreateAttachmentForTech = 871,
  UpdateAttachmentForTech = 872,
  DeleteAttachmentForTech = 873,
  ReadMailBox = 880,
  CreateMailBox = 881,
  UpdateMailBox = 882,
  DeleteMailBox = 883,
  ReadTemplate = 890,
  CreateTemplate = 891,
  UpdateTemplate = 892,
  DeleteTemplate = 893,
  ReadDocumentTemplate = 900,
  CreateDocumentTemplate = 901,
  UpdateDocumentTemplate = 902,
  DeleteDocumentTemplate = 903,
  EditBetaDocumentTemplates = 904,
  ReadInventoryNumberPattern = 910,
  CreateInventoryNumberPattern = 911,
  UpdateInventoryNumberPattern = 912,
  DeleteInventoryNumberPattern = 913,
  ReadInventoryNumber = 920,
  CreateInventoryNumber = 921,
  UpdateInventoryNumber = 922,
  DeleteInventoryNumber = 923,
  ReadContract = 930,
  CreateContract = 931,
  UpdateContract = 932,
  DeleteContract = 933,
  ReadResourceTaskSelector = 940,
  CreateResourceTaskSelector = 941,
  UpdateResourceTaskSelector = 942,
  DeleteResourceTaskSelector = 943,
  ReadResourceTaskPattern = 950,
  CreateResourceTaskPattern = 951,
  UpdateResourceTaskPattern = 952,
  DeleteResourceTaskPattern = 953,
  ReadResourceTaskCondition = 960,
  CreateResourceTaskCondition = 961,
  UpdateResourceTaskCondition = 962,
  DeleteResourceTaskCondition = 963,
  ReadResourceTask = 970,
  CreateResourceTask = 971,
  UpdateResourceTask = 972,
  DeleteResourceTask = 973,
  ReadArticleKind = 980,
  CreateArticleKind = 981,
  UpdateArticleKind = 982,
  DeleteArticleKind = 983,
  ReadMissionReport = 990,
  CreateMissionReport = 991,
  UpdateMissionReport = 992,
  DeleteMissionReport = 993,
  ReadMissionReportGroup = 1000,
  CreateMissionReportGroup = 1001,
  UpdateMissionReportGroup = 1002,
  DeleteMissionReportGroup = 1003,
  ReadArticleUsage = 1010,
  CreateArticleUsage = 1011,
  UpdateArticleUsage = 1012,
  DeleteArticleUsage = 1013,
  ReadArticle = 1020,
  CreateArticle = 1021,
  UpdateArticle = 1022,
  DeleteArticle = 1023,
  ReadAppOverview = 1070,
  CreateAppOverview = 1071,
  UpdateAppOverview = 1072,
  DeleteAppOverview = 1073,
  ReadAppShortcut = 1080,
  CreateAppShortcut = 1081,
  UpdateAppShortcut = 1082,
  DeleteAppShortcut = 1083,
  ReadAppShortcutParameter = 1090,
  CreateAppShortcutParameter = 1091,
  UpdateAppShortcutParameter = 1092,
  DeleteAppShortcutParameter = 1093,
  ReadAppShortcutValue = 1100,
  CreateAppShortcutValue = 1101,
  UpdateAppShortcutValue = 1102,
  DeleteAppShortcutValue = 1103,
  ReadTicketByProcessDefinition = 1110,
  CreateTicketByProcessDefinition = 1111,
  UpdateTicketByProcessDefinition = 1112,
  DeleteTicketByProcessDefinition = 1113,
  CreateTicketByProcess = 1114,
  ReadChatSkeleton = 1120,
  CreateChatSkeleton = 1121,
  UpdateChatSkeleton = 1122,
  DeleteChatSkeleton = 1123,
  ReadTicketSkeleton = 1130,
  CreateTicketSkeleton = 1131,
  UpdateTicketSkeleton = 1132,
  DeleteTicketSkeleton = 1133,
  ReadResourceTicketSkeletonSelector = 1134,
  CreateResourceTicketSkeletonSelector = 1135,
  UpdateResourceTicketSkeletonSelector = 1136,
  DeleteResourceTicketSkeletonSelector = 1137,
  ReadMissionSkeleton = 1140,
  CreateMissionSkeleton = 1141,
  UpdateMissionSkeleton = 1142,
  DeleteMissionSkeleton = 1143,
  ReadAuditOfCollection = 1150,
  ReadAuditOfProperty = 1160,
  ReadCategory = 1170,
  CreateCategory = 1171,
  UpdateCategory = 1172,
  DeleteCategory = 1173,
  ReadChatByFormDefinition = 1180,
  CreateChatByFormDefinition = 1181,
  UpdateChatByFormDefinition = 1182,
  DeleteChatByFormDefinition = 1183,
  ReadChatByFormMessagePattern = 1190,
  CreateChatByFormMessagePattern = 1191,
  UpdateChatByFormMessagePattern = 1192,
  DeleteChatByFormMessagePattern = 1193,
  ReadAreaAssignment = 1200,
  CreateAreaAssignment = 1201,
  UpdateAreaAssignment = 1202,
  DeleteAreaAssignment = 1203,
  ReadDeviceMetaEntry = 1211,
  CreateDeviceMetaEntry = 1212,
  UpdateDeviceMetaEntry = 1213,
  DeleteDeviceMetaEntry = 1214,
  ReadMissionsPerLocationByMonth = 1221,
  ReadMissionsPerSolutionContactByMonth = 1222,
  ReadMissionsPerLocationPerTypeByMonth = 1223,
  ReadTicketsPerLocationByMonth = 1224,
  ReadTicketsPerLocationPerTypeByMonth = 1225,
  ReadMissionsPerType = 1226,
  ReadMissionsPerResource = 1227,
  ReadMissionsPerSolutionContact = 1228,
  ReadMissionsPerResourceManufacturer = 1229,
  ReadTicketsPerResourceManufacturer = 1230,
  ReadTicketsPerType = 1231,
  ReadMissionsPerLocationByTime = 1232,
  ReadMissionsPerSolutionContactByTime = 1233,
  ReadMissionsPerLocationPerTypeByTime = 1234,
  ReadTicketsPerLocationByTime = 1235,
  ReadTicketsPerLocationPerTypeByTime = 1236,
  ReadMissionReceipt = 1251,
  CreateMissionReceipt = 1252,
  UpdateMissionReceipt = 1253,
  DeleteMissionReceipt = 1254,
  ReadMissionReceiptReport = 1261,
  CreateMissionReceiptReport = 1262,
  UpdateMissionReceiptReport = 1263,
  DeleteMissionReceiptReport = 1264,
  ReadApiKey = 1271,
  CreateApiKey = 1272,
  UpdateApiKey = 1273,
  DeleteApiKey = 1274,
  ReadErrorCode = 1341,
  CreateErrorCode = 1342,
  UpdateErrorCode = 1343,
  DeleteErrorCode = 1344,
  ReadConsumptionCode = 1351,
  CreateConsumptionCode = 1352,
  UpdateConsumptionCode = 1353,
  DeleteConsumptionCode = 1354,
  ReadConnectedDefinition = 1361,
  CreateConnectedDefinition = 1362,
  UpdateConnectedDefinition = 1363,
  DeleteConnectedDefinition = 1364,
  ReadOccurredConnectedError = 1371,
  CreateOccurredConnectedError = 1372,
  UpdateOccurredConnectedError = 1373,
  DeleteOccurredConnectedError = 1374,
  ReadOccurredConnectedConsumption = 1381,
  CreateOccurredConnectedConsumption = 1382,
  UpdateOccurredConnectedConsumption = 1383,
  DeleteOccurredConnectedConsumption = 1384,
  ReadTimelineSetting = 1391,
  CreateTimelineSetting = 1392,
  UpdateTimelineSetting = 1393,
  DeleteTimelineSetting = 1394,
  UseGetMeTimeline = 1400,
  ReadMailNode = 1410,
  CreateMailNode = 1412,
  UpdateMailNode = 1413,
  DeleteMailNode = 1414,
  ReadTicketMailNodeContent = 1420,
  CreateTicketMailNodeContent = 1422,
  UpdateTicketMailNodeContent = 1423,
  DeleteTicketMailNodeContent = 1424,
  CreateTicketByContractDefinition = 1430,
  ReadTicketByContractDefinition = 1431,
  UpdateTicketByContractDefinition = 1432,
  DeleteTicketByContractDefinition = 1433,
  ReadEditorSuggestion = 1440,
  CreateEditorSuggestion = 1441,
  UpdateEditorSuggestion = 1442,
  DeleteEditorSuggestion = 1443,
  ReadEditorSuggestionTarget = 1450,
  CreateEditorSuggestionTarget = 1451,
  UpdateEditorSuggestionTarget = 1452,
  DeleteEditorSuggestionTarget = 1453,
  ReadEditorQuest = 1460,
  CreateEditorQuest = 1461,
  UpdateEditorQuest = 1462,
  DeleteEditorQuest = 1463,
  ReadEditorRequest = 1470,
  CreateEditorRequest = 1471,
  UpdateEditorRequest = 1472,
  DeleteEditorRequest = 1473,
  DisplayEditorRequest = 1474,
  ReadResourceTaskConsumptionTrigger = 1480,
  CreateResourceTaskConsumptionTrigger = 1481,
  UpdateResourceTaskConsumptionTrigger = 1482,
  DeleteResourceTaskConsumptionTrigger = 1483,
  ReadOccurredConnectedConsumptionEntry = 1490,
  CreateOccurredConnectedConsumptionEntry = 1491,
  UpdateOccurredConnectedConsumptionEntry = 1492,
  DeleteOccurredConnectedConsumptionEntry = 1493,
  CreateNotificationRule = 1500,
  ReadNotificationRule = 1501,
  UpdateNotificationRule = 1502,
  DeleteNotificationRule = 1503,
  CreateNotificationTarget = 1510,
  ReadNotificationTarget = 1511,
  UpdateNotificationTarget = 1512,
  DeleteNotificationTarget = 1513,
  ImportEntity = 1520,
  ExportEntity = 1521,
  ReadSearchList = 1531,
  CreateSearchList = 1532,
  UpdateSearchList = 1533,
  DeleteSearchList = 1534,
  DisplaySearchLists = 1535,
  UseSetPassword = 1550,
  UseClassification = 1560,
  ReadLabel = 1561,
  CreateLabel = 1562,
  UpdateLabel = 1563,
  DeleteLabel = 1564,
  DisplayLabels = 1565,
  ModifyLabelManually = 1566,
  DisplayBackend = 10000,
  DisplayMasterData = 10100,
  DisplayContacts = 10101,
  DisplayFunctions = 10102,
  DisplayLocations = 10103,
  DisplayLocationGroups = 10104,
  DisplayForms = 10105,
  DisplayTasks = 10106,
  DisplayInfos = 10107,
  DisplayKnowledge = 10108,
  DisplayDevices = 10109,
  DisplayChats = 10110,
  DisplayDocuments = 10111,
  DisplayHolidays = 10112,
  DisplayDashboard = 10113,
  DisplayManual = 10114,
  DisplayAddresses = 10115,
  DisplayCompanies = 10116,
  DisplayRole = 10117,
  DisplayProductSubscription = 10118,
  DisplayStereoTypes = 10119,
  DisplaySettings = 10120,
  DisplayResources = 10121,
  DisplayTickets = 10122,
  DisplayStateMachines = 10123,
  DisplayDataTables = 10124,
  DisplayDataDashboard = 10125,
  DisplayPriorities = 10126,
  DisplayEscalations = 10128,
  DisplayMissions = 10129,
  DisplayInventoryNumberPatterns = 10130,
  DisplayContracts = 10131,
  DisplayResourceTasks = 10132,
  DisplayArticles = 10133,
  DisplayAppOverviews = 10135,
  DisplayProcesses = 10136,
  DisplayCategories = 10137,
  DisplayServices = 10138,
  DisplayConnectedDefinition = 10140,
  DisplayMissionReceipts = 10141,
  DisplayMailNodes = 10142,
  DisplayPortalAnalytics = 10143,
  DisplayNotificationRules = 10144,
  DisplayAppDashboard = 10201,
  DisplayAppInfos = 10202,
  DisplayAppTasks = 10203,
  DisplayAppTaskArchive = 10204,
  DisplayAppKnowledge = 10205,
  DisplayAppDocuments = 10206,
  DisplayAppChats = 10207,
  DisplayKnowledgeExam = 10208,
  ReadCalendarMission = 10301,
  CreateCalendarMission = 10302,
  UpdateCalendarMission = 10303,
  DeleteCalendarMission = 10304,
  ReadMailLog = 10311,
  CreateMailLog = 10312,
  UpdateMailLog = 10313,
  DeleteMailLog = 10314,
  ReadResourcesByLocationDefinition = 10510,
  CreateResourcesByLocationDefinition = 10511,
  UpdateResourcesByLocationDefinition = 10512,
  DeleteResourcesByLocationDefinition = 10513,
  ReadTicketsByLocationDefinition = 10520,
  CreateTicketsByLocationDefinition = 10521,
  UpdateTicketsByLocationDefinition = 10522,
  DeleteTicketsByLocationDefinition = 10523,
  ReadResourceSkeleton = 10530,
  CreateResourceSkeleton = 10531,
  UpdateResourceSkeleton = 10532,
  DeleteResourceSkeleton = 10533,
  ReadTicketByLocationSkeleton = 10540,
  CreateTicketByLocationSkeleton = 10541,
  UpdateTicketByLocationSkeleton = 10542,
  DeleteTicketByLocationSkeleton = 10543,
  ReadSafe = 10550,
  CreateSafe = 10551,
  UpdateSafe = 10552,
  DeleteSafe = 10553,
  DisplaySafe = 10554,
  BypassSafe = 10555,
  ReadKnowledgePath = 10561,
  CreateKnowledgePath = 10562,
  UpdateKnowledgePath = 10563,
  DeleteKnowledgePath = 10564,
  ReadQuickAccessConfiguration = 10570,
  CreateQuickAccessConfiguration = 10571,
  UpdateQuickAccessConfiguration = 10572,
  DeleteQuickAccessConfiguration = 10573,
  DisplayQuickAccessConfiguration = 10574,
  ReadQuickAccessKnowledgeConfiguration = 10580,
  ReadQuickAccessIssueTemplateConfiguration = 10590,
  ReadQuickAccessDashboardConfiguration = 10591,
  ReadHintCode = 10601,
  CreateHintCode = 10602,
  UpdateHintCode = 10603,
  DeleteHintCode = 10604,
  ReadOccurredConnectedHint = 10605,
  CreateOccurredConnectedHint = 10606,
  UpdateOccurredConnectedHint = 10607,
  DeleteOccurredConnectedHint = 10608,
  ReadResourceEvent = 10610,
  CreateResourceEvent = 10611,
  UpdateResourceEvent = 10612,
  DeleteResourceEvent = 10613,
  ReadResourceManufacturer = 10620,
  CreateResourceManufacturer = 10621,
  UpdateResourceManufacturer = 10622,
  DeleteResourceManufacturer = 10623,
  DisplayResourceManufacturers = 10624,
  ReadResourceModel = 10630,
  CreateResourceModel = 10631,
  UpdateResourceModel = 10632,
  DeleteResourceModel = 10633,
  MergeResourceModel = 10634,
  ReadIssueTemplate = 10640,
  CreateIssueTemplate = 10641,
  UpdateIssueTemplate = 10642,
  DeleteIssueTemplate = 10643,
  DisplayIssueTemplates = 10644,
  ReadIssueNotification = 10646,
  CreateIssueNotification = 10647,
  UpdateIssueNotification = 10648,
  DeleteIssueNotification = 10649,
  ReadMessageIssueContent = 10650,
  CreateMessageIssueContent = 10651,
  UpdateMessageIssueContent = 10652,
  DeleteMessageIssueContent = 10653,
  ReadWinterhalterOrder = 10658,
  ReadWinterhalterOrderOperation = 10659,
  ReadIssue = 10660,
  CreateIssue = 10661,
  UpdateIssue = 10662,
  DeleteIssue = 10663,
  ReadAllIssue = 10664,
  ReadRecentIssue = 10665,
  ReadUserIssue = 10666,
  ReadWinterhalterServiceCallIssue = 10667,
  ReadWinterhalterProductOrderIssue = 10668,
  ReadWinterhalterProductOrder = 10669,
  ReadOccurredConnectedHintHistory = 10670,
  ReadOccurredConnectedErrorHistory = 10671,
  ReadOccurredConnectedConsumptionHistory = 10672,
  UseMassImport = 10673,
  ReadInvitation = 10680,
  CreateInvitation = 10681,
  UpdateInvitation = 10682,
  DeleteInvitation = 10683,
  ShowReportForMaintenance = 10710,
  ShowReportForPrime = 10711,
  ReadKnowledgeExam = 10720,
  CreateKnowledgeExam = 10721,
  UpdateKnowledgeExam = 10723,
  DeleteKnowledgeExam = 10724,
  ReadKnowledgeExamAnswer = 10730,
  CreateKnowledgeExamAnswer = 10731,
  UpdateKnowledgeExamAnswer = 10733,
  DeleteKnowledgeExamAnswer = 10734,
  ReadTicketIssueContent = 10740,
  CreateTicketIssueContent = 10741,
  UpdateTicketIssueContent = 10742,
  DeleteTicketIssueContent = 10743,
  ReadMissionIssueContent = 10750,
  CreateMissionIssueContent = 10751,
  UpdateMissionIssueContent = 10752,
  DeleteMissionIssueContent = 10753,
  ReadWinterhalterServiceIssueContent = 10760,
  CreateWinterhalterServiceIssueContent = 10761,
  UpdateWinterhalterServiceIssueContent = 10762,
  DeleteWinterhalterServiceIssueContent = 10763,
  ReadWinterhalterOrderIssueContent = 10770,
  CreateWinterhalterOrderIssueContent = 10771,
  UpdateWinterhalterOrderIssueContent = 10772,
  DeleteWinterhalterOrderIssueContent = 10773,
  ReadMessageControlContent = 10780,
  CreateMessageControlContent = 10781,
  UpdateMessageControlContent = 10782,
  DeleteMessageControlContent = 10783,
  ReadMissionControlContent = 10790,
  CreateMissionControlContent = 10791,
  UpdateMissionControlContent = 10792,
  DeleteMissionControlContent = 10793,
  ReadControl = 10800,
  CreateControl = 10801,
  UpdateControl = 10802,
  DeleteControl = 10803,
  DisplayControls = 10804,
  ReadControlCondition = 10810,
  CreateControlCondition = 10811,
  UpdateControlCondition = 10812,
  DeleteControlCondition = 10813,
  ReadControlTrigger = 10820,
  CreateControlTrigger = 10821,
  UpdateControlTrigger = 10822,
  DeleteControlTrigger = 10823,
  ReadControlNotification = 10830,
  CreateControlNotification = 10831,
  UpdateControlNotification = 10832,
  DeleteControlNotification = 10833,
  ReadControlIssue = 10834,
  ReadIssueReason = 10835,
  ReadImportConfiguration = 10840,
  CreateImportConfiguration = 10841,
  UpdateImportConfiguration = 10842,
  DeleteImportConfiguration = 10843,
  DisplayImportConfigurations = 10844,
  ReadImportProtocol = 10850,
  CreateImportProtocol = 10851,
  UpdateImportProtocol = 10853,
  DeleteImportProtocol = 10854,
  DisplayImportProtocol = 10855,
  ReadWinterhalterServiceCallMailNodeContent = 10870,
  CreateWinterhalterServiceCallMailNodeContent = 10871,
  UpdateWinterhalterServiceCallMailNodeContent = 10872,
  DeleteWinterhalterServiceCallMailNodeContent = 10873,
  ReadMailNodeNotification = 10880,
  CreateMailNodeNotification = 10881,
  UpdateMailNodeNotification = 10882,
  DeleteMailNodeNotification = 10883,
  ReadKnowledgePathProgress = 10891,
  ReadMailReceiver = 103321,
  CreateMailReceiver = 103322,
  UpdateMailReceiver = 103323,
  DeleteMailReceiver = 103324,
  MergeResourceManufacturer = 106245,
}

/**
 * Defines values for RoleGeneralTerms.
 * Possible values include: 'OrgaUser', 'Admin', 'Manager', 'DeviceUser', 'SystemAdmin', 'Custom',
 * 'ServiceEmployee', 'QuickAccessUser', 'QuickAccessKnowledge', 'QuickAccessIssueTemplate',
 * 'QuickAccessDashboard', 'TeamUser', 'Analyst', 'QuickAccessIssueHistory', 'TechUser'
 * @readonly
 * @enum {string}
 */
export enum RoleGeneralTerms {
  OrgaUser = 0,
  Admin = 1,
  Manager = 2,
  DeviceUser = 4,
  SystemAdmin = 5,
  Custom = 6,
  ServiceEmployee = 12,
  QuickAccessUser = 13,
  QuickAccessKnowledge = 14,
  QuickAccessIssueTemplate = 15,
  QuickAccessDashboard = 16,
  TeamUser = 17,
  Analyst = 18,
  QuickAccessIssueHistory = 19,
  TechUser = 20,
}

/**
 * Defines values for TaskTypes.
 * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection'
 * @readonly
 * @enum {string}
 */
export enum TaskTypes {
  Task = 0,
  Confirmation = 1,
  Document = 2,
  Collection = 3,
}

/**
 * Defines values for PrimaryBillingSource.
 * Possible values include: 'None', 'Location', 'User'
 * @readonly
 * @enum {string}
 */
export enum PrimaryBillingSource {
  None = 0,
  Location = 1,
  User = 2,
}

/**
 * Defines values for Seeds.
 * Possible values include: 'Semco'
 * @readonly
 * @enum {string}
 */
export enum Seeds {
  Semco = 0,
}

/**
 * Defines values for Brands.
 * Possible values include: 'Nexnox', 'Semco', 'Pilona', 'Winterhalter'
 * @readonly
 * @enum {string}
 */
export enum Brands {
  Nexnox = 0,
  Semco = 1,
  Pilona = 2,
  Winterhalter = 3,
}

/**
 * Defines values for TimelineEntryImportance.
 * Possible values include: 'Unspecified', 'Overdue', 'Escalated'
 * @readonly
 * @enum {string}
 */
export enum TimelineEntryImportance {
  Unspecified = 0,
  Overdue = 1,
  Escalated = 2,
}

/**
 * Defines values for TimelineEntryType.
 * Possible values include: 'TaskJob', 'Mission'
 * @readonly
 * @enum {string}
 */
export enum TimelineEntryType {
  TaskJob = 0,
  Mission = 1,
}

/**
 * Defines values for TaskJobState.
 * Possible values include: 'Pending', 'Start', 'Started', 'Done', 'Canceled', 'Overdue',
 * 'Escalated'
 * @readonly
 * @enum {string}
 */
export enum TaskJobState {
  Pending = 1,
  Start = 2,
  Started = 3,
  Done = 4,
  Canceled = 5,
  Overdue = 6,
  Escalated = 7,
}

/**
 * Defines values for MissionState.
 * Possible values include: 'Created', 'Instructed', 'Rejected', 'Canceled', 'Done'
 * @readonly
 * @enum {string}
 */
export enum MissionState {
  Created = 0,
  Instructed = 1,
  Rejected = 2,
  Canceled = 3,
  Done = 4,
}

/**
 * Defines values for ResourceType.
 * Possible values include: 'Device', 'VirtualGroup', 'Virtual', 'Group'
 * @readonly
 * @enum {string}
 */
export enum ResourceType {
  Device = 0,
  VirtualGroup = 1,
  Virtual = 2,
  Group = 3,
}

/**
 * Defines values for Orders.
 * Possible values include: 'Ascending', 'Descending'
 * @readonly
 * @enum {string}
 */
export enum Orders {
  Ascending = 0,
  Descending = 1,
}

/**
 * Defines values for DataTableColumnType.
 * Possible values include: 'Base', 'ByCustomProperty', 'ByTransfer'
 * @readonly
 * @enum {string}
 */
export enum DataTableColumnType {
  Base = 0,
  ByCustomProperty = 1,
  ByTransfer = 2,
}

/**
 * Defines values for DataTableFilterType.
 * Possible values include: 'ByTransfer', 'ByCustomProperty', 'ByGroup', 'Base'
 * @readonly
 * @enum {string}
 */
export enum DataTableFilterType {
  ByTransfer = 0,
  ByCustomProperty = 1,
  ByGroup = 2,
  Base = -1,
}

/**
 * Defines values for AreaAssignmentReach.
 * Possible values include: 'Everything', 'Limited', 'Persona'
 * @readonly
 * @enum {string}
 */
export enum AreaAssignmentReach {
  Everything = 0,
  Limited = 1,
  Persona = 2,
}

/**
 * Defines values for MissionType.
 * Possible values include: 'Base', 'Ticket', 'Task', 'Manual'
 * @readonly
 * @enum {string}
 */
export enum MissionType {
  Base = 0,
  Ticket = 1,
  Task = 2,
  Manual = 3,
}

/**
 * Defines values for SolutionMemberType.
 * Possible values include: 'Ticket', 'Mission', 'Issue'
 * @readonly
 * @enum {string}
 */
export enum SolutionMemberType {
  Ticket = 1,
  Mission = 2,
  Issue = 3,
}

/**
 * Defines values for FormFieldTypes.
 * Possible values include: 'Base', 'Info', 'Text', 'Multiline', 'Number', 'Date', 'Time',
 * 'Picture', 'Signature', 'Checkbox', 'Dropdown', 'Attachment', 'SearchList'
 * @readonly
 * @enum {string}
 */
export enum FormFieldTypes {
  Base = 0,
  Info = 1,
  Text = 2,
  Multiline = 3,
  Number = 4,
  Date = 5,
  Time = 6,
  Picture = 7,
  Signature = 8,
  Checkbox = 9,
  Dropdown = 10,
  Attachment = 11,
  SearchList = 12,
}

/**
 * Defines values for AttachmentTypes.
 * Possible values include: 'Base', 'File', 'FilledForm', 'Knowledge', 'Folder', 'Link'
 * @readonly
 * @enum {string}
 */
export enum AttachmentTypes {
  Base = 0,
  File = 1,
  FilledForm = 2,
  Knowledge = 3,
  Folder = 4,
  Link = 5,
}

/**
 * Defines values for AuditKind.
 * Possible values include: 'Added', 'Modified', 'Deleted'
 * @readonly
 * @enum {string}
 */
export enum AuditKind {
  Added = 0,
  Modified = 1,
  Deleted = 2,
}

/**
 * Defines values for AuditPropertyType.
 * Possible values include: 'None', 'DateTime', 'TimeSpan', 'Double'
 * @readonly
 * @enum {string}
 */
export enum AuditPropertyType {
  None = 0,
  DateTime = 1,
  TimeSpan = 2,
  Double = 3,
}

/**
 * Defines values for CategorizedType.
 * Possible values include: 'Info', 'KnowledgeArticle', 'Task', 'KnowledgePath'
 * @readonly
 * @enum {string}
 */
export enum CategorizedType {
  Info = 1,
  KnowledgeArticle = 2,
  Task = 4,
  KnowledgePath = 8,
}

/**
 * Defines values for CategorySector.
 * Possible values include: 'App', 'Backend', 'Everywhere'
 * @readonly
 * @enum {string}
 */
export enum CategorySector {
  App = 1,
  Backend = 2,
  Everywhere = 3,
}

/**
 * Defines values for ResourceState.
 * Possible values include: 'Operation', 'Maintenance', 'Retired'
 * @readonly
 * @enum {string}
 */
export enum ResourceState {
  Operation = 1,
  Maintenance = 2,
  Retired = 3,
}

/**
 * Defines values for CharGroups.
 * Possible values include: 'LowerCase', 'UpperCase', 'NumberCase'
 * @readonly
 * @enum {string}
 */
export enum CharGroups {
  LowerCase = 1,
  UpperCase = 2,
  NumberCase = 4,
}

/**
 * Defines values for DocumentTemplateContextType.
 * Possible values include: 'ExportTicket', 'ExportMissionByTicket', 'ActivationMissionByTicket',
 * 'ExportMissionByTask', 'ActivationMissionByTask', 'ExportMission', 'ActivationMission',
 * 'MissionBase', 'FilledForm', 'FilledFormByChat', 'FilledFormByTask', 'Issue', 'MessageIssue',
 * 'TicketIssue', 'MissionIssue', 'WinterhalterIssue', 'KnowledgePathCertificate'
 * @readonly
 * @enum {string}
 */
export enum DocumentTemplateContextType {
  ExportTicket = 1,
  ExportMissionByTicket = 2,
  ActivationMissionByTicket = 3,
  ExportMissionByTask = 4,
  ActivationMissionByTask = 5,
  ExportMission = 6,
  ActivationMission = 8,
  MissionBase = 10,
  FilledForm = 11,
  FilledFormByChat = 12,
  FilledFormByTask = 13,
  Issue = 14,
  MessageIssue = 15,
  TicketIssue = 16,
  MissionIssue = 17,
  WinterhalterIssue = 18,
  KnowledgePathCertificate = 19,
}

/**
 * Defines values for ChatByFormReceivingKind.
 * Possible values include: 'Locations', 'FixedFunction'
 * @readonly
 * @enum {string}
 */
export enum ChatByFormReceivingKind {
  Locations = 1,
  FixedFunction = 2,
}

/**
 * Defines values for ChatByFormReceiverType.
 * Possible values include: 'Location', 'LocationGroup'
 * @readonly
 * @enum {string}
 */
export enum ChatByFormReceiverType {
  Location = 1,
  LocationGroup = 2,
}

/**
 * Defines values for ChatMemberType.
 * Possible values include: 'Base', 'Location', 'Contact', 'Function', 'External', 'Ticket'
 * @readonly
 * @enum {string}
 */
export enum ChatMemberType {
  Base = 0,
  Location = 1,
  Contact = 2,
  Function = 3,
  External = 4,
  Ticket = 5,
}

/**
 * Defines values for ChatSourceType.
 * Possible values include: 'Base', 'Mail', 'Form', 'Process'
 * @readonly
 * @enum {string}
 */
export enum ChatSourceType {
  Base = 0,
  Mail = 1,
  Form = 2,
  Process = 3,
}

/**
 * Defines values for MessageStatus.
 * Possible values include: 'Read', 'Send'
 * @readonly
 * @enum {string}
 */
export enum MessageStatus {
  Read = 0,
  Send = 1,
}

/**
 * Defines values for ChatMessageType.
 * Possible values include: 'Message', 'TicketStateChange'
 * @readonly
 * @enum {string}
 */
export enum ChatMessageType {
  Message = 0,
  TicketStateChange = 1,
}

/**
 * Defines values for NotificationTypes.
 * Possible values include: 'TaskState', 'ChatMessage', 'DeviceAction', 'PushedInfo',
 * 'PushedStartedMission'
 * @readonly
 * @enum {string}
 */
export enum NotificationTypes {
  TaskState = 1,
  ChatMessage = 2,
  DeviceAction = 3,
  PushedInfo = 4,
  PushedStartedMission = 5,
}

/**
 * Defines values for ChatStarterType.
 * Possible values include: 'Form', 'Process', 'Definition'
 * @readonly
 * @enum {string}
 */
export enum ChatStarterType {
  Form = 1,
  Process = 2,
  Definition = 3,
}

/**
 * Defines values for ConcreteTaskTypes.
 * Possible values include: 'Task', 'Confirmation', 'Document', 'Collection', 'ChildDocument'
 * @readonly
 * @enum {string}
 */
export enum ConcreteTaskTypes {
  Task = 0,
  Confirmation = 1,
  Document = 2,
  Collection = 3,
  ChildDocument = 4,
}

/**
 * Defines values for Clients.
 * Possible values include: 'Portal', 'Tech', 'Orga', 'Team', 'Link'
 * @readonly
 * @enum {string}
 */
export enum Clients {
  Portal = 0,
  Tech = 1,
  Orga = 2,
  Team = 3,
  Link = 4,
}

/**
 * Defines values for CodeStatus.
 * Possible values include: 'UnUsed', 'InUse', 'Expired'
 * @readonly
 * @enum {string}
 */
export enum CodeStatus {
  UnUsed = 0,
  InUse = 1,
  Expired = 2,
}

/**
 * Defines values for ExecutionTypes.
 * Possible values include: 'Base', 'Manual', 'Planned', 'Triggered'
 * @readonly
 * @enum {string}
 */
export enum ExecutionTypes {
  Base = 0,
  Manual = 1,
  Planned = 2,
  Triggered = 4,
}

/**
 * Defines values for TaskState.
 * Possible values include: 'Inactive', 'Active', 'Archived', 'Deleted'
 * @readonly
 * @enum {string}
 */
export enum TaskState {
  Inactive = 0,
  Active = 1,
  Archived = 2,
  Deleted = 3,
}

/**
 * Defines values for CombineOperator.
 * Possible values include: 'And', 'Or'
 * @readonly
 * @enum {string}
 */
export enum CombineOperator {
  And = 1,
  Or = 2,
}

/**
 * Defines values for ExportDestinationTypes.
 * Possible values include: 'Email'
 * @readonly
 * @enum {string}
 */
export enum ExportDestinationTypes {
  Email = 1,
}

/**
 * Defines values for ConnectedIdentificationType.
 * Possible values include: 'BySerialNumber', 'ByDirectId'
 * @readonly
 * @enum {string}
 */
export enum ConnectedIdentificationType {
  BySerialNumber = 1,
  ByDirectId = 2,
}

/**
 * Defines values for HintLevel.
 * Possible values include: 'None', 'Warning', 'Error', 'Information'
 * @readonly
 * @enum {string}
 */
export enum HintLevel {
  None = 0,
  Warning = 1,
  Error = 2,
  Information = 3,
}

/**
 * Defines values for ValueType.
 * Possible values include: 'String', 'Integer'
 * @readonly
 * @enum {string}
 */
export enum ValueType {
  String = 1,
  Integer = 2,
}

/**
 * Defines values for ContactNotificationBehaviour.
 * Possible values include: 'Unrestricted', 'Blocking'
 * @readonly
 * @enum {string}
 */
export enum ContactNotificationBehaviour {
  Unrestricted = 0,
  Blocking = -1,
}

/**
 * Defines values for LinkedElementType.
 * Possible values include: 'Base', 'Ticket', 'Resource', 'Mission', 'Contract', 'Contact'
 * @readonly
 * @enum {string}
 */
export enum LinkedElementType {
  Base = 0,
  Ticket = 1,
  Resource = 2,
  Mission = 3,
  Contract = 4,
  Contact = 5,
}

/**
 * Defines values for ControlReceiverType.
 * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
 * @readonly
 * @enum {string}
 */
export enum ControlReceiverType {
  Base = 0,
  EmailReceiver = 1,
  ContactReceiver = 2,
  FunctionReceiver = 3,
}

/**
 * Defines values for IssueReceiverType.
 * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
 * @readonly
 * @enum {string}
 */
export enum IssueReceiverType {
  Base = 0,
  EmailReceiver = 1,
  ContactReceiver = 2,
  FunctionReceiver = 3,
}

/**
 * Defines values for ContactLoginMethod.
 * Possible values include: 'Pin', 'Badge'
 * @readonly
 * @enum {string}
 */
export enum ContactLoginMethod {
  Pin = 1,
  Badge = 2,
}

/**
 * Defines values for SettingValueType.
 * Possible values include: 'Base', 'Text', 'HexColor', 'Picture', 'Culture', 'Stereotype',
 * 'Priority', 'Resource', 'TicketState', 'ContactLoginMethod'
 * @readonly
 * @enum {string}
 */
export enum SettingValueType {
  Base = 0,
  Text = 1,
  HexColor = 2,
  Picture = 3,
  Culture = 4,
  Stereotype = 5,
  Priority = 6,
  Resource = 7,
  TicketState = 8,
  ContactLoginMethod = 9,
}

/**
 * Defines values for MailNodeReceiverType.
 * Possible values include: 'Base', 'EmailReceiver', 'ContactReceiver', 'FunctionReceiver'
 * @readonly
 * @enum {string}
 */
export enum MailNodeReceiverType {
  Base = 0,
  EmailReceiver = 1,
  ContactReceiver = 2,
  FunctionReceiver = 3,
}

/**
 * Defines values for NotificationTargetType.
 * Possible values include: 'Base', 'Email', 'Contact', 'Function'
 * @readonly
 * @enum {string}
 */
export enum NotificationTargetType {
  Base = 0,
  Email = 1,
  Contact = 2,
  Function = 3,
}

/**
 * Defines values for ContentType.
 * Possible values include: 'AAB', 'AAC', 'AAM', 'AAS', 'ABW', 'AC', 'ACC', 'ACE', 'ACU', 'ACUTC',
 * 'ADP', 'AEP', 'AFM', 'AFP', 'AHEAD', 'AI', 'AIF', 'AIFC', 'AIFF', 'AIR', 'AIT', 'AMI', 'APK',
 * 'APPCACHE', 'APPLICATION', 'APR', 'ARC', 'ASC', 'ASF', 'ASM', 'ASO', 'ASX', 'ATC', 'ATOM',
 * 'ATOMCAT', 'ATOMSVC', 'ATX', 'AU', 'AVI', 'AW', 'AZF', 'AZS', 'AZW', 'BAT', 'BCPIO', 'BDF',
 * 'BDM', 'BED', 'BH2', 'BIN', 'BLB', 'BLORB', 'BMI', 'BMP', 'BOOK', 'BOX', 'BOZ', 'BPK', 'BTIF',
 * 'BZ', 'BZ2', 'C', 'C11AMC', 'C11AMZ', 'C4D', 'C4F', 'C4G', 'C4P', 'C4U', 'CAB', 'CAF', 'CAP',
 * 'CAR', 'CAT', 'CB7', 'CBA', 'CBR', 'CBT', 'CBZ', 'CC', 'CCT', 'CCXML', 'CDBCMSG', 'CDF',
 * 'CDKEY', 'CDMIA', 'CDMIC', 'CDMID', 'CDMIO', 'CDMIQ', 'CDX', 'CDXML', 'CDY', 'CER', 'CFS',
 * 'CGM', 'CHAT', 'CHM', 'CHRT', 'CIF', 'CII', 'CIL', 'CLA', 'CLASS', 'CLKK', 'CLKP', 'CLKT',
 * 'CLKW', 'CLKX', 'CLP', 'CMC', 'CMDF', 'CML', 'CMP', 'CMX', 'COD', 'COM', 'CONF', 'CPIO', 'CPP',
 * 'CPT', 'CRD', 'CRL', 'CRT', 'CRYPTONOTE', 'CSH', 'CSML', 'CSP', 'CSS', 'CST', 'CSV', 'CU',
 * 'CURL', 'CWW', 'CXT', 'CXX', 'DAE', 'DAF', 'DART', 'DATALESS', 'DAVMOUNT', 'DBK', 'DCR',
 * 'DCURL', 'DD2', 'DDD', 'DEB', 'DEF', 'DEPLOY', 'DER', 'DFAC', 'DGC', 'DIC', 'DIF', 'DIR', 'DIS',
 * 'DIST', 'DISTZ', 'DJV', 'DJVU', 'DLL', 'DMG', 'DMP', 'DMS', 'DNA', 'DOC', 'DOCM', 'DOCX', 'DOT',
 * 'DOTM', 'DOTX', 'DP', 'DPG', 'DRA', 'DSC', 'DSSC', 'DTB', 'DTD', 'DTS', 'DTSHD', 'DUMP', 'DV',
 * 'DVB', 'DVI', 'DWF', 'DWG', 'DXF', 'DXP', 'DXR', 'ECELP4800', 'ECELP7470', 'ECELP9600', 'ECMA',
 * 'EDM', 'EDX', 'EFIF', 'EI6', 'ELC', 'EMF', 'EML', 'EMMA', 'EMZ', 'EOL', 'EOT', 'EPS', 'EPUB',
 * 'ES3', 'ESA', 'ESF', 'ET3', 'ETX', 'EVA', 'EVY', 'EXE', 'EXI', 'EXT', 'EXTENSIONS', 'EZ', 'EZ2',
 * 'EZ3', 'F', 'F4V', 'F77', 'F90', 'FBS', 'FCDT', 'FCS', 'FDF', 'FE_LAUNCH', 'FG5', 'FGD', 'FH',
 * 'FH4', 'FH5', 'FH7', 'FHC', 'FIG', 'FLAC', 'FLI', 'FLO', 'FLV', 'FLW', 'FLX', 'FLY', 'FM',
 * 'FNC', 'FOR', 'FPX', 'FRAME', 'FSC', 'FST', 'FTC', 'FTI', 'FVT', 'FXP', 'FXPL', 'FZS', 'G2W',
 * 'G3', 'G3W', 'GAC', 'GAM', 'GBR', 'GCA', 'GDL', 'GEO', 'GEX', 'GGB', 'GGT', 'GHF', 'GIF', 'GIM',
 * 'GML', 'GMX', 'GNUMERIC', 'GPH', 'GPX', 'GQF', 'GQS', 'GRAM', 'GRAMPS', 'GRE', 'GRV', 'GRXML',
 * 'GSF', 'GTAR', 'GTM', 'GTW', 'GV', 'GXF', 'GXT', 'H', 'H261', 'H263', 'H264', 'HAL', 'HBCI',
 * 'HDF', 'HH', 'HLP', 'HPGL', 'HPID', 'HPS', 'HQX', 'HTKE', 'HTM', 'HTML', 'HVD', 'HVP', 'HVS',
 * 'I2G', 'IC', 'ICC', 'ICE', 'ICM', 'ICO', 'ICS', 'IEF', 'IFB', 'IFM', 'IGES', 'IGL', 'IGM',
 * 'IGS', 'IGX', 'IIF', 'IMP', 'IMS', 'IN', 'INK', 'INKML', 'INSTALL', 'IOTA', 'IPFIX', 'IPK',
 * 'IRM', 'IRP', 'ISO', 'ITP', 'IVP', 'IVU', 'JAD', 'JAM', 'JAR', 'JAVA', 'JISP', 'JLT', 'JNLP',
 * 'JODA', 'JP2', 'JPEG', 'JPG', 'JPGM', 'JPGV', 'JPM', 'JS', 'JSON', 'JSONML', 'KAR', 'KARBON',
 * 'KFO', 'KIA', 'KML', 'KMZ', 'KNE', 'KNP', 'KON', 'KPR', 'KPT', 'KPXX', 'KSP', 'KTR', 'KTX',
 * 'KTZ', 'KWD', 'KWT', 'LASXML', 'LATEX', 'LBD', 'LBE', 'LES', 'LHA', 'LINK66', 'LIST',
 * 'LIST3820', 'LISTAFP', 'LNK', 'LOG', 'LOSTXML', 'LRF', 'LRM', 'LTF', 'LVP', 'LWP', 'LZH', 'M13',
 * 'M14', 'M1V', 'M21', 'M2A', 'M2V', 'M3A', 'M3U', 'M3U8', 'M4A', 'M4B', 'M4P', 'M4U', 'M4V',
 * 'MA', 'MAC', 'MADS', 'MAG', 'MAKER', 'MAN', 'MAR', 'MATHML', 'MB', 'MBK', 'MBOX', 'MC1', 'MCD',
 * 'MCURL', 'MDB', 'MDI', 'ME', 'MESH', 'META4', 'METALINK', 'METS', 'MFM', 'MFT', 'MGP', 'MGZ',
 * 'MID', 'MIDI', 'MIE', 'MIF', 'MIME', 'MJ2', 'MJP2', 'MK3D', 'MKA', 'MKS', 'MKV', 'MLP', 'MMD',
 * 'MMF', 'MMR', 'MNG', 'MNY', 'MOBI', 'MODS', 'MOV', 'MOVIE', 'MP2', 'MP21', 'MP2A', 'MP3', 'MP4',
 * 'MP4A', 'MP4S', 'MP4V', 'MPC', 'MPE', 'MPEG', 'MPG', 'MPG4', 'MPGA', 'MPKG', 'MPM', 'MPN',
 * 'MPP', 'MPT', 'MPY', 'MQY', 'MRC', 'MRCX', 'MS', 'MSCML', 'MSEED', 'MSEQ', 'MSF', 'MSH', 'MSI',
 * 'MSL', 'MSTY', 'MTS', 'MUS', 'MUSICXML', 'MVB', 'MWF', 'MXF', 'MXL', 'MXML', 'MXS', 'MXU', 'N3',
 * 'NB', 'NBP', 'NC', 'NCX', 'NFO', 'NGDAT', 'NITF', 'NLU', 'NML', 'NND', 'NNS', 'NNW', 'NPX',
 * 'NSC', 'NSF', 'NTF', 'NZB', 'OA2', 'OA3', 'OAS', 'OBD', 'OBJ', 'ODA', 'ODB', 'ODC', 'ODF',
 * 'ODFT', 'ODG', 'ODI', 'ODM', 'ODP', 'ODS', 'ODT', 'OGA', 'OGG', 'OGV', 'OGX', 'OMDOC', 'ONEPKG',
 * 'ONETMP', 'ONETOC', 'ONETOC2', 'OPF', 'OPML', 'OPRC', 'ORG', 'OSF', 'OSFPVG', 'OTC', 'OTF',
 * 'OTG', 'OTH', 'OTI', 'OTP', 'OTS', 'OTT', 'OXPS', 'OXT', 'P', 'P10', 'P12', 'P7B', 'P7C', 'P7M',
 * 'P7R', 'P7S', 'P8', 'PAS', 'PAW', 'PBD', 'PBM', 'PCAP', 'PCF', 'PCL', 'PCLXL', 'PCT', 'PCURL',
 * 'PCX', 'PDB', 'PDF', 'PFA', 'PFB', 'PFM', 'PFR', 'PFX', 'PGM', 'PGN', 'PGP', 'PIC', 'PICT',
 * 'PKG', 'PKI', 'PKIPATH', 'PLB', 'PLC', 'PLF', 'PLS', 'PML', 'PNG', 'PNM', 'PNT', 'PNTG',
 * 'PORTPKG', 'POT', 'POTM', 'POTX', 'PPAM', 'PPD', 'PPM', 'PPS', 'PPSM', 'PPSX', 'PPT', 'PPTM',
 * 'PPTX', 'PQA', 'PRC', 'PRE', 'PRF', 'PS', 'PSB', 'PSD', 'PSF', 'PSKCXML', 'PTID', 'PUB', 'PVB',
 * 'PWN', 'PYA', 'PYV', 'QAM', 'QBO', 'QFX', 'QPS', 'QT', 'QTI', 'QTIF', 'QWD', 'QWT', 'QXB',
 * 'QXD', 'QXL', 'QXT', 'RA', 'RAM', 'RAR', 'RAS', 'RCPROFILE', 'RDF', 'RDZ', 'REP', 'RES', 'RGB',
 * 'RIF', 'RIP', 'RIS', 'RL', 'RLC', 'RLD', 'RM', 'RMI', 'RMP', 'RMS', 'RMVB', 'RNC', 'ROA',
 * 'ROFF', 'RP9', 'RPSS', 'RPST', 'RQ', 'RS', 'RSD', 'RSS', 'RTF', 'RTX', 'S', 'S3M', 'SAF',
 * 'SBML', 'SC', 'SCD', 'SCM', 'SCQ', 'SCS', 'SCURL', 'SDA', 'SDC', 'SDD', 'SDKD', 'SDKM', 'SDP',
 * 'SDW', 'SEE', 'SEED', 'SEMA', 'SEMD', 'SEMF', 'SER', 'SETPAY', 'SETREG', 'SFS', 'SFV', 'SGI',
 * 'SGL', 'SGM', 'SGML', 'SH', 'SHAR', 'SHF', 'SID', 'SIG', 'SIL', 'SILO', 'SIS', 'SISX', 'SIT',
 * 'SITX', 'SKD', 'SKM', 'SKP', 'SKT', 'SLDM', 'SLDX', 'SLT', 'SM', 'SMF', 'SMI', 'SMIL', 'SMV',
 * 'SMZIP', 'SND', 'SNF', 'SO', 'SPC', 'SPF', 'SPL', 'SPOT', 'SPP', 'SPQ', 'SPX', 'SQL', 'SRC',
 * 'SRT', 'SRU', 'SRX', 'SSDL', 'SSE', 'SSF', 'SSML', 'ST', 'STC', 'STD', 'STF', 'STI', 'STK',
 * 'STL', 'STR', 'STW', 'SUB', 'SUS', 'SUSP', 'SV4CPIO', 'SV4CRC', 'SVC', 'SVD', 'SVG', 'SVGZ',
 * 'SWA', 'SWF', 'SWI', 'SXC', 'SXD', 'SXG', 'SXI', 'SXM', 'SXW', 'T', 'T3', 'TAGLET', 'TAO',
 * 'TAR', 'TCAP', 'TCL', 'TEACHER', 'TEI', 'TEICORPUS', 'TEX', 'TEXI', 'TEXINFO', 'TEXT', 'TFI',
 * 'TFM', 'TGA', 'THMX', 'TIF', 'TIFF', 'TMO', 'TORRENT', 'TPL', 'TPT', 'TR', 'TRA', 'TRM', 'TSD',
 * 'TSV', 'TTC', 'TTF', 'TTL', 'TWD', 'TWDS', 'TXD', 'TXF', 'TXT', 'U32', 'UDEB', 'UFD', 'UFDL',
 * 'ULX', 'UMJ', 'UNITYWEB', 'UOML', 'URI', 'URIS', 'URLS', 'USTAR', 'UTZ', 'UU', 'UVA', 'UVD',
 * 'UVF', 'UVG', 'UVH', 'UVI', 'UVM', 'UVP', 'UVS', 'UVT', 'UVU', 'UVV', 'UVVA', 'UVVD', 'UVVF',
 * 'UVVG', 'UVVH', 'UVVI', 'UVVM', 'UVVP', 'UVVS', 'UVVT', 'UVVU', 'UVVV', 'UVVX', 'UVVZ', 'UVX',
 * 'UVZ', 'VCARD', 'VCD', 'VCF', 'VCG', 'VCS', 'VCX', 'VIS', 'VIV', 'VOB', 'VOR', 'VOX', 'VRML',
 * 'VSD', 'VSF', 'VSS', 'VST', 'VSW', 'VTU', 'VXML', 'W3D', 'WAD', 'WAV', 'WAX', 'WBMP', 'WBMXL',
 * 'WBS', 'WBXML', 'WCM', 'WDB', 'WDP', 'WEBA', 'WEBM', 'WEBP', 'WG', 'WGT', 'WKS', 'WM', 'WMA',
 * 'WMD', 'WMF', 'WML', 'WMLC', 'WMLS', 'WMLSC', 'WMV', 'WMX', 'WMZ', 'WOFF', 'WPD', 'WPL', 'WPS',
 * 'WQD', 'WRI', 'WRL', 'WSDL', 'WSPOLICY', 'WTB', 'WVX', 'X32', 'X3D', 'X3DB', 'X3DBZ', 'X3DV',
 * 'X3DVZ', 'X3DZ', 'XAML', 'XAP', 'XAR', 'XBAP', 'XBD', 'XBM', 'XDF', 'XDM', 'XDP', 'XDSSC',
 * 'XDW', 'XENC', 'XER', 'XFDF', 'XFDL', 'XHT', 'XHTML', 'XHVML', 'XIF', 'XLA', 'XLAM', 'XLC',
 * 'XLF', 'XLM', 'XLS', 'XLSB', 'XLSM', 'XLSX', 'XLT', 'XLTM', 'XLTX', 'XLW', 'XM', 'XML', 'XO',
 * 'XOP', 'XPI', 'XPL', 'XPM', 'XPR', 'XPS', 'XPW', 'XPX', 'XSL', 'XSLT', 'XSM', 'XSPF', 'XUL',
 * 'XVM', 'XVML', 'XWD', 'XYZ', 'XZ', 'YANG', 'YIN', 'Z1', 'Z2', 'Z3', 'Z4', 'Z5', 'Z6', 'Z7',
 * 'Z8', 'ZAZ', 'ZIP', 'ZIR', 'ZIRZ', 'ZMM', 'DEFAULT'
 * @readonly
 * @enum {string}
 */
export enum ContentType {
  AAB = 0,
  AAC = 1,
  AAM = 2,
  AAS = 3,
  ABW = 4,
  AC = 5,
  ACC = 6,
  ACE = 7,
  ACU = 8,
  ACUTC = 9,
  ADP = 10,
  AEP = 11,
  AFM = 12,
  AFP = 13,
  AHEAD = 14,
  AI = 15,
  AIF = 16,
  AIFC = 17,
  AIFF = 18,
  AIR = 19,
  AIT = 20,
  AMI = 21,
  APK = 22,
  APPCACHE = 23,
  APPLICATION = 24,
  APR = 25,
  ARC = 26,
  ASC = 27,
  ASF = 28,
  ASM = 29,
  ASO = 30,
  ASX = 31,
  ATC = 32,
  ATOM = 33,
  ATOMCAT = 34,
  ATOMSVC = 35,
  ATX = 36,
  AU = 37,
  AVI = 38,
  AW = 39,
  AZF = 40,
  AZS = 41,
  AZW = 42,
  BAT = 43,
  BCPIO = 44,
  BDF = 45,
  BDM = 46,
  BED = 47,
  BH2 = 48,
  BIN = 49,
  BLB = 50,
  BLORB = 51,
  BMI = 52,
  BMP = 53,
  BOOK = 54,
  BOX = 55,
  BOZ = 56,
  BPK = 57,
  BTIF = 58,
  BZ = 59,
  BZ2 = 60,
  C = 61,
  C11AMC = 62,
  C11AMZ = 63,
  C4D = 64,
  C4F = 65,
  C4G = 66,
  C4P = 67,
  C4U = 68,
  CAB = 69,
  CAF = 70,
  CAP = 71,
  CAR = 72,
  CAT = 73,
  CB7 = 74,
  CBA = 75,
  CBR = 76,
  CBT = 77,
  CBZ = 78,
  CC = 79,
  CCT = 80,
  CCXML = 81,
  CDBCMSG = 82,
  CDF = 83,
  CDKEY = 84,
  CDMIA = 85,
  CDMIC = 86,
  CDMID = 87,
  CDMIO = 88,
  CDMIQ = 89,
  CDX = 90,
  CDXML = 91,
  CDY = 92,
  CER = 93,
  CFS = 94,
  CGM = 95,
  CHAT = 96,
  CHM = 97,
  CHRT = 98,
  CIF = 99,
  CII = 100,
  CIL = 101,
  CLA = 102,
  CLASS = 103,
  CLKK = 104,
  CLKP = 105,
  CLKT = 106,
  CLKW = 107,
  CLKX = 108,
  CLP = 109,
  CMC = 110,
  CMDF = 111,
  CML = 112,
  CMP = 113,
  CMX = 114,
  COD = 115,
  COM = 116,
  CONF = 117,
  CPIO = 118,
  CPP = 119,
  CPT = 120,
  CRD = 121,
  CRL = 122,
  CRT = 123,
  CRYPTONOTE = 124,
  CSH = 125,
  CSML = 126,
  CSP = 127,
  CSS = 128,
  CST = 129,
  CSV = 130,
  CU = 131,
  CURL = 132,
  CWW = 133,
  CXT = 134,
  CXX = 135,
  DAE = 136,
  DAF = 137,
  DART = 138,
  DATALESS = 139,
  DAVMOUNT = 140,
  DBK = 141,
  DCR = 142,
  DCURL = 143,
  DD2 = 144,
  DDD = 145,
  DEB = 146,
  DEF = 147,
  DEPLOY = 148,
  DER = 149,
  DFAC = 150,
  DGC = 151,
  DIC = 152,
  DIF = 153,
  DIR = 154,
  DIS = 155,
  DIST = 156,
  DISTZ = 157,
  DJV = 158,
  DJVU = 159,
  DLL = 160,
  DMG = 161,
  DMP = 162,
  DMS = 163,
  DNA = 164,
  DOC = 165,
  DOCM = 166,
  DOCX = 167,
  DOT = 168,
  DOTM = 169,
  DOTX = 170,
  DP = 171,
  DPG = 172,
  DRA = 173,
  DSC = 174,
  DSSC = 175,
  DTB = 176,
  DTD = 177,
  DTS = 178,
  DTSHD = 179,
  DUMP = 180,
  DV = 181,
  DVB = 182,
  DVI = 183,
  DWF = 184,
  DWG = 185,
  DXF = 186,
  DXP = 187,
  DXR = 188,
  ECELP4800 = 189,
  ECELP7470 = 190,
  ECELP9600 = 191,
  ECMA = 192,
  EDM = 193,
  EDX = 194,
  EFIF = 195,
  EI6 = 196,
  ELC = 197,
  EMF = 198,
  EML = 199,
  EMMA = 200,
  EMZ = 201,
  EOL = 202,
  EOT = 203,
  EPS = 204,
  EPUB = 205,
  ES3 = 206,
  ESA = 207,
  ESF = 208,
  ET3 = 209,
  ETX = 210,
  EVA = 211,
  EVY = 212,
  EXE = 213,
  EXI = 214,
  EXT = 215,
  EXTENSIONS = 216,
  EZ = 217,
  EZ2 = 218,
  EZ3 = 219,
  F = 220,
  F4V = 221,
  F77 = 222,
  F90 = 223,
  FBS = 224,
  FCDT = 225,
  FCS = 226,
  FDF = 227,
  FELAUNCH = 228,
  FG5 = 229,
  FGD = 230,
  FH = 231,
  FH4 = 232,
  FH5 = 233,
  FH7 = 234,
  FHC = 235,
  FIG = 236,
  FLAC = 237,
  FLI = 238,
  FLO = 239,
  FLV = 240,
  FLW = 241,
  FLX = 242,
  FLY = 243,
  FM = 244,
  FNC = 245,
  FOR = 246,
  FPX = 247,
  FRAME = 248,
  FSC = 249,
  FST = 250,
  FTC = 251,
  FTI = 252,
  FVT = 253,
  FXP = 254,
  FXPL = 255,
  FZS = 256,
  G2W = 257,
  G3 = 258,
  G3W = 259,
  GAC = 260,
  GAM = 261,
  GBR = 262,
  GCA = 263,
  GDL = 264,
  GEO = 265,
  GEX = 266,
  GGB = 267,
  GGT = 268,
  GHF = 269,
  GIF = 270,
  GIM = 271,
  GML = 272,
  GMX = 273,
  GNUMERIC = 274,
  GPH = 275,
  GPX = 276,
  GQF = 277,
  GQS = 278,
  GRAM = 279,
  GRAMPS = 280,
  GRE = 281,
  GRV = 282,
  GRXML = 283,
  GSF = 284,
  GTAR = 285,
  GTM = 286,
  GTW = 287,
  GV = 288,
  GXF = 289,
  GXT = 290,
  H = 291,
  H261 = 292,
  H263 = 293,
  H264 = 294,
  HAL = 295,
  HBCI = 296,
  HDF = 297,
  HH = 298,
  HLP = 299,
  HPGL = 300,
  HPID = 301,
  HPS = 302,
  HQX = 303,
  HTKE = 304,
  HTM = 305,
  HTML = 306,
  HVD = 307,
  HVP = 308,
  HVS = 309,
  I2G = 310,
  IC = 311,
  ICC = 312,
  ICE = 313,
  ICM = 314,
  ICO = 315,
  ICS = 316,
  IEF = 317,
  IFB = 318,
  IFM = 319,
  IGES = 320,
  IGL = 321,
  IGM = 322,
  IGS = 323,
  IGX = 324,
  IIF = 325,
  IMP = 326,
  IMS = 327,
  IN = 328,
  INK = 329,
  INKML = 330,
  INSTALL = 331,
  IOTA = 332,
  IPFIX = 333,
  IPK = 334,
  IRM = 335,
  IRP = 336,
  ISO = 337,
  ITP = 338,
  IVP = 339,
  IVU = 340,
  JAD = 341,
  JAM = 342,
  JAR = 343,
  JAVA = 344,
  JISP = 345,
  JLT = 346,
  JNLP = 347,
  JODA = 348,
  JP2 = 349,
  JPEG = 350,
  JPG = 351,
  JPGM = 352,
  JPGV = 353,
  JPM = 354,
  JS = 355,
  JSON = 356,
  JSONML = 357,
  KAR = 358,
  KARBON = 359,
  KFO = 360,
  KIA = 361,
  KML = 362,
  KMZ = 363,
  KNE = 364,
  KNP = 365,
  KON = 366,
  KPR = 367,
  KPT = 368,
  KPXX = 369,
  KSP = 370,
  KTR = 371,
  KTX = 372,
  KTZ = 373,
  KWD = 374,
  KWT = 375,
  LASXML = 376,
  LATEX = 377,
  LBD = 378,
  LBE = 379,
  LES = 380,
  LHA = 381,
  LINK66 = 382,
  LIST = 383,
  LIST3820 = 384,
  LISTAFP = 385,
  LNK = 386,
  LOG = 387,
  LOSTXML = 388,
  LRF = 389,
  LRM = 390,
  LTF = 391,
  LVP = 392,
  LWP = 393,
  LZH = 394,
  M13 = 395,
  M14 = 396,
  M1V = 397,
  M21 = 398,
  M2A = 399,
  M2V = 400,
  M3A = 401,
  M3U = 402,
  M3U8 = 403,
  M4A = 404,
  M4B = 405,
  M4P = 406,
  M4U = 407,
  M4V = 408,
  MA = 409,
  MAC = 410,
  MADS = 411,
  MAG = 412,
  MAKER = 413,
  MAN = 414,
  MAR = 415,
  MATHML = 416,
  MB = 417,
  MBK = 418,
  MBOX = 419,
  MC1 = 420,
  MCD = 421,
  MCURL = 422,
  MDB = 423,
  MDI = 424,
  ME = 425,
  MESH = 426,
  META4 = 427,
  METALINK = 428,
  METS = 429,
  MFM = 430,
  MFT = 431,
  MGP = 432,
  MGZ = 433,
  MID = 434,
  MIDI = 435,
  MIE = 436,
  MIF = 437,
  MIME = 438,
  MJ2 = 439,
  MJP2 = 440,
  MK3D = 441,
  MKA = 442,
  MKS = 443,
  MKV = 444,
  MLP = 445,
  MMD = 446,
  MMF = 447,
  MMR = 448,
  MNG = 449,
  MNY = 450,
  MOBI = 451,
  MODS = 452,
  MOV = 453,
  MOVIE = 454,
  MP2 = 455,
  MP21 = 456,
  MP2A = 457,
  MP3 = 458,
  MP4 = 459,
  MP4A = 460,
  MP4S = 461,
  MP4V = 462,
  MPC = 463,
  MPE = 464,
  MPEG = 465,
  MPG = 466,
  MPG4 = 467,
  MPGA = 468,
  MPKG = 469,
  MPM = 470,
  MPN = 471,
  MPP = 472,
  MPT = 473,
  MPY = 474,
  MQY = 475,
  MRC = 476,
  MRCX = 477,
  MS = 478,
  MSCML = 479,
  MSEED = 480,
  MSEQ = 481,
  MSF = 482,
  MSH = 483,
  MSI = 484,
  MSL = 485,
  MSTY = 486,
  MTS = 487,
  MUS = 488,
  MUSICXML = 489,
  MVB = 490,
  MWF = 491,
  MXF = 492,
  MXL = 493,
  MXML = 494,
  MXS = 495,
  MXU = 496,
  N3 = 497,
  NB = 498,
  NBP = 499,
  NC = 500,
  NCX = 501,
  NFO = 502,
  NGDAT = 503,
  NITF = 504,
  NLU = 505,
  NML = 506,
  NND = 507,
  NNS = 508,
  NNW = 509,
  NPX = 510,
  NSC = 511,
  NSF = 512,
  NTF = 513,
  NZB = 514,
  OA2 = 515,
  OA3 = 516,
  OAS = 517,
  OBD = 518,
  OBJ = 519,
  ODA = 520,
  ODB = 521,
  ODC = 522,
  ODF = 523,
  ODFT = 524,
  ODG = 525,
  ODI = 526,
  ODM = 527,
  ODP = 528,
  ODS = 529,
  ODT = 530,
  OGA = 531,
  OGG = 532,
  OGV = 533,
  OGX = 534,
  OMDOC = 535,
  ONEPKG = 536,
  ONETMP = 537,
  ONETOC = 538,
  ONETOC2 = 539,
  OPF = 540,
  OPML = 541,
  OPRC = 542,
  ORG = 543,
  OSF = 544,
  OSFPVG = 545,
  OTC = 546,
  OTF = 547,
  OTG = 548,
  OTH = 549,
  OTI = 550,
  OTP = 551,
  OTS = 552,
  OTT = 553,
  OXPS = 554,
  OXT = 555,
  P = 556,
  P10 = 557,
  P12 = 558,
  P7B = 559,
  P7C = 560,
  P7M = 561,
  P7R = 562,
  P7S = 563,
  P8 = 564,
  PAS = 565,
  PAW = 566,
  PBD = 567,
  PBM = 568,
  PCAP = 569,
  PCF = 570,
  PCL = 571,
  PCLXL = 572,
  PCT = 573,
  PCURL = 574,
  PCX = 575,
  PDB = 576,
  PDF = 577,
  PFA = 578,
  PFB = 579,
  PFM = 580,
  PFR = 581,
  PFX = 582,
  PGM = 583,
  PGN = 584,
  PGP = 585,
  PIC = 586,
  PICT = 587,
  PKG = 588,
  PKI = 589,
  PKIPATH = 590,
  PLB = 591,
  PLC = 592,
  PLF = 593,
  PLS = 594,
  PML = 595,
  PNG = 596,
  PNM = 597,
  PNT = 598,
  PNTG = 599,
  PORTPKG = 600,
  POT = 601,
  POTM = 602,
  POTX = 603,
  PPAM = 604,
  PPD = 605,
  PPM = 606,
  PPS = 607,
  PPSM = 608,
  PPSX = 609,
  PPT = 610,
  PPTM = 611,
  PPTX = 612,
  PQA = 613,
  PRC = 614,
  PRE = 615,
  PRF = 616,
  PS = 617,
  PSB = 618,
  PSD = 619,
  PSF = 620,
  PSKCXML = 621,
  PTID = 622,
  PUB = 623,
  PVB = 624,
  PWN = 625,
  PYA = 626,
  PYV = 627,
  QAM = 628,
  QBO = 629,
  QFX = 630,
  QPS = 631,
  QT = 632,
  QTI = 633,
  QTIF = 634,
  QWD = 635,
  QWT = 636,
  QXB = 637,
  QXD = 638,
  QXL = 639,
  QXT = 640,
  RA = 641,
  RAM = 642,
  RAR = 643,
  RAS = 644,
  RCPROFILE = 645,
  RDF = 646,
  RDZ = 647,
  REP = 648,
  RES = 649,
  RGB = 650,
  RIF = 651,
  RIP = 652,
  RIS = 653,
  RL = 654,
  RLC = 655,
  RLD = 656,
  RM = 657,
  RMI = 658,
  RMP = 659,
  RMS = 660,
  RMVB = 661,
  RNC = 662,
  ROA = 663,
  ROFF = 664,
  RP9 = 665,
  RPSS = 666,
  RPST = 667,
  RQ = 668,
  RS = 669,
  RSD = 670,
  RSS = 671,
  RTF = 672,
  RTX = 673,
  S = 674,
  S3M = 675,
  SAF = 676,
  SBML = 677,
  SC = 678,
  SCD = 679,
  SCM = 680,
  SCQ = 681,
  SCS = 682,
  SCURL = 683,
  SDA = 684,
  SDC = 685,
  SDD = 686,
  SDKD = 687,
  SDKM = 688,
  SDP = 689,
  SDW = 690,
  SEE = 691,
  SEED = 692,
  SEMA = 693,
  SEMD = 694,
  SEMF = 695,
  SER = 696,
  SETPAY = 697,
  SETREG = 698,
  SFS = 699,
  SFV = 700,
  SGI = 701,
  SGL = 702,
  SGM = 703,
  SGML = 704,
  SH = 705,
  SHAR = 706,
  SHF = 707,
  SID = 708,
  SIG = 709,
  SIL = 710,
  SILO = 711,
  SIS = 712,
  SISX = 713,
  SIT = 714,
  SITX = 715,
  SKD = 716,
  SKM = 717,
  SKP = 718,
  SKT = 719,
  SLDM = 720,
  SLDX = 721,
  SLT = 722,
  SM = 723,
  SMF = 724,
  SMI = 725,
  SMIL = 726,
  SMV = 727,
  SMZIP = 728,
  SND = 729,
  SNF = 730,
  SO = 731,
  SPC = 732,
  SPF = 733,
  SPL = 734,
  SPOT = 735,
  SPP = 736,
  SPQ = 737,
  SPX = 738,
  SQL = 739,
  SRC = 740,
  SRT = 741,
  SRU = 742,
  SRX = 743,
  SSDL = 744,
  SSE = 745,
  SSF = 746,
  SSML = 747,
  ST = 748,
  STC = 749,
  STD = 750,
  STF = 751,
  STI = 752,
  STK = 753,
  STL = 754,
  STR = 755,
  STW = 756,
  SUB = 757,
  SUS = 758,
  SUSP = 759,
  SV4CPIO = 760,
  SV4CRC = 761,
  SVC = 762,
  SVD = 763,
  SVG = 764,
  SVGZ = 765,
  SWA = 766,
  SWF = 767,
  SWI = 768,
  SXC = 769,
  SXD = 770,
  SXG = 771,
  SXI = 772,
  SXM = 773,
  SXW = 774,
  T = 775,
  T3 = 776,
  TAGLET = 777,
  TAO = 778,
  TAR = 779,
  TCAP = 780,
  TCL = 781,
  TEACHER = 782,
  TEI = 783,
  TEICORPUS = 784,
  TEX = 785,
  TEXI = 786,
  TEXINFO = 787,
  TEXT = 788,
  TFI = 789,
  TFM = 790,
  TGA = 791,
  THMX = 792,
  TIF = 793,
  TIFF = 794,
  TMO = 795,
  TORRENT = 796,
  TPL = 797,
  TPT = 798,
  TR = 799,
  TRA = 800,
  TRM = 801,
  TSD = 802,
  TSV = 803,
  TTC = 804,
  TTF = 805,
  TTL = 806,
  TWD = 807,
  TWDS = 808,
  TXD = 809,
  TXF = 810,
  TXT = 811,
  U32 = 812,
  UDEB = 813,
  UFD = 814,
  UFDL = 815,
  ULX = 816,
  UMJ = 817,
  UNITYWEB = 818,
  UOML = 819,
  URI = 820,
  URIS = 821,
  URLS = 822,
  USTAR = 823,
  UTZ = 824,
  UU = 825,
  UVA = 826,
  UVD = 827,
  UVF = 828,
  UVG = 829,
  UVH = 830,
  UVI = 831,
  UVM = 832,
  UVP = 833,
  UVS = 834,
  UVT = 835,
  UVU = 836,
  UVV = 837,
  UVVA = 838,
  UVVD = 839,
  UVVF = 840,
  UVVG = 841,
  UVVH = 842,
  UVVI = 843,
  UVVM = 844,
  UVVP = 845,
  UVVS = 846,
  UVVT = 847,
  UVVU = 848,
  UVVV = 849,
  UVVX = 850,
  UVVZ = 851,
  UVX = 852,
  UVZ = 853,
  VCARD = 854,
  VCD = 855,
  VCF = 856,
  VCG = 857,
  VCS = 858,
  VCX = 859,
  VIS = 860,
  VIV = 861,
  VOB = 862,
  VOR = 863,
  VOX = 864,
  VRML = 865,
  VSD = 866,
  VSF = 867,
  VSS = 868,
  VST = 869,
  VSW = 870,
  VTU = 871,
  VXML = 872,
  W3D = 873,
  WAD = 874,
  WAV = 875,
  WAX = 876,
  WBMP = 877,
  WBMXL = 878,
  WBS = 879,
  WBXML = 880,
  WCM = 881,
  WDB = 882,
  WDP = 883,
  WEBA = 884,
  WEBM = 885,
  WEBP = 886,
  WG = 887,
  WGT = 888,
  WKS = 889,
  WM = 890,
  WMA = 891,
  WMD = 892,
  WMF = 893,
  WML = 894,
  WMLC = 895,
  WMLS = 896,
  WMLSC = 897,
  WMV = 898,
  WMX = 899,
  WMZ = 900,
  WOFF = 901,
  WPD = 902,
  WPL = 903,
  WPS = 904,
  WQD = 905,
  WRI = 906,
  WRL = 907,
  WSDL = 908,
  WSPOLICY = 909,
  WTB = 910,
  WVX = 911,
  X32 = 912,
  X3D = 913,
  X3DB = 914,
  X3DBZ = 915,
  X3DV = 916,
  X3DVZ = 917,
  X3DZ = 918,
  XAML = 919,
  XAP = 920,
  XAR = 921,
  XBAP = 922,
  XBD = 923,
  XBM = 924,
  XDF = 925,
  XDM = 926,
  XDP = 927,
  XDSSC = 928,
  XDW = 929,
  XENC = 930,
  XER = 931,
  XFDF = 932,
  XFDL = 933,
  XHT = 934,
  XHTML = 935,
  XHVML = 936,
  XIF = 937,
  XLA = 938,
  XLAM = 939,
  XLC = 940,
  XLF = 941,
  XLM = 942,
  XLS = 943,
  XLSB = 944,
  XLSM = 945,
  XLSX = 946,
  XLT = 947,
  XLTM = 948,
  XLTX = 949,
  XLW = 950,
  XM = 951,
  XML = 952,
  XO = 953,
  XOP = 954,
  XPI = 955,
  XPL = 956,
  XPM = 957,
  XPR = 958,
  XPS = 959,
  XPW = 960,
  XPX = 961,
  XSL = 962,
  XSLT = 963,
  XSM = 964,
  XSPF = 965,
  XUL = 966,
  XVM = 967,
  XVML = 968,
  XWD = 969,
  XYZ = 970,
  XZ = 971,
  YANG = 972,
  YIN = 973,
  Z1 = 974,
  Z2 = 975,
  Z3 = 976,
  Z4 = 977,
  Z5 = 978,
  Z6 = 979,
  Z7 = 980,
  Z8 = 981,
  ZAZ = 982,
  ZIP = 983,
  ZIR = 984,
  ZIRZ = 985,
  ZMM = 986,
  DEFAULT = 987,
}

/**
 * Defines values for ContextCloak.
 * Possible values include: 'Default', 'User', 'Location'
 * @readonly
 * @enum {string}
 */
export enum ContextCloak {
  Default = 0,
  User = 1,
  Location = 2,
}

/**
 * Defines values for ContractState.
 * Possible values include: 'Inactive', 'Active', 'Due'
 * @readonly
 * @enum {string}
 */
export enum ContractState {
  Inactive = 0,
  Active = 1,
  Due = 2,
}

/**
 * Defines values for ControlConditionType.
 * Possible values include: 'Base', 'FixedTime', 'Consumption', 'Error'
 * @readonly
 * @enum {string}
 */
export enum ControlConditionType {
  Base = 0,
  FixedTime = 2,
  Consumption = 3,
  Error = 5,
}

/**
 * Defines values for ControlTriggerType.
 * Possible values include: 'Base', 'Stepped', 'OutOfRange'
 * @readonly
 * @enum {string}
 */
export enum ControlTriggerType {
  Base = 0,
  Stepped = 2,
  OutOfRange = 3,
}

/**
 * Defines values for DataTableViewType.
 * Possible values include: 'Table', 'Gantt'
 * @readonly
 * @enum {string}
 */
export enum DataTableViewType {
  Table = 1,
  Gantt = 2,
}

/**
 * Defines values for DataTableUsage.
 * Possible values include: 'DataTable', 'Selector', 'Restriction', 'Calendar'
 * @readonly
 * @enum {string}
 */
export enum DataTableUsage {
  DataTable = 1,
  Selector = 2,
  Restriction = 3,
  Calendar = 4,
}

/**
 * Defines values for ControllerOperationId.
 * Possible values include: 'ContactControllerList', 'TicketControllerList',
 * 'ResourceControllerList', 'MissionControllerList', 'ContractControllerList',
 * 'LocationControllerList', 'CompanyControllerList', 'LocationGroupControllerList',
 * 'AddressControllerList', 'ArticlesControllerList', 'ResourceTaskControllerList',
 * 'InfoControllerList', 'KnowledgeArticleControllerList', 'TaskControllerList',
 * 'TicketsInResourcesControllerList', 'MissionsInResourcesControllerList',
 * 'TicketInLocationControllerId', 'ResourceManufacturerControllerId', 'ResourceModelControllerId',
 * 'IssueControllerId', 'ConsumptionHistoryForResourceList', 'ErrorHistoryForResourceList',
 * 'HintHistoryForResourceList', 'ResourceModelInManufacturerControllerId',
 * 'InfoProgressContactControllerId', 'InfoProgressFunctionControllerId',
 * 'InfoProgressLocationControllerId', 'KnowledgePathControllerList',
 * 'KnowledgePathProgressContactControllerId', 'KnowledgePathProgressFunctionControllerId',
 * 'KnowledgePathProgressLocationControllerId', 'OccuredConsumptionProtocol',
 * 'OccuredErrorProtocol', 'MessageIssueControllerId', 'TicketIssueControllerId',
 * 'MissionIssueControllerId', 'WinterhalterServiceCallIssueControllerId',
 * 'WinterhalterProductOrderIssueControllerId', 'MessageControlControllerId'
 * @readonly
 * @enum {string}
 */
export enum ControllerOperationId {
  ContactControllerList = 1,
  TicketControllerList = 2,
  ResourceControllerList = 3,
  MissionControllerList = 4,
  ContractControllerList = 5,
  LocationControllerList = 6,
  CompanyControllerList = 7,
  LocationGroupControllerList = 9,
  AddressControllerList = 10,
  ArticlesControllerList = 11,
  ResourceTaskControllerList = 12,
  InfoControllerList = 13,
  KnowledgeArticleControllerList = 14,
  TaskControllerList = 15,
  TicketsInResourcesControllerList = 16,
  MissionsInResourcesControllerList = 17,
  TicketInLocationControllerId = 18,
  ResourceManufacturerControllerId = 19,
  ResourceModelControllerId = 20,
  IssueControllerId = 21,
  ConsumptionHistoryForResourceList = 22,
  ErrorHistoryForResourceList = 23,
  HintHistoryForResourceList = 24,
  ResourceModelInManufacturerControllerId = 25,
  InfoProgressContactControllerId = 26,
  InfoProgressFunctionControllerId = 27,
  InfoProgressLocationControllerId = 28,
  KnowledgePathControllerList = 29,
  KnowledgePathProgressContactControllerId = 30,
  KnowledgePathProgressFunctionControllerId = 31,
  KnowledgePathProgressLocationControllerId = 32,
  OccuredConsumptionProtocol = 33,
  OccuredErrorProtocol = 34,
  MessageIssueControllerId = 211,
  TicketIssueControllerId = 212,
  MissionIssueControllerId = 213,
  WinterhalterServiceCallIssueControllerId = 214,
  WinterhalterProductOrderIssueControllerId = 215,
  MessageControlControllerId = 216,
}

/**
 * Defines values for MissionEditorSelectorKind.
 * Possible values include: 'None', 'Editor', 'PrioritySearch'
 * @readonly
 * @enum {string}
 */
export enum MissionEditorSelectorKind {
  None = 0,
  Editor = 1,
  PrioritySearch = 2,
}

/**
 * Defines values for ControlNotificationType.
 * Possible values include: 'Base', 'Email'
 * @readonly
 * @enum {string}
 */
export enum ControlNotificationType {
  Base = 0,
  Email = 1,
}

/**
 * Defines values for PropertyRating.
 * Possible values include: 'Ok', 'Error'
 * @readonly
 * @enum {string}
 */
export enum PropertyRating {
  Ok = 0,
  Error = 1,
}

/**
 * Defines values for CustomInfoKinds.
 * Possible values include: 'Warning', 'Hint'
 * @readonly
 * @enum {string}
 */
export enum CustomInfoKinds {
  Warning = 1,
  Hint = 2,
}

/**
 * Defines values for TimePatterns.
 * Possible values include: 'None', 'Single', 'Daily', 'Weekly', 'MonthlyDay', 'MonthlyWeekDay'
 * @readonly
 * @enum {string}
 */
export enum TimePatterns {
  None = 0,
  Single = 1,
  Daily = 2,
  Weekly = 3,
  MonthlyDay = 4,
  MonthlyWeekDay = 5,
}

/**
 * Defines values for DashboardItemTypes.
 * Possible values include: 'Base', 'DataTable'
 * @readonly
 * @enum {string}
 */
export enum DashboardItemTypes {
  Base = 0,
  DataTable = 1,
}

/**
 * Defines values for DeviceActionTypes.
 * Possible values include: 'Logout', 'Ping', 'Restart'
 * @readonly
 * @enum {string}
 */
export enum DeviceActionTypes {
  Logout = 1,
  Ping = 2,
  Restart = 3,
}

/**
 * Defines values for Platforms.
 * Possible values include: 'iOS', 'Android'
 * @readonly
 * @enum {string}
 */
export enum Platforms {
  IOS = 1,
  Android = 2,
}

/**
 * Defines values for MetaEntryType.
 * Possible values include: 'Device'
 * @readonly
 * @enum {string}
 */
export enum MetaEntryType {
  Device = 1,
}

/**
 * Defines values for DeviceStatus.
 * Possible values include: 'Created', 'Registered', 'Deleted'
 * @readonly
 * @enum {string}
 */
export enum DeviceStatus {
  Created = 0,
  Registered = 1,
  Deleted = 2,
}

/**
 * Defines values for FieldRating.
 * Possible values include: 'Ok', 'Error', 'Tolerant'
 * @readonly
 * @enum {string}
 */
export enum FieldRating {
  Ok = 0,
  Error = 1,
  Tolerant = 2,
}

/**
 * Defines values for DropDownStyles.
 * Possible values include: 'Default', 'ChooseList'
 * @readonly
 * @enum {string}
 */
export enum DropDownStyles {
  Default = 0,
  ChooseList = 1,
}

/**
 * Defines values for EditorQuestState.
 * Possible values include: 'Suggested', 'InProcess', 'Completed', 'Failed', 'Canceled'
 * @readonly
 * @enum {string}
 */
export enum EditorQuestState {
  Suggested = 1,
  InProcess = 2,
  Completed = 3,
  Failed = 4,
  Canceled = 5,
}

/**
 * Defines values for EditorRequestState.
 * Possible values include: 'Untouched', 'Accepted', 'Declined', 'Missed'
 * @readonly
 * @enum {string}
 */
export enum EditorRequestState {
  Untouched = 0,
  Accepted = 1,
  Declined = 2,
  Missed = 3,
}

/**
 * Defines values for EditorSuggestionType.
 * Possible values include: 'Base', 'Primary', 'Alternative'
 * @readonly
 * @enum {string}
 */
export enum EditorSuggestionType {
  Base = 0,
  Primary = 1,
  Alternative = 2,
}

/**
 * Defines values for TemplateContextType.
 * Possible values include: 'MissionByTicketDescription', 'MissionByTaskDescription',
 * 'NoteForTicket', 'NoteForMissionByTicket', 'NoteForMissionByTask', 'MissionDescription',
 * 'NoteForMission', 'NoteBase', 'MissionNotificationContent', 'TicketNotificationContent',
 * 'InfoNotificationContent', 'MissionNotificationSubject', 'TicketNotificationSubject',
 * 'InfoNotificationSubject', 'IssueEmailSubject', 'IssueEmailContent', 'MessageIssueEmailSubject',
 * 'TicketIssueEmailSubject', 'MissionIssueEmailSubject', 'WinterhalterIssueEmailSubject',
 * 'TicketMailNodeEmailSubject', 'WinterhalterMailNodeEmailSubject', 'MessageIssueEmailContent',
 * 'TicketIssueEmailContent', 'MissionIssueEmailContent', 'WinterhalterIssueEmailContent',
 * 'TicketMailNodeEmailContent', 'WinterhalterMailNodeEmailContent'
 * @readonly
 * @enum {string}
 */
export enum TemplateContextType {
  MissionByTicketDescription = 1,
  MissionByTaskDescription = 2,
  NoteForTicket = 3,
  NoteForMissionByTicket = 4,
  NoteForMissionByTask = 5,
  MissionDescription = 6,
  NoteForMission = 9,
  NoteBase = 11,
  MissionNotificationContent = 12,
  TicketNotificationContent = 13,
  InfoNotificationContent = 14,
  MissionNotificationSubject = 15,
  TicketNotificationSubject = 16,
  InfoNotificationSubject = 17,
  IssueEmailSubject = 18,
  IssueEmailContent = 19,
  MessageIssueEmailSubject = 181,
  TicketIssueEmailSubject = 182,
  MissionIssueEmailSubject = 183,
  WinterhalterIssueEmailSubject = 184,
  TicketMailNodeEmailSubject = 186,
  WinterhalterMailNodeEmailSubject = 187,
  MessageIssueEmailContent = 191,
  TicketIssueEmailContent = 192,
  MissionIssueEmailContent = 193,
  WinterhalterIssueEmailContent = 194,
  TicketMailNodeEmailContent = 196,
  WinterhalterMailNodeEmailContent = 197,
}

/**
 * Defines values for ReportLevel.
 * Possible values include: 'Info', 'Error', 'Warning'
 * @readonly
 * @enum {string}
 */
export enum ReportLevel {
  Info = 0,
  Error = 1,
  Warning = 2,
}

/**
 * Defines values for IssueNotificationType.
 * Possible values include: 'Base', 'Email'
 * @readonly
 * @enum {string}
 */
export enum IssueNotificationType {
  Base = 0,
  Email = 1,
}

/**
 * Defines values for MailNodeNotificationType.
 * Possible values include: 'Base', 'Email'
 * @readonly
 * @enum {string}
 */
export enum MailNodeNotificationType {
  Base = 0,
  Email = 1,
}

/**
 * Defines values for ExternalApp.
 * Possible values include: 'SemcoOrgaManual'
 * @readonly
 * @enum {string}
 */
export enum ExternalApp {
  SemcoOrgaManual = 1,
}

/**
 * Defines values for FileFormat.
 * Possible values include: 'Csv'
 * @readonly
 * @enum {string}
 */
export enum FileFormat {
  Csv = 1,
}

/**
 * Defines values for FormRowTypes.
 * Possible values include: 'Base', 'Repeatable', 'Single'
 * @readonly
 * @enum {string}
 */
export enum FormRowTypes {
  Base = 0,
  Repeatable = 1,
  Single = 2,
}

/**
 * Defines values for FilterOperations.
 * Possible values include: 'Include', 'Exclude', 'All'
 * @readonly
 * @enum {string}
 */
export enum FilterOperations {
  Include = 0,
  Exclude = 1,
  All = 2,
}

/**
 * Defines values for SettingType.
 * Possible values include: 'Base', 'General', 'Ticket', 'Mission'
 * @readonly
 * @enum {string}
 */
export enum SettingType {
  Base = 0,
  General = 1,
  Ticket = 2,
  Mission = 3,
}

/**
 * Defines values for PdfViewBehaviour.
 * Possible values include: 'Internal', 'External'
 * @readonly
 * @enum {string}
 */
export enum PdfViewBehaviour {
  Internal = 0,
  External = 1,
}

/**
 * Defines values for GeneralTerms.
 * Possible values include: 'None', 'All'
 * @readonly
 * @enum {string}
 */
export enum GeneralTerms {
  None = 0,
  All = 1,
}

/**
 * Defines values for IssueReasonType.
 * Possible values include: 'Base', 'ReachedFixedTime', 'ReachedError', 'ReachedConsumption',
 * 'ReceivedMail'
 * @readonly
 * @enum {string}
 */
export enum IssueReasonType {
  Base = 0,
  ReachedFixedTime = 1,
  ReachedError = 2,
  ReachedConsumption = 3,
  ReceivedMail = 4,
}

/**
 * Defines values for HistoryTypes.
 * Possible values include: 'Base', 'FormRow', 'Form', 'FormField'
 * @readonly
 * @enum {string}
 */
export enum HistoryTypes {
  Base = 0,
  FormRow = 1,
  Form = 2,
  FormField = 3,
}

/**
 * Defines values for NotificationTrigger.
 * Possible values include: 'TicketChangedResource', 'TicketChangedState', 'TicketChangedEditor',
 * 'MissionChangedResource', 'MissionChangedState', 'MissionChangedEditor',
 * 'MissionChangedCompletion', 'ContractChangedState', 'ActivatedInfo'
 * @readonly
 * @enum {string}
 */
export enum NotificationTrigger {
  TicketChangedResource = 10,
  TicketChangedState = 11,
  TicketChangedEditor = 12,
  MissionChangedResource = 20,
  MissionChangedState = 21,
  MissionChangedEditor = 22,
  MissionChangedCompletion = 23,
  ContractChangedState = 30,
  ActivatedInfo = 40,
}

/**
 * Defines values for ImportType.
 * Possible values include: 'User', 'Receipt', 'Resource', 'ResourceManufacturer'
 * @readonly
 * @enum {string}
 */
export enum ImportType {
  User = 0,
  Receipt = 1,
  Resource = 2,
  ResourceManufacturer = 3,
}

/**
 * Defines values for ImportProtocolStatus.
 * Possible values include: 'Processing', 'Successful', 'Failed'
 * @readonly
 * @enum {string}
 */
export enum ImportProtocolStatus {
  Processing = 0,
  Successful = 1,
  Failed = 2,
}

/**
 * Defines values for ImportRelationBehaviour.
 * Possible values include: 'Ignore', 'Update', 'Patch'
 * @readonly
 * @enum {string}
 */
export enum ImportRelationBehaviour {
  Ignore = 0,
  Update = 1,
  Patch = 2,
}

/**
 * Defines values for InfoState.
 * Possible values include: 'Pending', 'Active', 'Expired'
 * @readonly
 * @enum {string}
 */
export enum InfoState {
  Pending = 1,
  Active = 2,
  Expired = 3,
}

/**
 * Defines values for InvitationPurpose.
 * Possible values include: 'Team'
 * @readonly
 * @enum {string}
 */
export enum InvitationPurpose {
  Team = 1,
}

/**
 * Defines values for IssueContentType.
 * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
 * 'WinterhalterProductOrder'
 * @readonly
 * @enum {string}
 */
export enum IssueContentType {
  Message = 1,
  Ticket = 2,
  Mission = 3,
  WinterhalterServiceCall = 4,
  WinterhalterProductOrder = 5,
}

/**
 * Defines values for IssueType.
 * Possible values include: 'Message', 'Ticket', 'Mission', 'WinterhalterServiceCall',
 * 'WinterhalterProductOrder', 'ControlMessage', 'ControlMission', 'MailIssue'
 * @readonly
 * @enum {string}
 */
export enum IssueType {
  Message = 1,
  Ticket = 2,
  Mission = 3,
  WinterhalterServiceCall = 4,
  WinterhalterProductOrder = 5,
  ControlMessage = 6,
  ControlMission = 7,
  MailIssue = 8,
}

/**
 * Defines values for IssueResourceSelectorKind.
 * Possible values include: 'Selection', 'None'
 * @readonly
 * @enum {string}
 */
export enum IssueResourceSelectorKind {
  Selection = 0,
  None = 1,
}

/**
 * Defines values for KnowledgePathProgressStatus.
 * Possible values include: 'Started', 'CompletedUnsuccessfully', 'CompletedSuccessfully'
 * @readonly
 * @enum {string}
 */
export enum KnowledgePathProgressStatus {
  Started = 0,
  CompletedUnsuccessfully = 1,
  CompletedSuccessfully = 2,
}

/**
 * Defines values for LoginChallengeTypes.
 * Possible values include: 'Base', 'DeviceCode'
 * @readonly
 * @enum {string}
 */
export enum LoginChallengeTypes {
  Base = 0,
  DeviceCode = 1,
}

/**
 * Defines values for ResourceTaskConsumptionTriggerType.
 * Possible values include: 'Base', 'Stepped', 'OutOfRange', 'LookBack'
 * @readonly
 * @enum {string}
 */
export enum ResourceTaskConsumptionTriggerType {
  Base = 0,
  Stepped = 2,
  OutOfRange = 3,
  LookBack = 4,
}

/**
 * Defines values for NotificationReceiverType.
 * Possible values include: 'To', 'Cc', 'Bcc'
 * @readonly
 * @enum {string}
 */
export enum NotificationReceiverType {
  To = 1,
  Cc = 2,
  Bcc = 3,
}

/**
 * Defines values for NotificationReceiverStatus.
 * Possible values include: 'Unknown', 'Delivered', 'Blocked', 'Bounced', 'Dropped'
 * @readonly
 * @enum {string}
 */
export enum NotificationReceiverStatus {
  Unknown = 0,
  Delivered = 2,
  Blocked = 3,
  Bounced = 4,
  Dropped = 5,
}

/**
 * Defines values for MailNodeContentType.
 * Possible values include: 'Ticket', 'WinterhalterServiceCall'
 * @readonly
 * @enum {string}
 */
export enum MailNodeContentType {
  Ticket = 2,
  WinterhalterServiceCall = 4,
}

/**
 * Defines values for MailNodeSubjectType.
 * Possible values include: 'Unrestricted', 'Resource', 'Location'
 * @readonly
 * @enum {string}
 */
export enum MailNodeSubjectType {
  Unrestricted = 0,
  Resource = 1,
  Location = 2,
}

/**
 * Defines values for MailRelationTypes.
 * Possible values include: 'From', 'To', 'Cc', 'Bcc'
 * @readonly
 * @enum {string}
 */
export enum MailRelationTypes {
  From = 1,
  To = 2,
  Cc = 3,
  Bcc = 4,
}

/**
 * Defines values for ProductEditions.
 * Possible values include: 'OrgaClassicModule', 'TechClassicModule', 'OrgaModule',
 * 'MissionClassicAddon', 'ConnectedClassicAddon', 'SafeClassicAddon', 'LinkClassicAddon',
 * 'TechModule', 'DataClassicModule', 'ClassificationClassicAddon', 'SyncLocationClassicAddon',
 * 'DataModule', 'MissionAddon', 'ConnectedAddon', 'SafeAddon', 'LinkAddon', 'ClassificationAddon',
 * 'SyncLocationAddon', 'OrgaTechClassicModule', 'OrgaTechModule', 'SyncReceiptsClassicAddon',
 * 'SyncContactsClassicAddon', 'SyncDriveClassicAddon', 'SyncReceiptsAddon', 'SyncContactsAddon',
 * 'SyncDriveAddon', 'NotificationClassicAddon', 'NotificationAddon', 'TeamModule',
 * 'MdmClassicModule', 'PrimeReportAddon', 'MaintenanceReportAddon'
 * @readonly
 * @enum {string}
 */
export enum ProductEditions {
  OrgaClassicModule = 2,
  TechClassicModule = 11,
  OrgaModule = 12,
  MissionClassicAddon = 14,
  ConnectedClassicAddon = 15,
  SafeClassicAddon = 16,
  LinkClassicAddon = 17,
  TechModule = 18,
  DataClassicModule = 20,
  ClassificationClassicAddon = 21,
  SyncLocationClassicAddon = 22,
  DataModule = 23,
  MissionAddon = 25,
  ConnectedAddon = 26,
  SafeAddon = 27,
  LinkAddon = 28,
  ClassificationAddon = 29,
  SyncLocationAddon = 31,
  OrgaTechClassicModule = 32,
  OrgaTechModule = 33,
  SyncReceiptsClassicAddon = 34,
  SyncContactsClassicAddon = 35,
  SyncDriveClassicAddon = 36,
  SyncReceiptsAddon = 37,
  SyncContactsAddon = 38,
  SyncDriveAddon = 39,
  NotificationClassicAddon = 42,
  NotificationAddon = 43,
  TeamModule = 44,
  MdmClassicModule = 45,
  PrimeReportAddon = 46,
  MaintenanceReportAddon = 47,
}

/**
 * Defines values for MissionStartingState.
 * Possible values include: 'Created', 'Instructed'
 * @readonly
 * @enum {string}
 */
export enum MissionStartingState {
  Created = 0,
  Instructed = 1,
}

/**
 * Defines values for NoteType.
 * Possible values include: 'Text', 'Mail', 'Caller', 'ChangedStatus', 'EscalatedByEvent',
 * 'EscalatedByUser', 'CanceledEscalation', 'ChangedStatusOfMission', 'ChatMessage',
 * 'ChangedResource', 'FinalizedMission', 'Base'
 * @readonly
 * @enum {string}
 */
export enum NoteType {
  Text = 0,
  Mail = 1,
  Caller = 2,
  ChangedStatus = 4,
  EscalatedByEvent = 5,
  EscalatedByUser = 6,
  CanceledEscalation = 7,
  ChangedStatusOfMission = 8,
  ChatMessage = 9,
  ChangedResource = 10,
  FinalizedMission = 11,
  Base = -1,
}

/**
 * Defines values for SystemDirections.
 * Possible values include: 'None', 'In', 'Out'
 * @readonly
 * @enum {string}
 */
export enum SystemDirections {
  None = 0,
  In = 1,
  Out = 2,
}

/**
 * Defines values for NotificationType.
 * Possible values include: 'Email'
 * @readonly
 * @enum {string}
 */
export enum NotificationType {
  Email = 0,
}

/**
 * Defines values for OrgaFolderItemType.
 * Possible values include: 'Document', 'Folder'
 * @readonly
 * @enum {string}
 */
export enum OrgaFolderItemType {
  Document = 1,
  Folder = 2,
}

/**
 * Defines values for OverviewTaskKind.
 * Possible values include: 'Task', 'Location', 'General'
 * @readonly
 * @enum {string}
 */
export enum OverviewTaskKind {
  Task = 0,
  Location = 1,
  General = 2,
}

/**
 * Defines values for Owners.
 * Possible values include: 'Unspecified', 'Location', 'Resource', 'Contact'
 * @readonly
 * @enum {string}
 */
export enum Owners {
  Unspecified = 0,
  Location = 1,
  Resource = 2,
  Contact = 3,
}

/**
 * Defines values for PadDirection.
 * Possible values include: 'Left', 'Right'
 * @readonly
 * @enum {string}
 */
export enum PadDirection {
  Left = 0,
  Right = 1,
}

/**
 * Defines values for ProductKind.
 * Possible values include: 'Internal', 'Demo', 'Customer'
 * @readonly
 * @enum {string}
 */
export enum ProductKind {
  Internal = 0,
  Demo = 1,
  Customer = 2,
}

/**
 * Defines values for QuickAccessKnowledgeVisibility.
 * Possible values include: 'None', 'Own', 'Explicit'
 * @readonly
 * @enum {string}
 */
export enum QuickAccessKnowledgeVisibility {
  None = 1,
  Own = 2,
  Explicit = 3,
}

/**
 * Defines values for QuickAccessIssueTemplateVisibility.
 * Possible values include: 'None', 'Own', 'Explicit'
 * @readonly
 * @enum {string}
 */
export enum QuickAccessIssueTemplateVisibility {
  None = 1,
  Own = 2,
  Explicit = 3,
}

/**
 * Defines values for QuickAccessIssueHistoryVisibility.
 * Possible values include: 'Recent', 'Hide'
 * @readonly
 * @enum {string}
 */
export enum QuickAccessIssueHistoryVisibility {
  Recent = 0,
  Hide = 1,
}

/**
 * Defines values for QuickAccessDashboardVisibility.
 * Possible values include: 'None', 'Own'
 * @readonly
 * @enum {string}
 */
export enum QuickAccessDashboardVisibility {
  None = 1,
  Own = 2,
}

/**
 * Defines values for RecordStatus.
 * Possible values include: 'Untouched', 'New', 'Updated', 'Removed', 'Failed'
 * @readonly
 * @enum {string}
 */
export enum RecordStatus {
  Untouched = 0,
  New = 1,
  Updated = 2,
  Removed = 3,
  Failed = 4,
}

/**
 * Defines values for RefreshTokenTypes.
 * Possible values include: 'Base', 'User', 'Device', 'QuickAccess'
 * @readonly
 * @enum {string}
 */
export enum RefreshTokenTypes {
  Base = 0,
  User = 1,
  Device = 3,
  QuickAccess = 4,
}

/**
 * Defines values for ReportGroup.
 * Possible values include: 'None', 'ControlPoint', 'Tag', 'Resource'
 * @readonly
 * @enum {string}
 */
export enum ReportGroup {
  None = 0,
  ControlPoint = 1,
  Tag = 2,
  Resource = 3,
}

/**
 * Defines values for ResourceEventType.
 * Possible values include: 'Base', 'ChangedState', 'ChangedLocation'
 * @readonly
 * @enum {string}
 */
export enum ResourceEventType {
  Base = 0,
  ChangedState = 1,
  ChangedLocation = 2,
}

/**
 * Defines values for ResourceTaskScheduleType.
 * Possible values include: 'Base', 'Relative', 'Fixed', 'Consumption', 'Error'
 * @readonly
 * @enum {string}
 */
export enum ResourceTaskScheduleType {
  Base = 0,
  Relative = 1,
  Fixed = 2,
  Consumption = 3,
  Error = 5,
}

/**
 * Defines values for ResourceTaskPatternType.
 * Possible values include: 'Base', 'Offset'
 * @readonly
 * @enum {string}
 */
export enum ResourceTaskPatternType {
  Base = 0,
  Offset = 1,
}

/**
 * Defines values for SeedSequence.
 * Possible values include: 'Init', 'InitTechAdmin', 'InitCorporateIdentitySetting',
 * 'AddGeneralSettingDefaultLanguage', 'InitTaskJobStatusGenre', 'MigrateOldAppUser',
 * 'InitDashboardRole', 'InitChatManagerRole', 'InitInfoManagerRole', 'InitSemcoDefaultStereotype',
 * 'InitTicketSetting', 'InitMissionSetting', 'InitContractDefault', 'InitArticleKind',
 * 'InitArticleStereotypes', 'AddGeneralSettingDefaultLoginMethod',
 * 'AddGeneralSettingAvailableLoginMethod', 'InitServiceEmployee',
 * 'InitInspectionReportStereotype', 'InitControlTagStereotype', 'InitNewChatAndTaskTemplates',
 * 'InitQuickAccess', 'InitQuickAccessProcesses', 'InitQuickAccessDashboard', 'InitTeamApp',
 * 'InitAnalyst', 'InitQuickAccessIssueHistory', 'InitTechApp', 'AddDefaultMissionReportSettings'
 * @readonly
 * @enum {string}
 */
export enum SeedSequence {
  Init = 0,
  InitTechAdmin = 1,
  InitCorporateIdentitySetting = 2,
  AddGeneralSettingDefaultLanguage = 3,
  InitTaskJobStatusGenre = 4,
  MigrateOldAppUser = 5,
  InitDashboardRole = 6,
  InitChatManagerRole = 7,
  InitInfoManagerRole = 8,
  InitSemcoDefaultStereotype = 9,
  InitTicketSetting = 10,
  InitMissionSetting = 11,
  InitContractDefault = 12,
  InitArticleKind = 13,
  InitArticleStereotypes = 14,
  AddGeneralSettingDefaultLoginMethod = 15,
  AddGeneralSettingAvailableLoginMethod = 16,
  InitServiceEmployee = 17,
  InitInspectionReportStereotype = 18,
  InitControlTagStereotype = 19,
  InitNewChatAndTaskTemplates = 20,
  InitQuickAccess = 21,
  InitQuickAccessProcesses = 22,
  InitQuickAccessDashboard = 23,
  InitTeamApp = 24,
  InitAnalyst = 25,
  InitQuickAccessIssueHistory = 26,
  InitTechApp = 27,
  AddDefaultMissionReportSettings = 28,
}

/**
 * Defines values for SemcoArticleKinds.
 * Possible values include: 'Materials', 'Services'
 * @readonly
 * @enum {string}
 */
export enum SemcoArticleKinds {
  Materials = 0,
  Services = 1,
}

/**
 * Defines values for SemcoStereotypes.
 * Possible values include: 'Disruption', 'DeliveryAddress', 'InvoiceAddress', 'ServiceProvider',
 * 'Customer', 'Branch', 'Production', 'Employee', 'Technician', 'Material', 'Apparatus', 'Group',
 * 'GroupForLocations', 'District', 'Assignment', 'Contract', 'ArticleStandard',
 * 'MissionReportStandard', 'InspectionReportStandard', 'ControlTag'
 * @readonly
 * @enum {string}
 */
export enum SemcoStereotypes {
  Disruption = 0,
  DeliveryAddress = 1,
  InvoiceAddress = 2,
  ServiceProvider = 3,
  Customer = 4,
  Branch = 5,
  Production = 6,
  Employee = 7,
  Technician = 8,
  Material = 9,
  Apparatus = 10,
  Group = 11,
  GroupForLocations = 12,
  District = 13,
  Assignment = 14,
  Contract = 15,
  ArticleStandard = 16,
  MissionReportStandard = 17,
  InspectionReportStandard = 18,
  ControlTag = 19,
}

/**
 * Defines values for SqlDatePart.
 * Possible values include: 'Year', 'Quarter', 'Month', 'DayOfYear', 'Day', 'Week', 'WeekDay',
 * 'Hour', 'Minute', 'Second', 'Millisecond', 'Microsecond', 'Nanosecond', 'TZoffset', 'ISO_WEEK'
 * @readonly
 * @enum {string}
 */
export enum SqlDatePart {
  Year = 1,
  Quarter = 2,
  Month = 3,
  DayOfYear = 4,
  Day = 5,
  Week = 6,
  WeekDay = 7,
  Hour = 8,
  Minute = 9,
  Second = 10,
  Millisecond = 11,
  Microsecond = 12,
  Nanosecond = 13,
  TZoffset = 14,
  ISOWEEK = 15,
}

/**
 * Defines values for StartingMissionEditorType.
 * Possible values include: 'Contact', 'Function'
 * @readonly
 * @enum {string}
 */
export enum StartingMissionEditorType {
  Contact = 1,
  Function = 2,
}

/**
 * Defines values for TaskJobConditionType.
 * Possible values include: 'Base', 'Time'
 * @readonly
 * @enum {string}
 */
export enum TaskJobConditionType {
  Base = 0,
  Time = 1,
}

/**
 * Defines values for TaskJobStateTerm.
 * Possible values include: 'Undefined', 'Pending', 'Open', 'Closed'
 * @readonly
 * @enum {string}
 */
export enum TaskJobStateTerm {
  Undefined = 0,
  Pending = 1,
  Open = 2,
  Closed = 4,
}

/**
 * Defines values for TaskJobStatusGenre.
 * Possible values include: 'System', 'Client'
 * @readonly
 * @enum {string}
 */
export enum TaskJobStatusGenre {
  System = 1,
  Client = 2,
}

/**
 * Defines values for TicketByProcessDefinitionKind.
 * Possible values include: 'Default', 'Linkable'
 * @readonly
 * @enum {string}
 */
export enum TicketByProcessDefinitionKind {
  Default = 0,
  Linkable = 1,
}

/**
 * Defines values for TicketEventType.
 * Possible values include: 'Base', 'Escalation', 'FollowUp'
 * @readonly
 * @enum {string}
 */
export enum TicketEventType {
  Base = 0,
  Escalation = 1,
  FollowUp = 2,
}

/**
 * Defines values for UserTypes.
 * Possible values include: 'Device', 'Contact', 'Resource', 'Guest'
 * @readonly
 * @enum {string}
 */
export enum UserTypes {
  Device = 1,
  Contact = 4,
  Resource = 8,
  Guest = 16,
}

/**
 * Defines values for WinterhalterOperationStatus.
 * Possible values include: 'Created', 'Instructed', 'Canceled', 'Done', 'Transition'
 * @readonly
 * @enum {string}
 */
export enum WinterhalterOperationStatus {
  Created = 1,
  Instructed = 2,
  Canceled = 3,
  Done = 4,
  Transition = 5,
}
