import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {MissionReportDto} from "@nexnox-web/core-shared";

export interface LocalArticleDurationItem {
  travelDurationInHours: number;
  travelDurationCost: number;
  workDurationInHours: number;
  workDurationCost: number;
}

@Component({
  selector: 'nexnox-web-missions-mission-report-article-duration-item',
  templateUrl: './mission-report-article-duration-item.component.html',
  styleUrls: ['./mission-report-article-duration-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionReportArticleDurationItemComponent {
  @Input() public item: { model: MissionReportDto, position: string, title: string, fields: FormlyFieldConfig[] };
  @Input() public readonly = true;

  public form: FormGroup = new FormGroup({});

  @Output() public durationsChanged: EventEmitter<{ durationItem: MissionReportDto, valid: boolean }> =
    new EventEmitter<{ durationItem: MissionReportDto, valid: boolean }>();

  public faPlus = faPlus;

  public get total(): number {
    const model = this.item?.model;
    if (!model) return 0;
    let cost;
    let hours;
    if (this.item?.position === 'travel') {
      cost = Number(model?.travelDurationCost);
      hours = Number(model?.travelDurationInHours);
    } else if (this.item?.position === 'work') {
      cost = Number(model?.workDurationCost);
      hours = Number(model?.workDurationInHours);
    } else return 0;

    if ((!cost || cost === 0) || (!hours || hours === 0)) return 0;
    return (cost * hours);
  }

  public onItemChange(durationItem: MissionReportDto): void {
    this.durationsChanged.emit({durationItem, valid: this.form.valid});
  }
}
