import {
  missionAuditStore,
  MissionAuditStoreState,
  missionDetailStore,
  MissionDetailStoreState,
  missionEditorQuestStore,
  MissionEditorQuestStoreState,
  missionGanttListStore,
  missionListStore,
  missionMyRequestsStore,
  MissionMyRequestsStoreState,
  missionReceiptStore,
  MissionReceiptStoreState,
  missionReportStore,
  MissionReportStoreState,
  missionSolutionStore,
  MissionSolutionStoreState
} from './stores';
import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {MissionDto} from '@nexnox-web/core-shared';

export interface MissionsState {
  missionList: PagedEntitiesXsStoreState<MissionDto>;
  missionDetail: MissionDetailStoreState;

  missionSolution: MissionSolutionStoreState;
  missionReport: MissionReportStoreState;
  missionReceipt: MissionReceiptStoreState;
  missionAudit: MissionAuditStoreState;
  missionGanttList: PagedEntitiesXsStoreState<MissionDto>;
  missionEditorQuest: MissionEditorQuestStoreState;
  missionMyRequests: MissionMyRequestsStoreState;
}

export const getInitialMissionsState = (): MissionsState => ({
  missionList: missionListStore.getInitialState(),
  missionDetail: missionDetailStore.getInitialState(),

  missionSolution: missionSolutionStore.getInitialState(),
  missionReport: missionReportStore.getInitialState(),
  missionReceipt: missionReceiptStore.getInitialState(),
  missionAudit: missionAuditStore.getInitialState(),
  missionGanttList: missionGanttListStore.getInitialState(),
  missionEditorQuest: missionEditorQuestStore.getInitialState(),
  missionMyRequests: missionMyRequestsStore.getInitialState()
});
