<nexnox-web-cardbox paddingClasses="px-2"
                    ghostPaddingClasses="px-3 pb-3"
                    [title]="title"
                    [headerActions]="headerActions"
                    [withAccent]="true"
                    [loading]="loading">
  <div class="pt-2 px-2 pb-3">
    <!-- Working and Travel costs -->
    <div class="d-flex row align-items-center w-100 mb-2 mt-2">
      <h5>{{ 'core-shared.shared.fields.service' | translate }}</h5>
    </div>

    <nexnox-web-sortable-list *ngIf="(missionReportDurationItems$ | async) as items"
                              [items]="items"
                              [editable]="false"
                              [sortable]="false"
                              [disabled]="readonly">
      <ng-template nexnoxWebSortableListItemTemplate let-item="item">
        <nexnox-web-missions-mission-report-article-duration-item *ngIf="item"
                                                                  [item]="item"
                                                                  [title]="item.title"
                                                                  [readonly]="readonly"
                                                                  (durationsChanged)="onDurationsChange($event)">
        </nexnox-web-missions-mission-report-article-duration-item>
      </ng-template>
    </nexnox-web-sortable-list>
  </div>

  <!-- Material-->
  <div *ngIf="!readonly" class="d-flex row align-items-center w-100 mb-2 mt-4 pl-2">
    <h5>{{ 'missions.actions.mission-report-add-article' | translate }}</h5>
  </div>

  <!-- Add Article -->
  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addArticleForm">
      <formly-form class="row w-100"
                   [form]="addArticleForm"
                   [model]="addArticleModelSubject | async"
                   [fields]="addArticlesFields"
                   (modelChange)="addArticleModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              class="p-button-secondary p-button-outlined mr-2"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              [label]="'missions.actions.mission-report-add-article' | translate"
              [disabled]="addArticleForm.invalid"
              (click)="onAddArticle()">
      </button>
    </div>
  </div>

  <!-- Articles -->
  <div *ngIf="(missionReportUsedArticleItems$ | async).length > 0" class="d-flex row align-items-center w-100 mb-2 mt-3 pl-2">
    <h5>{{ 'core-shared.shared.fields.material' | translate }}</h5>
  </div>
  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list *ngIf="(missionReportUsedArticleItems$ | async) as items"
                              [items]="items"
                              [sortable]="true"
                              [editable]="false"
                              [disabled]="readonly"
                              (itemsChange)="onArticlesChange($event)">
      <ng-template nexnoxWebSortableListItemTemplate let-item="item" let-position="position">
        <!-- Article item -->
        <nexnox-web-missions-mission-report-article-item
          [item]="item"
          [title]="item.title"
          [readonly]="readonly"
          (articleChanged)="onArticleChange(position, $event)">
        </nexnox-web-missions-mission-report-article-item>
      </ng-template>
    </nexnox-web-sortable-list>

      <hr class="my-4">
      <div class="d-flex justify-content-end w-100">
        <h5>{{ 'missions.subtitles.mission-report-total' | translate }}: <u>{{ missionReportTotal$ | async }} €</u></h5>
      </div>

  </div>
</nexnox-web-cardbox>

<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [isTitleEditable]="false"
                        [useTitle]="false"
                        [customTitle]="'core-portal.settings.title' | translate"
                        [hideAccent]="true"
                        [disableActions]="true">
</nexnox-web-entity-edit>
