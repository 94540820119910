import {SidebarItem} from '@nexnox-web/core-portal';
import {faChartBar} from '@fortawesome/free-solid-svg-icons/faChartBar';
import {AppPermissions} from '@nexnox-web/core-shared';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faDatabase} from '@fortawesome/free-solid-svg-icons/faDatabase';
import {faMobileAlt} from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faTags} from '@fortawesome/free-solid-svg-icons/faTags';
import {faTasks} from '@fortawesome/free-solid-svg-icons/faTasks';
import {faFileSignature} from '@fortawesome/free-solid-svg-icons/faFileSignature';
import {faInfo} from '@fortawesome/free-solid-svg-icons/faInfo';
import {faComments} from '@fortawesome/free-solid-svg-icons/faComments';
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile';
import {faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';
import {faInbox} from "@fortawesome/free-solid-svg-icons/faInbox";
import {faCrosshairs} from "@fortawesome/free-solid-svg-icons/faCrosshairs";

export function provideSidebarItems(): SidebarItem[] {
  return [
    ...provideManagementSidebarItems(),
    ...provideCommunicationSidebarItems(),
    ...provideInventorySidebarItems(),
    ...provideServiceSidebarItems(),
    ...provideAnalyticsSidebarItems()
  ];
}

function provideManagementSidebarItems(): SidebarItem[] {
  return [
    {
      title: 'core-portal.core.sidebar.menu.dashboard',
      path: '/tech-dashboard',
      module: 'management',
      permission: AppPermissions.DisplayDashboard,
      icon: faChartBar
    },
    {
      title: 'core-portal.core.sidebar.menu.performance',
      path: '/performance',
      module: 'management',
      permission: AppPermissions.DisplayPortalAnalytics,
      icon: faChartBar
    },
    {
      title: 'orga-portal.dashboard.subtitles.dashboard',
      path: '/orga-dashboard',
      module: 'management',
      permission: AppPermissions.ReadGraph,
      icon: faChartBar,
      children: [
        {
          title: 'orga-portal.dashboard.subtitles.dashboard-overview',
          path: '/orga-dashboard/overview',
          module: 'management'
        },
        {
          title: 'orga-portal.dashboard.subtitles.dashboard-task-history',
          path: '/orga-dashboard/task-history',
          module: 'management'
        }
      ]
    },
    {
      title: 'core-portal.core.sidebar.menu.master-data.label',
      path: '/masterdata',
      module: 'management',
      icon: faDatabase,
      children: [
        {
          title: 'core-portal.core.sidebar.menu.master-data.contacts',
          path: '/masterdata/contacts',
          module: 'management',
          permission: AppPermissions.DisplayContacts
        },
        {
          title: 'core-portal.core.sidebar.menu.master-data.functions',
          path: '/masterdata/functions',
          module: 'management',
          permission: AppPermissions.DisplayFunctions
        },
        {
          title: 'core-portal.core.sidebar.menu.master-data.locations',
          path: '/masterdata/locations',
          module: 'management',
          permission: AppPermissions.DisplayLocations
        },
        {
          title: 'core-portal.core.sidebar.menu.master-data.locationgroups',
          path: '/masterdata/locationgroups',
          module: 'management',
          permission: AppPermissions.DisplayLocationGroups
        },
        {
          title: 'core-portal.core.sidebar.menu.master-data.companies',
          path: '/masterdata/companies',
          module: 'management',
          permission: AppPermissions.DisplayCompanies
        },
        {
          title: 'core-portal.core.sidebar.menu.master-data.addresses',
          path: '/masterdata/addresses',
          module: 'management',
          permission: AppPermissions.DisplayAddresses
        },
        {
          title: 'core-portal.core.sidebar.menu.master-data.holidays',
          path: '/masterdata/holidays',
          module: 'management',
          permission: AppPermissions.DisplayHolidays
        }
      ]
    },
    {
      title: 'core-portal.core.sidebar.menu.devices',
      path: '/devices',
      module: 'management',
      icon: faMobileAlt,
      permission: AppPermissions.DisplayDevices
    }
  ];
}

function provideCommunicationSidebarItems(): SidebarItem[] {
  return [
    {
      title: 'core-portal.core.sidebar.menu.tickets',
      path: '/tickets',
      module: 'communication',
      icon: faEnvelope,
      permission: AppPermissions.DisplayTickets
    },
    {
      title: 'core-portal.core.sidebar.menu.issues',
      path: '/issues',
      module: 'communication',
      icon: faInbox,
      permission: AppPermissions.ReadIssue
    },
    {
      title: 'orga-portal.infos.subtitles.info-list',
      path: '/infos',
      module: 'communication',
      icon: faInfo,
      permission: AppPermissions.DisplayInfos
    },
    {
      title: 'orga-portal.chat.subtitles.chat-list',
      path: '/chat',
      module: 'communication',
      icon: faComments,
      permission: AppPermissions.DisplayChats
    },
    {
      title: 'orga-portal.documents.subtitles.document-list',
      path: '/documents',
      module: 'communication',
      icon: faFile,
      permission: AppPermissions.DisplayDocuments
    },
    {
      title: 'orga-portal.knowledge.subtitles.knowledge',
      path: '/knowledge',
      module: 'communication',
      icon: faQuestion,
      permission: AppPermissions.DisplayKnowledge,
      children: [
        {
          title: 'orga-portal.knowledge.subtitles.article-list',
          path: '/knowledge/articles',
          module: 'communication',
          permission: AppPermissions.DisplayKnowledge
        },
        {
          title: 'orga-portal.knowledge.subtitles.exam-list',
          path: '/knowledge/exams',
          module: 'communication',
          permission: AppPermissions.DisplayKnowledgeExam
        },
        {
          title: 'orga-portal.knowledge.subtitles.path-list',
          path: '/knowledge/paths',
          module: 'communication',
          permission: AppPermissions.DisplayKnowledge
        }
      ]
    },
    {
      title: 'orga-portal.tasks.subtitles.task-list',
      path: '/tasks',
      module: 'communication',
      icon: faCheck,
      permission: AppPermissions.DisplayTasks
    }
  ];
}

function provideInventorySidebarItems(): SidebarItem[] {
  return [
    {
      title: 'core-portal.core.sidebar.menu.missions',
      path: '/missions',
      module: 'inventory',
      icon: faClipboardCheck,
      permission: AppPermissions.DisplayMissions,
      children: [
        {
          title: 'missions.subtitles.all-missions',
          path: '/missions/all',
          module: 'inventory',
          permission: AppPermissions.DisplayMissions
        },
        {
          title: 'missions.subtitles.my-requests',
          path: '/missions/my-requests',
          module: 'inventory',
          permission: AppPermissions.DisplayMissions
        }
      ]
    },
    {
      title: 'core-portal.core.sidebar.menu.resources',
      path: '/resources',
      module: 'inventory',
      icon: faFolder,
      permission: AppPermissions.DisplayResources
    },
    {
      title: 'core-portal.core.sidebar.menu.articles',
      path: '/articles',
      module: 'inventory',
      icon: faTags,
      permission: AppPermissions.DisplayArticles
    },
    {
      title: 'core-portal.core.sidebar.menu.resource-tasks',
      path: '/resource-tasks',
      module: 'inventory',
      icon: faTasks,
      permission: AppPermissions.DisplayResourceTasks
    },
    {
      title: 'core-portal.core.sidebar.menu.controls',
      path: '/controls',
      module: 'inventory',
      icon: faCrosshairs,
      permission: AppPermissions.DisplayControls
    },
    {
      title: 'core-portal.core.sidebar.menu.contracts',
      path: '/contracts',
      module: 'inventory',
      icon: faFileSignature,
      permission: AppPermissions.DisplayContracts
    }
  ];
}

function provideAnalyticsSidebarItems(): SidebarItem[] {
  return [
    {
      title: 'data-portal.maintenance.title',
      icon: faUsers,
      path: '/maintenance',
      module: 'analytics',
      permission: AppPermissions.ShowReportForMaintenance
    },
    {
      title: 'data-portal.prime.title',
      icon: faChartBar,
      path: '/prime',
      module: 'analytics',
      permission: AppPermissions.ShowReportForPrime
    }
  ];
}


function provideServiceSidebarItems(): SidebarItem[] {
  return [
    {
      title: 'core-portal.service.tenants.list',
      icon: faUsers,
      path: '/tenants',
      module: 'service',
      permission: AppPermissions.UseExpiredSubscription
    },
    {
      title: 'core-portal.core.sidebar.menu.overwatch',
      icon: faChartBar,
      path: '/overwatch',
      module: 'service',
      permission: AppPermissions.DisplayDashboard
    }
  ];
}
