import {Injectable, Injector} from '@angular/core';
import {
  AppliedTemplateDto,
  ChangedStateOfMissionDto,
  ChangeStateOfMissionDto,
  CoreSharedApiBaseService,
  Filter,
  FollowedUpMissionsDto,
  FollowUpMissionDto,
  FollowUpMissionsDto,
  MissionCompleteDto,
  MissionSimpleDto,
  MissionState,
  MissionType,
  Product,
  ResourceInMissionDto,
  SendMissionMailDto,
  StartingMissionDto
} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpParams} from "@angular/common/http";
import {MissionDistanceListDtoPageOutputDto} from "./mission-bydistance-page.model";


@Injectable()
export class TechPortalFeatureMissionService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'mission');
  }

  public createOneManually<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(`${this.buildPath(parentIds)}/manual`, data).pipe(
      map(response => response.body)
    );
  }

  public changeState(
    id: number | string,
    state: MissionState,
    sendMail?: boolean,
    parentIds?: Array<number | string>,
    reason?: string
  ): Observable<ChangedStateOfMissionDto> {
    return this.httpRequestService.post(`${this.buildPathForOne(id, parentIds)}/changeState`, {
      missionId: id,
      wantedState: state,
      isSendingMail: sendMail,
      ...(reason ? {reason} : null)
    } as ChangeStateOfMissionDto).pipe(
      map(response => response.body)
    );
  }

  public sendMail(id: number | string, parentIds?: Array<number | string>): Observable<SendMissionMailDto> {
    return this.httpRequestService.post(`${this.buildPathForOne(id, parentIds)}/sendMail`, null).pipe(
      map(response => response.body)
    );
  }

  public export(id: number | string, templateId?: number | string, parentIds?: Array<number | string>): Observable<AppliedTemplateDto> {
    return this.httpRequestService.post(`${this.buildPathForOne(id, parentIds)}/export`, {
      templateId
    }).pipe(
      map(response => response.body)
    );
  }

  public complete(
    id: number | string,
    parentIds?: Array<number | string>
  ): Observable<MissionCompleteDto> {
    return this.httpRequestService.post(`${this.buildPathForOne(id, parentIds)}/complete`, null).pipe(
      map(response => response.body)
    );
  }

  public resume(
    id: number | string,
    parentIds?: Array<number | string>
  ): Observable<MissionCompleteDto> {
    return this.httpRequestService.post(`${this.buildPathForOne(id, parentIds)}/resume`, null).pipe(
      map(response => response.body)
    );
  }

  public assignToMe(id: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(id, parentIds)}/editor/me/assign`, null).pipe(
      map(response => response.body)
    );
  }

  public assignTo(id: number | string, contactId: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(id, parentIds)}/editor/${contactId}/assign`, null).pipe(
      map(response => response.body)
    );
  }

  public unassignContact(id: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(id, parentIds)}/editor/unassign`, null).pipe(
      map(response => response.body)
    );
  }

  public assignResource(missionId: number | string, resourceId: number | string, isUpdateRelations: boolean = false): Observable<ResourceInMissionDto> {
    const url = `${this.buildPathForOne(missionId)}/resource/${resourceId}/assign?isSyncingRelation=${isUpdateRelations}`
    return this.httpRequestService.post<ResourceInMissionDto>(url, null).pipe(
      map(response => response.body)
    );
  }

  public addFollowUps(
    id: number | string,
    missions: FollowUpMissionDto[],
    parentIds?: Array<number | string>
  ): Observable<FollowedUpMissionsDto> {
    return this.httpRequestService.post<MissionSimpleDto[]>(`${this.buildPathForOne(id, parentIds)}/followup`, {
      items: missions
    } as FollowUpMissionsDto).pipe(
      map(response => response.body as FollowedUpMissionsDto)
    );
  }

  // distance in meter
  public getMissionsByDistance(
    origin: google.maps.LatLngLiteral,
    distance: number,
    filter: Filter[],
    pageNumber: number,
    pageSize: number): Observable<MissionDistanceListDtoPageOutputDto> {
    const params = new HttpParams()
      .set('lat', origin.lat)
      .set('lng', origin.lng)
      .set('dist', distance)
      .set('pagenumber', pageNumber)
      .set('pagesize', pageSize);
    return this.httpRequestService.post<any>('mission/bydistance/filter', filter, null, params).pipe(
      map(response => response.body),
      take(1)
    );
  }

  public getPreviewAfterResourceChange(type: MissionType, resourceId: number, ticketId?: number): Observable<StartingMissionDto> {
    return this.httpRequestService.post<StartingMissionDto>(`${this.buildPath()}/preview`, {
      type,
      resourceId,
      ticketId
    }).pipe(
      map(response => response.body)
    );
  }
}
