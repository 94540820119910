<nexnox-web-cardbox *ngIf="canDeleteMigrationPaths$ | async"
                    paddingClasses="p-3"
                    [noTitle]="true">
  <div class="d-flex align-items-center justify-space-between">
    <div>
      <span>
      <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true" class="mr-2"></fa-icon>
        {{ 'orga-portal.knowledge.descriptions.can-delete-migration-paths' | translate }}
        &nbsp;
      </span>
      <ng-container *ngFor="let id of learningPathsToDelete$ | async; let last = last;">
        <a [nexnoxWebTenantRouterLink]="'/knowledge/paths/' + id"
           [module]="'communication'"
           target="_blank">
          {{ '#' + id }}
        </a>
        <span *ngIf="!last">&nbsp;</span>
      </ng-container>
    </div>
    <div>
      <button pButton
              type="button"
              class="p-button p-button-primary"
              [label]="'core-shared.shared.select.clear-all' | translate"
              [icon]="faTrash | nexnoxWebFaIconStringPipe"
              (click)="onDeleteAllMigrationPaths()">
      </button>
    </div>
  </div>

</nexnox-web-cardbox>

<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-knowledge-paths-edit #modelComponent
                                   [title]="title"
                                   [id]="id"
                                   [model]="model$ | async"
                                   [readonly]="readonly$ | async"
                                   [creating]="false"
                                   [loading]="loading$ | async"
                                   [stereotyped]="false"
                                   (modelChange)="onModelChange($event)">
  </nexnox-web-knowledge-paths-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="paths"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <!-- Progress -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="hasExamPermission$ | async"
               tabId="progress"
               [heading]="'orga-portal.infos.subtitles.info-progress' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['progress'])">
    <nexnox-web-knowledge-paths-progress *ngIf="passedIds"
                                         [parentIds]="passedIds">
    </nexnox-web-knowledge-paths-progress>
  </ng-template>
</nexnox-web-entity-detail>


