import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AppEntityType,
  ControllerOperationId,
  DataTableDto,
  SortObject,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faFilter} from '@fortawesome/free-solid-svg-icons/faFilter';
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {CorePortalDatatableViewService, LastUsedView, LocalDataTableDto} from "../../services";
import {PredefinedDatatableView} from "../../../../../tokens";
import {DatatableTableColumn, DatatableTableColumnOption, DatatableTableColumnTyping} from "../../models";
import {map, mergeMap, take} from "rxjs/operators";
import {LocalDatatableTableColumn} from "../../sidebars";
import {faUndo} from "@fortawesome/free-solid-svg-icons/faUndo";
import {faClock} from "@fortawesome/free-regular-svg-icons/faClock";

interface DatatableViewDropdownItem {
  menuTitle?: string,
  buttons: DatatableViewDropdownItemButton[]
}

interface DatatableViewDropdownItemButton {
  title: string,
  action?: () => void,
  icon?: IconDefinition,
  hasTimeStamp?: boolean,
  useDivider?: boolean
}

@Component({
  selector: 'nexnox-web-entity-datatable-view-dropdown',
  templateUrl: './entity-datatable-view-dropdown.component.html',
  styleUrls: ['./entity-datatable-view-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableViewDropdownComponent extends UnsubscribeHelper {

  @Input() public componentId: string = null;
  @Input() public enableViews = false;
  @Input() public showResetFiltersOnly = false;
  @Input() public pageOperation: ControllerOperationId = null;
  @Input() public entityType: AppEntityType = null;
  @Input() public columnTypings: DatatableTableColumnTyping[] = [];
  @Input() public datatablePredefinedViewConfig: DataTableDto & PredefinedDatatableView[] = null;

  @Input()
  public set datatableViewService(service: CorePortalDatatableViewService) {
    if (service) {
      this._datatableViewService = service;
      this._datatableViewService.setup();
      this.setup();
    }
  }

  public get datatableViewService(): CorePortalDatatableViewService {
    return this._datatableViewService;
  }

  @Input()
  public set datatableConfig(datatableConfig: LocalDataTableDto) {
    if (this._datatableViewService && this.enableViews && !this.showResetFiltersOnly) {
      this.updateDatatableConfig(datatableConfig);
    } else if (this.showResetFiltersOnly) {
      this.loadDropdown()
    }
  }

  public get datatableConfig(): LocalDataTableDto {
    return this._datatableViewService?.getSelectedDatatableView();
  }

  @Input()
  public set activeColumns(activeColumns: DatatableTableColumn[]) {
    this.activeColumnsSubject.next(activeColumns);
  }

  @Input()
  public set dataColumns(dataColumns: DatatableTableColumn[]) {
    this.dataColumnsSubject.next(dataColumns);
  }

  @Input()
  public set optionalColumns(optionalColumns: DatatableTableColumn[]) {
    this.optionalColumnsSubject.next(optionalColumns);
  }

  @Input()
  public set stereotypeColumns(stereotypeColumns: DatatableTableColumn[]) {
    this.stereotypeColumnsSubject.next(stereotypeColumns);
  }

  @Input()
  public set sortObject(sortObject: SortObject) {
    this.sortObjectSubject.next(sortObject);
  }

  @Output() public openSettings: EventEmitter<void> = new EventEmitter();
  @Output() public resetFilters: EventEmitter<void> = new EventEmitter();

  public faFilter = faFilter;

  public dropdownActions: BehaviorSubject<DatatableViewDropdownItem[]> = new BehaviorSubject<DatatableViewDropdownItem[]>([]);

  private allColumns$: Observable<LocalDatatableTableColumn[]>;
  private lastUsedViews: BehaviorSubject<LastUsedView[]> = new BehaviorSubject<LastUsedView[]>([]);
  private activeColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private dataColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private optionalColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private stereotypeColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);
  private sortObjectSubject: BehaviorSubject<SortObject> = new BehaviorSubject<SortObject>(null);
  private _datatableViewService: CorePortalDatatableViewService;

  public async onPredefinedViewChange(predefinedView: PredefinedDatatableView): Promise<void> {
    let datatableConfig: any = {...predefinedView};
    if (predefinedView?.columns?.length > 0) {
      const allColumns = await firstValueFrom(this.allColumns$.pipe(take(1)));
      const usedColumns = allColumns.filter((column: LocalDatatableTableColumn) =>
        predefinedView.columns?.some(c => column.prop === c));

      datatableConfig = {
        ...datatableConfig,
        columns: !usedColumns ? undefined : usedColumns
          .map(c => this._datatableViewService.mapColumn(c, this.sortObjectSubject.getValue()))
      };
    }
    this.onViewChange(datatableConfig);
  }

  public onViewChange(selectedView?: LocalDataTableDto): void {
    if (this._datatableViewService) {
      if (selectedView?.dataTableId && selectedView?.dataTableId !== this.datatableConfig?.dataTableId) {
        this._datatableViewService.onSelectedDatatableViewChange(selectedView, (datatableView) => {
          this._datatableViewService.setOriginalDatatableView(this.datatableConfig);
          this.applyChanges(datatableView);
        });
      } else {
        this.datatableViewService.setOriginalDatatableView(selectedView);
        this.datatableViewService.setSelectedDatatableView(selectedView);
        this.datatableViewService.setApplied(true);
        this.applyChanges(selectedView);
      }
    }
  }

  public onResetFilters(): void {
    this.resetFilters.emit();
  }

  public loadDropdown(): void {
    this.dropdownActions.next([
      this.getPredefinedViews(),
      {
        menuTitle: 'core-shared.shared.table.subtitles.datatable-views.last-used-views',
        buttons: this.mapDatatableViewsToDropdownItemButtons(this.lastUsedViews.getValue())
      },
      ...this.getStaticDropdownItems(),
    ].filter(i => i.buttons?.length > 0));
  }

  private updateDropdown(config?: LocalDataTableDto): void {
    this.lastUsedViews?.next(config?.lastUsedViews ?? this.datatableConfig?.lastUsedViews);
    this._datatableViewService.lastUsedViewsSubject.next(this.lastUsedViews.getValue());
    this.loadDropdown();
  }

  private applyChanges(newConfig?: LocalDataTableDto): Promise<void> {
    return this._datatableViewService.onSettingsChange(
      {...newConfig, lastUsedViews: this.lastUsedViews.getValue()},
      this.enableViews,
      this.componentId,
      this.pageOperation
    );
  }

  private mapDatatableViewsToDropdownItemButtons(views: LastUsedView[]): DatatableViewDropdownItemButton[] {
    return views?.map(v => ({
      title: v.name ?? '',
      icon: v.usedOn ? faClock : null,
      hasTimeStamp: !!v?.usedOn,
      action: () => this.onViewChange(v)
    })) ?? [];
  }

  private async updateDatatableConfig(datatableConfig: LocalDataTableDto): Promise<void> {
    // This logic does not happen in DatatableViewService because it is available for this dropdown only
    let skipServiceUpdate = false;
    if (!datatableConfig?.lastUsedViews) {
      if (this.lastUsedViews.getValue()?.length > 0) {
        datatableConfig = {
          ...datatableConfig,
          lastUsedViews: this.lastUsedViews.getValue()
        } as DataTableDto;
      } else {
        // Search for recently used views in local storage
        const config = await this.datatableViewService.getDatatableConfig(
          this.pageOperation ?? this.entityType,
          this.componentId,
          datatableConfig
        );
        if (!config?.lastUsedViews) {
          skipServiceUpdate = true;
          await this.applyChanges(datatableConfig);
        }
      }
    }
    if (!skipServiceUpdate) {
      if (this._datatableViewService.getSelectedDatatableView() !== datatableConfig) {
        this._datatableViewService?.setDatatableConfig(datatableConfig);
      }
      this.updateDropdown(datatableConfig);
    }
  }

  private getPredefinedViews(): DatatableViewDropdownItem {
    return {
      menuTitle: 'core-shared.shared.table.subtitles.datatable-views.predefined-views',
      buttons: this.datatablePredefinedViewConfig && !this.showResetFiltersOnly ? [
        ...Object.values(this.datatablePredefinedViewConfig).map((predefinedView: PredefinedDatatableView) =>
          ({
            title: predefinedView.name,
            action: () => {
              if (predefinedView.name !== this.datatableViewService?.currentViewTitle$?.getValue())
                this.onPredefinedViewChange(predefinedView);
            }
          })
        )
      ] : []
    };
  }

  private setup(): void {
    this.allColumns$ = this.dataColumnsSubject.asObservable().pipe(
      mergeMap(dataColumns => this.optionalColumnsSubject.asObservable().pipe(
        mergeMap(optionalColumns => this.stereotypeColumnsSubject.asObservable().pipe(
          map(stereotypeColumns => [
            ...dataColumns.map(x => ({
              ...x,
              name: this.columnTypings.find(y => y.key === x.prop)?.name ?? x.name,
              identifier: x.prop.toString(),
              option: DatatableTableColumnOption.DATA,
              isArchived: false
            })),
            ...optionalColumns.map(x => ({
              ...x,
              name: this.columnTypings.find(y => y.key === x.prop)?.name ?? x.name,
              identifier: x.prop.toString(),
              option: DatatableTableColumnOption.OPTIONAL,
              isArchived: false
            })),
            ...stereotypeColumns.map(x => ({
              ...x,
              identifier: x.customPropertyId.toString(),
              option: DatatableTableColumnOption.STEREOTYPE,
              isArchived: x.isOfArchivedStereotype
            }))
          ] as LocalDatatableTableColumn[])
        ))
      ))
    );
  }

  private getStaticDropdownItems(): DatatableViewDropdownItem[] {
    return [this.getResetToDefaultButton(), this.getOpenSettingsButton()];
  }

  private getResetToDefaultButton(): DatatableViewDropdownItem {
    return {
      menuTitle: null,
      buttons: [
        {
          title: 'core-shared.shared.datatable-settings.actions.reset-to-default',
          icon: faUndo,
          useDivider: true,
          action: () => this.showResetFiltersOnly ? this.onResetFilters() : this.onViewChange(null)
        }
      ]
    };
  }

  private getOpenSettingsButton(): DatatableViewDropdownItem {
    return {
      menuTitle: null,
      buttons: this.enableViews ? [
        {
          title: 'core-shared.shared.table.tooltip.settings',
          icon: faCog,
          useDivider: true,
          action: () => this.openSettings.emit()
        }
      ] : []
    };
  }
}
