import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  MissionCreateTicketComponent,
  MissionDetailComponent, MissionMyRequestsComponent,
  TechPortalFeatureMissionListComponent
} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalComponentConfigGuard, CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {MissionCreateTicketBreadcrumbsResolver} from './resolvers';

export const TECH_PORTAL_MISSIONS_ROUTES: Routes = [
  {
    path: 'all',
    canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TechPortalFeatureMissionListComponent,
    data: {
      permissions: [AppPermissions.DisplayMissions],
      requiredComponentConfig: ['allowList']
    }
  },
  {
    path: 'my-requests',
    component: MissionMyRequestsComponent,
    canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
    data: {
      permissions: [AppPermissions.DisplayMissions],
      requiredComponentConfig: ['allowList']
    }
  },
  {
    path: 'all/:missionId',
    component: MissionDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadMission, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'mission',
      text: 'core-portal.core.header.breadcrumbs.missions.mission-detail'
    },
    children: [
      {path: 'solution', data: {tab: 'solution'}, children: []},
      {path: 'report', data: {tab: 'report'}, children: []},
      {path: 'links', data: {tab: 'links'}, children: []},
      {path: 'attachments', data: {tab: 'attachments'}, children: []},
      {path: 'receipt', data: {tab: 'receipt'}, children: []},
      {path: 'audit', data: {tab: 'audit'}, children: []}
    ]
  },
  {
    path: 'all/:missionId/create-ticket',
    component: MissionCreateTicketComponent,
    canActivate: [CorePortalPermissionGuard],
    data: {
      permissions: [AppPermissions.CreateTicket],
      breadcrumbs: MissionCreateTicketBreadcrumbsResolver,
      text: 'missions.actions.create-ticket'
    }
  },
  // vvv Workaround that prevents wrong focus of sidebar items by redirecting to inventory/missions/all.
  {
    path: ':missionId',
    redirectTo: 'all/:missionId'
  },
  {
    path: '',
    redirectTo: 'all',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_MISSIONS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class MissionsRoutingModule {
}
