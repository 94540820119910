import {ProductEditions} from '@nexnox-web/core-shared';

export const tenantSubscriptionTypeEnumOptions = [
  { label: 'core-portal.service.tenants.subscription-types.2', value: ProductEditions.OrgaClassicModule },
  { label: 'core-portal.service.tenants.subscription-types.11', value: ProductEditions.TechClassicModule },
  { label: 'core-portal.service.tenants.subscription-types.12', value: ProductEditions.OrgaModule },
  { label: 'core-portal.service.tenants.subscription-types.14', value: ProductEditions.MissionClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.15', value: ProductEditions.ConnectedClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.16', value: ProductEditions.SafeClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.17', value: ProductEditions.LinkClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.18', value: ProductEditions.TechModule },
  { label: 'core-portal.service.tenants.subscription-types.20', value: ProductEditions.DataClassicModule },
  { label: 'core-portal.service.tenants.subscription-types.21', value: ProductEditions.ClassificationClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.22', value: ProductEditions.SyncLocationClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.23', value: ProductEditions.DataModule },
  { label: 'core-portal.service.tenants.subscription-types.25', value: ProductEditions.MissionAddon },
  { label: 'core-portal.service.tenants.subscription-types.26', value: ProductEditions.ConnectedAddon },
  { label: 'core-portal.service.tenants.subscription-types.27', value: ProductEditions.SafeAddon },
  { label: 'core-portal.service.tenants.subscription-types.28', value: ProductEditions.LinkAddon },
  { label: 'core-portal.service.tenants.subscription-types.29', value: ProductEditions.ClassificationAddon },
  { label: 'core-portal.service.tenants.subscription-types.31', value: ProductEditions.SyncLocationAddon },
  { label: 'core-portal.service.tenants.subscription-types.32', value: ProductEditions.OrgaTechClassicModule },
  { label: 'core-portal.service.tenants.subscription-types.33', value: ProductEditions.OrgaTechModule },
  { label: 'core-portal.service.tenants.subscription-types.34', value: ProductEditions.SyncReceiptsClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.35', value: ProductEditions.SyncContactsClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.36', value: ProductEditions.SyncDriveClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.37', value: ProductEditions.SyncReceiptsAddon },
  { label: 'core-portal.service.tenants.subscription-types.38', value: ProductEditions.SyncContactsAddon },
  { label: 'core-portal.service.tenants.subscription-types.39', value: ProductEditions.SyncDriveAddon },
  { label: 'core-portal.service.tenants.subscription-types.42', value: ProductEditions.NotificationClassicAddon },
  { label: 'core-portal.service.tenants.subscription-types.43', value: ProductEditions.NotificationAddon },
  { label: 'core-portal.service.tenants.subscription-types.44', value: ProductEditions.TeamModule },
  { label: 'core-portal.service.tenants.subscription-types.45', value: ProductEditions.MdmClassicModule },
  { label: 'core-portal.service.tenants.subscription-types.46', value: ProductEditions.PrimeReportAddon },
  { label: 'core-portal.service.tenants.subscription-types.47', value: ProductEditions.MaintenanceReportAddon }
];

export const nonClassicTenantSubscriptionTypeEnumOptions = [
  { label: 'core-portal.service.tenants.subscription-types.12', value: ProductEditions.OrgaModule },
  { label: 'core-portal.service.tenants.subscription-types.18', value: ProductEditions.TechModule },
  { label: 'core-portal.service.tenants.subscription-types.23', value: ProductEditions.DataModule },
  { label: 'core-portal.service.tenants.subscription-types.25', value: ProductEditions.MissionAddon },
  { label: 'core-portal.service.tenants.subscription-types.26', value: ProductEditions.ConnectedAddon },
  { label: 'core-portal.service.tenants.subscription-types.27', value: ProductEditions.SafeAddon },
  { label: 'core-portal.service.tenants.subscription-types.28', value: ProductEditions.LinkAddon },
  { label: 'core-portal.service.tenants.subscription-types.29', value: ProductEditions.ClassificationAddon },
  { label: 'core-portal.service.tenants.subscription-types.31', value: ProductEditions.SyncLocationAddon },
  { label: 'core-portal.service.tenants.subscription-types.33', value: ProductEditions.OrgaTechModule },
  { label: 'core-portal.service.tenants.subscription-types.37', value: ProductEditions.SyncReceiptsAddon },
  { label: 'core-portal.service.tenants.subscription-types.38', value: ProductEditions.SyncContactsAddon },
  { label: 'core-portal.service.tenants.subscription-types.39', value: ProductEditions.SyncDriveAddon },
  { label: 'core-portal.service.tenants.subscription-types.43', value: ProductEditions.NotificationAddon },
  { label: 'core-portal.service.tenants.subscription-types.44', value: ProductEditions.TeamModule },
  { label: 'core-portal.service.tenants.subscription-types.46', value: ProductEditions.PrimeReportAddon },
  { label: 'core-portal.service.tenants.subscription-types.47', value: ProductEditions.MaintenanceReportAddon }
];
