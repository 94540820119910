import {Injectable, Injector} from '@angular/core';
import {authStore} from '@nexnox-web/core-portal';
import {AppEntityType, ContactSimpleDto} from '@nexnox-web/core-shared';
import {EntityXsStoreState} from '@nexnox-web/core-store';
import {createEffect, ofType} from '@ngrx/effects';
import {Action, createSelector, select} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {selectMissionsState} from '../../missions.selectors';
import {TechPortalFeatureMissionService} from '../../services';
import {MissionDetailXsStore, MissionDetailXsStoreActions} from './mission-detail.xs-store';
import {ILocalMissionDto} from "../../../components";

export interface MissionDetailStoreState extends EntityXsStoreState<ILocalMissionDto> {
}

/* istanbul ignore next */
export const missionDetailStore = new MissionDetailXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Detail',
  stateSelector: createSelector(selectMissionsState, state => state.missionDetail),
  serviceType: TechPortalFeatureMissionService,
  entityType: AppEntityType.Mission
});

@Injectable()
export class MissionDetailStoreEffects extends missionDetailStore.effects {
  public changeState$: any;
  public changeStateSuccess$: any;

  public sendMail$: any;
  public sendMailSuccess$: any;

  public export$: any;
  public exportSuccess$: any;

  public complete$: any;
  public completeSuccess$: any;

  public resume$: any;
  public resumeSuccess$: any;

  public assignContactToMe$: any;
  public assignContactToMeSuccess$: any;

  public assignContact$: any;
  public assignContactSuccess$: any;

  public unassignContact$: any;
  public unassignContactSuccess$: any;

  public assignResource$: any;
  public assignResourceSuccess$: any;

  public addFollowUps$: any;
  public addFollowUpsSuccess$: any;

  public updateSolutionContact$: any;
  public updateSolutionContactSuccess$: any;

  protected declare actions: MissionDetailXsStoreActions;
  protected declare service: TechPortalFeatureMissionService;

  constructor(
    protected injector: Injector,
    private translate: TranslateService
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.changeState$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeState),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([{state, sendMail, reason}, entity]) => this.service.changeState(entity.missionId, state, sendMail, null, reason).pipe(
        map(({plannedStart, plannedEnd, actualStart, actualEnd, lastEmailSend}) => this.actions.changeStateSuccess({
          state,
          plannedStart,
          plannedEnd,
          actualStart,
          actualEnd,
          lastEmailSend,
          name: this.translate.instant(`missions.mission-states.${state}`)
        })),
        catchError(error => of(this.actions.error({error, action: this.actions.changeState})))
      ))
    ));

    this.changeStateSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeStateSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.sendMail$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.sendMail),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([_, entity]) => this.service.sendMail(entity.missionId).pipe(
        map(({lastEmailSend}) => this.actions.sendMailSuccess({lastEmailSend})),
        catchError(error => of(this.actions.error({error, action: this.actions.sendMail})))
      ))
    ));

    this.sendMailSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.sendMailSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.export$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.export),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([{templateId}, entity]) => this.service.export(entity.missionId, templateId).pipe(
        map(({uri}) => this.actions.exportSuccess({uri, templateId})),
        catchError(error => of(this.actions.error({error, action: this.actions.export})))
      ))
    ));

    this.exportSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.exportSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});


    this.complete$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.complete),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([_, entity]) => this.service.complete(entity.missionId).pipe(
        map((mission) => this.actions.completeSuccess(mission)),
        catchError(error => of(this.actions.error({error, action: this.actions.complete})))
      ))
    ));

    this.completeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.completeSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});


    this.resume$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.resume),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([_, entity]) => this.service.resume(entity.missionId).pipe(
        map(() => this.actions.resumeSuccess()),
        catchError(error => of(this.actions.error({error, action: this.actions.resume})))
      ))
    ));

    this.resumeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.resumeSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});


    this.assignContactToMe$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContactToMe),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{parentIds}, {missionId}]) => this.service.assignToMe(missionId, parentIds).pipe(
        withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
        map(([_, tenant]) => this.actions.assignContactToMeSuccess({
          contact: (tenant?.names ?? []).length ? tenant.names[0] : null,
          parentIds
        })),
        catchError(error => of(this.actions.error({error, action: this.actions.assignContactToMe})))
      ))
    ));

    this.assignContactToMeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContactToMeSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.assignContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContact),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{
        contact,
        parentIds
      }, {missionId}]) => this.service.assignTo(missionId, contact.contactId, parentIds).pipe(
        map(() => this.actions.assignContactSuccess({contact, parentIds})),
        catchError(error => of(this.actions.error({error, action: this.actions.assignContact})))
      ))
    ));

    this.assignContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.unassignContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.unassignContact),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{parentIds}, {missionId}]) => this.service.unassignContact(missionId, parentIds).pipe(
        map(() => this.actions.unassignContactSuccess({parentIds})),
        catchError(error => of(this.actions.error({error, action: this.actions.unassignContact})))
      ))
    ));

    this.unassignContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.unassignContactSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.assignResource$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignResource),
      exhaustMap(({missionId, resource, isUpdateRelations}) => this.service.assignResource(missionId, resource?.resourceId, isUpdateRelations).pipe(
        map((response) => this.actions.assignResourceSuccess({missionId, isUpdateRelations, resource: response})),
        catchError(error => of(this.actions.error({error, action: this.actions.assignResource})))
      ))
    ));

    this.assignResourceSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.assignResourceSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    /* Add follow ups */
    this.addFollowUps$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.addFollowUps),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      exhaustMap(([{
        missions,
        parentIds
      }, {missionId}]) => this.service.addFollowUps(missionId, missions, parentIds).pipe(
        map(newMissions => this.actions.addFollowUpsSuccess({missions: newMissions.items})),
        catchError(error => of(this.actions.error({error, action: this.actions.addFollowUps})))
      ))
    ));
    this.addFollowUpsSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.addFollowUpsSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    /* Add follow ups */
    this.updateSolutionContact$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.updateSolutionContactOffline),
      map(({contact}) => this.actions.updateSolutionContactOfflineSuccess({contact})),
    ));
    this.updateSolutionContactSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.updateSolutionContactOfflineSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.changeStateSuccess, action, ({name}) => this.changeStateSuccessActionCallback(name));
    this.checkAction(this.actions.sendMailSuccess, action, () => this.sendMailSuccessActionCallback());
    this.checkAction(this.actions.exportSuccess, action, ({uri}) => this.exportSuccessActionCallback(uri));
    this.checkAction(this.actions.completeSuccess, action, () => this.completeSuccessActionCallback());
    this.checkAction(this.actions.resumeSuccess, action, () => this.resumeSuccessActionCallback());
    this.checkAction(this.actions.assignContactToMeSuccess, action, () => this.assignToMeSuccessActionCallback());
    this.checkAction(this.actions.assignContactSuccess, action, ({contact}) => this.assignToSuccessActionCallback(contact));
    this.checkAction(this.actions.unassignContactSuccess, action, () => this.unassignSuccessActionCallback());
    this.checkAction(this.actions.addFollowUpsSuccess, action, () => this.addFollowUpsSuccessActionCallback());
    this.checkAction(this.actions.assignResourceSuccess, action, () => this.assignResourceSuccessCallback());
  }

  protected changeStateSuccessActionCallback(name: string): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.state-changed', {name});
  }

  protected sendMailSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.mail-sent');
  }

  protected exportSuccessActionCallback(uri: string): void {
    window.open(uri, '_blank');
  }

  protected completeSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.completed');
  }

  protected resumeSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.resumed');
  }

  protected assignToMeSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.assign-to-me');
  }

  protected assignToSuccessActionCallback(contact: ContactSimpleDto): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.assign-to', {contact: contact?.displayName});
  }

  protected unassignSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.unassign');
  }

  protected assignResourceSuccessCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-resource');
  }

  protected addFollowUpsSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.successors-added');
  }
}
