import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown';
import {faCaretUp} from '@fortawesome/free-solid-svg-icons/faCaretUp';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {ArticleUsageDto} from '@nexnox-web/core-shared';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'nexnox-web-missions-mission-report-article-item',
  templateUrl: './mission-report-article-item.component.html',
  styleUrls: ['./mission-report-article-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionReportArticleItemComponent {
  @Input() public item: any;
  @Input() public readonly = true;

  public form: FormGroup = new FormGroup({});

  @Output() public articleChanged: EventEmitter<{ articleUsage: ArticleUsageDto, valid: boolean }> =
    new EventEmitter<{ articleUsage: ArticleUsageDto, valid: boolean }>();

  public faPlus = faPlus;
  public faCaretUp = faCaretUp;
  public faCaretDown = faCaretDown;

  public get total(): number {
    const price = Number(this.item?.model?.price);
    if (!price || price === 0) return 0;
    const count = Number(this.item?.model?.count);
    if (!count || count === 0) return 0;
    return (price * count) ?? 0;
  }

  public onArticleChange(articleUsage: ArticleUsageDto): void {
    this.articleChanged.emit({articleUsage, valid: this.form.valid});
  }

  public onChangeArticleNote(note: string): void {
    this.onArticleChange({...this.item.model, note});
  }
}
