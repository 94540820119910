<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
  <div class="row">
    <div class="col-xl-8 pl-0 pr-0 pr-xl-2">
      <nexnox-web-missions-mission-edit #modelComponent
                                        [title]="title"
                                        [id]="id"
                                        [model]="model$ | async"
                                        [readonly]="(readonly$ | async) || (isClosed$ | async)"
                                        [creating]="false"
                                        [loading]="loading$ | async"
                                        [stereotypes$]="stereotypes$"
                                        [assignContactLoading$]="loadingAssignContact$"
                                        [assignResourceLoading$]="loadingAssignRessource$"
                                        (assignContact)="onAssignContact($event)"
                                        (unassignContact)="onUnassignContact()"
                                        (assignResource)="onAssignResource($event)"
                                        (modelChange)="onModelChange($event)">
      </nexnox-web-missions-mission-edit>
    </div>

    <div class="col-xl-4 pl-0 pl-xl-2 pr-0">
      <nexnox-web-missions-mission-status [model$]="model$"
                                          [quest$]="quest$"
                                          [loading]="loading$ | async"
                                          [loadingQuest]="loadingQuest$ | async"
                                          [readonly]="readonly$ | async"
                                          (openQuestSidebar)="onOpenEditorQuestSidebar($event)">
      </nexnox-web-missions-mission-status>

      <nexnox-web-mission-ticket-issue-status *ngIf="primaryIssue$ | async"
                                              [issue$]="primaryIssue$">
      </nexnox-web-mission-ticket-issue-status>

      <nexnox-web-missions-mission-addresses-edit #modelComponent
                                                  [title]="'missions.subtitles.mission-addresses' | translate"
                                                  [model]="model$ | async"
                                                  [readonly]="readonly$ | async"
                                                  [loading]="loading$ | async"
                                                  [allowedAddresses$]="allowedAddresses$"
                                                  [stereotyped]="false"
                                                  (modelChange)="onModelChange($event)">
      </nexnox-web-missions-mission-addresses-edit>

      <nexnox-web-missions-mission-ticket-list *ngIf="(missionType$ | async) === missionTypes.Task"
                                               [model$]="model$"
                                               [loading]="loading$ | async">
      </nexnox-web-missions-mission-ticket-list>

      <ng-container *ngIf="(hasPredecessor$ | async) === false">
        <nexnox-web-missions-mission-successors-edit [successors$]="successors$"
                                                     [readonly]="readonly$ | async"
                                                     [loading]="loading$ | async"
                                                     (successorsAdded)="onAddSuccessors($event)">
        </nexnox-web-missions-mission-successors-edit>
      </ng-container>
      <ng-container *ngIf="model$ | async as model">
        <nexnox-web-entity-labels *ngIf="displayLabelPermission$ | async"
                                  [tenantId]="tenantId$ | async"
                                  [entityId]="this.id"
                                  [labelType]="labelType"
                                  [labels]="labels$ | async"
                                  [loading]="loading$ | async"
                                  (labelsChange)="onLabelsChange($event, model)">
        </nexnox-web-entity-labels>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="(readonly$ | async) === false && (loading$ | async) === false">
  <div *ngIf="(isClosed$ | async)"
       class="alert alert-warning">
    <fa-icon [icon]="faExclamationTriangle"
             [fixedWidth]="true"></fa-icon>
    {{ 'missions.descriptions.mission-closed' | translate }}
  </div>
</ng-container>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="mission"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']"
                          [ready$]="loaded$">
  <!-- Solution Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="solution"
               *ngIf="readSolutionPermission$ | async"
               [heading]="'solutions.subtitles.solution' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['solution'])">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-missions-mission-solution *ngIf="passedIds"
                                            [isEmbedded]="true"
                                            [canCreate]="(isClosed$ | async) === false"
                                            [solution]="$any(model?.solution)"
                                            [relationId]="model?.missionId"
                                            [missionType]="missionType$ | async"
                                            relationIdProperty="missionId">
      </nexnox-web-missions-mission-solution>
    </ng-container>
  </ng-template>

  <!-- Mission Report Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="report"
               [heading]="'missions.subtitles.mission-report' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['report'])">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-missions-mission-report *ngIf="model.missionId"
                                          [missionId]="model.missionId"
                                          [reportId]="model.reportId"
                                          [mission]="model">
      </nexnox-web-missions-mission-report>
    </ng-container>
  </ng-template>

  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['links'])">
    <nexnox-web-tech-links *ngIf="model$ | async as model"
                           #linksComponent
                           #modelComponent
                           [links$]="links$"
                           [entityId]="model.missionId"
                           [type]="linkedElementTypes.Mission"
                           [readonly]="(readonly$ | async) || (isClosed$ | async)"
                           [loading]="loading$ | async"
                           (linksChange)="onLinksChange($event, model)">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template nexnoxWebTabTemplate
               tabId="attachments"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsString$ | async)"
               [hasError]="(attachmentsComponent ? !attachmentsComponent.isModelValid() : false) || (solutionAttachmentsComponent ? !solutionAttachmentsComponent.isModelValid() : false)"
               (selectTab)="onNavigateToTab(['attachments'])">
    <nexnox-web-tech-attachments *ngIf="model$ | async as model"
                                 #attachmentsComponent
                                 #modelComponent
                                 [attachments$]="attachments$"
                                 [readonly]="readonly$ | async"
                                 [loading]="loading$ | async"
                                 (attachmentsChange)="onAttachmentsChange($event, model)">
    </nexnox-web-tech-attachments>

    <nexnox-web-tech-attachments *ngIf="readSolutionPermission$ | async"
                                 #solutionAttachmentsComponent
                                 #modelComponent
                                 cardboxTitle="tech-portal.subtitles.solution-attachments"
                                 [attachments$]="solutionAttachments$"
                                 [readonly]="true"
                                 [loading]="loading$ | async">
    </nexnox-web-tech-attachments>
  </ng-template>

  <!-- Mission Receipt Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="receipt"
               *ngIf="displayMissionReceiptsPermission$ | async"
               [heading]="'missions.subtitles.mission-receipt' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['receipt'])">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-missions-mission-receipt *ngIf="model.missionId"
                                           [missionId]="model.missionId">
      </nexnox-web-missions-mission-receipt>
    </ng-container>
  </ng-template>

  <!-- Mission Audit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="audit"
               *ngIf="readAuditPermission$ | async"
               [heading]="'core-portal.audit.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['audit'])">
    <nexnox-web-missions-mission-audit *ngIf="passedIds"
                                       [isEmbedded]="true"
                                       [parentIds]="[entityTypes.Mission, passedIds[0]]">
    </nexnox-web-missions-mission-audit>
  </ng-template>
</nexnox-web-entity-detail>

<nexnox-web-missions-editor-suggestions-sidebar #editorSuggestionsSidebar
                                                [currentSolutionContact$]="solutionContact$"
                                                [editors$]="editors$"
                                                [loading]="loadingQuest$ | async"
                                                (commissionContact)="onCommissionContact($event)"
                                                (startQuest)="onStartEditorQuest($event)">
</nexnox-web-missions-editor-suggestions-sidebar>

<nexnox-web-missions-editor-quest-sidebar #editorQuestSidebar
                                          [currentSolutionContact$]="solutionContact$"
                                          [quest$]="quest$"
                                          [loading]="loadingQuest$ | async"
                                          (startQuest)="onStartEditorQuest($event)"
                                          (cancelQuest)="onCancelEditorQuest($event)"
                                          (restartQuest)="onRestartEditorQuest($event)"
                                          (acceptContact)="onAcceptContact($event)"
                                          (declineContact)="onDeclineContact($event)">
</nexnox-web-missions-editor-quest-sidebar>


