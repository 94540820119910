import {DocumentTemplateContextType} from '@nexnox-web/core-shared';
import {CorePortalFormlyNgSelectOption} from '@nexnox-web/core-portal';

export const documentContextTypeForTicketsEnumOptions: CorePortalFormlyNgSelectOption[] = [
  /* Ticket */
  {
    value: DocumentTemplateContextType.ExportTicket,
    label: 'core-portal.settings.document-template-context-types.1'
  },
]

export const documentContextTypeForMissionsEnumOptions: CorePortalFormlyNgSelectOption[] = [
  /* Mission */
  {
    value: DocumentTemplateContextType.MissionBase,
    label: 'core-portal.settings.document-template-context-types.10'
  },
  // Export Mission
  {
    value: DocumentTemplateContextType.ExportMission,
    label: 'core-portal.settings.document-template-context-types.6',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.6',
    depth: 1
  },
  {
    value: DocumentTemplateContextType.ExportMissionByTicket,
    label: 'core-portal.settings.document-template-context-types.2',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.2',
    depth: 2
  },
  {
    value: DocumentTemplateContextType.ExportMissionByTask,
    label: 'core-portal.settings.document-template-context-types.4',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.4',
    depth: 2
  },
  // Activate Mission
  {
    value: DocumentTemplateContextType.ActivationMission,
    label: 'core-portal.settings.document-template-context-types.8',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.8',
    depth: 1
  },
  {
    value: DocumentTemplateContextType.ActivationMissionByTicket,
    label: 'core-portal.settings.document-template-context-types.3',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.3',
    depth: 2
  },
  {
    value: DocumentTemplateContextType.ActivationMissionByTask,
    label: 'core-portal.settings.document-template-context-types.5',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.5',
    depth: 2
  },
]

export const documentContextTypeForFormsEnumOptions: CorePortalFormlyNgSelectOption[] = [

  // Filled form
  {
    value: DocumentTemplateContextType.FilledForm,
    label: 'core-portal.settings.document-template-context-types.11',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.11',
  },
  {
    value: DocumentTemplateContextType.FilledFormByTask,
    label: 'core-portal.settings.document-template-context-types.13',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.13',
    depth: 1
  },
  {
    value: DocumentTemplateContextType.FilledFormByChat,
    label: 'core-portal.settings.document-template-context-types.12',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.12',
    depth: 1
  }
];

export const documentContextTypeForIssuesEnumOptions: CorePortalFormlyNgSelectOption[] = [

  // Issue
  {
    value: DocumentTemplateContextType.Issue,
    label: 'core-portal.settings.document-template-context-types.14',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.14',
  },
  {
    value: DocumentTemplateContextType.MessageIssue,
    label: 'core-portal.settings.document-template-context-types.15',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.15',
  },
  {
    value: DocumentTemplateContextType.MissionIssue,
    label: 'core-portal.settings.document-template-context-types.17',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.17',
  },
  {
    value: DocumentTemplateContextType.TicketIssue,
    label: 'core-portal.settings.document-template-context-types.16',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.16',
  },
  {
    value: DocumentTemplateContextType.WinterhalterIssue,
    label: 'core-portal.settings.document-template-context-types.18',
    readonlyLabel: 'core-portal.settings.document-template-context-types-readonly.18',
  }
];

// All together for several detail pages that contain contextType dropdowns, eg. ticket-detail
export const documentContextTypeEnumOptions: CorePortalFormlyNgSelectOption[] = [
  ...documentContextTypeForTicketsEnumOptions,
  ...documentContextTypeForMissionsEnumOptions,
  ...documentContextTypeForFormsEnumOptions,
  ...documentContextTypeForIssuesEnumOptions
]
