import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {CoreSharedModalBaseComponent} from "@nexnox-web/core-shared";
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";

@Component({
  selector: 'nexnox-web-mission-cancel-reason-modal',
  templateUrl: './mission-cancel-reason-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionCancelReasonModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  public form: FormGroup;
  public model: { reason: string };
  public fields: FormlyFieldConfig[];

  constructor(
    protected injector: Injector
  ) {
    super(injector);

    this.preConfirmFn = async () => await this.onPreConfirm();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as { reason: string };
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'reason',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 px-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'missions.subtitles.cancel-reason',
          },
          type: 'text'
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected onPreConfirm(): any {
    return this.model;
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();

    this.disableConfirmButton();
  }
}
